import * as React from 'react'
import {
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from '@silevis/reactgrid'
import { Icon, Tooltip } from 'src/js/components/static';

const tooltipButton = (
  <span>
      <Icon
          icon="info_bubble"
          fill="red"
          width="12"
          height="12"
          top={3}
          tooltip
      />
  </span>
);

export class ErrorCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text)
    return { ...uncertainCell, text, value }
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return { cell, enableEditMode: true }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    }
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text })
  }

  render() {
    return (
      <div>
        <Tooltip
          placement="right"
          button={tooltipButton}
        >
            Please fix all errors.
        </Tooltip>
      </div>
    )
  }
}
