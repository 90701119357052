import axios from 'axios';
import fileDownload from 'src/js/lib/fileDownload';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {string} id
 * @param  {String} gtin
 * @param  {String} targetMarket
 * @param  {string} language
 * @return {Promise}
 */
export function fetchPreviousBatches(gtin, targetMarket, language) {
    return axios
        .request({
            url: [
                config.urls.redemption,
                'variants',
                gtin,
                targetMarket,
                language,
                'batches'
            ].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Generates a batch of codes based off of GTIN, target market and language
 *
 * @param  {String} gtin
 * @param  {String} targetMarket
 * @param  {String} language
 * @param  {object} count
 * @return {Promise}
 */
export function forceGenerateBatch(gtin, targetMarket, language, count) {
    return axios
        .request({
            url: [
                config.urls.redemption,
                'variants',
                gtin,
                targetMarket,
                language,
                'generate'
            ].join('/'),
            method: 'post',
            data: count
        })
        .then(responseHandler);
}

/**
 * Download a previously generated batch of GTIN's
 *
 * @param  {String} batchId
 * @param  {String} name
 * @return {Promise}
 */
export function forceDownloadBatch(batchId, name) {
    return axios
        .request({
            url: [
                config.urls.redemption,
                'batches',
                batchId,
                'codes',
                'csv'
            ].join('/'),
            method: 'get'
        })
        .then(response =>
            fileDownload(responseHandler(response), `${name}-codes.csv`)
        );
}

/**
 * Check the status of a batch request
 *
 * @param  {String} batchId
 * @return {Promise}
 */
export function checkBatchProgress(batchId) {
    return axios
        .request({
            url: [config.urls.redemption, 'batches', batchId, 'status'].join(
                '/'
            ),
            method: 'get'
        })
        .then(responseHandler);
}
