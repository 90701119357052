export const passwordErrors = {
    NO_MATCH: 'The password you have entered does not match.',
    NO_VALUE: 'Please enter in a value for your password',
    MIN_LEN: 'Your password must be a minimum length of 8 characters'
};

export const csvFileErrors = {
    NO_TYPE_MATCH: 'Incorrect file type! Please upload a CSV file.',
    MAX_SIZE: 'You may only upload a maximum of 10mb at a time.',
    EMPTY_ROWS: 'CSV files must have two or more rows.',
    MAX_ROWS: 'CSV files must have a maximum of 20,000 rows.',
    DATE_FORMAT: 'Please make sure dates follow the valid formats.',
    MAX_FILE_LIMIT: 'Please upload one csv file at a time.'
};
