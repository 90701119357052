export const newClientObject = {
    company_name: '',
    type: 'Licensee',
    email: '',
    phone: '',
    direct_phone: '',
    company_number: '',
    business_unit: '',
    title: '',
    job_title: '',
    first_name: '',
    last_name: '',
    address_line1: '',
    address_country: '',
    address_city: '',
    address_postcode: ''
};

export const newClientObjectValidations = {
    company_name: {
        required: true
    },
    type: {
        required: true
    },
    email: {
        required: true
    },
    phone: {
        required: true
    },
    direct_phone: {
        required: true
    },
    business_unit: {
        required: false
    },
    title: {
        required: true
    },
    job_title: {
        required: true
    },
    first_name: {
        required: true
    },
    last_name: {
        required: true
    },
    address_line1: {
        required: true
    },
    address_country: {
        required: true
    },
    address_city: {
        required: true
    },
    address_postcode: {
        required: true
    }
};

export const userObject = {
    user_id: '',
    email: '',
    information: {}
};

export const approvedMessage = {
    approved: {
        licensee: 'One or more concepts have been linked to this product',
        licensor: 'A concept has been successfully linked to this product'
    },
    linked: {
        licensee: 'One or more concepts may match this product, but have not yet been linked',
        licensor: 'There are no concepts linked to this product'
    },
    nodata: {
        licensee: 'There is no concept information available for this product',
        licensor: 'There are no concepts linked to this product'
    }
};

export const registeredMessage = {
    registered: 'Your product is registered with your licensor.',
    'not registered':
        'This product has not been registered with your Licensor.',
    'partly registered':
        'You have made some changes to this product’s details since it was registered with your Licensor.'
};