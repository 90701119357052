import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { Icon, Tooltip } from 'src/js/components/static';

export default class AnimatedInput extends Component {
    constructor(props) {
        super(props);

        const { value } = this.props;

        this.state = {
            selected: false,
            hasValue: value && value.length > 0
        };
    }

    onFocusHandler() {
        this.setState({ selected: true });
    }

    onBlurHandler() {
        this.setState({ selected: false });
        this.checkValue();
    }

    checkValue() {
        if (this.props.value === '') {
            this.setState({ hasValue: false });
        } else {
            this.setState({ hasValue: true });
        }
    }

    onChangeHandler() {
        this.checkValue();
    }

    focusInput() {
        // Helps to focus on input when we click on the label
        ReactDOM.findDOMNode(this.textInput).focus();
    }

    selectOnChange(e) {
        this.setState({ hasValue: e.target.value !== '' });
        if (this.props.onChange) this.props.onChange(e);
    }

    renderType() {
        const {
            select,
            value,
            children,
            className,
            borderBottom,
            noBorder,
            required,
            placeholder,
            alwaysontop,
            error
        } = this.props;
        const { hasValue, selected } = this.state;
        const cx = classnames({
            'c-animated-input__select': select,
            'c-animated-input__selected':
                hasValue ||
                selected ||
                (value && value.length > 0) ||
                alwaysontop,
            'c-animated-input__select-placeholder': !value
        });

        const inputCx = classnames(
            'c-input-text',
            'c-input-text--full-width',
            className,
            {
                'c-input-text--border-bottom-only': borderBottom,
                'c-input-text--no-border': noBorder,
                'c-animated-input__error': error,
                'c-animated-input__selected':
                    hasValue ||
                    selected ||
                    (value && value.length > 0) ||
                    alwaysontop
            }
        );

        const inputProps = Object.assign({}, this.props);
        // TODO: Got to be a better way to do this
        delete inputProps.borderBottom;
        delete inputProps.purpleBottom;
        delete inputProps.noBorder;
        delete inputProps.viewPassType;
        delete inputProps.viewPass;
        delete inputProps.errorMessage;
        delete inputProps.error;

        if (select) {
            return (
                <select
                    ref={input => {
                        this.textInput = input;
                    }}
                    onClick={() => this.setState({ selected: true })}
                    onBlur={() => this.setState({ selected: false })}
                    onChange={e => this.selectOnChange(e)}
                    value={value || ''}
                    className={cx}
                    required={required}
                >
                    {children}
                </select>
            );
        }

        return (
            <input
                className={inputCx}
                onFocus={() => this.onFocusHandler()}
                onBlur={() => this.onBlurHandler()}
                onChange={() => this.onChangeHandler()}
                ref={input => {
                    this.textInput = input;
                }}
                placeholder={placeholder}
                {...inputProps}
                required={required}
            />
        );
    }

    render() {
        const {
            className,
            select,
            icon,
            label,
            purpleBottom,
            error,
            type,
            value,
            tooltip,
            viewPass,
            viewPassType,
            required,
            errorMessage,
            nolabel
        } = this.props;
        const { selected, hasValue } = this.state;

        const wrapperCx = classnames('c-animated-input__holder', className, {
            'c-animated-input__select-input': select,
            'c-animated-input__purple-bottom': purpleBottom,
            'c-animated-input__purple-bottom--active':
                purpleBottom &&
                (hasValue || selected || (value && value.length > 0)),
            'c-animated-input__error': error
        });

        const inputLabelCx = classnames('c-animated-input__label', {
            'c-label--with-tooltip': !!tooltip
        });

        if (type === 'hidden') return this.renderType();

        const ttButton = (
            <div className="c-animated-input__password-toggle-trig">
                <Icon
                    fill="grey13"
                    icon={viewPass ? 'SHOW_PASS' : 'HIDE_PASS'}
                    size="20"
                    description={viewPass ? 'Hide Password' : 'Show Password'}
                    onClick={viewPassType}
                    className=""
                />
            </div>
        );

        return (
            <div className="u-relative">
                <div className={wrapperCx} onClick={() => this.focusInput()}>
                    {this.renderType()}
                    {!nolabel && (
                        <span className={inputLabelCx}>
                            {icon && (
                                <Icon
                                    className="c-icon c-icon--animated"
                                    icon={icon}
                                />
                            )}
                            {label}
                            {required && (
                                <sup
                                    className="u-color-pink"
                                    style={{ margin: '0 5px' }}
                                >
                                    *
                                </sup>
                            )}
                            {tooltip}
                        </span>
                    )}
                </div>
                {errorMessage && (
                    <div className="c-animated-input__error-message">
                        {errorMessage}
                    </div>
                )}
                {viewPassType && (
                    <Tooltip
                        button={ttButton}
                        className="c-animated-input__password-toggle"
                    >
                        {viewPass ? 'Hide Password' : 'Show Password'}
                    </Tooltip>
                )}
            </div>
        );
    }
}
