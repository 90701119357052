import React from 'react';
import { Component } from 'react';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="c-error-boundary">
                    {/*<h1>An error has occured</h1>*/}
                    {/*<h3>Try refreshing the page or go back Home</h3>*/}
                    {/*<div className="btn-container">*/}
                        {/*<button*/}
                            {/*className="btn"*/}
                            {/*onClick={() => window.location.reload()}*/}
                        {/*>*/}
                            {/*Refresh*/}
                        {/*</button>*/}
                        {/*<a className="btn" href="/">*/}
                            {/*Home*/}
                        {/*</a>*/}
                    {/*</div>*/}
                </div>
            );
        }

        return this.props.children;
    }
}
