import { setMyRecords } from 'src/js/actions/xelacore';

import isEmpty from 'lodash/isEmpty';

// We are working with the MyRecords root on the Redux Store
// All main actions are set here
// There are fetch records, set counts, set checked

export function setInitialCount(count) {
    return (dispatch) => {
        return dispatch(
            setMyRecords({ recordsCount: { initialCount: count } })
        );
    };
}

export function checkRecordsCount(newRecordsCount, initialCount, filters) {
    return (dispatch) => {
        const isFiltersEmpty = isEmpty(filters);
        if (initialCount === newRecordsCount || !isFiltersEmpty) return;
        const recordsCount = localStorage.getItem('recordsCount');

        dispatch(setInitialCount(newRecordsCount));
        if (recordsCount === newRecordsCount) return;

        localStorage.setItem('recordsCount', newRecordsCount);
    };
}

export function setDuplicateForRecord(
    id,
    duplications,
    data,
    totalItems,
    page,
    page_size
) {
    return (dispatch) => {
        // update duplicates data on the list
        data.map((item) => {
            if (item.record_id === id) {
                item.duplications = duplications;
            }
        });
        const payloadRecords = {
            records: {
                data,
                totalItems,
                page,
                page_size
            }
        };
        return dispatch(
            setMyRecords({
                records: payloadRecords
            })
        );
    };
}

export function setUpdatedMyRecord(
    updatedData,
    data,
    totalItems,
    page,
    page_size
) {
    return (dispatch) => {
        // if no significant data is provided - return
        if (!data || !updatedData) return;
        // Populate MyRecords with updated data
        data.map((item, idx) => {
            const { record_id: id } = updatedData;
            if (item.record_id === id) {
                data[idx] = updatedData;
            }
        });
        const payloadRecords = {
            records: {
                data,
                totalItems,
                page,
                page_size
            }
        };

        return dispatch(
            setMyRecords({
                records: payloadRecords
            })
        );
    };
}
