export const SYS_KEYS = [
    'sy_',
    '_id',
    'reference_ids',
    'output_log',
    'outputs',
    'approval_code_confirmed',
    'approval_code_confirmed_by',
    'approval_code_confirmed_on',
    'licensor_slug',

    // Additional Keys from new service
    'record_id',
    'product_id',
    'created_at',
    'updated_at',
    'deleted_at',
    'exact_hash',
    'conflict_hash',
    'organisation_id',
    '_meta',
    'licensor_slug',
    'validations',
    'meta',
    'product_approval',
    'is_active'
];

export const FILTERS = [
    { key: 'eq', title: 'Equals' },
    { key: 'ne', title: 'Not equals' },
    { key: 'gt', title: 'Greater than' },
    { key: 'gte', title: 'Greater than or equals' },
    { key: 'lt', title: 'Less than' },
    { key: 'in', title: 'In' },
    { key: 'nin', title: 'Not in' },
    { key: 'startswith', title: 'Starts with' },
    { key: 'endswith', title: 'Ends with' },
    { key: 'contains', title: 'Contains' }
];

export const KEY_FIELDS = ['gtin', 'target_market', 'language'];

export const DEFAULT_ROWS = 20;
// Move constants here as this is a general values for pagination
export const PAGE_LIMIT = 20;
export const EXCEEDS_LIMIT = 10000;
export const ITEMS_PER_PAGE = [10, 20, 25, 50, 100];

export const FILE_TYPES = {
    csv: ['csv', 'tsv'],
    excel: ['xslx', 'xls'],
    binary: ['*']
};

export const DEFAULT_SORT = {
    last_registered_at: 'desc'
};

export const NUMBER_OF_RECORDS_EXCEEDS = 'The number of records exceeds the pagination limit, please apply filters to refine your results.';
