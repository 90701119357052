import * as React from 'react'
import {
  getCellProperty
} from '@silevis/reactgrid'
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';

export class LoaderCellTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text)
    return { ...uncertainCell, text, value }
  }

  render() {
    return (
      <div className="c-rec-table__page-loader loading-cell">
        <img src={imgPinkSpinner} alt="Updating cell..."/>
      </div>
    )
  }
}
