export const MAP_ID = 'bd9948a1-c6ba-496c-aae2-7b559e8886eb';
export const CONCEPTS_MAP_ID = 'c09bd80d-41ff-462e-8296-e23bd97d14e1';
export const CATALOGUE_DESTINATION = 'xelacore-catalog';

export const DEFAULT_LIST = {
    name: '',
    filters: {}
};

export const DEFAULT_SORT = {
    uploaded_at: 'desc'
};

export const MULTIPLE_VALUES_PLACEHOLDER = 'Multiple Values';
export const MULTIPLE_VALUES_MESSAGE =
    'The selected products have different values specified for the field.';
