import React from 'react';
import { Button, Modal } from 'src/js/components/static';
import { showModal } from 'src/js/actions/xelacore';

export default function SetRefreshModal(props) {
    const { dispatch, refetchAndClose, isModal } = props;
    const bodyText = (
        <p>Another user is updating this record right now. Please try again.</p>
    );
    const bodyTextModal = (
        <p>
            Another user is updating this record right now. If you need to edit
            this record, please try again in few minutes.
        </p>
    );
    const body = (
        <div>
            {isModal ? bodyTextModal : bodyText}
            <div className="c-modal__sticky-footer">
                <Button purpleGrad onClick={refetchAndClose}>
                    Update record{isModal && 's'}
                </Button>
            </div>
        </div>
    );
    const title = 'Another user is updating this record';
    const modal = <Modal title={title} body={body} close={refetchAndClose} />;
    return dispatch(showModal(modal));
}
