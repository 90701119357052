import React from 'react';
import { showModal } from 'src/js/actions/xelacore';
import { Lightbox } from 'src/js/components/static';

import ApprovalOwnProduct from './ApprovalOwnProduct';
import LicensorNoCode from './LicensorNoCode';
import AprovalNonRegistered from './AprovalNonRegistered';
import ApprovalEditBlock from './ApprovalEditBlock';

import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

const Approval = props => {
    const {
        data = {},
        dispatch,
        toggleOpaPanel,
        opaDataMatch,
        confirmCode,
        updateProductConcept,
        removeConcept,
        type,
        licensor,
        showProductOverlay,
        xelacore,
        agent,
        dismissConceptInfo,
        dismissConceptInfoFunc,
        dontShow,
        getProductData
    } = props;

    const {
        record: {
            record_id,
            concept_code_confirmed_info = [],
            concept_code = [],
            concept_info = [],
            is_licensed,
            registered_version_id
        } = {}
    } = data;
    const ownProduct = !is_licensed;
    const isCatalogue = type === 'catalog';
    const registered = !!registered_version_id && isCatalogue;

    const viewImage = imgLink => {
        const lb = <Lightbox img={imgLink} />;
        dispatch(showModal(lb));
    };

    let concept_information = cloneDeep(concept_info);

    each(concept_code_confirmed_info, code => {
        let index = findIndex(concept_information, concept => concept.xelacore_concept_id === code.xelacore_concept_id);

        if(index > -1) {
            concept_information[index].confirmed_by = !!code && !!code.concept_code_confirmed_by && code.concept_code_confirmed_by || null;
            concept_information[index].confirmed_at = !!code && !!code.concept_code_confirmed_at && code.concept_code_confirmed_at || null;
        }
    });

    concept_information = (licensor || agent) ? concept_information.filter(code => !!code.confirmed_by) : [...concept_information]

    return (
        <div>
            {ownProduct && <ApprovalOwnProduct />}
            {!ownProduct && !registered && (dismissConceptInfo === 'false' || dismissConceptInfo === 'null') && (
                <AprovalNonRegistered
                    isCatalogue={isCatalogue}
                    registered={!!registered_version_id}
                    record_id={record_id}
                    conceptCodeConfirmedInfo={concept_code_confirmed_info}
                    dismissConceptInfo={dismissConceptInfo}
                    dismissConceptInfoFunc={dismissConceptInfoFunc}
                    dontShow={dontShow}
                />
            )}
            {(isCatalogue && licensor && !concept_information.length) || (isCatalogue && agent && !concept_information.length) && (
                <div className="c-product__detail-listing c-product__general-block marg-b-10">
                    <LicensorNoCode />
                </div>
            )}

            {!ownProduct && registered && (
                <div className="c-product__detail-listing c-product__general-block marg-b-10"
                    ><ApprovalEditBlock
                        opaDataMatch={opaDataMatch}
                        viewImage={viewImage}
                        toggleOpaPanel={toggleOpaPanel}
                        confirmCode={confirmCode}
                        conceptCode={concept_code}
                        conceptInfo={concept_information}
                        record_id={record_id}
                        removeConcept={removeConcept}
                        getProductData={getProductData}
                        updateProductConcept={updateProductConcept}
                        licensor={licensor}
                        agent={agent}
                        showProductOverlay={(productInfo, search) =>
                            showProductOverlay(productInfo, search)
                        }
                        xelacore={xelacore}
                    />
                </div>
            )}
        </div>
    );
};

export default Approval;
