import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'modules/UiKit/components/Button/Button';
import classnames from 'classnames';
import {
    uploadFile
} from 'src/js/apicalls/other/royaltyReporting';
import { SmartUploader, Icon, Modal } from 'src/js/components/static';
// import { csvFileErrors } from 'src/js/constants/errorConstants';
import { newUpload, upload } from 'src/js/actions/fileUpload';
import { showModal } from 'src/js/actions/xelacore';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';


function IngestReport({
    dispatch,
    close,
    reportId,
    fetchList
}) {
    const [files, setFiles] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    // const [error, setError] = useState(null);

    const panelCx = classnames('c-panel c-panel--curved');
    const smartCx = classnames('c-file-upload__dropzone', {
        'c-file-upload--uploading': uploading
    });

    // const checkTotalFileSizes = (files) => {
    //     const limit = 10000000; //10mb
    //     const amount = files.reduce((acc, el) => {
    //         return acc + el.size;
    //     }, 0);
    //     if (amount > limit) return false;
    //     return true;
    // }

    // const checkFileTypes = (files) => {
    //     return files.some(el => !el.name.endsWith('.csv'));
    // }

    const onProgress = () => {
        return;
    }

    const onCancel = () => {
        return;
    }


    const onDone= (args, category, files) => {
        setUploading(false);
        close();

        const { name } = args;
            return uploadFile(files, category, reportId).then(() => {
                const modal = (
                    <Modal
                        alert
                        body={
                            <p>
                                Your file {name} has been successfully uploaded
                            </p>
                        }
                        title={'Successful Upload'}
                    />
                );

                fetchList();
                dispatch(showModal(modal));
            });

    }

    const handleFilesDrop = (files) => {
        const category = 'royalties/ingest';

        dispatch(
            upload(
                category,
                files,
                args => onDone(args, category, files),
                args => onProgress(args),
                args => onCancel(args)
            )
        ).then((uploadList) => {
            dispatch(newUpload(uploadList, false)).then(() => {
                close();
            });
        });
    }

    const confirmFileDrop = (droppedFiles) => {
        // setError(null);

        Promise.all(droppedFiles).then(resp => {
            const files = resp.filter(f => f !== null);

            if (files[0].rowslength <= 1) {
                // setError(csvFileErrors.EMPTY_ROWS);
                return;
            }

            if (files[0].rowslength > 20001) {
                // setError(csvFileErrors.MAX_ROWS);
                return;
            }
            
            setFiles(files);
            setConfirm(true);
        });
    }

    return (
        <div>
            {!confirm && (
                <div className={panelCx}>
                    <div className="c-panel__body">
                        <div className="c-file-upload">
                            <div className="u-flex">
                                <span
                                    className="c-file-upload__close-panel"
                                    onClick={() => close()}
                                >
                                    <span>close</span>
                                    <Icon icon="close" fill="black" size="16" />
                                </span>
                            </div>

                            <SmartUploader
                                errorMessage={''}
                                onDrop={files => confirmFileDrop(files)}
                                link={null}
                                openHelp={null}
                                className={`${smartCx} ${''}`}
                            />
                        </div>
                    </div>
                </div>
            )}

            {confirm && !uploading && (
                <div className="c-file-upload__confirm-panel">
                    <span
                        className="c-file-upload__close-confirm-panel"
                        onClick={() => this.setConfirm(false)}
                    >
                        <CloseIcon></CloseIcon>
                    </span>

                    <div className="c-file-upload__ripple">
                        <div className="c-file-upload__ripple--mr-ripple" />
                        <Icon size={40} icon="csv" fill="cyan" />
                    </div>

                    {!!files && files.map((el, i) => (
                        <p key={`${el.name}-${i}`}>{el.name}</p>
                    ))}

                    <Button
                        onClick={() => handleFilesDrop(files)}
                        size={'medium'}
                        type={'primary'}
                    >
                        Upload {'Data'}
                    </Button>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({
    xelacore
}) => {
    return {
        xelacore
    };
};

export default connect(mapStateToProps)(IngestReport);