import React from 'react';
import classnames from 'classnames';
import { Icon } from 'src/js/components/static';
import RcTooltip from 'rc-tooltip';

const Tooltip = props => {
    const {
        children,
        className,
        button,
        trigger,
        position,
        purple,
        align,
        overlay,
        warn,
        error,
        small,
        icon,
        width,
        height
    } = props;

    const cx = classnames('c-tooltip', className);
    const cxContent = classnames('c-tooltip__content', {
        'c-tooltip__content--right': !position || position === 'right',
        'c-tooltip__content--left': position === 'left',
        'c-tooltip__content--small': small
    });
    const cxColor = classnames('c-tooltip__button', {
        'c-tooltip__button--purple': purple,
        'c-tooltip__button--warn': warn,
        'c-tooltip__button--error': error
    });

    const defaultContent = <div className={cxContent}>{children}</div>;
    const content = overlay ? overlay() : defaultContent;
    const offset = align ? align : { offset: [0, '50%'] };
    const defaultButton = (
        <div className={cxColor}>
            <Icon fill="grey13" icon={icon ? icon : 'Info'} width={width ? width : '15'} height={height ? height : '15'} />
        </div>
    );
    const tipButton = button ? button : defaultButton;

    return (
        <div className={cx}>
            <RcTooltip
                placement={position ? position : 'right'}
                overlay={content}
                trigger={[trigger ? trigger : 'hover']}
                align={offset}
            >
                {tipButton}
            </RcTooltip>
        </div>
    );
};

export default Tooltip;
