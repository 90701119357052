import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import remove from 'lodash/remove';
import size from 'lodash/size';
import each from 'lodash/each';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import {
    searchableFileds, countableRange
} from 'src/js/constants/advancedFilteringConstants';
import { isLicensor } from 'src/js/helpers/permissions';
import AdvancedFilteringHeader from './AdvancedFilteringHeader';
import AdvancedFilteringBody from './AdvancedFilteringBody';
import {
    buildFilterObject, getNewFiltersValue
} from './AdvancedFilteringHelpers';
import classNames from 'classnames';
import AdvancedFilteringFiltersEmptyBody from './AdvancedFilteringFiltersEmptyBody';

import { selectAll } from 'src/js/actions/productsFetch';

import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import { getUrlParam } from 'src/js/helpers/strings';

class AdvancedFiltering extends Component {
    constructor(props) {
        super(props);
        const { isMyRecords } = this.props;
        this.type = !isMyRecords ? 'catalog' : 'records';

        this.state = {
            collapsedGroup: {}, expandedList: {}, inputValues: {}, filterBusy: false
        };
    }

    rootRef = createRef();

    componentDidMount() {
        document.addEventListener('advancedFilterClear', () => {

            this.resetState();
        });
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('advancedFilterClear', this.handleClickOutside, true);
    }

    handleClickOutside = (event) => {
        const domNode = this.rootRef.current;

        if (!domNode || !domNode.contains(event.target)) {
            this.props.close();
        }
    };

    toggleCollapsedGroup = (id) => {
        this.setState({
            collapsedGroup: {
                ...this.state.collapsedGroup, [id]: !this.state.collapsedGroup[id]
            }
        });
    };

    toggleExpandedList = (id) => {
        this.setState({
            expandedList: {
                ...this.state.expandedList, [id]: !this.state.expandedList[id]
            }
        });
    };

    filterTerms = (key, desc, checked) => {
        const { updateFiltersState, queryObject, basicFilterObj, categoryLevels, xelacore, dispatch } = this.props;
        const { auth: { companyData } = {} } = xelacore;
        const licensor = isLicensor(companyData);
        const newFilters = { ...basicFilterObj };
        const newQueryObj = cloneDeep(queryObject);
        const isMust = 'must';
        const musts = newQueryObj.query.query.bool[isMust];
        const categoryIndex = findIndex(categoryLevels, cat => key === cat) || 0;
        let newKey = !!categoryLevels && key === categoryLevels[categoryIndex] ? `${licensor ? 'licensor' : 'licensee'}_category_path.${categoryIndex}.keyword` : key;

        dispatch(
            selectAll(
                [],
                this.type === 'records' ? true : false,
                []
            )
        );

        newFilters[newKey] = newFilters[newKey] || [];

        if (checked) {
            size(newFilters[newKey]) > 1 ? remove(newFilters[newKey], (item) => item === desc) : delete newFilters[newKey];

            remove(musts, (filterValue) => {
                return isEqual(filterValue, buildFilterObject(newKey, desc, categoryLevels, licensor));
            });
        } else {
            includes(countableRange, newKey) ? (newFilters[newKey] = getNewFiltersValue(newKey, desc)) : newFilters[newKey].push(...getNewFiltersValue(newKey, desc));

            newQueryObj.query.query.bool[isMust].push(buildFilterObject(newKey, desc, categoryLevels, licensor));
        }

        this.setState({
            filterBusy: true
        })

        updateFiltersState(newQueryObj, newFilters, true, { page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT });

        setTimeout(() => {
            this.setState({
                filterBusy: false
            })
        }, 1000)
    };

    resetState = (hardReset = true) => {
        const { updateFiltersState, queryObject, basicFilterObj, dispatch } = this.props;
        let newQueryObject = cloneDeep(queryObject);
        let newFilters = basicFilterObj;

        dispatch(
            selectAll(
                [],
                this.type === 'records' ? true : false,
                []
            )
        );

        newQueryObject.query.query.bool.must = filter(get(queryObject, 'query.query.bool.must', []), (item) => item.query_string);

        each(newFilters, (filter, key) => {
            if (!includes(searchableFileds, key)) {
                delete newFilters[key];
            }
        });


        this.setState({
            collapsedGroup: {}, expandedList: {}, inputValues: {}
        }, () => {
            updateFiltersState(newQueryObject, newFilters, hardReset, {
                page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT
            });
        });
    };

    setFilter = (key, desc) => {
        const { updateFiltersState, queryObject, basicFilterObj, categoryLevels, xelacore } = this.props;
        const { auth: { companyData } = {} } = xelacore;
        const licensor = isLicensor(companyData);
        const val = get(desc, 'target.value');

        let newFilters = { ...basicFilterObj };
        if (newFilters[key] || !val) delete newFilters[key];
        if (val) newFilters[key] = getNewFiltersValue(key, val);

        const newQueryObj = cloneDeep(queryObject);
        const musts = get(newQueryObj, 'query.query.bool.must', []).filter((item) => !item.query_string || item.query_string.default_field !== key);
        musts.push(buildFilterObject(key, val, categoryLevels, licensor));
        newQueryObj.query.query.bool.must = musts;
        updateFiltersState(newQueryObj, newFilters, true, { page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT });
    };

    filterGroup = (e, group, specialValues) => {
        const { inputValues } = this.state;
        const { aggregations, originalAggregations } = this.props;
        const inputValue = e.target.value.toLowerCase();
        const newObj = originalAggregations[group] && originalAggregations[group].buckets && originalAggregations[group].buckets.filter((f) => f.key.toLowerCase().includes(inputValue) || (specialValues && specialValues[f.key] && specialValues[f.key].toLowerCase().includes(inputValue)));
        const newVal = { ...originalAggregations[group], buckets: newObj };

        this.setState({
            inputValues: { ...inputValues, [group]: inputValue }
        });

        this.props.setAggregations(aggregations, group, newVal);
    };

    render() {
        const { collapsedGroup, expandedList, inputValues, filterBusy } = this.state;
        const {
            xelacore,
            showLists,
            aggregations,
            originalAggregations,
            dynamicSpecialValues,
            queryObject,
            isFiltersReturnedNoData = false,
            categoryLevels,
            ipLevels
        } = this.props;
        const { auth: { companyData } = {} } = xelacore;
        const licensor = isLicensor(companyData);

        return (<div
            ref={this.rootRef}
            className={classNames('c-advanced-filtering-holder ui-filters', {
                '-open': showLists, '-closed': !showLists
            })}
        >
            <Fragment>
                <div className='c-advanced-filtering'>
                    <AdvancedFilteringHeader
                        resetState={this.resetState}
                        aggregations={aggregations}
                        close={() => this.props.close()}
                        isFiltersReturnedNoData={isFiltersReturnedNoData}
                    />
                    {!isFiltersReturnedNoData ? (<AdvancedFilteringBody
                        toggleCollapsedGroup={this.toggleCollapsedGroup}
                        collapsedGroup={collapsedGroup}
                        toggleExpandedList={this.toggleExpandedList}
                        expandedList={expandedList}
                        aggregations={aggregations}
                        originalAggregations={originalAggregations}
                        dynamicSpecialValues={dynamicSpecialValues}
                        filterGroup={this.filterGroup}
                        filterTerms={this.filterTerms}
                        queryObject={queryObject}
                        licensor={licensor}
                        type={this.type}
                        setFilter={this.setFilter}
                        inputValues={inputValues}
                        categoryLevels={categoryLevels}
                        ipLevels={ipLevels}
                        filterBusy={filterBusy}
                    />) : (<AdvancedFilteringFiltersEmptyBody
                        resetState={this.resetState}
                    ></AdvancedFilteringFiltersEmptyBody>)}
                </div>
            </Fragment>
        </div>);
    }
}

export default connect((state) => ({ ...state }), (dispatch) => ({ dispatch }))(AdvancedFiltering);
