import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import PageNotFound from 'src/js/modules/SingleStaticPages/PageNotFound';
import { getUserPermissions } from 'src/js/actions/permissions';
import {
    checkPermissions,
    checkAccountTypePermission,
    checkPermissionLevels
} from 'src/js/helpers/permissions';

function mapStateToProps(state) {
    const {
        xelacore: { auth: { token, userPermissions = {}, companyData } } = {}
    } = state;
    return { token, userPermissions, companyData };
}

export default connect(
    (state) => mapStateToProps(state),
    (dispatch) => ({ dispatch })
)((props) => <RestrictedRoute {...props} />);

class RestrictedRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasNoRestriction: props.restrictTo ? null : true,
            hasPermission: props.permission ? null : true,
            checked: false
        };
    }

    fetchPermissions(token, companyData) {
        const { dispatch } = this.props;
        const { employee_id } = jwtDecode(token).data;
        return dispatch(
            getUserPermissions(companyData.organisation_id, employee_id)
        );
    }

    componentWillMount() {
        const { token, userPermissions, companyData } = this.props;
        if (isEmpty(userPermissions))
            return this.fetchPermissions(token, companyData);
    }

    componentDidMount() {
        this.checkIfWereGood();
    }

    componentDidUpdate(prevProps) {
        const { userPermissions, level } = this.props;
        if (
            Object.keys(prevProps.userPermissions || {}).length !==
                Object.keys(userPermissions || {}).length ||
            level !== prevProps.level
        )
            this.checkIfWereGood();
        return true;
    }

    checkIfWereGood() {
        const {
            permission,
            restrictTo,
            userPermissions,
            level,
            companyData
        } = this.props;

        const { modules, system } = userPermissions || {};
        if (isEmpty(userPermissions)) return null;
        const allPerms = Object.assign({}, modules, system);
        return this.setState({
            hasNoRestriction: restrictTo
                ? checkAccountTypePermission(companyData, restrictTo)
                : true,
            hasPermission: permission
                ? level
                    ? checkPermissionLevels(allPerms, permission, level)
                    : checkPermissions(allPerms, permission)
                : true,
            checked: true
        });
    }

    render() {
        const { children } = this.props;
        const { hasPermission, hasNoRestriction, checked } = this.state;
        if (!checked) return null;
        if (hasPermission && hasNoRestriction) return children;

        if (
            (hasPermission && !hasNoRestriction) ||
            (!hasPermission && hasNoRestriction) ||
            (!hasPermission && !hasNoRestriction)
        )
            return <PageNotFound />;

        return null;
    }
}
