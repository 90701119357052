import React from 'react';
import { getMainImage } from 'src/js/helpers/dataHelpers';
import MediaView from 'src/js/components/MediaView';

export default function Tile({ ...props }) {
    const { data, setActiveProduct, alreadyLinked } = props;
    const { images, product_name } = data;
    const imagePath = getMainImage(images);

    return (
        <div className="c-product__opa-tile" onClick={() => setActiveProduct()}>
            {
                alreadyLinked &&
                <span className="c-product__concept-block__linked_list__item__info__other__status is-confirmed"></span>
            }
            <MediaView className="c-product__opa-tile-image" src={imagePath} />
            <small className="c-product__opa-tile-title">{!!product_name && product_name.substring(0, 50) || ''}</small>
        </div>
    );
}