import React from 'react';
import { connect } from 'react-redux';
import Button from 'modules/UiKit/components/Button/Button';
import toArray from 'lodash/toArray';
import { showModal } from 'src/js/actions/xelacore';
import ReportActionConfirmation from '../Components/ReportActionConfirmation';

function ReportingPeriods({
    salesData,
    setOpenUploadArea,
    exportCsvFunc,
    deleteReport,
    dispatch
}) {
    const deleteReportEntries = (reportId) => {
        const modalContent = (
            <ReportActionConfirmation
                title={'Delete Confirmation'}
                confirm={() => deleteReport(reportId)}
                message={'Are you sure you want to delete this Reporting Period?'}
            />
        );

        return dispatch(showModal(modalContent));
    }

    const sortedData = salesData.sort((a, b) => {
        const [quarterA, yearA] = a.yearQuarter.split('-');
        const [quarterB, yearB] = b.yearQuarter.split('-');

        if (yearA !== yearB) {
            return yearB - yearA;
        }

        return quarterB.slice(1) - quarterA.slice(1);
    });

    return (
        <div className='royalty-report-assistant --display-data'>
            <div className='report-period'>
                <h2>Royalty Report Co-Pilot</h2>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => setOpenUploadArea(true)}
                >
                    Create New Period
                </Button>
            </div>

            <div className='report-period-body'>
                {toArray(sortedData).map((el) => {
                    return (
                        <div className='report-period-group'>
                            <div className='period-title'>
                                REPORTING PERIOD: {el.quarter} {el.year}

                                <Button
                                    type="secondary-2"
                                    size="small"
                                    onClick={() => setOpenUploadArea(true, el)}
                                >
                                    Upload
                                </Button>

                                <Button
                                    type="secondary-danger"
                                    size="small"
                                    onClick={() => deleteReportEntries(el.id)}
                                >
                                    Delete
                                </Button>
                            </div>

                            <div className='period-items-wrapper'>
                                {el.salesPerLicensor.map((period) => {
                                    period.image = period.licensorId ? `https://assets.xelacore.io/org/${period.licensorId}.png` : null;
                                    
                                    return (
                                        <div className='period-item'>
                                            <div className='period-body'>
                                                <span className='image'>
                                                    <img src={period.image} onError={(event) => event.target.style.display = 'none'} />
                                                </span>
                                                <div className='description'>
                                                    <div className='title'>No. of records:</div>
                                                    <div className='desc'>{period.count || 0}</div>
                                                </div>
                                            </div>

                                            <div className='period-footer'>
                                                <Button
                                                    type="secondary"
                                                    size="small"
                                                    to={`/royalty-report-assistant/${el.id}?quarter=${el.quarter}&year=${el.year}&licensor=${period.licensorId}`}
                                                >
                                                    View
                                                </Button>

                                                <Button
                                                    type="secondary"
                                                    size="small"
                                                    onClick={() => exportCsvFunc(el.id, period.licensorId)}
                                                >
                                                    Export
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(ReportingPeriods);