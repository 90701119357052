import React, { Component } from 'react';
import classnames from 'classnames';

export default class extends Component {
    state = {
        expanded: false
    };

    render() {
        const { expanded, expand } = this.state;
        const { value = '', bold = false } = this.props;
        const len = 50;

        if (value === null) return null;
        const cx = classnames('c-product__detail-listing-item-textarea', {
            'c-product__detail-listing-item-textarea--expanded': expanded,
            'u-bold': bold
        });

        const displayedValue =
            expanded || (value || '').length < len
                ? value
                : value.slice(0, len) + '...';

        return (
            <div className={cx}>
                {displayedValue}
                <br />
                <u
                    className="u-clickable"
                    onClick={() => this.setState({ expanded: !expanded })}
                >
                    {expand && value.length > len && (
                        <span>
                            {!expanded && value.length >= len
                                ? 'Expand'
                                : 'Hide'}
                        </span>
                    )}
                </u>
            </div>
        );
    }
}
