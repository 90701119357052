import React, { Fragment } from 'react';
import classNames from 'classnames';
import {Triangle} from 'src/js/components/static'

import { Auth } from 'src/js/components/static';

export default ({
    approvalData,
    allConcepts,
    setConceptInfo,
    activeCode,
    licensor
}) => {
    const currentConceptIndex = allConcepts.findIndex(
        el => {
                return !!approvalData ? el.xelacore_concept_id === approvalData.xelacore_concept_id : false;
        }
    );

    return (
        <Fragment>
            <div className={classNames('c-approval-overlay__pagination__buttons', {'-licensor': licensor})}>
                <Auth restrictTo="licensee">
                    { activeCode && (
                        <div>
                            <span className="u-bold">
                                Viewing linked concepts:
                            </span>
                        </div>
                    )}
                </Auth>

                <div
                    className={classNames('c-approval-overlay__pagination__buttons-go', {
                        '-disabled': currentConceptIndex === 0
                    })}
                    onClick={() =>
                        setConceptInfo(currentConceptIndex - 1, allConcepts)
                    }
                >
                    <Triangle left /> &nbsp;Previous
                </div>

                <div
                    className={classNames('c-approval-overlay__pagination__buttons-go', {
                        '-disabled': currentConceptIndex === allConcepts.length - 1
                    })}
                    onClick={() =>
                        setConceptInfo(currentConceptIndex + 1, allConcepts)
                    }
                >
                    Next&nbsp; <Triangle right />
                </div>
            </div>
        </Fragment>
    );
}
