import * as React from 'react'
import {
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from '@silevis/reactgrid'
import { Icon, Tooltip } from 'src/js/components/static';
import find from 'lodash/find';

const tooltipButton = (
  <span>
      <Icon
          icon="info_bubble"
          fill="grey4"
          width="12"
          height="12"
          top={3}
          tooltip
      />
  </span>
);

export class ErrorValueTemplate {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text)
    return { ...uncertainCell, text, value }
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
    return { cell, enableEditMode: true }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    }
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text })
  }
  
  render(cell, isInEditMode, onCellChanged) {
    let path = JSON.parse(cell.text).path;
    let parsedCell = JSON.parse(cell.text).entry[path];
    let errorMessage = find(JSON.parse(cell.text).entry.validations, item => {
          return item.error.problem.path === path
        }).error.problem.title
    
    return (
      <div className="error-cell">
        { isInEditMode && (
          <input
            ref={(input) => {
              input && input.focus();
            }}
            defaultValue={parsedCell}
            onKeyDown={(e) => {
              if(e.keyCode === 13) {
                onCellChanged(
                  this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
                  true
                )
                e.stopPropagation();
              }

              e.stopPropagation();
            }}
            onPointerDown={(e) => e.stopPropagation()}
            onCopy={(e) => e.stopPropagation()}
            onCut={(e) => e.stopPropagation()}
            onPaste={(e) => e.stopPropagation()}
          />
        )}

        { !isInEditMode && (
          <div className="error-cell-text">
            <span className="text">{parsedCell}</span>
              
            <Tooltip
              placement="right"
              button={tooltipButton}
            >
                {errorMessage}
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
}
