import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

import forEach from 'lodash/forEach';

import CryptoJS from 'crypto-js';

const axiosUpload = axios.create({ headers: { Authorization: '' } });

const CancelToken = axios.CancelToken;

/**
 *
 * @return {Promise}
 */
export function getUnreportedContracts(orgId) {
    return axios
        .request({
            url: [config.urls.catalogv3, `contracts/${orgId}/unreported`].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params: {}
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function createReport(data) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'reports'].join('/'),
            method: 'put',
            headers: { 'Cache-Control': 'no-cache' },
            data
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function createVersion(reportId, data) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'reports', reportId, 'versions'].join('/'),
            method: 'put',
            headers: { 'Cache-Control': 'no-cache' },
            data
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function fetchReports() {
    return axios
        .request({
            url: [config.urls.catalogv3, 'reports'].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params: {}
        })
        .then(responseHandler);
}


/**
 *
 * @return {Promise}
 */
 export function deleteReport(reportId) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'reports', reportId].join('/'),
            method: 'delete',
            headers: { 'Cache-Control': 'no-cache' }
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function fetchReportEntries(reportId, params) {
    return axios
        .request({
            url: [config.urls.catalogv3, `reports/${reportId}/entries?sync=true`].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function updateEntriesData(reportId, data) {
    return axios
        .request({
            url: [config.urls.catalogv3, `reports/${reportId}/entries?sync=true`].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function updateReport(reportId, data) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'reports', reportId].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function deleteEntriesData(reportId) {
    return axios
        .request({
            url: [config.urls.catalogv3, `reports/${reportId}/entries`].join('/'),
            method: 'delete'
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
 export function fetchReportData(reportId) {
    return axios
        .request({
            url: [config.urls.catalogv3, `reports/${reportId}`].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params: {}
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
 export function getProductDetails(reportId) {
    return axios
        .request({
            url: [config.urls.catalogv3, `reports/${reportId}/selections/productdetails`].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params: {}
        })
        .then(responseHandler);
}


/**
 * This API call uploads a file against a URL
 *
 * @param  {String} category
 * @param  {File} file the file to upload
 * @param  {Function} progress The callback to call on each progress event
 * @param  {Function} cancelBind
 * @return {Promise}
 */
 export function upload(category, file, progress, cancelBind) {
    return axios
        .request({
            url: [config.urls.files, 'private/upload'].join('/'),
            method: 'post',
            data: { file: { category, name: file.name } },
            cancelToken: new CancelToken(cancelBind || (() => {}))
        })
        .then((response) => {
            const data = responseHandler(response, 'data');
            var decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decrypted);
        })
        .then(({ post_body, location }) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                forEach(post_body, (val, key) => data.append(key, val));
                data.append('file', file);

                setTimeout(() => {
                    axiosUpload
                        .request({
                            url: location,
                            method: 'post',
                            data,
                            onUploadProgress: progress,
                            cancelToken: new CancelToken(
                                cancelBind || (() => {})
                            )
                        })
                        .then((result) => resolve(result))
                        .catch((err) => reject(err));
                }, 0);
            });
        });
}

export function uploadFile(file, category, reportId) {
    let data = {
        'reportId': reportId,
        'fileStore': 's3',
        'sourceInfo': {
            'path': file[0].name,
            'security': 'private',
            category
        }
    }
               
    return axios
        .request({
            url: [config.urls.catalogv3, 'ingest'].join('/'),
            method: 'post',
            data
        })
        .then(resp => {
            return resp
        });
}