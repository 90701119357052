import React from 'react';
import { pluralise } from 'src/js/helpers/strings';
import classnames from 'classnames';
import {
    registeredMessage,
    approvedMessage
} from 'src/js/constants/registerConstants';
import { Auth } from 'src/js/components/static';

export const errorContent = errors => {
    if (errors === 0) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--red">
            <div>
                This record contains <b>{errors}</b>{' '}
                {pluralise('error', errors)}
                <br />
            </div>
        </div>
    );
};

export const warningContent = warnings => {
    if (warnings === 0) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--orange">
            <div>
                This record contains <b>{warnings}</b>{' '}
                {pluralise('warning', warnings)}
                <br />
            </div>
        </div>
    );
};

export const duplicateErrorContent = (conflicts = 0) => {
    if (!conflicts) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
            <div>
                You should fix errors before resolving conflicts.
            </div>
        </div>
    );
};

export const duplicateContent = (conflicts = 0) => {
    if (!conflicts) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate">
            <div>
                This product record is currently in conflict with <b>{conflicts}</b> other product {' '}
                {pluralise('record', conflicts)}. This conflict needs to be resolved before the record
                can be registered with the Licensor.
            </div>
        </div>
    );
};

export const confirmedDuplicateContent = (conflicts = 0) => {
    if (!conflicts) return null;
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
            <div>This conflicting records has been confirmed.</div>
        </div>
    );
};

export const inErrorContent = () => {
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate">
            <div>Please click here to correct the errors.</div>
        </div>
    );
};

export const onGtinWaiver = () => {
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
            <div>This product is exempted from GTIN requirement.</div>
        </div>
    );
};

export const registeredContent = register => {
    if (!register) return;
    const cx = classnames('c-rec-table__tooltip', {
        'c-rec-table__tooltip--green': register === 'registered',
        'c-rec-table__tooltip--orange': register === 'partly registered',
        'c-rec-table__tooltip--grey': register === 'not registered'
    });
    const msg = registeredMessage[register];

    return (
        <div className={cx}>
            <div>{msg}</div>
        </div>
    );
};

export const approvedContent = status => {
    const cx = classnames('c-rec-table__tooltip', {
        'c-rec-table__tooltip--green': status === 'approved',
        'c-rec-table__tooltip--orange': status === 'linked',
        'c-rec-table__tooltip--grey': status === 'nodata'
    });
    const msg = approvedMessage[status];

    return (
        <div className={cx}>
            <div>
                <Auth restrictTo="licensor">
                    {msg.licensor}
                </Auth>
                <Auth restrictTo="licensee">
                   {msg.licensee}
                </Auth>
                <Auth restrictTo="agent">
                   {msg.licensor}
                </Auth>
            </div>
        </div>
    );
};
export const customTableContent = () => {
    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--grey">
            <div>
                Here you can re-organise the columns in the table - please drag
                and drop your selected headers to change the order.{' '}
            </div>
        </div>
    );
};

export const errorContentMessage = errorMessage => {
    if (!errorMessage) return null;

    return (
        <div className="c-rec-table__tooltip c-rec-table__tooltip--red">
            <div>
                {errorMessage}
            </div>
        </div>
    );
};
