import SocketV3 from 'components/static/SocketV3/SocketV3';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GlobalNotification, PageNotification } from 'src/js/components/static';

class OrphanLayout extends Component {
    render() {
        const { xelacore, children } = this.props;

        return (
            <div id="container">
                {xelacore.modal.displayModal && xelacore.modal.content}
                <div className="o-app-layout">
                    <div className="page page--front-cover">
                        <div className="l-frontpage-body">
                            <GlobalNotification />
                            <SocketV3 />
                            <PageNotification />
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    ({ xelacore }) => ({ xelacore }),
    dispatch => ({ dispatch })
)(OrphanLayout);
