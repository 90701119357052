import React from 'react';
import ownProducts from 'statics/imgs/desktop/concepts/own_products.svg';

export default function ApprovalOwnProduct() {
    return (
        <div>
            <h3 className="c-product__header u-margin-bottom">
                Concept Approval Information
            </h3>

            <div className="c-product__concept-block">
                <div>
                    <div className="u-padding">
                        <img
                            src={ownProducts}
                            className="u-padding"
                            alt="Own Product"
                        />
                    </div>
                    <div className="u-color-green">
                        Your unlicensed products do not require concept
                        matching.
                    </div>
                </div>
            </div>
        </div>
    );
}
