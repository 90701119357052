import React from 'react';
import config from 'config';

import { ReactComponent as RegIcon } from 'src/js/components/static/Icon/svg-icons/xelacoreModules/Fabacus_Module_Icons_Register.svg';
import { ReactComponent as PlmIcon } from 'src/js/components/static/Icon/svg-icons/xelacoreModules/Fabacus_Module_Icons_PLM.svg';
import { ReactComponent as CamIcon } from 'src/js/components/static/Icon/svg-icons/xelacoreModules/Fabacus_Module_Icons_Create.svg';
import { ReactComponent as ReachIcon } from 'src/js/components/static/Icon/svg-icons/xelacoreModules/Fabacus_Module_Icons_Reach.svg';

export const XelacoreModule = {
    reg: 'reg',
    plm: 'plm',
    cam: 'cam',
    reach: 'reach'
};

export const xelacoreModuleDefinitions = new Map([
    [
        XelacoreModule.reg,
        {
            name: 'Registration',
            icon: <RegIcon></RegIcon>,
            link: config.services.reg
        }
    ],
    [
        XelacoreModule.plm,
        {
            name: 'PLM',
            icon: <PlmIcon></PlmIcon>,
            link: config.services.plm
        }
    ],
    [
        XelacoreModule.reach,
        {
            name: 'Reach',
            icon: <ReachIcon></ReachIcon>,
            link: config.services.reach
        }
    ],
    [
        XelacoreModule.cam,
        {
            name: 'Concept Approval',
            icon: <CamIcon></CamIcon>,
            link: config.services.cam
        }
    ]
]);

export const AccountModuleLink = config.services.account;
