export const RETAIL_LIST = [
    {
        key: 'listingStatus',
        label: 'Listing Status',
        filter: true
    },
    {
        key: 'matchStatus',
        label: 'Match Status',
        filter: true
    },
    {
        key: 'licensorBrand',
        label: 'Brand',
        filter: true
    },
    {
        key: 'title',
        label: 'Listing Name',
        multiple: 'true',
        action: true
    },
    {
        key: 'listingId',
        label: 'Listing ID',
        search: true
    },
    {
        key: 'retailerName',
        label: 'Retailer',
        filter: true,
        parentKey: 'retailerDetail'
    },
    {
        key: 'retailerChannel',
        label: 'Retail Channel'
    },
    {
        key: 'merchantName',
        label: 'Seller/Merchant',
        filter: true,
        parentKey: 'retailerMerchant',
        action: true
    },
    {
        key: 'category',
        label: 'Listing Category',
        filter: true
    },
    {
        key: 'brand',
        label: 'Listing Brand',
        filter: true
    },
    {
        key: 'gtin',
        label: 'Listing GTIN',
        search: true
    },
    {
        key: 'mpn',
        label: 'Listing MPN',
        search: true
    },
    {
        key: 'price',
        label: 'Listing Price'
    }
]