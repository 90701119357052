import React, { Component, Fragment } from 'react';
import ApprovalWithNumberAndData from './ApprovalWithNumberAndData';
import ApprovalWithoutData from './ApprovalWithoutData';
import ApprovalCodeBlock from './ApprovalCodeBlock';
import ApprovalListOfLinkedConcepts from './ApprovalListOfLinkedConcepts';
import SelectedProduct from '../OpaSearchPanel/SelectedProduct';

export default class ApprovalEditBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productInfo: null,
            productPreview: null,
            oldCode: null
        };
    }

    toggleProductInfo = (productInfo, search = false) =>
        this.props.showProductOverlay(productInfo, search);

    toggleProductPreview = productInfo =>
        this.props.showProductOverlay(productInfo);

    updateCode = (recordId, code) => {
        const { toggleOpaPanel } = this.props;
        this.setState({
            productPreview: null,
            productInfo: null,
            oldCode: code
        });
        return toggleOpaPanel(false, code);
    };

    confirmCode = (record_id, code) => {
        const { confirmCode, updateProductConcept } = this.props;
        const { oldCode } = this.props;

        this.setState({
            productPreview: null,
            productInfo: null,
            oldCode: null
        });
        if (oldCode) {
            return updateProductConcept(
                record_id,
                code,
                oldCode
            );
        }
        return confirmCode(record_id, code);
    };

    removeConcept = (record_id, code) => {
        const { removeConcept } = this.props;
        this.setState({
            productPreview: null,
            productInfo: null,
            oldCode: null
        });
        return removeConcept(record_id, code);
    };

    render() {
        const {
            toggleOpaPanel,
            conceptInfo = [],
            opaDataMatch,
            viewImage,
            record_id,
            licensor,
            agent,
            xelacore
        } = this.props;
        const { productInfo, productPreview } = this.state;
        const showForLicensor =
            (conceptInfo.length && licensor) || !licensor;
        const showForAgent =
            (conceptInfo.length && agent) || !agent;
        if (!showForLicensor || !showForAgent) return null;
    
        return (
            <div>
                <h3 className="c-product__header u-margin-bottom">
                    Concept Approval Information
                </h3>

                {// View details of one concept
                productInfo && (
                    <div className="c-product__opa-panel">
                        <SelectedProduct
                            data={productInfo}
                            record_id={record_id}
                            close={() => this.toggleProductInfo()}
                            updateProductConcept={this.confirmCode}
                            isLicensor={licensor}
                            xelacore={xelacore}
                        />
                    </div>
                )}
                {// Preview one concept
                productPreview && (
                    <Fragment>
                        <ApprovalWithNumberAndData
                            productPreview={productPreview}
                            viewImage={viewImage}
                            toggleProductInfo={this.toggleProductInfo}
                            close={() => this.toggleProductPreview()}
                        />
                        <ApprovalCodeBlock
                            approvalCode={productPreview.approval_code}
                            aprConfirmed={productPreview.confirmed_by}
                            aprConfirmedOn={productPreview.confirmed_at}
                            confirmCode={this.confirmCode}
                            removeCode={this.removeConcept}
                            record_id={record_id}
                            updateCode={(record_id, code) =>
                                this.updateCode(record_id, code)
                            }
                        />
                    </Fragment>
                )}

                {// List of linked concepts
                !productPreview && !productInfo && !!conceptInfo.length && (
                    <ApprovalListOfLinkedConcepts
                        conceptInfo={conceptInfo}
                        licensor={licensor}
                        toggleOpaPanel={toggleOpaPanel}
                        matchingData={opaDataMatch}
                        toggleProductInfo={this.toggleProductPreview}
                        toggleOverlayPanel={() => this.toggleProductInfo(null, true)}
                        xelacore={xelacore}
                    />
                )}

                {// Did not find existed concepts
                !productPreview && !productInfo && !conceptInfo.length && (
                    <ApprovalWithoutData
                        toggleOpaPanel={() => this.toggleProductInfo(null, true)}
                        approvalCodes={[]}
                        toggleProductInfo={this.toggleProductInfo}
                        matchingData={opaDataMatch}
                    />
                )}
            </div>
        );
    }
}