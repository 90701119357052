import Uploader from 'src/js/lib/uploader';
import {
    fetchProductInfo,
    updateProductInformation
} from 'src/js/apicalls/mixed/myRecords';
import { BUCKET } from 'src/js/constants/actions/dataManager';

const uploader = Uploader(BUCKET);

export function upload(files, onDone, onProgress, onCancel) {
    return () => {
        return uploader({ files, onDone, onProgress, onCancel });
    };
}

export function getProductInfo(productType, productId) {
    return () => {
        if (!productId)
            return Promise.reject('No productId found, illegal action');
        if (!productType)
            return Promise.reject('No productType found, illegal action');

        return fetchProductInfo(productType, productId);
    };
}

export function updateProductInfo(productId, data) {
    return () => {
        if (!productId)
            return Promise.reject('No productId found, illegal action');
        if (!data) return Promise.reject('No data found, illegal action');

        return updateProductInformation(productId, data);
    };
}