import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

export function getConflictsCount(licensorId) {
    const licensor = licensorId ? `?licensor_organisation_id=${licensorId}` : '';
    let url = [config.urls.catalog, 'conflicts/count', licensor].join('/');

    return axios
        .request({
            url,
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchAllConflicts(licensorId) {
    const licensor = licensorId ? `?licensor_organisation_id=${licensorId}` : '';
    let url = [config.urls.catalog, 'conflicts', licensor].join('/');

    return axios
        .request({
            url,
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchConflict(id, self=false) {
    let url = [config.urls.catalog, 'records', id, 'conflicts'].join('/');
    return axios
        .request({
            url,
            method: 'get',
            params: {
                self
            }
        })
        .then(responseHandler);
}

export function fetchQuarantine(id, self=false) {
    let url = [config.urls.catalog, 'records', id, 'quarantine'].join('/');
    return axios
        .request({
            url,
            method: 'get',
            params: {
                self
            }
        })
        .then(responseHandler);
}

export function deleteTheConflicts(id, data) {
    return axios
        .request({
            url: [config.urls.catalog, 'records', id, 'conflicts'].join('/'),
            method: 'delete',
            data
        })
        .then(responseHandler);
}


export function deleteSets(data) {
    return axios
        .request({
            url: [config.urls.catalog, 'conflicts'].join('/'),
            method: 'delete',
            data
        })
        .then(responseHandler);
}

export function mergeConflictsMpn(id, data) {
    return axios
        .request({
            url: [config.urls.catalog, 'records', id, 'conflicts'].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}


export function getRecordIds(ids) {
    return axios
        .request({
            url: [config.urls.catalog, 'records', 'product_ids'].join('/'),
            method: 'post',
            data: {product_ids: ids}
        })
        .then(responseHandler);
}
