import {
    REMOVE_PRODUCT_CATALOGUE,
    SET_PRODUCT_CATALOGUE,
    SET_QUARANTINE
} from 'src/js/constants/actions/xelacore';

export function productCatalogue(state = {}, action = {}) {
    const { payload = {} } = action;
    
    switch (action.type) {
        case SET_PRODUCT_CATALOGUE:
            const {
                records = {},
                fetch = {},
                filterings = {},
                recordsCount = {},
                selectedRows = [],
                advanced = {},
                selected = null,
                previousRow = null
            } = payload;
            return {
                ...state,
                ...records,
                ...fetch,
                ...filterings,
                ...recordsCount,
                ...{selectedRows: selectedRows},
                ...{selected: selected},
                ...{previousRow: previousRow},
                ...advanced
            };
        case SET_QUARANTINE:
            return {
                ...state,
                quarantine: { ...payload }
            };
        case REMOVE_PRODUCT_CATALOGUE:
            return { ...payload };
        default:
            return state;
    }
}
