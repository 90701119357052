import React, { Component } from 'react';
import classnames from 'classnames';
import { Icon } from 'src/js/components/static';
import { connect } from 'react-redux';
import { hideModal } from 'src/js/actions/xelacore';

/*
* In order to dpslay, you need to dispatch an action in Redux.
* Sample below for use in your component:

  import { Modal } from 'src/js/components/static';
  import { showModal } from 'src/js/actions/xelacore';

  displayModal() {
    const {dispatch} = this.props;
    const modalBody = <div>Hello there my lover</div>;
    const modal = <Lightbox image={'http://placehold.it/600'} />
    return dispatch(showModal(modal));
  }
*
*/

export default connect(
    state => ({ ...state }),
    dispatch => ({ dispatch })
)(props => <Lightbox {...props} />);

class Lightbox extends Component {
    constructor(props) {
        super(props);
        this.escapeKey = this.escapeKey.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escapeKey, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escapeKey, false);
    }

    close() {
        const { dispatch } = this.props;
        dispatch(hideModal());
    }

    escapeKey(event) {
        if (event.keyCode === 27) return this.close();
        return;
    }

    render() {
        const { modal } = this.props.xelacore;
        const { img, video } = this.props;
        if (!modal.displayModal) return null;

        const cx = classnames('c-lightbox', this.props.className);

        return (
            <div className={cx}>
                <div
                    className="c-lightbox__overlay"
                    onClick={() => this.close()}
                />
                <div className="c-lightbox__outer-body">
                    <Icon
                        icon="close"
                        size="16"
                        className="c-lightbox__close-icon"
                        onClick={() => this.close()}
                    />
                    <div className="c-lightbox__body">
                        {img && <img src={this.props.img} />}
                        {video && video}
                    </div>
                </div>
            </div>
        );
    }
}
