import React from 'react';
import { Auth, Tooltip } from 'src/js/components/static';
import { readyToRegister } from 'src/js/helpers/dataHelpers';

export default function TableRegistration({ selectedRows, data }) {
    return (
        <Auth permission="registration.manage">
            <Tooltip>
                Please fix all errors and conflicts before registering.
            </Tooltip>
            {readyToRegister(data, selectedRows)}
        </Auth>
    );
}
