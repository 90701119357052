'use strict';

var merge = require('lodash/merge');
var axios = require('axios');
var CryptoJS = require('crypto-js');

// hacky solution because of webworkers, and other environments
var win =
    typeof self !== 'undefined'
        ? self
        : typeof window !== 'undefined'
            ? window
            : {};
var ENV =
    (process && process.env.RUNTIME_ENV) ||
    (win && win.__ENVIRONMENT__) ||
    'development';
// Fetch the config, based on the environment
ENV = process.env.RUNTIME_ENV

// eslint-disable-next-line no-console
console.log(process.env.NODE_ENV, process.env.RUNTIME_ENV)

var envConf = require('./' + ENV);

var assertedConfig = false;
var config = merge(
    {
        env: ENV,
        baseUrl: 'https://api.testing.xelacore.io',
        client_id: 'f21ce494-0385-4c2e-9175-96f15344aab7',
        redirect_uri: 'http://localhost:8000/',
        // API endpoints
        urls: {
            oauth: '/oauth',
            auth: '/v1/auth',
            catalog: '/v1/catalog',
            concepts: '/v1/concepts',
            files: '/v1/files',
            filesv3: '/v3/files',
            ingest: '/v1/ingest',
            redemption: '/v1/redemption',
            policy: '/v1/policy',
            policyv3: '/v3/policy',
            catalogv3: '/v3/catalog',
            ingestv3: '/v3/ingest'
        },
        fileUploadDelay: 1000,
        sentry: {
            key: '2ee50670c23f4cc2965bee5da367cf2',
            app: '249694'
        },
        hotjar: {
            trackingCode: 1091262,
            snippetVersion: 6
        },
        injectFlow: function injectFlow() { },
        cookieDomain: '.xelacore.io'
    },
    envConf
);

config.assertConfig = function () {
    return assertedConfig
        ? Promise.resolve(config)
        : axios
            .request({
                url: '/self/config',
                method: 'get',
                headers: {}
            })
            .then(function (response) {
                assertedConfig = true;

                if (!response || response.status !== 200 || !response.data)
                    return config;

                var decrypted = CryptoJS.AES.decrypt(
                    response.data,
                    'process.env.NODE_ENV'
                ).toString(CryptoJS.enc.Utf8);
                return Object.assign(config, JSON.parse(decrypted) || {});
            })
            .catch(function () {
                return config;
            });
};

module.exports = config;
