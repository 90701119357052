import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Button from 'modules/UiKit/components/Button/Button';
import {
    getReportSalesData,
    exportCsv
} from 'src/js/apicalls/other/royaltyReporting';
// import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import { getUrlParam } from 'src/js/helpers/strings';

import size from 'lodash/size';
import toArray from 'lodash/toArray';
import { Icon } from 'src/js/components/static';
import {
    displayNotification
} from 'src/js/actions/xelacore';
import includes from 'lodash/includes';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';


function Report({
    params,
    dispatch
}) {
    const [, setTableWidth] = useState(0);
    const [pageSize] = useState(1000);
    const [page] = useState(1);
    const [headers, setHeaders] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [meta, setMeta] = useState({});
    const [quarter] = useState(getUrlParam('quarter'));
    const [year] = useState(getUrlParam('year'));
    const [licensor] = useState(getUrlParam('licensor'));

    const mainTable = useRef(null);
    const table = useRef(null);
    const scrollableheader = useRef(null);
    const scrollbar = useRef(null);

    const defaultImage = { imgPlaceholder };

    const scrollHandler = () => {
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        const tableElement = table.current;
        const rect = tableElement.getBoundingClientRect();
        const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

        if (window.scrollY > 140) {
            document.getElementById('siteHeader').style.boxShadow = 'none';

        } else {
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }

        if (distanceToBottom < tableDistanceToBottom + 40) {
            scrollbar.current.classList.add('bottom_fixed');
        } else {
            scrollbar.current.classList.remove('bottom_fixed');
        }

    };

    const handleScroll = (e, ref) => {
        if (ref === 'table') {
            scrollableheader.current.scrollTo(table.current.scrollLeft, 0);
            scrollbar.current.scrollTo(table.current.scrollLeft, 0);
        }

        if (ref === 'header') {
            table.current.scrollTo(scrollableheader.current.scrollLeft, 0);
            scrollbar.current.scrollTo(scrollableheader.current.scrollLeft, 0);
        }

        if (ref === 'scrollbar') {
            scrollableheader.current.scrollTo(scrollbar.current.scrollLeft, 0);
            table.current.scrollTo(scrollbar.current.scrollLeft, 0);
        }
    };

    const scrollHandlerBound = scrollHandler.bind();

    useEffect(() => {
        fetchData({ page, pageSize });
    }, [])

    useEffect(() => {
        document.addEventListener('scroll', scrollHandlerBound);

        setTimeout(() => {
            setTableWidth(mainTable.current.offsetWidth);
        }, 1000);

        return () => {
            document.removeEventListener('scroll', scrollHandlerBound);
            document.getElementById('siteHeader').style.boxShadow = '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        };
    }, []);

    const fetchData = (data) => {
        getReportSalesData(params.id, data, licensor).then(response => {
            setHeaders(response && response.records && response.records.length > 0 && Object.keys(response.records[0]) || []);
            setSalesData(response.records || []);
            setMeta(response.meta || {});
        })
    }

    const exportCsvFunc = (reportId) => {
        exportCsv(reportId, licensor).then(() => {}, () => {
            dispatch(
                displayNotification({
                    message: 'Oops... Something went wrong. Please try again.',
                    type: 'error',
                    timeOut: 5000
                })
            );
        })
    }

    return (
        <div className='royalty-report-wrapper'>
            <div className="c-my-records__flex-header">
                <div className="c-my-records__button-container">
                    <div className="u-flex">
                        <a href="/royalty-report-assistant">
                            <Icon className="back-icon" fill="black" icon="ARROW_LEFT_STYLE_2" size="14" />
                        </a>
                        <h1>Royalty Report Co-Pilot - {quarter} {year}</h1>
                        <div className="c-rec-panel__counter">
                            <span>
                                {meta.totalItems || 0}
                            </span>
                        </div>
                    </div>
                </div>

                <Button
                    type="secondary-2"
                    size="small"
                    onClick={() => exportCsvFunc(params.id)}
                >
                    Export
                </Button>
            </div>

            <div className="c-my-records">
                <div className={'ui-table table'}>
                    <div className="ui-table__wrapper table_ui">
                        <div className="ui-table__header-container">
                            <div
                                className="c-rec-table__table-holder table_header_wrapper"
                            >
                                <div ref={scrollableheader} onScroll={(e) => handleScroll(e, 'header')} className="table_wrapper_header">
                                    <table className="ui-table__top-header -dark">
                                        <thead>
                                            <tr>
                                                {includes(headers, 'Image') && (<th className='ui-table__head-cell image-th'>Image</th>)}

                                                {headers.map((period) => {
                                                    if(period !== 'Image') {
                                                        return(
                                                            <th className='ui-table__head-cell'>{period}</th>
                                                        )
                                                    }
                                                })}
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div className="c-rec-table">
                            <div
                                className="c-rec-table__table-holder c-rec-table__main-table"
                                ref={table}
                                onScroll={(e) => handleScroll(e, 'table')}
                                style={{ minHeight: 100 }}
                            >
                                <table>
                                    <tbody>
                                        {toArray(salesData).map((record) => {
                                            return (
                                                <tr key={'ui-table__body-'}>
                                                    {includes(headers, 'Image') && (
                                                        <td className='image-td ui-table__body-cell'>
                                                            <div className="c-rec-table__image-icon">
                                                                <MediaView
                                                                    src={record['Image']}
                                                                    alt="image"
                                                                    fallbackSrc={fallbackImageSmall}
                                                                    useImgTag
                                                                />
                                                            </div>
                                                            {record['Image'] !== defaultImage && (
                                                                <MediaView
                                                                    src={record['Image']}
                                                                    className="c-rec-table__hover-image"
                                                                    fallbackSrc={fallbackImageSmall}
                                                                    useImgTag
                                                                />
                                                            )}
                                                        </td>
                                                    )}

                                                    {headers.map((header) => {
                                                        if (header !== 'Image') {
                                                            return (
                                                                <td
                                                                    className="ui-table__body-cell"
                                                                >
                                                                    <span>{record[header] || ''}</span>
                                                                </td>
                                                            )
                                                        }
                                                        
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        ref={scrollbar}
                        className="c-rec-table__bottom-scrollbar"
                        onScroll={(e) => handleScroll(e, 'scrollbar')}
                    >
                        <div
                            style={{
                                height: 20, width: (table.current && table.current.scrollWidth) || 0
                            }}
                        />
                    </div>
                </div>

                {/* {!!salesData && size(salesData) > 0 && (<DataViewPagination
                    extraclass={'marg-t-40'}
                    totalItems={!!meta && !!meta.totalItems && meta.totalItems || 0}
                    fetchData={(params) => fetchData(params)}
                    pageLimit={pageSize}
                    pageNum={page}
                />)} */}
            </div>

            {!size(salesData) && !toArray(salesData).length && (
                <div className="u-text-center u-margin-top-4X">
                    <h3 className="c-my-records__no-data-header">
                        No Data Available
                    </h3>
                    <div className='no-data-catalogue'>
                        <p className='c-my-records__no-data-text'>
                            You have no data here.
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(Report);