import React, { useEffect, useRef, useState } from 'react';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import { showModal } from 'src/js/actions/xelacore';
import Button from 'modules/UiKit/components/Button/Button';
import { ReactComponent as FilterIcon } from 'components/static/Icon/svg-icons/filter.svg';
import { ClickableText } from 'src/js/components/ProductListElements';
import MatchingModal from 'modules/RetailerListings/modals/MatchingModal';
import recordPlaceholder from 'statics/imgs/record-placeholder.png';
import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import { Icon } from 'src/js/components/static';
import { cloneDeep } from 'lodash';
import AggregatedFilters from '../components/Filters';
import AggregatedSearch from '../components/SearchItems';
import {
    getMainImage
} from 'src/js/helpers/dataHelpers';
import { ReactComponent as ExternalIcon } from 'src/js/components/static/Icon/svg-icons/external-icon.svg';
import MediaView from 'src/js/components/MediaView';


export default function UiTable({
    dispatch,
    headersArr,
    aggregations,
    list,
    updateTable,
    totalResults,
    page,
    pageSize
}) {
    const [searchBox, setSearchBox] = useState(null);
    const [tableWidth, setTableWidth] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [filtersObj, setFiltersObj] = useState({});
    const [searchObj, setSearchObj] = useState({});

    const mainTable = useRef(null);
    const refPopover = useRef(null);
    const table = useRef(null);
    const header = useRef(null);
    const scrollbar = useRef(null);

    const scrollHandler = () => {
        if (table.current.getBoundingClientRect().top < 140) {
            document.getElementById('siteHeader').style.boxShadow = 'none';
        } else {
            document.getElementById('siteHeader').style.boxShadow =
                '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }
    }

    const scrollHandlerBound = scrollHandler.bind();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (refPopover.current && !refPopover.current.contains(event.target)) {
                setSearchBox(null);
            }
        };
        
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('scroll', scrollHandlerBound);

        setTimeout(() => {
            setTableWidth(mainTable.current.offsetWidth)
        }, 1000);
        
        return() => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('scroll', scrollHandlerBound);
            document.getElementById('siteHeader').style.boxShadow =
                '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }
    }, []);

    const handleChangeSearch = (event) => {
        setInputValue(event.target.value);
    }

    const openSearchBox = (el) => {
        let searchText = searchBox;

        if (
            (el && el.key === searchBox) ||
            (el && el.label === searchBox)
        ) {
            searchText = '';
        } else {
            searchText = el.key || el.label;
        }

        setSearchBox(searchText);
        setInputValue(searchObj[el.key])
    }

    const clearInput = () => {
        setInputValue('');
    }

    const clearSearch = (e, key, all) => {
        e.stopPropagation();

        let cloneSearchObj = cloneDeep(searchObj);

        if(all) {
            cloneSearchObj = {}
        } else {
            delete cloneSearchObj[key.key]
        }

        setInputValue('');
        setSearchObj(cloneSearchObj);

        return updateTable(filtersObj, cloneSearchObj, {page: 1, page_size: pageSize});
    }

    const showMatch = (el) => {
        const modalContent = (
            <MatchingModal
                data={el}
                dispatch={dispatch}
            />
        );

        dispatch(showModal(modalContent));
    }

    const handleScroll = (e, ref) => {
        if (ref === 'table') {
            header.current.scrollTo(table.current.scrollLeft, 0);
            scrollbar.current.scrollTo(table.current.scrollLeft, 0);
        }

        if (ref === 'header') {
            table.current.scrollTo(header.current.scrollLeft, 0);
            scrollbar.current.scrollTo(header.current.scrollLeft, 0);
        }

        if (ref === 'scrollbar') {
            header.current.scrollTo(scrollbar.current.scrollLeft, 0);
            table.current.scrollTo(scrollbar.current.scrollLeft, 0);
        }
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const filterTable = (group, filter) => {

        if(!filtersObj[group.key]) {
            filtersObj[group.key] = [];
            filtersObj[group.key].push(filter.key);
        } else {
            if(!(includes(filtersObj[group.key], filter.key))) {
                filtersObj[group.key].push(filter.key);
            } else {
                remove(filtersObj[group.key], item => {
                    return item === filter.key
                });

                if(!filtersObj[group.key].length) {
                    delete filtersObj[group.key]
                }
            }
        }

        setFiltersObj(filtersObj);

        return updateTable(filtersObj, searchObj, {page: 1, page_size: pageSize});
    }

    const clearFilters = () => {
        setFiltersObj({});
        return updateTable({}, searchObj);
    }

    const searchTable = (event, group) => {
        let value = !!event && event.target.value;

        if (event.key === 'Enter' && size(value) > 0) {
            if(!searchObj[group.key]) {
                searchObj[group.key] = [];
                searchObj[group.key] = value;
            } else {
                searchObj[group.key] = value;
            }
    
            setSearchBox(null)
            return updateTable(filtersObj, searchObj);
        }
    }

        return (
            <div className='ui-table'>
                <div className="ui-table__panel-nav">
                    <AggregatedFilters
                        aggregations={aggregations}
                        showFilters={showFilters}
                        headersArr={headersArr}
                        filtersObj={filtersObj}
                        filterTable={(group, filter) => filterTable(group, filter)}
                        closeFilters={() => setShowFilters(false)}
                        clearFilters={() => clearFilters()}
                        toggleFilters={() => toggleFilters()}>
                    </AggregatedFilters>

                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => toggleFilters()}
                        iconLeft={
                            <FilterIcon height="14"></FilterIcon>
                        }
                    >
                        <div className="c-rec-panel__nav__content">
                            Filters
                            {!!filtersObj && toArray(filtersObj).length > 0 && (
                                <div className="c-rec-panel__filters_counter">
                                    <span>{toArray(filtersObj).length}</span>
                                </div>
                            )}
                        </div>
                    </Button>
                </div>

                <div className="ui-table__wrapper">
                    <div className="ui-table__header-container">
                        <div
                            className="c-rec-table__table-holder"
                            ref={header}
                            onScroll={(e) => handleScroll(e, 'header')}
                        >
                            <table className="ui-table__top-header">
                                <thead>
                                <tr ref={mainTable}>
                                    {headersArr.map((el) => {
                                        const key = el.key;

                                        return (
                                            <th
                                                className={`ui-table__head-cell ${el.multiple === 'true' ? 'big-cell' : ''}`}
                                                key={`${key}-mrhead`}
                                            >
                                                <div className="u-flex-align c-table-search">
                                                    { el.search && (
                                                        <Icon
                                                            className="u-clickable marg-r-5"
                                                            icon="search"
                                                            fill="black"
                                                            onClick={() => openSearchBox(el)}
                                                        />
                                                    )}

                                                    {searchBox === key && (
                                                        <div
                                                            ref={refPopover}
                                                            className="c-table-search__popover"
                                                        >
                                                            <input
                                                                className="form-control"
                                                                autoFocus
                                                                value={inputValue}
                                                                onChange={(e) => handleChangeSearch(e)}
                                                                onKeyPress={(e) => searchTable(e, el)}
                                                                placeholder={'Search for ' + el.label}
                                                            />
                                                            <Icon
                                                                className="u-clickable clear-icon"
                                                                onClick={() => clearInput()}
                                                                icon="BIN"
                                                                fill="gray"
                                                            />
                                                        </div>
                                                    )}

                                                    {el.label}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                                </thead>
                            </table>

                            {!!toArray(searchObj).length && (
                                <AggregatedSearch
                                    tableWidth={tableWidth}
                                    headersArr={headersArr}
                                    clearSearch={(e, key, all) => clearSearch(e, key, all)}
                                    searchObj={searchObj}
                                    openSearchBox={(el) => openSearchBox(el)}
                                    handleChangeSearch={(e) => handleChangeSearch(e)}>
                                </AggregatedSearch>
                            )}
                        </div>
                    </div>

                    <div className="c-rec-table">
                        <div
                            className="c-rec-table__table-holder c-rec-table__main-table"
                            ref={table}
                            onScroll={(e) => handleScroll(e, 'table')}
                            style={{ minHeight: 100 }}
                        >
                            <table>
                                <tbody>
                                    {list.map((el, index) => {
                                        return (
                                            <tr key={`ui-table__body-${index}`}>
                                                {headersArr.map((header) => {
                                                    let value = !!header.parentKey ? el[header.parentKey][header.key] : el[header.key];
                                                    const image = getMainImage(el['images']);
                                                    const matchedStatus = header.key === 'matchStatus' && value === 'matched';

                                                    return (
                                                        <td className={`ui-table__body-cell ${header.multiple === 'true' ? 'big-cell' : ''} ${((header.key === 'matchStatus') || (header.key === 'listingStatus')) ? 'match-status' : '' }`} key={`ui-table__body-cell-${header.key}`}>
                                                            { (!!header.action || !!matchedStatus) && (
                                                                <div className='ui-table__body-cell-multiple'>
                                                                    { header.key === 'title' && (
                                                                        <div className='c-rec-table__cell c-rec-table__image-cell'
                                                                            onClick={() => {
                                                                                window.open(
                                                                                    el['url'],
                                                                                    '_blank'
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div className='ui-table__body-cell-image c-rec-table__image-icon'>
                                                                                <MediaView
                                                                                    src={image}
                                                                                    fallbackSrc={recordPlaceholder}
                                                                                    useImgTag
                                                                                />
                                                                            </div>
                                                                            
                                                                            <MediaView
                                                                                src={image}
                                                                                className="c-rec-table__hover-image"
                                                                                fallbackSrc={recordPlaceholder}
                                                                                useImgTag
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    <ClickableText
                                                                        func={() => {
                                                                            if(header.key === 'matchStatus') {
                                                                                showMatch(el);
                                                                            }

                                                                            if(header.key === 'title') {
                                                                                window.open(
                                                                                    el['url'],
                                                                                    '_blank'
                                                                                  );
                                                                            }

                                                                            if(header.key === 'merchantName') {
                                                                                window.open(
                                                                                    el['retailerMerchant']['merchantUrl'],
                                                                                    '_blank'
                                                                                  );
                                                                            }
                                                                        }}
                                                                        value={value}
                                                                    />

                                                                    { ((header.key === 'merchantName') || (header.key === 'title')) && (
                                                                        <ExternalIcon></ExternalIcon>
                                                                    )}
                                                                </div>
                                                            )}

                                                            { !header.action && !matchedStatus && (
                                                                <div className={`ui-table__body-cell-value
                                                                                ${el[header.key] === 'listed'
                                                                                    ? 'success-status'
                                                                                    : (value === 'unmatched') || (value === 'not matched') || (value === 'delisted')
                                                                                    ? 'rejected-status'
                                                                                    : ''}
                                                                                `}>
                                                                    { header.key === 'retailerName' && (
                                                                        <div className='ui-table__body-cell-image-icon'>
                                                                            <img src={el['retailerDetail']['retailerLogoImage'] || recordPlaceholder}/>
                                                                        </div>
                                                                    )}

                                                                    {value}
                                                                </div>
                                                            )}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </div>

                    <div
                        ref={scrollbar}
                        className="c-rec-table__bottom-scrollbar"
                        onScroll={(e) => handleScroll(e, 'scrollbar')}
                    >
                        <div
                            style={{
                                height: 20,
                                width:
                                    (table.current &&
                                        table.current.scrollWidth) ||
                                    0
                            }}
                        />
                    </div>
                </div>

                { !!list && size(list) > 0 && (
                    <DataViewPagination
                        totalItems={totalResults || 0}
                        fetchData={(params) => updateTable(filtersObj, searchObj, params)}
                        pageLimit={pageSize}
                        pageNum={page}
                    />
                )}
            </div>
        );
}