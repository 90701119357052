export const excludedLicensorData = [
    'company_brand',
    'company_sub_brand',
    'company_character',
    'is_licensed',
    'licensor',
    'is_character_licensed',
    'additional',
    'registered_by_user',
    'uploaded_by'
];

export const excludedAgentData = [
    'company_brand',
    'company_sub_brand',
    'company_character',
    'is_licensed',
    'is_character_licensed',
    'additional',
    'registered_by_user',
    'uploaded_by'
];


export const excludedLicenseeCatalog = [
    'additional',
    'uploaded_by'
];

export const excludedLicenseeMyRecords = [
    'last_registered_at',
    'updated_at'
];

export const staticAttributesDates = [
    'flow_through_end_date',
    'flow_through_start_date',
    'promotion_period_end',
    'promotion_period_start'
];

export const esgConstants = [
    {
        key: 'pd',
        label: 'Product Detail'
    },
    {
        key: 'sc',
        label: 'Scoring'
    },
    {
        key: 'mp',
        label: 'Mapping'
    },
    {
        key: 'co',
        label: 'Components'
    },
    {
        key: 'gt',
        label: 'Garment Type'
    },
    {
        key: 'cr',
        label: 'Care'
    },
    {
        key: 'ts',
        label: 'Testing & Safety'
    },
    {
        key: 'sp',
        label: 'Service Providers'
    }
]
