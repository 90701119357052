import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import isPlainObject from 'lodash/isPlainObject';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import levenshtein from 'js-levenshtein';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import {
    getProductStatusList,
    keyFields,
    conditionalFields,
    getMaxMinDate
} from 'src/js/constants/productConstants/index';
import { Modal } from 'src/js/components/static';
import { nullToString, valueToString } from 'src/js/helpers/objects';
import { mapIps } from 'src/js/helpers/ipHierarchy';
import {
    getSortedFormFields,
    getGroupedHeaders,
    getCollapsedHeaderGroups
} from 'src/js/helpers/headers';
import { bulkEditRecordImages, editRecord } from 'src/js/actions/records';
import { setSchemas } from 'src/js/actions/schemas';
import { getProductInfo } from 'src/js/actions/dataManager';
import {
    displayNotification,
    showModal,
    hideModal
} from 'src/js/actions/xelacore';
import ProductReRegistrationModal from './ProductReRegistrationModal';
import LicenseeRightsGroup from './LicenseeRightsGroup';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';
import classnames from 'classnames';
import Button from 'modules/UiKit/components/Button/Button';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';

const mapStateToProps = ({ xelacore }) => ({ xelacore });
const mapDispatchToProps = (dispatch) => ({ dispatch });

@connect(mapStateToProps, mapDispatchToProps)
export default class ProductFormEdit extends Component {
    constructor(props) {
        super(props);
        const {
            data: { validations: validations = [] },
            orderByValidations,
            hiddenFields = []
        } = props;

        this.defaultHeaders = defaultHeaders
            .filter((header) => header.config && header.config.group)
            .filter((h) => h.showInForm !== false)
            .filter(({ dataName }) => !hiddenFields.includes(dataName));

        const validationsHash = isPlainObject(validations)
            ? validations
            : validations.reduce(
                (hash, el) => ({ ...hash, [el.field]: el }),
                {}
            );

        const sortedHeaders = orderByValidations
            ? getSortedFormFields(validationsHash, this.defaultHeaders)
            : this.defaultHeaders;

        this.groupedHeaders = getGroupedHeaders(sortedHeaders);
        const arrayFields = this.formatArrayFields(props.data);
        const arrayImagesFields = this.formatArrayImagesFields(props.data);
        this.state = {
            data: {
                ...cloneDeep(props.data),
                ...arrayImagesFields,
                ...arrayFields
            },
            uploadedImages: [],
            originalData: cloneDeep(props.data),
            editedData: {},
            localValidations: {},
            fetching: false,
            submitted: false,
            confirmedCodeModal: false,
            showConfirmingReRegMessage: false,
            showUnconfirmCodeMessage:'',
            showConfirmedMessage: false,
            licensorName: this.props.licensor_brand_id,
            collapsed: getCollapsedHeaderGroups(sortedHeaders, validationsHash)
        };
    }

    formatArrayFieldWithValidations(data, dataName) {
        const values = data[dataName] || [];
        return values.map((val, i) => {
            const formatVals = isPlainObject(data.validations)
                ? this.formatValidations(data.validations)
                : data.validations;
            const validations = formatVals
                ? formatVals.filter(
                    (validation) => validation.field === `${dataName}.${i}`
                )
                : [];
            return {
                value: val,
                validations
            };
        });
    }

    formatArrayFields(data) {
        const arrayFields = {};
        const arrayHeaders = this.defaultHeaders.filter(
            (elem) => elem.form && elem.form.type === 'array'
        );
        arrayHeaders.forEach((header) => {
            const { dataName } = header;
            arrayFields[dataName] = this.formatArrayFieldWithValidations(
                data,
                dataName
            );
        });
        return arrayFields;
    }

    formatArrayImagesFields(data) {
        const arrayImagesFields = {};
        const imagesHeaders = this.defaultHeaders.filter(
            (elem) => elem.form && elem.form.type === 'imageArray'
        );

        imagesHeaders.forEach((header) => {
            const { dataName } = header;
            const values = data[dataName] || [];
            arrayImagesFields[dataName] = values.map((val, i) => {
                const formatVals = isPlainObject(data.validations)
                    ? this.formatValidations(data.validations)
                    : data.validations;
                const validations = formatVals
                    ? formatVals.filter(
                        (val) => val.field === `${dataName}.${i}`
                    )
                    : [];
                return {
                    ...val,
                    validations
                };
            });
        });
        return arrayImagesFields;
    }

    deformatImageFields(data) {
        const arrayImagesFields = {};
        const imagesHeaders = this.defaultHeaders.filter(
            (elem) => elem.form && elem.form.type === 'imageArray'
        );
        imagesHeaders.forEach((header) => {
            const { dataName } = header;
            const values = data[dataName] || [];
            arrayImagesFields[dataName] = values.reduce((arr, val) => {
                if (!val.local_url && !val.original_url) {
                    return arr;
                }
                const imageVal = { ...val };
                delete imageVal.validations;
                arr.push(imageVal);
                return arr;
            }, []);
        });
        return arrayImagesFields;
    }

    deformatArrayFields(data) {
        const arrayHeaders = this.defaultHeaders.filter(
            (elem) => elem.form && elem.form.type === 'array'
        );
        const arrayFields = {};
        arrayHeaders.forEach((header) => {
            const { dataName } = header;
            arrayFields[dataName] = this.deFormatArrayField(data, dataName);
        });
        return arrayFields;
    }

    deFormatArrayField(data, dataName) {
        const values = data[dataName] || [];
        return values.map((val) => val.value).filter((val) => val.length > 0);
    }

    componentWillMount() {
        this.loadSchemasForValidation();
    }

    loadSchemasForValidation() {
        // Fetch the schemas that we're using to validate our form data
        // These are set and updated on the backend
        const { dispatch, xelacore } = this.props;
        if (!isEmpty(xelacore.schemas['catalog'])) return true;
        return dispatch(setSchemas('catalog'));
    }

    // Change function for all normal form elements such as input / select etc
    onChange(e, key) {
        const { editedData, data, originalData } = this.state;
        const {
            logChange,
            xelacore: {
                categories: { linkedBrands }
            }
        } = this.props;

        const formEl = e && e.preventDefault;
        if (formEl) e.preventDefault();
        const value = formEl ? e.target.value : e;

        const newData = { ...editedData, [key]: value };

        if (valueToString(value) === valueToString(originalData[key])) {
            delete newData[key];
        }

        const updatedData = Object.assign({}, data, { [key]: value });

        if (!!updatedData.static_attributes && !!updatedData.static_attributes.hasOwnProperty(key)) {
            updatedData.static_attributes[key] = value;
        }

        if (key === 'licensor_brand_id') {
            const newLicensor = linkedBrands.rows.find(
                ({ brand_id }) => brand_id === e.target.value
            );

            updatedData.licensor = !!newLicensor && newLicensor.licensor_organisation_name || '';
            updatedData.licensor_organisation_id = !!newLicensor && newLicensor.organisation_id || '';
        }

        if (key === 'ips') {
            updatedData.ips = mapIps(value);
            newData.licensor_brand_id = !!value && !!value[0] && !!value[0][0] && value[0][0].ip_id;
            newData.ips = mapIps(value);
            newData.agent_organisation_id = null;
            updatedData.agent_organisation_id = null;
            newData.agent_organisation = null;
            updatedData.agent_organisation = null;
        }

        if (key === 'agent_organisation') {
            newData.agent_organisation_id = value;
            updatedData.agent_organisation_id = value;
        }

        this.setState(
            {
                editedData: newData,
                data: updatedData
            },
            () => (logChange ? logChange(this.state.editedData) : null)
        );
    }

    // Change function for things like objects & arrays
    // Anything that's being submitted to us must conform
    // to this type of updating
    changeObject(val, key) {
        const { data, editedData } = this.state;
        const { logChange } = this.props;

        this.setState(
            {
                editedData: Object.assign({}, editedData, { [key]: val }),
                data: Object.assign({}, data, { [key]: val })
            },
            () => (logChange ? logChange(this.state.editedData) : null)
        );
    }

    // Helper function to display the notification
    setNotification(type = 'success') {
        const { dispatch } = this.props;
        const message = {
            error: 'There has been an error updating your record',
            success: 'Record successfully updated'
        };

        return dispatch(
            displayNotification({
                type,
                message: message[type]
            })
        );
    }

    getProductInfo() {
        const {
            data,
            data: { record_id }
        } = this.state;
        const { dispatch, save } = this.props;
        return dispatch(getProductInfo('records', record_id))
            .then((resp) => {
                const { record: { version_id, validation_version_id } = {} } =
                    resp.data;
                if (
                    version_id === data.version_id ||
                    validation_version_id === data.version_id
                ) {
                    // Recursion baby
                    // Because it takes some time for backend to updated
                    // We refetch the product records until we know that the
                    // product records have been updated. It ain't pretty
                    // but you gotta do what you gotta do
                    // Yes this is messy but the backend is super f*****g slow at updating at times
                    return setTimeout(() => this.getProductInfo(), 1000);
                }

                this.setState({
                    fetching: false,
                    data: get(resp, 'data.record')
                });

                this.setNotification();
                save();
            })
            .catch(() => {
                this.setState({ fetching: false });
                this.setNotification('error');
            });
    }

    resubmit(e, modalConfirmField, confMessage) {
        const { dispatch, isModalView } = this.props;
        e.persist();
        this.setState(
            {
                [modalConfirmField]: true,
                [confMessage]: false
            },
            () => this.submitForm(e)
        );
        if (!isModalView) dispatch(hideModal());
    }

    showCodeModal(countChangedCodes) {
        const { dispatch } = this.props;
        const body = (
            <div>
                <p>
                    You are going to modify{' '}
                    <i>
                        {' '}
                        {countChangedCodes} Confirmed Concept Code
                        {countChangedCodes === 1 ? '' : 's'}
                    </i>
                    . After the update,{' '}
                    {countChangedCodes === 1 ? 'this' : 'these'} code
                    {countChangedCodes === 1 ? '' : 's'} will be unconfirmed and
                    removed from this record.
                </p>
                <p>Do you wish to continue?</p>
                <div>
                    <Button
                        size={'small'}
                        type={'primary'}
                        onClick={(e) => this.resubmit(e, 'confirmedCodeModal')}
                    >
                        Confirm
                    </Button>
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => dispatch(hideModal())}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
        const modal = <Modal title="Confirm changes" body={body} />;
        return dispatch(showModal(modal));
    }

    showReregistrationModal(editedData) {
        const { dispatch, xelacore, data: defaultData, ipLevels, ips } = this.props;
        const modal = (
            <ProductReRegistrationModal
                defaultData={defaultData}
                editedData={editedData}
                onHide={() => dispatch(hideModal())}
                onConfirm={(e) => this.resubmit(e, 'confirmedReregistration')}
                ipLevels={ipLevels}
                ips={ips}
                dispatch={dispatch}
                xelacore={xelacore}
            />
        );
        return dispatch(showModal(modal));
    }

    countChangedCodes(editedData, defaultData) {
        const key = 'concept_code';
        const confirmedKey = 'concept_code_confirmed_info';
        return (
            !!editedData[key] &&
            defaultData[key].filter(
                (code) =>
                    !!defaultData[confirmedKey].find(
                        (confCode) => confCode.concept_code === code
                    ) && !editedData[key].find((updCode) => updCode === code)
            ).length
        );
    }

    isReregistrationModal(editedData, defaultData) {
        if (!defaultData.registered_with) {
            return false;
        }

        const changedKeyFields = keyFields.find(
            (field) => {
                let checkField = field === 'ip_paths' ? 'ips' : field === 'licensee_category_path' ? 'licensee_category_id' : field;
                let isSimilar = checkField === 'ips'
                    ? isEqual(defaultData[checkField], editedData[checkField])
                    : valueToString(defaultData[checkField]) === valueToString(editedData[checkField]);

                return editedData.hasOwnProperty(checkField) && !isSimilar;
            }
        );

        return !!(
            changedKeyFields ||
            conditionalFields.find(
                (field) =>
                    editedData.hasOwnProperty(field) &&
                    (levenshtein(defaultData[field], editedData[field]) * 100) /
                    defaultData[field].length >
                    50
            )
        );
    }

    closeForm(e, confirmMessage) {
        e.preventDefault();

        return confirmMessage ? this.setState({
            showConfirmingReRegMessage: false
        }) : this.props.close();
    }

    validateAssociatedIpFields() {
        const { localValidations, data } = this.state;
        const associatedIpHeaders = this.defaultHeaders.filter(
            (elem) => elem.config && elem.config.isAssociatedIp === true
        );
        const associatedIpFields = this.deformatArrayFields(data);
        const validations = { ...localValidations };
        let isValidationPassed = true;
        associatedIpHeaders.forEach((header) => {
            const { dataName } = header;
            if (data[dataName].length && !associatedIpFields[dataName].length) {
                validations[dataName] = 'Have to be filled';
                isValidationPassed = false;
            } else {
                delete validations[dataName];
            }
        });
        this.setState({ localValidations: validations });
        return isValidationPassed;
    }

    submitForm(e) {
        e.preventDefault();
        const {
            dispatch,
            opaDataMatch,
            data: defaultData,
            save,
            setSent,
            isModalView
        } = this.props;
        const {
            data,
            originalData,
            localValidations,
            editedData,
            confirmedCodeModal,
            confirmedReregistration
        } = this.state;

        if (!this.validateAssociatedIpFields()) {
            return this.setState({ submitted: true });
        }

        const arrayFields = this.deformatArrayFields(data);
        const imageFields = this.deformatImageFields(data);
        const isImagesEqual = imageFields.images
            ? isEqual(imageFields.images.sort(), originalData.images.sort())
            : true; //imageFields && imageFields.images && imageFields.images.filter(e => !originalData.images.includes(e));
        const editData = { ...editedData, ...imageFields, ...arrayFields };

        const filteredBrandGroupFields = this.defaultHeaders.filter(item => item.config && item.config.group === 'Brand Attributes')
            .map(field => field.dataName);
        data.static_attributes = data.static_attributes || {};
        filteredBrandGroupFields.forEach(item => {
            if (data[item] !== undefined && !data[item]) {
                delete data[item];
            }
            if (!!data[item]) {
                data.static_attributes[item] = clone(data[item]);
                delete data[item];
            }
        });

        const countChangedCodes = this.countChangedCodes(
            editData,
            defaultData
        );

        if (
            isEmpty(data.product_name) ||
            (!data.gtin_waiver && isEmpty(data.gtin))
        ) {
            this.setState({ submitted: true });
            return;
        }

        if ((isModalView && data.registered_with && !!countChangedCodes && !confirmedCodeModal)
            || (data.registered_with && !isEmpty(opaDataMatch) && !!countChangedCodes && !confirmedCodeModal)) {
            if (isModalView) {
                const message = (
                    <div>
                        You are going to modify{' '}
                        <i>
                            {' '}
                            {countChangedCodes} Confirmed Concept Code
                            {countChangedCodes === 1 ? '' : 's'}
                        </i>
                        . After the update,{' '}
                        {countChangedCodes === 1 ? 'this' : 'these'} code
                        {countChangedCodes === 1 ? '' : 's'} will be unconfirmed and
                        removed from this record. Do you wish to continue?
                    </div>
                );
                return this.setState({ showUnconfirmCodeMessage: message });
            } else {
                return this.showCodeModal(countChangedCodes);
            }
        }

        if (
            this.isReregistrationModal(editData, defaultData) &&
            !confirmedReregistration
        ) {
            if (isModalView) {
                return this.setState({ showConfirmingReRegMessage: true });
            } else {
                return this.showReregistrationModal(editData);
            }
        }

        if (
            Object.keys(editedData).length === 0 &&
            isImagesEqual &&
            this.state.uploadedImages.length === 0
        ) {
            return save();
        }

        this.setState({ fetching: true });
        // I'm not sure if we still need this nullToString function but
        // I'm keeping it here all the same
        const obj = {
            record: nullToString({
                ...data,
                ...imageFields,
                ...arrayFields
            })
        };

        if (!isEmpty(localValidations)) {
            return this.setState({ fetching: false, submitted: true });
        }

        if (isModalView) {
            setSent(true);
        }

        const imagePromise =
            this.state.uploadedImages.length > 0
                ? dispatch(
                    bulkEditRecordImages(
                        [data.record_id, data.record_id],
                        this.state.uploadedImages
                    )
                )
                : Promise.resolve();

        return imagePromise.then(() => {
            if (this.state.uploadedImages.length > 0) {
                delete obj.record.images;
            }

            dispatch(editRecord(data.record_id, obj)).then(() => {
                if (
                    ((data.gtin_waiver && data.gtin !== originalData.gtin) ||
                    (!data.gtin_waiver && data.mpn !== originalData.mpn)) && !isModalView
                ) {
                    browserHistory.push('/my-records');
                }

                if (isModalView) {
                    save();
                }
            });
        });
    }

    formatValidations(v) {
        // We can get two different values for the form right now
        // So we use this lil function to format them properly to match
        // our desired layout.
        // TODO: See if we can get a standardised validations layout

        return Object.keys(v).map((k) => ({
            field: k,
            level: v[k].level,
            message: v[k].message,
            value: v[k].value
        }));
    }

    getValuesList(fieldName, values, data) {
        return fieldName === 'product_status'
            ? getProductStatusList(data, values)
            : values;
    }

    componentWillUnmount() {
        this.setState({ data: this.state.originalData });
    }

    setGroupCollapsed = (groupName) => {
        this.setState(({ collapsed }) => ({
            collapsed: {
                ...collapsed,
                [groupName]: !collapsed[groupName]
            }
        }));
    };


    renderLicenseeRightsGroup(elements) {
        const {
            data,
            data: { validations = [] },
            localValidations
        } = this.state;
        const { isModalView, ipLevels, ips, isAgent, agenciesData, originalData } = this.props;

        return (
            <LicenseeRightsGroup
                isAgent={isAgent}
                elements={elements}
                data={data}
                validations={validations}
                isModalView={isModalView}
                localValidations={localValidations}
                onChange={(e, dataName, additionalValue) => this.onChange(e, dataName, additionalValue)}
                formatValidations={this.formatValidations}
                changeObject={this.changeObject}
                ipLevels={ipLevels}
                ips={ips}
                agenciesData={agenciesData}
                originalData={originalData}
            />
        );
    }

    render() {
        const {
            data,
            data: { validations = [] },
            fetching,
            localValidations,
            submitted,
            collapsed,
            showConfirmingReRegMessage,
            showUnconfirmCodeMessage
        } = this.state;

        const { isModalView, xelacore, isLicensor, categoryAttributes, hasStaticAttributes, isAgent, agenciesData } = this.props;
        const renderFieldsSet = (elements, groupName) => (
            <div
                className={classnames({
                    'c-product__editing-group-content': groupName,
                    'c-product__editing-group-content--collapsed':
                        collapsed[groupName],
                    'c-product__editing-group-content--ungrouped': !groupName
                })}
            >
                {groupName === 'Licensed Properties'
                    ? this.renderLicenseeRightsGroup(elements)
                    : elements
                        .filter((el) => {
                            const {
                                form: { hideIfEmpty },
                                dataName
                            } = el;
                            const value = data[dataName];
                            return (
                                !hideIfEmpty ||
                                (hideIfEmpty && value && value.length > 0)
                            );
                        })
                        .map((el, i) => {
                            // Breakout all the values from the header object
                            const {
                                form: {
                                    type,
                                    values = null,
                                    isGrouped,
                                    isMulti,
                                    fetchValues,
                                    reliesOn,
                                    showFullLabel = false
                                },
                                label,
                                dataName,
                                tooltip
                            } = el;

                            const id = `${dataName}--product-edit-listing-${i}`;
                            let value = data[dataName];

                            const formatVals = isPlainObject(validations)
                                ? this.formatValidations(validations)
                                : validations;

                            // Lots of props here eh?
                            return (
                                <FormItemWrapper
                                    key={id + '1'}
                                    id={id}
                                    isLicensor={isLicensor}
                                    isAgent={isAgent}
                                    data={data}
                                    type={type}
                                    name={dataName}
                                    label={label}
                                    value={value === null ? '' : value}
                                    values={this.getValuesList(
                                        dataName,
                                        values,
                                        data
                                    )}
                                    tooltip={tooltip['licensee']}
                                    onChange={(e, key) =>
                                        this.onChange(e, key || dataName)
                                    }
                                    onImageUploadChange={(uploadedImages) =>
                                        this.setState({
                                            uploadedImages
                                        })
                                    }
                                    updateObject={(val, key) =>
                                        this.changeObject(val, key)
                                    }
                                    validations={formatVals.filter(
                                        ({ field }) =>
                                            dataName === 'images'
                                                ? field.indexOf(dataName) > -1
                                                : type === 'array'
                                                    ? field.startsWith(dataName)
                                                    : type === 'dynamicCategory'
                                                        ? field.startsWith(dataName)
                                                        : type === 'dynamicArray'
                                                            ? field.startsWith(dataName)
                                                            : field === dataName || field === `static_attributes.${dataName}`
                                    )}
                                    isGrouped={isGrouped}
                                    isMulti={isMulti}
                                    showFullLabel={showFullLabel}
                                    fetchValues={fetchValues}
                                    reliesOn={reliesOn}
                                    localValidations={localValidations}
                                    dates={getMaxMinDate(dataName, data)}
                                    categories={xelacore.categories}
                                    agenciesData={agenciesData}
                                    categoryAttributes={categoryAttributes}
                                />

                            );
                        })}
            </div>
        );

        const showSpinner = !isModalView && fetching;

        return (
            <div className="u-relative">
                <form
                    className="c-product__editing-form"
                    onSubmit={(e) => this.submitForm(e)}
                >
                    <fieldset
                        className="c-product__editing-form__fieldset"
                    >
                        {showSpinner && (
                            <div className="c-product__editing-spinny">
                                <img src={imgPinkSpinner} />
                            </div>
                        )}

                        <div className="c-product__editing-groups-container">
                            {Object.keys(this.groupedHeaders.grouped).map(
                                (groupName) => {
                                    let showCategoryAttributes = groupName === 'Category Attributes' && !!categoryAttributes && categoryAttributes.length > 0;
                                    let showDynamicAttributes = groupName === 'Dynamic Attributes' && !!data.dynamic_attributes;
                                    let showBrandAttributes = groupName === 'Brand Attributes' && hasStaticAttributes && !!data.ips;

                                    return (groupName !== 'Category Attributes' && groupName !== 'Dynamic Attributes' && groupName !== 'Brand Attributes' || showCategoryAttributes || showDynamicAttributes || showBrandAttributes) && (

                                        <div
                                            key={groupName}
                                            className="c-product__editing-group"
                                        >
                                            <div
                                                onClick={() =>
                                                    this.setGroupCollapsed(
                                                        groupName
                                                    )
                                                }
                                                className={classnames(
                                                    'c-product__editing-group-header',
                                                    {
                                                        'c-product__editing-group-header--collapsed':
                                                            collapsed[groupName]
                                                    }
                                                )}
                                            >
                                                <h5>{groupName}</h5>
                                                <span>
                                                    <i />
                                                </span>
                                            </div>
                                            {renderFieldsSet(
                                                Object.values(
                                                    this.groupedHeaders.grouped[
                                                    groupName
                                                    ]
                                                ),
                                                groupName
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </div>

                        {renderFieldsSet(
                            Object.values(this.groupedHeaders.ungrouped)
                        )}

                        <div className="c-product__detail-listing-footer u-flex-gap u-flex-align--center">
                            {
                                // Prevent user from submitting the form if the change they have made
                                // reuslt in errors from our local validations
                                submitted &&
                                (!isEmpty(localValidations) || (!data.gtin_waiver && isEmpty(data.gtin))) && (
                                    <p className="c-form-element__local-validation">
                                        You have invalid fields. You must
                                        fix these before you can update your
                                        product details.
                                    </p>
                                )
                            }

                            {!showConfirmingReRegMessage && !size(showUnconfirmCodeMessage) && (
                                <div className="u-flex u-flex-align--center">
                                    <Button
                                        type="secondary"
                                        size="small"
                                        onClick={(e) => this.closeForm(e)}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        size={'small'}
                                        type={'primary'}
                                        submit
                                    >
                                        Update record
                                    </Button>
                                </div>
                            )}

                            {!!size(showUnconfirmCodeMessage) && isModalView && (
                                <div className="c-product__deregistration-confirmation">
                                    <div className="c-product__deregistration-confirmation__message">
                                        {showUnconfirmCodeMessage}
                                    </div>
                                    <div className="c-product__deregistration-confirmation__buttons">
                                        <Button
                                            type="secondary"
                                            size="small"
                                            onClick={() => this.setState({showUnconfirmCodeMessage:false})}>
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            type="primary"
                                            onClick={(e) => this.resubmit(e, 'confirmedCodeModal', 'showUnconfirmCodeMessage')}
                                        >
                                            Update Record
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {showConfirmingReRegMessage && isModalView && (
                                <div className="c-product__deregistration-confirmation">
                                    <div className="c-product__deregistration-confirmation__message">
                                        You have changed some core attributes which requires
                                        the record to be de-registered from the product
                                        catalogue. The Licensor will be notified about this
                                        action.
                                    </div>
                                    <div className="c-product__deregistration-confirmation__buttons">
                                        <Button
                                            type="secondary"
                                            size="small"
                                            onClick={(e) => this.closeForm(e, true)}>
                                            Cancel
                                        </Button>
                                        <Button
                                            size="small"
                                            type="primary"
                                            onClick={(e) =>
                                                this.resubmit(e, 'confirmedReregistration', 'showConfirmingReRegMessage')
                                            }
                                        >
                                            Update Record & De-Register
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
}
