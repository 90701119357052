import {
    NOTIFICATION_SHOW,
    NOTIFICATION_HIDE
} from 'src/js/constants/actions/xelacore';

export function notification(
    state = {
        displayNotification: false,
        content: {}
    },
    action = {}
) {
    switch (action.type) {
        case NOTIFICATION_SHOW:
            const content = action.notificationContent;
            const displayNotification = true;
            return Object.assign({}, state, { displayNotification, content });

        case NOTIFICATION_HIDE:
            return Object.assign({}, state, {
                displayNotification: false,
                content: {}
            });

        default:
            return state;
    }
}
