export const SET_TABLE_DATA = 'LICENSEE_DASHBOARD::SET_TABLE_DATA';
export const SET_TABLE_DATA_LAZY_LOADING =
    'LICENSEE_DASHBOARD::SET_TABLE_DATA_LAZY_LOADING';
export const SET_PRODUCTS_LINKED_TO_CONCEPTS_DATA =
    'LICENSEE_DASHBOARD::SET_PRODUCTS_LINKED_TO_CONCEPTS_DATA';
export const TOGGLE_LICENSEES_PANEL =
    'LICENSEE_DASHBOARD::TOGGLE_LICENSEES_PANEL';
export const TOGGLE_FILTERS_PANEL = 'LICENSEE_DASHBOARD::TOGGLE_FILTERS_PANEL';
export const TOGGLE_LOCAL_FILTER_OPTION =
    'LICENSEE_DASHBOARD::TOGGLE_LOCAL_FILTER_OPTION';
export const SET_ALL_LOCAL_FILTER_VALUES_FOR_SECTION =
    'LICENSEE_DASHBOARD::SET_ALL_LOCAL_FILTER_VALUES_FOR_SECTION';
export const CLEAR_ALL_FILTERS = 'LICENSEE_DASHBOARD::CLEAR_ALL_FILTERS';
export const SET_LICENSEES_DATA = 'LICENSEE_DASHBOARD::SET_LICENSEES_DATA';
export const SET_LICENSEES_DATA_LOADING =
    'LICENSEE_DASHBOARD::SET_LICENSEES_DATA_LOADING';
export const SET_UNSUBMITTED_CATALOGUES_DATA =
    'LICENSEE_DASHBOARD::SET_UNSUBMITTED_CATALOGUES_DATA';
export const SET_UNSUBMITTED_CATALOGUES_DATA_LOADING =
    'LICENSEE_DASHBOARD::SET_UNSUBMITTED_CATALOGUES_DATA_LOADING';
export const SET_FILTERS_OPTIONS = 'LICENSEE_DASHBOARD::SET_FILTERS_OPTIONS';
export const SET_FILTERS_OPTIONS_LOADING =
    'LICENSEE_DASHBOARD::SET_FILTERS_OPTIONS_LOADING';
export const TOGGLE_SELECTED_FILTER_OPTION =
    'LICENSEE_DASHBOARD::TOGGLE_SELECTED_FILTER_OPTION';
export const SET_ALL_FILTER_OPTIONS_SELECTED =
    'LICENSEE_DASHBOARD::SET_ALL_FILTER_OPTIONS_SELECTED';
export const SET_DASHBOARD_DATA = 'LICENSEE_DASHBOARD::SET_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA_LOADING =
    'LICENSEE_DASHBOARD::SET_DASHBOARD_DATA_LOADING';
export const SET_SELECTED_SECTION_FILTER_OPTION =
    'LICENSEE_DASHBOARD::SET_SELECTED_SECTION_FILTER_OPTION';
export const SET_CURRENT_CONCEPT_ANALYTICS_CHART =
    'LICENSEE_DASHBOARD::SET_CURRENT_CONCEPT_ANALYTICS_CHART';
