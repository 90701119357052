import React, { Component } from 'react';
import classnames from 'classnames';

export default class extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { value = '', bold = false, func } = this.props;
        const len = 50;

        const displayedValue =
            (value || '').length < len
                ? value
                : value.slice(0, len) + '...';

        return (
            <span
                className={classnames('c-product__detail-listing-item-clickable-text', {
                    'u-bold': bold
                })} onClick={() => func()}>
                {displayedValue}
            </span>
        )
    }
}
