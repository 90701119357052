import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call returns the set userData state, given an access token and a dataset key.
 *
 * @param  {String} token
 * @param  {String} key
 * @param  {Object} data
 * @return {Promise}
 */
export function setApiUserState(key, data) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'state', key].join('/'),
            method: 'post',
            data: data
        })
        .then(response => responseHandler(response, 'data', 'state'));
}

/**
 * This API call returns the get userData state, given a dataset key.
 *
 * @param  {String} key
 * @return {Promise}
 */
export function getApiUserState(key) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'state', key].join('/'),
            method: 'get'
        })
        .then(response => responseHandler(response, 'data', 'state'));
}

/**
 * This API call returns the profile userData, given an access token.
 *
 * @param  {String} token
 * @return {Promise}
 */
// Don't remove token form here. It isn't set automatically
export function getNewUserData(token) {
    return axios
        .request({
            url: [config.urls.auth, 'user'].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(response => responseHandler(response, 'data', 'user'));
}

/**
 * Returns org data for all or single relating to a user
 *
 * @param  {String} id
 * @return {Promise}
 */
export function fetchOrganisationsInfo(id) {
    return axios
        .request({
            url: [config.urls.auth, 'organisations', id].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call returns the set userData state, given an access token and a dataset key.
 *
 * @param  {String} creds.token
 * @param  {String} creds.password
 * @param  {Object} data
 * @return {Promise}
 */
export function resetPassword(creds) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'reset', creds.token].join('/'),
            method: 'post',
            data: { password: creds.password }
        })
        .then(responseHandler);
}

export function getAllUserInfo(token) {
    return axios
        .request({
            url: [config.urls.auth, 'user'].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}

/**
 * Returns custom reports if they exists
 */
export function fetchCustomReports() {
    return axios
        .request({
            url: 'v1/dashboard/quicksight-dashboard/',
            method: 'get'
        })
        .then(responseHandler);
}
