import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from './Select';
import toLower from 'lodash/toLower';
import {
    fetchLicensedProperites
} from 'src/js/apicalls/other/categories';

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

@connect(null, mapDispatchToProps)
export default class DynamicSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData(newBrandId) {
        const { valueProp, data } = this.props;
    
        let brandId = !!newBrandId ? newBrandId : data.licensor_brand_id;

        fetchLicensedProperites(data.licensor_organisation_id, brandId).then(({ data }) => {
            const selectData = !!data && data.properties;
            const dataToSet = selectData.map((el) => ({
                name: valueProp ? el[valueProp] : toLower(el.property),
                label: el.property
            }));

            this.setState({ values: dataToSet });
        })
    }

    componentDidUpdate(prevProps) {
        const { data, onChange, name } = this.props;

        if (
            prevProps.data.licensor_brand_id !== data.licensor_brand_id
        ) {
            this.fetchData();
            return onChange('', name, null);
        }
    }

    render() {
        const { values } = this.state;

        return (
            <div className="c-select__styled-select">
                <Select {...this.props} values={values}/>
            </div>
        );
    }
}
