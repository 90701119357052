import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import each from 'lodash/each';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { isUpdated } from 'src/js/helpers/dataHelpers';
import ListFields from './ListFields';
import classnames from 'classnames';
import { Icon, Tooltip, Auth } from 'src/js/components/static';
import {
    licenseeTooltips,
    licensorTooltips
} from 'src/js/constants/productConstants/index';
import ProductFormEdit from 'src/js/components/ProductFormEdit/ProductFormEdit';
import { parseV1Response } from 'src/js/helpers/arrays';

import {
    fetchIps,
    fetchIpsV3
} from 'src/js/apicalls/other/ips';
import { isAgent } from 'helpers/permissions';

const mapStateToProps = ({ xelacore }) => {
    return {
        xelacore,
        messages: get(xelacore, 'socket.messages', [])
    };
};
const mapDispatchToProps = (dispatch) => ({ dispatch });

@connect(mapStateToProps, mapDispatchToProps)
export default class Listing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changedData: {},
            openQuarantine: false,
            quarantineMode: false,
            modalActions: false,
            ips: []
        };

        this.popoverRef = React.createRef();
    }

    static propTypes = {
        editMode: PropTypes.bool.isRequired,
        type: PropTypes.string,
        dispatch: PropTypes.func,
        licensor: PropTypes.bool,
        getProductData: PropTypes.func,
        switchTo: PropTypes.func.isRequired,
        setChanged: PropTypes.func
    };

    componentDidMount() {
        this.fetchIpsFunction();
    }

    componentWillMount() {
        const can = get(
            this,
            'props.xelacore.auth.userPermissions.modules.registration.can',
            []
        );
        if (this.props.editMode && (can || []).indexOf('manage') === -1) {
            browserHistory.push('/404');
        }

        // this.fetchCategoryAttributes()
    }

    componentDidUpdate() {
        const { switchTo, switchTime } = this.props;
        const messages = get(this, 'props.xelacore.socket.messages');

        if (
            isUpdated(
                messages,
                'postgres',
                this.state.notifiedAt || switchTime,
                this.props.data.record_id
            )
        ) {
            this.setState({ notifiedAt: Date.now() });

            switchTo('view');
        }
    }

    refetchAndClose() {
        const { getProductData, switchTo } = this.props;
        switchTo('view');
        getProductData(true);
    }

    openQuarantineFunction(quarantineMode, modalActions) {
        this.setState({
            openQuarantine: !this.state.openQuarantine,
            quarantineMode: quarantineMode,
            modalActions: modalActions
        });

        if (!close) {
            document.getElementById('container').style.pointerEvents = 'none';
            document.getElementsByTagName('html')[0].className = 'backdrop';
        } else {
            document.getElementsByTagName('html')[0].className = '';
            document.getElementById('container').style.pointerEvents = null;
        }
    }

    fetchIpsFunction() {
        const { xelacore, data } = this.props;
        const {
            auth: {
                token,
                companyData
            }
        } = xelacore;

        if(!isAgent(companyData)) {
            fetchIps(companyData.organisation_id, token, data.licensor_organisation_id).then((response) => {
                this.setState({
                    ips: response.data && response.data.ips
                });
            });
        } else {
            fetchIpsV3(token, data.licensor_organisation_id).then((response) => {
                this.setState({
                    ips: !!response.data && map(response.data, item => parseV1Response(item)) || []
                });
            });
        }
    }

    openCatalog = () => {
        const { data } = this.props;
        browserHistory.push(`/product/catalog/${data.record_id}`);
        return location.reload();
    };

    render() {
        const {ips} = this.state;
        const { data, agenciesData, type, editMode, licensor, opaDataMatch, closeEditMode, dispatch, xelacore, categoryAttributes, ipLevels, hasStaticAttributes, originalData} = this.props;
        const productDescriptionValidation = data.validations.find(validation => validation.field === 'product_description');
        const staticAttrs = !!data.static_attributes ? Object.keys(data.static_attributes) : [];

        // console.log({agenciesData});
        const {
            auth: {
                companyData
            }
        } = xelacore;

        each(staticAttrs, attr => {
            data[attr] = data.static_attributes[attr];
        });

        return (
            <div>
                <div className="c-product__detail">
                    <div className="c-product__product-information-title">
                        <h3 className="c-product__header">
                            Product Information
                            <Auth restrictTo="licensee">
                                <Tooltip>
                                    This is the product information you provided
                                    when you uploaded the CSV file.
                                </Tooltip>
                            </Auth>
                        </h3>
                        {type !== 'catalog' && data.registered_with && (
                            <Auth
                                restrictTo="licensee"
                                permission="registration.manage"
                            >
                                <h3
                                    className="c-product__header u-clickable u-small"
                                    onClick={() => this.openCatalog()}
                                >
                                    View in Product Catalogue
                                    <Auth restrictTo="licensee">
                                        <Tooltip>
                                            From the Product Catalogue you will
                                            be able to view and link your
                                            Concepts and more. Editing product
                                            information is only possible in My
                                            Records
                                        </Tooltip>
                                    </Auth>
                                </h3>
                            </Auth>
                        )}
                    </div>
                    {!editMode && (
                        <div className="c-product__detail-description">
                            <h2 className="u-color-black u-padding-half-bottom">
                                {data.product_name}
                                <Tooltip>
                                    {licenseeTooltips.product_name}
                                </Tooltip>
                            </h2>
                            <Auth restrictTo="licensor">
                                <h5 className="c-product__registered-user">
                                    Registered by: {data.company}
                                </h5>
                            </Auth>
                            <Auth restrictTo="agent">
                                <h5 className="c-product__registered-user">
                                    Registered by: {data.company}
                                </h5>
                            </Auth>
                            <div
                                className={classnames(
                                    'u-margin-half-top u-smaller u-margin-bottom',
                                    {
                                        'c-product__detail-listing-item-error u-padding': !!productDescriptionValidation
                                    }
                                )}

                                style={{ wordBreak: 'break-word' }}
                            >
                                {data.product_description}

                                {!!productDescriptionValidation && (
                                    <span>
                                        <Icon icon="error2" fill="red" className="u-margin-small-right" top={2}
                                              size="12" />
                                        <span>{productDescriptionValidation.message}</span>
                                    </span>
                                )}
                                <Tooltip>
                                    <Auth restrictTo="licensee">
                                        {licenseeTooltips.product_description}
                                    </Auth>
                                    <Auth restrictTo="licensor">
                                        {licensorTooltips.product_description}
                                    </Auth>
                                    <Auth restrictTo="agent">
                                        {licensorTooltips.product_description}
                                    </Auth>
                                </Tooltip>
                            </div>
                        </div>
                    )}

                    <div className="c-product__detail-listing c-product__product-information">
                        {editMode && (
                            <ProductFormEdit
                                type={type}
                                data={data}
                                opaDataMatch={opaDataMatch}
                                save={() => this.refetchAndClose()}
                                close={closeEditMode}
                                isModalView={false}
                                isLicensor={licensor}
                                isAgent={isAgent(companyData)}
                                hiddenFields={['images']}
                                logChange={(d) =>
                                    this.setState({ editedData: d })
                                }
                                categoryAttributes={categoryAttributes}
                                ipLevels={ipLevels}
                                ips={ips}
                                hasStaticAttributes={hasStaticAttributes}
                                agenciesData={agenciesData}
                                originalData={originalData}
                            />
                        )}
                        {!editMode && (
                            <ListFields
                                data={data}
                                isLicensor={licensor}
                                isAgent={isAgent(companyData)}
                                productType={type}
                                dispatch={dispatch}
                                xelacore={xelacore}
                                categoryAttributes={categoryAttributes}
                                ipLevels={ipLevels}
                                ips={ips}
                                hasStaticAttributes={hasStaticAttributes}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
