import React, { Component } from 'react';
import { Icon } from 'src/js/components/static';
import each from 'lodash/each';
import { returnUnique } from 'helpers/formValidationHelpers';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';

const EMPTY_ITEM = { value: '', validations: [] };

export default class CommonArray extends Component {
    state = {
        data: []
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                data: this.formatValue(this.props.value) || []
            });
        }, 1000);
    }

    formatValue(data) {
        let parsedData = [];

        each(data, (el) => {
            if (!el.value) {
                parsedData.push({
                    value: el,
                    validations: []
                });
            } else {
                parsedData.push(el);
            }
        });

        return parsedData;
    }

    onChange(e, index) {
        e.preventDefault();
        const { onChange } = this.props;
        const { data } = this.state;
        data[index].value = e.target.value;

        return this.setState({ data }, () => onChange(data));
    }

    deleteElement(i) {
        const { data } = this.state;
        const { onChange } = this.props;

        i === 0 && data.length === 1
            ? (data[i] = { ...EMPTY_ITEM })
            : data.splice(i, 1);
        return this.setState({ data }, () => onChange(data));
    }

    addElement() {
        const { onChange } = this.props;
        const { data } = this.state;
        data.push({ ...EMPTY_ITEM });
        return this.setState({ data }, () => onChange(data));
    }

    renderValidations(errors, warnings) {
        if (!errors.length && !warnings.length) {
            return '';
        }
        return (
            <div className='o-list'>
                {returnUnique(errors).map((el) => (
                    <p
                        className='c-product__detail-listing-item-level-error'
                        key={`${el}`}
                    >
                        {el}
                    </p>
                ))}
                {returnUnique(warnings).map((el) => (
                    <p
                        className='c-product__detail-listing-item-level-warn'
                        key={`${el}`}
                    >
                        {el}
                    </p>
                ))}
            </div>
        );
    }

    render() {
        const { data } = this.state;
        const { name } = this.props;

        return (
            <div className='c-product__detail-block'>
                {data.map((el, i) => {
                    const { validations = [], value = '' } = el;
                    const errors = validations.filter(
                        (f) => f.level === 'error'
                    );
                    const warnings = validations.filter(
                        (f) => f.level === 'warn'
                    );

                    return (
                        <div
                            key={`${name}-element-${i}`}
                            className='c-form-element'
                        >
                            <div className='u-flex-align'>
                                <FormItemWrapper
                                    type='text'
                                    onChange={(e) => this.onChange(e, i)}
                                    value={value}
                                />
                                <div className='u-margin-left'>
                                    <Icon
                                        className='u-clickable'
                                        icon='close2'
                                        size={16}
                                        description='Remove element'
                                        onClick={() => this.deleteElement(i)}
                                    />
                                </div>
                            </div>
                            {this.renderValidations(errors, warnings)}
                        </div>
                    );
                })}
                <div
                    className='u-flex-align u-clickable'
                    onClick={() => this.addElement()}
                >
                    <small className='u-padding-half-left u-padding-half-right'>
                        Add a new element
                    </small>
                    <div>
                        <Icon
                            icon='add'
                            size={15}
                            title='Add a new item'
                            description='Add a new item'
                        />
                    </div>
                </div>
            </div>
        );
    }
}
