import React from 'react';
import config from 'config';
import get from 'lodash/get';
import classnames from 'classnames';
import { connect } from 'react-redux';
import openSocket from 'socket.io-client';
import { socketNotification } from 'src/js/actions/xelacore';
import { destroyNotification } from 'src/js/actions/xelacore';
import { Icon } from 'src/js/components/static';

export default connect(
    (state) => state,
    (dispatch) => ({ dispatch })
)((props) => <GlobalNotification {...props} />);

class GlobalNotification extends React.Component {
    constructor(props) {
        super(props);

        const socket = openSocket(config.baseUrl, {
            query: { token: get(props, 'xelacore.auth.token') }
        });

        socket.on('message', (message) => {
            if (this.lastMessageAt < Date.now() - 1000) {
                props.dispatch(socketNotification(message));
            } else {
                this.stack = (this.stack || []).concat(message);
                clearTimeout(this.notificationTimeout);
                this.notificationTimeout = setTimeout(() => {
                    props.dispatch(socketNotification(this.stack));
                    this.stack = [];
                }, 1000);
            }
            this.lastMessageAt = Date.now();
        });
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.xelacore.globalNotification !=
            nextProps.xelacore.globalNotification
        );
    }

    componentDidUpdate() {
        const {
            globalNotification: { timeOut, message }
        } = this.props.xelacore;
        if (message) {
            const timmy = timeOut ? timeOut : 5000;
            this.hideNotificationTimeout(timmy);
        }
    }

    hideNotificationTimeout(time) {
        const { dispatch } = this.props;
        const { globalNotification } = this.props.xelacore;
        if (globalNotification.message) {
            clearTimeout(this.notificationTimer);
            this.notificationTimer = setTimeout(function () {
                dispatch(destroyNotification());
            }, time);
        }
    }

    destroyNotification() {
        const { dispatch } = this.props;
        dispatch(destroyNotification());
    }

    render() {
        const { globalNotification } = this.props.xelacore;
        if (!globalNotification.message) return null;

        const cx = classnames('c-global-notification', {
            'c-global-notification--error': globalNotification.type === 'error',
            'c-global-notification--warning':
                globalNotification.type === 'warning',
            'c-global-notification--success':
                globalNotification.type === 'success',
            'c-global-notification--info': globalNotification.type === 'info'
        });

        return (
            <div onClick={() => this.destroyNotification()} className={cx}>
                <div className="c-global-notification__notification-text u-flex">
                    <div>{globalNotification.message}</div>
                    <div>
                        <Icon
                            icon="CLOSE"
                            fill="white"
                            size="15"
                            onClick={() => this.destroyNotification()}
                            className="u-clickable u-margin-left"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
