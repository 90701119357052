import _ from 'lodash';

import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import toNumber from 'lodash/toNumber';
import map from 'lodash/map';
import size from 'lodash/size';

export const mapLevels = (ips, ipLevels, value) => {
    let finalMappedTree = [];
    let map = {};

    let cloneValue = cloneDeep(value);
    let cloneLevels = cloneDeep(ipLevels);
    
    !!cloneLevels && cloneLevels.forEach((node) => {
        node.value = !!node.multi ? [] : {};

        each(cloneValue, ip => {
            each(ip, item => {
                let findIp = find(ips, iii => iii.ip_id === item.ip_id);

                if(item.ip_level_id === node.ip_level_id) {
                    if(!!node.multi) {
                        node.value.push(!!findIp ? {
                            ip_id: findIp.ip_id,
                            ip_level_id: findIp.ip_level_id,
                            value: findIp.string_path[findIp.string_path.length - 1]
                        } : item);
                    } else {
                        node.value = item;
                    }

                    if(!!findIp) {
                        node.ip_id = !!findIp && !!findIp.ip_id && findIp.ip_id || null;
                        node.parent_ip_id = !!findIp && !!findIp.parent_ip_id && findIp.parent_ip_id || findIp.ip_id;
                    }
                }
            })
        });

        if (!node.parent_ip_level_id || size(node.parent_ip_level_id) === 0) return finalMappedTree.push(node);

        let parentIndex = map[node.parent_ip_level_id];

        if (parentIndex !== 'number') {
            parentIndex = cloneLevels.findIndex(el => el.ip_level_id === node.parent_ip_level_id);
            map[node.parent_ip_level_id] = parentIndex;
        }

        if (!!cloneLevels[parentIndex] && !cloneLevels[parentIndex].children) {
            node.parent_ip_id = cloneLevels[parentIndex].ip_id || null;
            return cloneLevels[parentIndex].children = [node];
        }

        cloneLevels[parentIndex].children.push(node);
        cloneLevels[parentIndex].children = uniqBy(cloneLevels[parentIndex].children, 'ip_level_id');

        if(!!cloneLevels[parentIndex] && !!cloneLevels[parentIndex].children) {
            each(cloneLevels[parentIndex].children, (children, key) => {
                if(!!cloneLevels[parentIndex] && cloneLevels[parentIndex].ip_id) {
                    cloneLevels[parentIndex].children[key].parent_ip_id = cloneLevels[parentIndex].ip_id;
                }
            })
        }
    })

    return !!cloneLevels && cloneLevels.filter(item => item.parent_ip_level_id === null || !size(item.parent_ip_level_id));
}

export const removeShadowedIps = (value) => {
    const ipStrings = [];

    each(value, ip => {
      let ipString = [];

      each(ip, component => {
        ipString.push(`${component.ip_level_id}${component.ip_id}${component.value}`);
      })

      ipStrings.push(ipString.join(';'));
    });
    
    return _.chain(ipStrings).map((stringIp, idx) => {
      const matchingStrings = filter(ipStrings, v => v.startsWith(stringIp) && v.length > stringIp.length);

      if (matchingStrings.length) {
        return null;
      }

      return value[idx];
    }).compact().value();
}

export const addValidations = (data) => {
    const cloneData = cloneDeep(data);

    each(cloneData.validations, validation => {
        if(!!validation.code && validation.code === 'validation.ipIsInvalid') {
            if(!!cloneData && !!cloneData.ips) {
                let key1 = toNumber(validation.field.split('.')[1]);
                let key2 = toNumber(validation.field.split('.')[2]);

                if(!!cloneData.ips && !!cloneData.ips[key1] && cloneData.ips[key1][key2]) {
                    cloneData.ips[key1][key2].error = true;
                    cloneData.ips[key1][key2].validation = validation.message;
                }
            }
        }
    })

    return cloneData.ips;
}

export const findError = (node) => {
    if(!!node.multi) {
        return !!find(node.value, val => !!val.error);
    } else {
        return !!node.value.error;
    }
}

export const mapIps = (value) => {
    return map(value, ipPath => {
        return map(ipPath, ip => {
            return {
                ip_level_id: ip.ip_level_id,
                value: ip.value,
                ip_id: ip.ip_id || null
            }
        })
    });
}