import {
    SET_MY_RECORDS,
    REMOVE_MY_RECORDS
} from 'src/js/constants/actions/xelacore';

export function myRecords(state = {}, action = {}) {
    const { payload = {} } = action;

    switch (action.type) {
        case SET_MY_RECORDS:
            const {
                records = {},
                fetch = {},
                filterings = {},
                recordsCount = {},
                selectedRows = [],
                registrableIds = [],
                advanced = {},
                selected = null,
                previousRow = null
            } = payload;
            return {
                ...state,
                ...records,
                ...fetch,
                ...filterings,
                ...recordsCount,
                ...{selectedRows: selectedRows},
                ...{registrableIds: registrableIds},
                ...{selected: selected},
                ...{previousRow: previousRow},
                ...advanced
            };
        case REMOVE_MY_RECORDS:
            return { ...payload };
        default:
            return state;
    }
}
