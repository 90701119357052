import 'core-js/fn/object/assign';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import smoothscroll from 'smoothscroll-polyfill';

import * as am4core from '@amcharts/amcharts4/core';
/* eslint-disable no-unused-vars */
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.addLicense('CH294371076');
am4core.addLicense('MP294371076');
am4core.useTheme(am4themes_animated);

// IE polyfill for scrollTo
smoothscroll.polyfill();

// Now the real app-{React,components,redux} stuff
import bootstrap from 'src/js/core/bootstrap';
import App from 'src/js/core/AppProvider';

import 'src/styles/App.scss';
import networkService from './js/helpers/networkService';

// Congratulations! You are in the client-side (mind breaking right?). If this
// client has been injected by an HTML that the server has already render, then
// this mean we need to take into account the state that the server has render.
// Thus the fancy `window.__INITIAL_STATE__` argument. Otherwise, you must be
// running the client in a development mode, where the client is rendered by
// webpack. And the state fetch will happen eventually once redux runs the
// reducers. In the client's nature, any execution feels the same, it only
// differs in the initial State.
bootstrap(window.__INITIAL_STATE__, window.location.toString())
    .then(({ store, routes }) => {
        const history = syncHistoryWithStore(browserHistory, store);
        const orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
        networkService.setupInterceptors(store, history);
        

        // The main difference with the Middleware flow, is that the app is not
        // wrapped with the HTML component. This shouldn't matter if you haven't hack
        // the planet. As the HTML should already exist (since you are in the client
        // already, du-oh!).

        ReactDOM.hydrate(
            <App store={store}>
                <Router onUpdate={()=>{
                    window.scrollTo(0, 0)
                }}
                        history={history}>
                    {routes}
                </Router>

            </App>,
            document.getElementById('app-container')
        );
    })
    .catch(() => {
        localStorage.clear();
        browserHistory.push('/');
        setTimeout(() => window.location.reload && window.location.reload());
    });
