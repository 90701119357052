import React, { Component } from 'react';
import each from 'lodash/each';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import filter from 'lodash/filter';
import { deleteConflictSets } from 'src/js/actions/conflicts';
import { updateBatch } from 'src/js/apicalls/mixed/myRecords';
import { conflictsList, coreFields } from 'src/js/constants/conflictsConstants';
import { parsedSets, checkValidations } from 'src/js/helpers/conflictsHelper';

import Button from 'modules/UiKit/components/Button/Button';

export default class BulkTableFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmation: null
        };
    }

    setDataForUpdate(all) {
        let formatedData = {};

        each(conflictsList, (item) => {
            if (
                item.key !== 'checkbox' &&
                item.key !== 'registered' &&
                item.key !== 'image'
            ) {
                formatedData.record_id = all.record_id;
                formatedData.version_id = all.version_id;
                formatedData[item.key] = all[item.key] || '';
            }
        });

        return formatedData;
    }

    resolutionType = (data) => {
        const { cloneData } = this.props;

        var resType = '';
        var initialData = map(parsedSets(cloneData), (row) => {
            return this.setDataForUpdate(row);
        });

        var initialIndex = findIndex(initialData, (item) => {
            return item.record_id === data.record_id;
        });

        if (data.gtin_waiver) {
            resType = initialData[initialIndex].mpn === data.mpn;
        } else {
            resType = true;

            each(coreFields, (field) => {
                if (!isEqual(initialData[initialIndex][field], data[field])) {
                    resType = false;
                }
            });
        }

        return resType;
    };

    updateConflictsFunction = () => {
        const { rowsToUpdate, fetchSets } = this.props;

        let dataToUpdate = map(parsedSets(rowsToUpdate), (row) => {
            return this.setDataForUpdate(row);
        });

        each(dataToUpdate, (data) => {
            data.conflicts_resolved_local = this.resolutionType(data);
        });

        updateBatch(dataToUpdate).then(() => {
            this.confirmAction('close');
            fetchSets();
        });
    };

    deleteConflictsFunction = () => {
        const { rowsToUpdate, fetchSets } = this.props;
        let idsMap = map(parsedSets(rowsToUpdate), 'record_id');

        deleteConflictSets(idsMap).then(() => {
            this.confirmAction('close');
            fetchSets();
        });
    };

    confirmAction = (label) => {
        this.setState({
            confirmation: label === 'close' ? null : label
        });
    };

    render() {
        const { confirmation } = this.state;
        const { rowsToUpdate, isBusy } = this.props;

        let hasErrors = !!size(
            filter(parsedSets(rowsToUpdate), (row) => {
                return (
                    checkValidations(row.validations) ||
                    row.conflicts_resolved_local
                );
            })
        );

        return (
            <div className="c-conflicts-popover-footer">
                {!confirmation && (
                    <div className="c-conflicts-popover-footer-default">
                        <div className="c-conflicts-popover-footer-actions u-flex u-flex-between">
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() => this.confirmAction('confirm')}
                                disabled={
                                    !size(rowsToUpdate) || hasErrors || isBusy
                                }
                            >
                                Confirm Information
                            </Button>

                            <Button
                                type="secondary-danger-2"
                                size="small"
                                onClick={() => this.confirmAction('delete')}
                                disabled={!size(rowsToUpdate) || isBusy}
                            >
                                Delete Selected
                            </Button>

                            {hasErrors && (
                                <div>
                                    <label>
                                        You cannot confirm records that have
                                        errors or that have been already
                                        confirmed.
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {confirmation && (
                    <div className="c-conflicts-popover-footer-confirmation u-flex u-flex-align--left">
                        <div>
                            <label>
                                Are you sure you want to {confirmation} the
                                selected records?
                            </label>
                        </div>
                        <div className='c-conflicts-footer-buttons'>
                            <Button
                                type="secondary"
                                size="small"
                                onClick={
                                    confirmation === 'delete'
                                        ? () => this.deleteConflictsFunction()
                                        : () => this.updateConflictsFunction()
                                }
                            >
                                Yes
                            </Button>
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() => this.confirmAction('close')}
                            >
                                No
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
