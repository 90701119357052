import React, { Component } from 'react';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { Icon } from 'src/js/components/static';

import { MULTIPLE_VALUES_PLACEHOLDER } from 'src/js/constants/myRecordsConstants';

export default class SelectDropDown extends Component {
    constructor(props) {
        super(props);
        const ungroupedList = props.isGrouped
            ? this.buildUngrouppedList(props.values)
            : props.values;
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            showList: false,
            selectedData: props.value || [],
            isGrouped: props.isGrouped,
            ungroupedList: ungroupedList,
            filteredList: [...ungroupedList],
            searchedValue: '',
            expandedGroups: [],
            showSelected: [],
            showSelectedAll: false
        };
    }

    static getDerivedStateFromProps({value}) {
        return value ? { selectedData: value } : {};
    }

    buildUngrouppedList(groupedList) {
        const ungroupedList = [];
        groupedList.forEach((option) => {
            option.items.forEach((item) => ungroupedList.push(item));
        });
        return ungroupedList;
    }

    showMenuList() {
        if (!this.state.showList) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener(
                'click',
                this.handleOutsideClick,
                false
            );
        }
        this.setState({
            showList: !this.state.showList
        });
    }

    handleOutsideClick(e) {
        if (
            (this.node && this.node.contains(e.target)) ||
            e.target.type === 'checkbox'
        ) {
            return;
        }
        this.showMenuList();
    }

    onChangeValue(e, value) {
        e.stopPropagation();
        const { onChange, name } = this.props;
        const { selectedData } = this.state;
        if (!this.isSelected(value)) {
            selectedData.push(value);
        } else {
            const selectedIndex = selectedData.findIndex(
                (selectedItem) => selectedItem === value
            );
            selectedData.splice(selectedIndex, 1);
        }
        this.setState({ selectedData });
        e.target.value = selectedData;

        onChange(selectedData, name);
    }

    selectValue(e, value) {
        const { onChange, name } = this.props;
        const { selectedData } = this.state;
        this.setState({ selectedData: value });
        e.target.value = value;
        onChange(selectedData, name);
        this.showMenuList();
    }

    onChangeSearch(e) {
        const { value } = e.target;
        const { isGrouped } = this.props;
        const { ungroupedList } = this.state;
        if (value.length === 0) {
            this.setState({
                searchedValue: value,
                filteredList: [...ungroupedList],
                isGrouped
            });
        } else {
            const newFilteredList = ungroupedList.filter(
                (item) =>
                    item.label.toLowerCase().startsWith(value.toLowerCase()) ||
                    item.value.toLowerCase().startsWith(value.toLowerCase())
            );
            this.setState({
                searchedValue: value,
                filteredList: newFilteredList,
                isGrouped: false
            });
        }
    }

    onChangeGroup(e, groupName) {
        e.stopPropagation();
        const { values, onChange, name } = this.props;
        const { selectedData } = this.state;
        const group = values.find((group) => group.label === groupName);
        const selectedVals = group.items.filter((item) =>
            selectedData.includes(item.value)
        );
        if (selectedVals.length) {
            group.items.forEach((item) => {
                const index = selectedData.findIndex((i) => i === item.value);
                if (index !== -1) {
                    selectedData.splice(index, 1);
                }
            });
        } else {
            group.items.forEach((item) => {
                selectedData.push(item.value);
            });
        }

        this.setState({ selectedData });
        e.target.value = selectedData;
        onChange(selectedData, name);
    }

    isSelected(value) {
        const { isMulti } = this.props;

        return isMulti
            ? this.state.selectedData.includes(value)
            : this.state.selectedData === value;
    }

    selectAll(e, clear) {
        const { onChange, name } = this.props;
        const { ungroupedList, selectedData, searchedValue, filteredList } =
            this.state;
        const allData = searchedValue.length
            ? [...filteredList]
            : [...ungroupedList];
        const alreadySelected = allData.filter((item) =>
            selectedData.includes(item.value)
        );
        let selectedValues = [...selectedData];

        if (alreadySelected.length === allData.length) {
            selectedValues = selectedValues.filter(
                (item) => !allData.find((i) => i.value === item)
            );
        } else if (clear) {
            selectedValues = [];
        } else {
            allData.forEach((item) => selectedValues.push(item.value));
        }

        selectedValues = uniq(selectedValues);
        this.setState({ selectedData: selectedValues });
        e.target.value = selectedValues;
        onChange(selectedValues, name);
    }

    showHideGroup(e, groupName) {
        if (e.target.type === 'checkbox') {
            return false;
        }
        const { expandedGroups } = this.state;
        if (expandedGroups.includes(groupName)) {
            const groupIndex = expandedGroups.findIndex(
                (selectedGroup) => selectedGroup === groupName
            );
            expandedGroups.splice(groupIndex, 1);
        } else {
            expandedGroups.push(groupName);
        }

        this.setState({
            expandedGroups
        });
    }

    showSelected(e, groupName) {
        e.stopPropagation();
        const { expandedGroups, showSelected } = this.state;

        if (showSelected.includes(groupName)) {
            const showSelectedIndex = showSelected.findIndex(
                (opt) => opt === groupName
            );
            showSelected.splice(showSelectedIndex, 1);
            this.setState({
                showSelected
            });
        } else {
            showSelected.push(groupName);
            if (!expandedGroups.includes(groupName)) {
                expandedGroups.push(groupName);
            }
            this.setState({
                expandedGroups,
                showSelected
            });
        }
    }

    showAllSelectedCount() {
        const { isGrouped } = this.props;
        const {
            searchedValue,
            ungroupedList,
            selectedData,
            filteredList,
            showSelectedAll
        } = this.state;
        if (isGrouped || searchedValue.length) {
            return {
                isShowSelectedAllButton: false,
                selectedCount: 0,
                lastSelected: 0,
                filteredOptions: filteredList,
                showSelectedAll: false
            };
        }

        const selectedOptions = ungroupedList.filter(
            (opt) => !!selectedData.includes(opt.value)
        );
        const selectedLength = selectedOptions.length;
        let items = [...ungroupedList];
        if (showSelectedAll) {
            const notSelectedOptions = ungroupedList.filter(
                (opt) => !selectedData.includes(opt.value)
            );
            items = [...selectedOptions, ...notSelectedOptions];
        }
        return {
            isShowSelectedAllButton: true,
            selectedCount: ungroupedList.filter(
                (item) => !!selectedData.includes(item.value)
            ).length,
            lastSelected: selectedLength - 1,
            filteredOptions: items,
            showSelectedAll
        };
    }

    showAllSelected() {
        this.setState({
            showSelectedAll: !this.state.showSelectedAll
        });
    }

    groupInfo(groupName) {
        const { values } = this.props;
        const { selectedData, expandedGroups, showSelected } = this.state;
        const group = values.find((group) => group.label === groupName);
        if (!group) {
            return {
                selectedCount: 0,
                isExpanded: false,
                items: [],
                lastSelected: 0,
                isShowSelected: false
            };
        }
        const selectedVals = group.items.filter((item) =>
            selectedData.includes(item.value)
        );

        let items = [...group.items];
        let lastSelected = 0;
        if (showSelected.includes(groupName)) {
            const selectedOptions = group.items.filter(
                (opt) => !!selectedData.includes(opt.value)
            );
            const notSelectedOptions = group.items.filter(
                (opt) => !selectedData.includes(opt.value)
            );
            items = [...selectedOptions, ...notSelectedOptions];
            lastSelected = selectedOptions.length - 1;
        }

        return {
            selectedCount: selectedVals.length,
            isExpanded: expandedGroups.includes(groupName),
            items: items,
            lastSelected,
            isShowSelected: showSelected.includes(groupName)
        };
    }

    renderGroup(option) {
        const { label, isMulti } = this.props;
        const {
            selectedCount,
            items,
            isExpanded,
            isShowSelected,
            lastSelected
        } = this.groupInfo(option.label);

        return (
            <div
                className="select-drop-down__results__group"
                key={`${name}_group_${option.label}`}
            >
                <div
                    className={`select-drop-down__results__group__title ${
                        isExpanded ? 'expanded' : ''
                    }`}
                    onClick={(e) => this.showHideGroup(e, option.label)}
                >
                    <div className="select-drop-down__results__group__title__name">
                        {isMulti && (
                            <React.Fragment>
                                <input
                                    type="checkbox"
                                    className="checkbox"
                                    value={option.label}
                                    onChange={(e) =>
                                        this.onChangeGroup(e, option.label)
                                    }
                                    checked={selectedCount !== 0}
                                    id={`${name}_group_${option.label}`}
                                />
                                <div
                                    onClick={(e) =>
                                        this.onChangeGroup(e, option.label)
                                    }
                                    className="checkmark"
                                ></div>
                            </React.Fragment>
                        )}
                        <span>{option.label}</span>
                    </div>
                    {isMulti ? (
                        <div
                            className="select-drop-down__results__group__title__selected_count"
                            onClick={(e) => this.showSelected(e, option.label)}
                        >
                            {selectedCount} {label}(s) selected
                        </div>
                    ) : (
                        <div className="select-drop-down__results__group__title__selected_count inactive">
                            {selectedCount} {label} selected
                        </div>
                    )}

                    <div className="select-drop-down__results__group__title__arrow">
                        <span
                            className={`select-drop-down__results__group__title__arrow__triangle ${
                                isExpanded ? 'expanded' : ''
                            }`}
                        />
                    </div>
                </div>
                {isExpanded && (
                    <div className="select-drop-down__results__items">
                        {this.renderItems(items, lastSelected, isShowSelected)}
                    </div>
                )}
            </div>
        );
    }

    renderItems(items, lastSelected = 0, isShowSelected = false) {
        const { name, isMulti, showFullLabel = false } = this.props;
        return items.map((item, i) => {
            if (isMulti) {
                return (
                    <label
                        htmlFor={`${name}_item_${item.value}`}
                        key={`${name}_item_${item.value}_${i}`}
                        className={`select-drop-down__results__items__item ${
                            isShowSelected && i === lastSelected
                                ? 'last_selected'
                                : ''
                            }`}
                    >
                        <input
                            type="checkbox"
                            value={item.value}
                            className="checkbox"
                            onChange={(e) => this.onChangeValue(e, item.value)}
                            checked={this.isSelected(item.value)}
                            id={`${name}_item_${item.value}`}
                        />
                        <div className="checkmark"></div>
                        <label htmlFor={`${name}_item_${item.value}`}
                               title={
                                   showFullLabel
                                    ? `${item.label} (${item.value})`
                                    : item.label
                                }>
                            {showFullLabel
                                ? `${item.label} (${item.value})`
                                : item.label}
                        </label>
                    </label>
                );
            }
            return (
                <div
                    key={`${name}_item_${item.value}_${i}`}
                    className={`select-drop-down__results__items__item single ${
                        this.isSelected(item.value) ? 'selected' : ''
                    }`}
                    onClick={(e) => this.selectValue(e, item.value)}
                >
                    <span>{item.label}</span>
                </div>
            );
        });
    }

    renderSearch() {
        const { searchedValue } = this.state;
        return (
            <div className="select-drop-down__menulist__search">
                <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    className="select-drop-down__menulist__search__field"
                    value={searchedValue}
                    onChange={(e) => this.onChangeSearch(e)}
                />
                <span className="select-drop-down__menulist__search__icon">
                    <Icon
                        icon={'search'}
                        size={13}
                        fill="grey6"
                        onClick={() => this.setFilterValue()}
                    />
                </span>
            </div>
        );
    }

    renderErrorBlock() {
        const { validations, label } = this.props;
        const { searchedValue } = this.state;
        const validationErroredValues = get(validations, '0.value', null);
        if (
            !validationErroredValues ||
            !validationErroredValues.length ||
            searchedValue.length
        ) {
            return null;
        }
        const erroredValues = validationErroredValues.split(';');
        return (
            <div className="select-drop-down__menulist__error_block">
                <div className="select-drop-down__menulist__error_block__title">
                    {erroredValues.length} {label}(s) could not be read from the
                    CSV file uploaded
                </div>
                <div className="select-drop-down__menulist__error_block__subtitle">
                    {label}(s) unable to read:
                </div>
                <ul className="select-drop-down__menulist__error_block__values">
                    {erroredValues.map((err) => (
                        <li key={`errored-value-${err}`}>{err}</li>
                    ))}
                </ul>
                <div className="select-drop-down__menulist__error_block__footer">
                    Please add them manually from the list provided below
                </div>
            </div>
        );
    }

    checkforErrors(){
        const { validations } = this.props;
        const validationErroredValues = get(validations, '0.value', null);
        if (
            !validationErroredValues ||
            !validationErroredValues.length
        ) {
            return null;
        }
        const erroredValues = validationErroredValues.split(';');
        this.props.fieldHasErrors(erroredValues);
    }

    selectedPlaceholder() {
        const { isMulti, label, isMultipleValues } = this.props;
        const { ungroupedList } = this.state;
        const { selectedData } = this.state;
        if (selectedData.length === 0) {
            return isMultipleValues
                ? MULTIPLE_VALUES_PLACEHOLDER
                : `Select ${label} from the list`;
        }
        if (!isMulti) {
            return selectedData;
        }
        let val = selectedData[0];
        if (selectedData.length === 1) {
            const found = ungroupedList.find((v) => v.value === val);
            return `${found && found.label} (${val.toUpperCase()})`;
        }
        return `${selectedData.length} ${label}s`;
    }

    render() {
        const { values = [], label, isMulti, disabled = false } = this.props;
        const { showList, isGrouped, selectedData, filteredList } = this.state;
        const {
            selectedCount,
            isShowSelectedAllButton,
            lastSelected,
            filteredOptions,
            showSelectedAll
        } = this.showAllSelectedCount();
        this.checkforErrors();

        const filteredSelected = isMulti
            ? filteredList.filter((item) => selectedData.includes(item.value))
            : null;
        return (
            <div className={`select-drop-down ${disabled ? 'disabled' : ''}`}>
                <div
                    className="select-drop-down__control"
                    onClick={() => this.showMenuList()}
                >
                    {this.selectedPlaceholder()}
                    <div className="select-drop-down__results__group__title__arrow">
                        <span
                            className={`select-drop-down__results__group__title__arrow__triangle ${
                                showList ? 'expanded' : ''
                            }`}
                        />
                    </div>
                </div>
                {showList && (
                    <div
                        className="select-drop-down__menulist"
                        ref={(node) => (this.node = node)}
                    >
                        <div className="select-drop-down__menulist__container">
                            {isShowSelectedAllButton && isMulti && (
                                <div
                                    className="select-drop-down__menulist__container__selected_count"
                                    onClick={(e) => this.showAllSelected(e)}
                                >
                                    {selectedCount} {label}(s) selected
                                </div>
                            )}
                            {this.renderSearch()}
                            {this.renderErrorBlock()}
                            <div className="select-drop-down__results">
                                {isGrouped ? (
                                    values.map((option) => {
                                        return this.renderGroup(option);
                                    })
                                ) : (
                                    <div className="select-drop-down__results__items">
                                        {this.renderItems(
                                            filteredOptions,
                                            lastSelected,
                                            showSelectedAll
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="select-drop-down__menulist__footer">
                            {isMulti && (
                                <div className="u-flex-align select-drop-down__actions">
                                    <div
                                        className="select-drop-down__menulist__footer__select_all"
                                        onClick={(e) => this.selectAll(e)}
                                    >
                                        {filteredList.length ===
                                        filteredSelected.length
                                            ? 'Unselect all'
                                            : 'Select all'}
                                    </div>

                                    <div
                                        className="select-drop-down__menulist__footer__select_all marg-l-10"
                                        onClick={(e) => this.selectAll(e, true)}
                                    >
                                        Clear all
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
