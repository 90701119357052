import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import size from 'lodash/size';
import each from 'lodash/each';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import get from 'lodash/get';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import { Icon } from 'src/js/components/static';
import Tooltip from 'rc-tooltip';
import {
    errorContentMessage,
    inErrorContent
} from 'src/js/components/TableRow/common';
import { getConflict, getQuarantine } from 'src/js/actions/conflicts';
import ConflictsHeader from './ConflictsHeader';
import ConflictsFooter from './ConflictsFooter';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import MediaView from 'src/js/components/MediaView';
import {
    conflictsList,
    extendedAttrs,
    quarantineHeader
} from 'src/js/constants/conflictsConstants';
import { getMainImage } from 'src/js/helpers/dataHelpers';
import TooltipRegisteredData
    from 'src/js/modules/MyRecords/routes/MyRecords/components/TooltipsData/TooltipRegisteredData';

import { ClickableText } from 'src/js/components/ProductListElements';
import isEmpty from 'lodash/isEmpty';
import { getLinkedBrands } from 'src/js/actions/categories';
import Button from 'modules/UiKit/components/Button/Button';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';
import { hideModal } from 'src/js/actions/xelacore';

class Conflicts extends Component {
    constructor(props) {
        super(props);
        this.newRecordsTime = 60;
        this.state = {
            metaVisible: false,
            uploader: null,
            profile_image: null,
            rowsToUpdate: [],
            rowData: [],
            cloneData: [],
            isBusy: false,
            isFailed: false,
            additionalAttr: false,
            conflicts: [],
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }

    componentWillMount() {
        this.getLinkedBrands();
    }

    componentDidMount() {
        this.fetchRecords();
    }

    getLinkedBrands() {
        const { dispatch, linkedBrands } = this.props;
        if (isEmpty(linkedBrands)) {
            dispatch(getLinkedBrands());
        }
    }

    fetchRecords = () => {
        const { rowsToUpdate } = this.state;
        const { dispatch, id, data, quarantineMode, self } =
            this.props;

        return dispatch(
            quarantineMode ? getQuarantine(id, !!self) : getConflict(id, !!self)
        ).then((r) => {
            let conflicts = r.data.records.rows;
            let cloneConflicts = cloneDeep(conflicts);

            const firstRow = filter(
                conflicts,
                (row) => row.record_id === data.record_id
            );
            const restRows = filter(
                conflicts,
                (row) => row.record_id !== data.record_id
            );

            if (size(rowsToUpdate)) {
                each(conflicts, (item) => {
                    each(rowsToUpdate, (row, k) => {
                        if (row.record_id === item.record_id) {
                            this.state.rowsToUpdate[k]['version_id'] =
                                item.version_id;
                            this.forceUpdate();
                        }
                    });
                });
            }

            this.setState({
                isBusy: false,
                rowsToUpdate: remove(rowsToUpdate, (item) =>
                    includes(map(conflicts, 'record_id'), item.record_id)
                ),
                rowData: [...firstRow, ...restRows],
                cloneData: cloneConflicts
            });
        });
    };

    checkValidations = (validations) => {
        return (
            validations &&
            validations.some((validation) => validation.level === 'error')
        );
    };

    noErrors = (response) => {
        return response.some((s) => s.success);
    };

    displayError = (error) => {
        this.setState({
            isFailed: error
        });
    };

    markforDeletion(val) {
        const { rowsToUpdate } = this.state;

        const recordsIds = map(rowsToUpdate, 'record_id');

        if (includes(recordsIds, val.record_id)) {
            remove(rowsToUpdate, (item) => {
                return item.record_id === val.record_id;
            });
        } else {
            rowsToUpdate.push(val);
        }

        return this.setState({
            rowsToUpdate: rowsToUpdate
        });
    }

    onChange = (e, el, key) => {
        const { rowsToUpdate } = this.state;
        const { linkedBrands } = this.props;

        let value = !!e.target && !!e.target.value ? e.target.value : e

        let brand = find(linkedBrands.linkedBrands.rows, row => row.brand_id === value);
        let index = findIndex(rowsToUpdate, (row) => {
            return row.record_id === el.record_id;
        });

        el[key] = value;
        el.ips[0][0].ip_id = value;
        el.ips[0][0].value = brand.name;

        if (index > -1) {
            rowsToUpdate[index][key] = value;
            rowsToUpdate[index].ips[0][0].ip_id = value;
            rowsToUpdate[index].ips[0][0].value = brand.name;
        }

        this.setState({
            rowsToUpdate: rowsToUpdate
        });
    };

    toggleExtendedAttrs = () => {
        this.setState({
            additionalAttr: !this.state.additionalAttr
        });
    };

    openProduct = (record_id) => {
        const { quarantineMode } = this.props;

        if (quarantineMode) {
            browserHistory.push(`/product/catalog/${record_id}`);
        } else {
            browserHistory.push(`/product/records/${record_id}`);
        }

        return location.reload();
    };

    updateRows = () => {
        const { dispatch } = this.props;
        
        this.setState({
            isBusy: true,
            rowsToUpdate: []
        });

        setTimeout(() => {
            dispatch(hideModal())
        }, 5000);
    };

    render() {
        const {
            rowsToUpdate,
            rowData,
            isBusy,
            isFailed,
            additionalAttr,
            cloneData,
            selectedLicensor
        } = this.state;

        const {
            id,
            setConflicts,
            data,
            fetchData,
            quarantineMode,
            modalActions,
            self,
            linkedBrands
        } = this.props;
        const defaultImage = { imgPlaceholder };
        let conflictsItems = !modalActions ? quarantineHeader : conflictsList;

        return (
            <div
                className={`c-conflicts-popover ${isBusy ? 'loading' : ''} ${
                    !modalActions ? 'quarantine' : ''
                }`}
            >
                <ConflictsHeader
                    setConflicts={setConflicts}
                    quarantineMode={quarantineMode}
                    length={!self ? rowData.length : rowData.length - 1}
                />

                <div className="c-conflicts-popover-body">
                    <div className="c-conflicts-popover-body-sticky">
                        <table className="c-conflicts-popover-body-table">
                            <thead className="c-conflicts-popover-body-table-header">
                            <tr>
                                {conflictsItems.map((item) => {
                                    if(item.key !== 'ips') {
                                        return (
                                            <th
                                                key={item.key}
                                                className={`${item.key} ${
                                                    !additionalAttr &&
                                                    includes(
                                                        extendedAttrs,
                                                        item.key
                                                    ) &&
                                                    modalActions
                                                        ? 'hidden'
                                                        : ''
                                                }`}
                                            >
                                                {item.label}
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <table className="c-conflicts-popover-body-table">
                        <tbody className="c-conflicts-popover-body-table-body">
                        {rowData.map((el) => {
                            const image = getMainImage(el.images);
                            const key = `${el.record_id}-${el.version_id}`;

                            let validations = [];

                            each(el.validations, (item) => {
                                validations[item.field] = item;
                            });

                            return (
                                <tr
                                    key={key}
                                    className={`${
                                        rowsToUpdate.some(
                                            (s) =>
                                                s.record_id === el.record_id
                                        )
                                            ? 'highlighted'
                                            : ''
                                    } ${
                                        el.record_id === data.record_id
                                            ? 'main-record'
                                            : ''
                                    }`}
                                >
                                    {conflictsItems.map((item) => {
                                        if(item.key !== 'ips') {
                                            return (
                                                <td
                                                    key={item.key}
                                                    className={`c-rec-table__cell ${item.key}`}
                                                >
                                                    {modalActions && (
                                                        <div className="u-flex u-flex-align--center">
                                                            {item.key ===
                                                                'checkbox' &&
                                                                !el.conflicts_resolved_local &&
                                                                !this.checkValidations(
                                                                    el.validations
                                                                ) && (
    
                                                                    <UiCheckbox
                                                                        id={`${key}-conflicts`}
                                                                        name={`${key}-conflicts`}
                                                                        defaultChecked={rowsToUpdate.some(
                                                                            (
                                                                                s
                                                                            ) =>
                                                                                s.record_id ===
                                                                                el.record_id
                                                                        )}
                                                                        onChange={() =>
                                                                            this.markforDeletion(
                                                                                el
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            !!get(
                                                                                el,
                                                                                'registered_with'
                                                                            ) ||
                                                                            el.conflicts_resolved_local
                                                                        }
                                                                        type="checkbox">
    
                                                                    </UiCheckbox>
                                                                )}
    
                                                            {item.key ===
                                                                'checkbox' &&
                                                                el.conflicts_resolved_local && (
                                                                    <span className="confirmed-label">
                                                                            Confirmed
                                                                        </span>
                                                                )}
    
                                                            {item.key ===
                                                                'checkbox' &&
                                                                this.checkValidations(
                                                                    el.validations
                                                                ) && (
                                                                    <Tooltip
                                                                        placement="right"
                                                                        align={{
                                                                            offset: [
                                                                                0,
                                                                                12
                                                                            ]
                                                                        }}
                                                                        overlay={() =>
                                                                            inErrorContent()
                                                                        }
                                                                        trigger={[
                                                                            'hover'
                                                                        ]}
                                                                    >
                                                                        <div>
                                                                                <span
                                                                                    className="confirmed-label error"
                                                                                    onClick={() =>
                                                                                        this.openProduct(
                                                                                            el.record_id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    In
                                                                                    error
                                                                                </span>
                                                                        </div>
                                                                    </Tooltip>
                                                                )}
    
                                                            {item.key ===
                                                                'registered' && (
                                                                    <TooltipRegisteredData
                                                                        data={el}
                                                                        getRegisteredLink={() =>
                                                                            this.openProduct(
                                                                                el.record_id
                                                                            )
                                                                        }
                                                                    />
                                                                )}
    
                                                            {item.key ===
                                                                'image' && (
                                                                    <div className="c-rec-table__image-icon">
                                                                        <MediaView
                                                                            src={
                                                                                image
                                                                            }
                                                                            alt={
                                                                                el.product_name
                                                                            }
                                                                            fallbackSrc={
                                                                                fallbackImageSmall
                                                                            }
                                                                            useImgTag
                                                                            onClick={() =>
                                                                                this.openProduct(
                                                                                    el.record_id
                                                                                )
                                                                            }
                                                                            className="u_pointer"
                                                                        />
                                                                        {image !==
                                                                            defaultImage && (
                                                                                <MediaView
                                                                                    src={
                                                                                        image
                                                                                    }
                                                                                    className="c-rec-table__hover-image u_pointer"
                                                                                    fallbackSrc={
                                                                                        fallbackImageSmall
                                                                                    }
                                                                                    useImgTag
                                                                                    onClick={() =>
                                                                                        this.openProduct(
                                                                                            el.record_id
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </div>
                                                                )}
    
                                                            {item.key ===
                                                                'licensor_brand_id' && (
                                                                    <div
                                                                        className={`c-form-element ${
                                                                            validations[
                                                                                item
                                                                                    .key
                                                                                ]
                                                                                ? 'error'
                                                                                : ''
                                                                        }
                                                                                        ${
                                                                            !!get(
                                                                                el,
                                                                                'registered_with'
                                                                            ) ||
                                                                            el.conflicts_resolved_local ||
                                                                            this.checkValidations(
                                                                                el.validations
                                                                            )
                                                                        }
                                                                                        ${
                                                                            !additionalAttr &&
                                                                            includes(
                                                                                extendedAttrs,
                                                                                item.key
                                                                            )
                                                                                ? 'hidden'
                                                                                : ''
                                                                        }`}
                                                                    >
    
                                                                        <FormItemWrapper
                                                                            type={'select'}
                                                                            disabled={
                                                                                !!get(
                                                                                    el,
                                                                                    'registered_with'
                                                                                ) ||
                                                                                (!additionalAttr &&
                                                                                    includes(
                                                                                        extendedAttrs,
                                                                                        item.key
                                                                                    ) &&
                                                                                    modalActions) ||
                                                                                this.checkValidations(
                                                                                    el.validations
                                                                           
                                                                                    ) ||
                                                                                el.conflicts_resolved_local
                                                                            }
                                                                            value={
                                                                                !!size(
                                                                                    el[
                                                                                        item
                                                                                            .key
                                                                                        ]
                                                                                )
                                                                                    ? el[
                                                                                        item
                                                                                            .key
                                                                                        ]
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
    
                                                                                this.onChange(
                                                                                    e,
                                                                                    el,
                                                                                    item.key
                                                                                );
                                                                            }}
                                                                            values={
                                                                                linkedBrands.data[selectedLicensor.licensor_organisation_id].brands.map(
                                                                                    ({
                                                                                         brand_id,
                                                                                         brand
                                                                                     }) => {
                                                                                        return {
                                                                                            value: brand_id,
                                                                                            label: brand
                                                                                        };
                                                                                    }
                                                                                )
                                                                            } />
    
                                                                        {!!size(
                                                                            validations[
                                                                                item
                                                                                    .key
                                                                                ]
                                                                        ) && (
                                                                            <Tooltip
                                                                                placement="right"
                                                                                align={{
                                                                                    offset: [
                                                                                        0,
                                                                                        20
                                                                                    ]
                                                                                }}
                                                                                overlay={errorContentMessage(
                                                                                    validations[
                                                                                        item
                                                                                            .key
                                                                                        ]
                                                                                        .message
                                                                                )}
                                                                                trigger={[
                                                                                    'hover'
                                                                                ]}
                                                                            >
                                                                                <div>
                                                                                    <Icon
                                                                                        className="error-icon"
                                                                                        icon="Info"
                                                                                        fill="red"
                                                                                        size="12"
                                                                                        tooltip
                                                                                    />
                                                                                </div>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                )}
    
                                                            {item.key !==
                                                                'checkbox' &&
                                                                item.key !==
                                                                'image' &&
                                                                item.key !==
                                                                'licensor_brand_id' &&
                                                                item.key !==
                                                                'registered' && (
                                                                    <div
                                                                        className={`c-form-element ${
                                                                            validations[
                                                                                item
                                                                                    .key
                                                                                ]
                                                                                ? 'error'
                                                                                : ''
                                                                        }
                                                                                        ${
                                                                            !!get(
                                                                                el,
                                                                                'registered_with'
                                                                            ) ||
                                                                            el.conflicts_resolved_local ||
                                                                            this.checkValidations(
                                                                                el.validations
                                                                            ) ||
                                                                            (item.key ===
                                                                                'gtin' &&
                                                                                el.gtin_waiver)
                                                                                ? 'disabled'
                                                                                : ''
                                                                        }
                                                                                        ${
                                                                            !additionalAttr &&
                                                                            includes(
                                                                                extendedAttrs,
                                                                                item.key
                                                                            )
                                                                                ? 'hidden'
                                                                                : ''
                                                                        }`}
                                                                    >
    
    
                                                                        <FormItemWrapper
                                                                            type="text"
                                                                            maxLength={
                                                                                item.key === 'product_name'
                                                                                    ? '255'
                                                                                    : ''
                                                                            }
                                                                            disabled={
                                                                                !!get(
                                                                                    el,
                                                                                    'registered_with'
                                                                                ) ||
                                                                                (!additionalAttr &&
                                                                                    includes(
                                                                                        extendedAttrs,
                                                                                        item.key
                                                                                    ) &&
                                                                                    modalActions) ||
                                                                                this.checkValidations(
                                                                                    el.validations
                                                                                ) ||
                                                                                el.conflicts_resolved_local ||
                                                                                (item.key ===
                                                                                    'gtin' &&
                                                                                    el.gtin_waiver)
                                                                            }
                                                                            placeholder="Type value"
                                                                            defaultValue={
                                                                                !!size(
                                                                                    el[
                                                                                        item
                                                                                            .key
                                                                                        ]
                                                                                )
                                                                                    ? el[
                                                                                        item
                                                                                            .key
                                                                                        ]
                                                                                    : ''
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.onChange(
                                                                                    e,
                                                                                    el,
                                                                                    item.key
                                                                                )
                                                                            }
                                                                        />
    
                                                                        {!!size(
                                                                            validations[
                                                                                item
                                                                                    .key
                                                                                ]
                                                                        ) && (
                                                                            <Tooltip
                                                                                placement="right"
                                                                                align={{
                                                                                    offset: [
                                                                                        0,
                                                                                        20
                                                                                    ]
                                                                                }}
                                                                                overlay={errorContentMessage(
                                                                                    validations[
                                                                                        item
                                                                                            .key
                                                                                        ]
                                                                                        .message
                                                                                )}
                                                                                trigger={[
                                                                                    'hover'
                                                                                ]}
                                                                            >
                                                                                <div>
                                                                                    <Icon
                                                                                        className="error-icon"
                                                                                        icon="Info"
                                                                                        fill="red"
                                                                                        size="12"
                                                                                        tooltip
                                                                                    />
                                                                                </div>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )}
    
    
                                                    {!modalActions && (
                                                        <div>
                                                            {item.key ===
                                                                'view' && (
                                                                    <Button
                                                                        type="secondary-2"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            this.openProduct(
                                                                                el.record_id
                                                                            )
                                                                        }
                                                                    >
                                                                        View
                                                                    </Button>
                                                                )}
    
                                                            {item.key ===
                                                                'product_name' && (
                                                                    <ClickableText
                                                                        func={() =>
                                                                            this.openProduct(
                                                                                el.record_id
                                                                            )
                                                                        }
                                                                        value={
                                                                            el.product_name
                                                                        }
                                                                    />
                                                                )}
    
                                                            {item.key ===
                                                                'image' && (
                                                                    <div className="c-rec-table__image-icon">
                                                                        <MediaView
                                                                            src={
                                                                                image
                                                                            }
                                                                            alt={
                                                                                el.product_name
                                                                            }
                                                                            fallbackSrc={
                                                                                fallbackImageSmall
                                                                            }
                                                                            useImgTag
                                                                            className="u_pointer"
                                                                        />
                                                                        {image !==
                                                                            defaultImage && (
                                                                                <MediaView
                                                                                    src={
                                                                                        image
                                                                                    }
                                                                                    className="c-rec-table__hover-image u_pointer"
                                                                                    fallbackSrc={
                                                                                        fallbackImageSmall
                                                                                    }
                                                                                    useImgTag
                                                                                />
                                                                            )}
                                                                    </div>
                                                                )}
    
                                                            {item.key !==
                                                                'view' &&
                                                                item.key !==
                                                                'image' &&
                                                                item.key !==
                                                                'product_name' && (
                                                                    <span
                                                                        className={`${
                                                                            !el[
                                                                                item
                                                                                    .key
                                                                                ]
                                                                                ? 'not-defined'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                            {el[
                                                                                    item
                                                                                        .key
                                                                                    ] ||
                                                                                'Not Defined'}
                                                                        </span>
                                                                )}
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                            
                                        }
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>

                {modalActions && (
                    <ConflictsFooter
                        id={id}
                        updateRows={() => this.updateRows()}
                        rowData={rowData}
                        cloneData={cloneData}
                        data={data}
                        isBusy={isBusy}
                        isFailed={isFailed}
                        additionalAttr={additionalAttr}
                        toggleExtendedAttrs={() => this.toggleExtendedAttrs()}
                        fetchData={fetchData}
                        noErrors={(response) => this.noErrors(response)}
                        handleError={(error) => this.displayError(error)}
                        setConflicts={setConflicts}
                        rowsToUpdate={rowsToUpdate}
                    />
                )}

                {isBusy && (
                    <div>
                        <div className="loading"></div>
                        <div className="c-spinner">
                            <div className="c-spinner-icon">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <span className="c-spinner-label">
                                Please wait...
                            </span>
                        </div>
                    </div>
                )}

                {!isBusy && isFailed && (
                    <div>
                        <div className="loading"></div>
                        <div className="c-error">
                            <span className="c-error-message">
                                Sorry, there was a problem with your request.
                                Please try again.
                            </span>
                            <button
                                className="c-button"
                                onClick={() => this.displayError(false)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { xelacore: { categories: { linkedBrands = [] } = {} } = {} } = state;

    return {
        linkedBrands
    };
}

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(
    Conflicts
);
