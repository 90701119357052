import React from 'react';
import classnames from 'classnames';
import { Link } from 'src/js/components/static';

export default function Button({ children, ...props }) {
    const {
        iconLeft,
        iconRight,
        square,
        long,
        medium,
        bulky,
        fat,
        black,
        pink,
        blue,
        orange,
        navy,
        purple,
        purpleHover,
        green,
        green2,
        blue2,
        red,
        purpleGrad,
        purpleGrad2,
        greyHover,
        pinkHover,
        green2Hover,
        blueHover,
        noStyle,
        style,
        to,
        onClick,
        purpleLight,
        blueText,
        redText,
        noBorder,
        shadow,
        disabled,
        target
    } = props;
    const cx = classnames(props.className, 'c-button', {
        'c-button--icon-left': iconLeft,
        'c-button--icon-right': iconRight,
        'c-button--square': square,
        'c-button--long': long,
        'c-button--medium': medium,
        'c-button--bulky': bulky,
        'c-button--fat': fat,

        'c-button--black': black,
        'c-button--pink': pink,
        'c-button--blue': blue,
        'c-button--orange': orange,
        'c-button--navy': navy,
        'c-button--purple': purple,
        'c-button--purple-light': purpleLight,
        'c-button--purple-hover': purpleHover,

        'c-button--green': green,
        'c-button--green2': green2,
        'c-button--blue2': blue2,
        'c-button--red': red,

        'c-button--purpleGrad': purpleGrad,
        'c-button--purpleGrad2': purpleGrad2,

        'c-button--grey-hover': greyHover,
        'c-button--pink-hover': pinkHover,
        'c-button--green2-hover': green2Hover,
        'c-button--blue-hover': blueHover,
        'c-button--blue-text': blueText,
        'c-button--red-text': redText,

        'c-button--no-border': noBorder,
        'c-button--no-style': noStyle,

        'c-button--shadow': shadow,
        'disabled': !!disabled
    });

    if (props.to) {
        return (
            <Link
                style={style}
                className={cx} to={to}
                target={!!target ? '_blank' : '_self'}
                onClick={onClick}>
                <span>{children}</span>
            </Link>
        );
    }

    return (
        <button
            style={props.style}
            className={cx}
            onClick={props.onClick}
            disabled={!!disabled}
            type={props.type}
        >
            <span>{children}</span>
        </button>
    );
}
