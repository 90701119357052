import React from 'react';
import linkFromCatalogue from 'statics/imgs/desktop/concepts/link_from_catalogue.svg';

export default function AprovalNonRegistered() {
    return (
        <div>
            <div className="c-product__concept-block u-fat-bottom">
                <div>
                    <div className="u-padding">
                        <img
                            src={linkFromCatalogue}
                            className="u-padding"
                            alt="Link from catalogue"
                        />
                    </div>
                    <h3>
                        <span>Concept</span> Linking
                    </h3>
                    <div>There is no linked concept code for this product.</div>
                </div>
            </div>
        </div>
    );
}
