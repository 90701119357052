import React from 'react';

import MaintenanceImg from 'statics/imgs/app/maintenance.png';
import imgLogo from 'statics/imgs/app/xelacore-logo.svg';

const Maintenance = () => {
    return (
        <div className="c-maintenance">
            <img
                src={imgLogo}
                alt="Xelacore"
                className="c-maintenance__logo"
                height="50"
            />
            <div className="c-maintenance__container">
                <img
                    className="c-maintenance__img"
                    src={MaintenanceImg}
                    width="450"
                    alt="We're Undergoing Maintenance"
                />
                <h1 className="c-maintenance__heading">
                    We're Undergoing Maintenance
                </h1>
                <p className="c-maintenance__description">
                    We're undergoing maintenance, we'll be back online soon
                </p>
            </div>
        </div>
    );
};

export default Maintenance;
