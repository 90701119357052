// Adds commas to our numbers
// This function can be replaced with `Number.toLocaleString()`
export function numberCommas(x) {
    const val = x ? parseInt(x) : 0;
    if (isNaN(val)) return x;

    let parts = val.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export const isNum = (num, pos = false) => {
    const checkNum = Number.isInteger(parseInt(num));
    if (pos) return checkNum && num > 0;
    return checkNum;
};

export const getPercentageValue = (value, total) => Math.round(
    Math.ceil(
        (parseInt(value) / parseInt(total)) * 100
    )
);

export const getLevelValue = p => {
    if (p <= 39) return 'LOW';
    if (p <= 79) return 'MEDIUM';
    return 'HIGH';
};