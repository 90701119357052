export default {
    query: {
        query: {
            bool: {
                must: [
                    //  { term: { 'validations.gtin.level': 'error' } },
                    //  { term: { 'validations.language.level': 'success' } },
                    //  { term: { 'licensor_brand': 'White Toys' } }
                ],
                must_not: []
            }
        },
        aggregations: {
            Licensee: {
                terms: {
                    field: 'company',
                    size: 250
                },
                meta: {
                    field: 'company'
                }
            },
            Licensor: {
                terms: {
                    field: 'licensor',
                    size: 250
                },
                meta: {
                    field: 'licensor'
                }
            },
            Category: {
                terms: {
                    field: 'licensee_category_path',
                    size: 250
                },
                meta: {
                    field: 'licensee_category_path'
                }
            },
            'Manufacturing Status': {
                terms: {
                    field: 'manufacturing_status',
                    size: 250
                },
                meta: {
                    field: 'manufacturing_status'
                }
            },
            'Product Status': {
                terms: {
                    field: 'product_status',
                    size: 250
                },
                meta: {
                    field: 'product_status'
                }
            },
            Registration: {
                terms: {
                    field: 'is_registered',
                    size: 250
                },
                meta: {
                    field: 'is_registered'
                }
            },
            Actions: {
                filter: {
                  bool: {
                    must: [
                      {
                        bool: {
                          should: [
                            {
                              bool: {
                                must: [
                                  {
                                    term: {
                                      'conflicts_gtin_local_count': 0
                                    }
                                  },
                                  {
                                    term: {
                                      'conflicts_mpn_local_count': 0
                                    }
                                  }
                                ]
                              }
                            },
                            {
                              term: {
                                'conflicts_resolved_local': true
                              }
                            }
                          ]
                        }
                      },
                      {
                          term: {
                            errors: 0
                          }
                        },
                      {
                          term: {
                              is_registered: false
                          }
                      }
                    ]
                  }
                },
                meta: {
                    field: 'Actions'
                }
            },
            Concept: {
                terms: {
                    field: 'is_concept',
                    size: 250
                },
                meta: {
                    field: 'is_concept'
                }
            },
            Errors: {
                range: {
                    field: 'errors',
                    ranges: [{ from: 1 }]
                },
                meta: { field: 'errors' }
            },
            Warnings: {
                range: {
                    field: 'warnings',
                    ranges: [{ from: 1 }]
                },
                meta: { field: 'warnings' }
            },
            Conflicts: {
                range: {
                    field: 'conflicts_total_count',
                    ranges: [{ from: 1 }]
                },
                meta: { field: 'conflicts_total_count' }
            },
            'Target Market': {
                terms: { field: 'target_market', size: 250 },
                meta: { field: 'target_market' }
            },
            Validation: {
                terms: { field: 'validation_messages' },
                meta: { field: 'validation_messages' }
            },
            Talent: {
                terms: { field: 'licensor_talent' },
                meta: { field: 'licensor_talent' }
            },
            'Other IP': {
                terms: { field: 'licensor_other' },
                meta: { field: 'licensor_other' }
            }
        }
    }
};
