export const nullToString = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((e) => {
        if (e === 'mpn_combined')
            return (newObj[e] = obj[e] ? obj[e].filter(Boolean) : []);
        if (obj[e] === null) return (newObj[e] = '');
        return (newObj[e] = obj[e]);
    });
    return newObj;
};

export const valueToString = (value) => {
    switch (typeof value) {
        case 'undefined':
        case null:
            return '';
        case 'object':
            return Array.isArray(value)
                ? value.join('')
                : JSON.stringify(value);
        default:
            return value.toString();
    }
};

const setJerkHelper = (el) => {
    if (el === 'record_id') return '[in]';
    if (el === 'gpc' || el === 'trade_pk_qty' || el === 'product_id') return '';
    if (
        el === 'is_licensed' ||
        el === 'is_character_licensed' ||
        el === 'product_status' ||
        el === 'manufacturing_status'
    )
        return '[eq]';
    return '[ilike]';
};

// Returns a query string for My Records filtering
export const createQueryString = (obj) => {
    const filters = {};
    if (!obj) return filters;
    Object.keys(obj).forEach((el) => {
        const elements = obj[el].map((item) => item).join(',');
        // TODO: Create specific logic for this
        let ifJerk = setJerkHelper(el);
        const string = `filter[${el}]${ifJerk}`;
        // Might need to create rules for the '[in]' param but leave for now
        filters[string] = elements;
    });
    return filters;
};

export const createQueryStringForCsv = (obj) => {
    const filters = {};
    if (!obj) return filters;
    if (!obj || (Object.keys(obj).length === 0 && obj.constructor === Object))
        return filters;
    Object.keys(obj).forEach((el) => {
        const ifJerk =
            el === 'gpc' || el === 'trade_pk_qty' || el === 'columns'
                ? ''
                : '[ilike]';
        filters[`${el}${ifJerk}`] = obj[el].map((item) => item).join(',');
    });
    return filters;
};

export const serializeObject = function (obj) {
    const str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
};

// Returns a query string for My Records filtering
export const getFilenameWithoutExt = (fileName) => {
    // break the name to the name and extension
    var m = fileName.match(/([^:\\/]*?)(?:\.([^ :\\/.]*))?$/);
    return m === null ? 'name' : m[1];
};

export const isNullOrUndefined = (obj) => obj === undefined || obj === null;
