import React, { Fragment, Component } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import capitalize from 'lodash/capitalize';
import each from 'lodash/each';
import classnames from 'classnames';
import { Icon, Tooltip, Auth } from 'src/js/components/static';
import {
    Url,
    Text,
    ObjectField,
    AsinArray,
    DynamicArray,
    DynamicCategory,
    DynamicIps
} from 'src/js/components/ProductListElements';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import { specialSectionsNameForLicensor } from 'src/js/constants/productConstants/index';
import { formatValue } from 'src/js/helpers/strings';
import { getSortedFormFields, getGroupedHeaders } from 'src/js/helpers/headers';

const ARRAY_TYPES = ['array', 'imageArray', 'selectdropdown', 'dynamicCategory', 'dynamicArray'];
export default class ListFields extends Component {
    constructor(props) {
        super(props);
        const { data: { validations = [] } = {}, orderByValidations } = props;

        // These two headers are already displayed separately above
        const headers = defaultHeaders
            .filter((header) => header.config && header.config.group)
            .filter((h) => h.showInForm !== false)
            .filter(
                (f) =>
                    f.dataName !== 'product_name' &&
                    f.dataName !== 'product_description'
            );

        const validationsHash = isPlainObject(validations)
            ? validations
            : validations.reduce(
                  (hash, el) => ({ ...hash, [el.field]: el }),
                  {}
              );

        const sortedHeaders = orderByValidations
            ? getSortedFormFields(validationsHash, headers)
            : headers;

        this.groupedHeaders = getGroupedHeaders(sortedHeaders);

        this.state = {
            collapsed: {}
        };
    }

    renderElement = (
        data,
        xelacore,
        dispatch,
        isLicensor,
        type,
        value,
        dataName,
        validations = [],
        expand = TextTrackCue
    ) => {
        const {ips, ipLevels, isAgent} = this.props;

        switch (type) {
            case 'selectdropdown':
            case 'object':
            case 'image':
            case 'array':
            case 'imageArray':
                return (
                    <ObjectField
                        bold
                        value={value}
                        type={dataName}
                        expand={expand}
                        validations={validations}
                    />
                );
            case 'url':
                return <Url bold value={value} />;
            case 'asinArray':
                return <AsinArray value={value} type={dataName} />;
            case 'dynamicArray':
                return <DynamicArray
                            value={value}
                            type={dataName}
                            validations={validations}
                        />;
            case 'dynamicCategory':
                return <DynamicCategory
                            validations={validations}
                            xelacore={xelacore}
                            dispatch={dispatch}
                            value={value}
                            isAgent={isAgent}
                            type={isLicensor || isAgent ? 'licensor_category_path' : 'licensee_category_path'}
                        />
            case 'dynamicIps':
                return <DynamicIps
                            validations={validations}
                            xelacore={xelacore}
                            dispatch={dispatch}
                            value={value}
                            data={data}
                            ips={ips}
                            ipLevels={ipLevels}
                        />
            default:
                return <Text bold value={formatValue(value)} />;
        }
    };

    getValue(name, isLicensor, data) {
        const staticAttrs = !!data.static_attributes ? Object.keys(data.static_attributes) : [];
        const { categoryAttributes, isAgent } = this.props;

        if (name === 'concept_code_confirmed_info' && isLicensor) {
            return data.concept_code_confirmed_info.map(
                (code) => code.xelacore_concept_id
            );
        }

        if(name === 'category_attributes') {
            let newCategoryAttrsArray = [];

            each(categoryAttributes, attr => {
                newCategoryAttrsArray.push({
                    label: !!attr && !!attr.attribute_key && capitalize(attr.attribute_key.replaceAll('_', ' ')) || '',
                    attribute_key: !!attr && !!attr.attribute_key && attr.attribute_key || '',
                    attribute_value: !!attr && !!attr.attribute_key && data[name][attr.attribute_key] || ''
                })
            })

            return newCategoryAttrsArray;
        }

        if (includes(staticAttrs, name)) {
            each(staticAttrs, (attr) => {
                data[attr] = data.static_attributes[attr];
            })
        }

        if(name === 'licensee_category_path' && (!!isLicensor || !!isAgent)) {
            return data['licensor_category_path'];
        }

        return data[name];
    }

    render() {
        const {
            data,
            data: { validations = [] },
            productType,
            isLicensor,
            dispatch,
            xelacore,
            categoryAttributes,
            hasStaticAttributes
        } = this.props;
        const { collapsed } = this.state;
        const renderFieldsSet = (elements, groupName) => (
            <ul
                className={classnames({
                    'c-product__group-content': groupName,
                    'c-product__group-content--collapsed': !collapsed[groupName]
                })}
            >
                {elements
                    .filter((el) => {
                        const {
                            form: { hideIfEmpty },
                            dataName
                        } = el;
                        const value = data[dataName];
                        return (
                            !hideIfEmpty ||
                            (hideIfEmpty && value && value.length > 0)
                        );
                    })
                    .map(
                        (
                            {
                                dataName,
                                label,
                                tooltip,
                                form: { type },
                                config: { isAssociatedIp = false } = {}
                            },
                            i
                        ) => {
                            let fieldVals = validations.filter((f) => {
                                let fieldToCheck = (f.field === 'agent_organisation_id' || f.field === 'agent_organisation') ? 'agent_organisation' : f.field

                                return ARRAY_TYPES.includes(type)
                                    ? f.field.startsWith(dataName)
                                    : fieldToCheck === dataName || f.field === `static_attributes.${dataName}`
                            });

                            if (isAssociatedIp) {
                                fieldVals = uniqBy(fieldVals, 'code');
                            }

                            // If its got validations
                            // Return the first error or else return the first warning
                            const val = fieldVals.find(
                                ({ level }) =>
                                    level === 'error' || level === 'warn'
                            );
                            const vlevel = !!val ? val.level : null;

                            return productType === 'catalog' || isLicensor ? (
                                <li
                                    key={`${dataName}-${i}-product-detail-listing`}
                                    className={classnames(
                                        'c-product__detail-listing-item',
                                        {
                                            'c-product__detail-listing-item--flex':
                                                type !== 'asinArray',
                                            hidden: dataName === 'images'
                                        }
                                    )}
                                >
                                    <div className={`c-product__detail-listing-item-title ${!label || dataName === 'ip_paths' || dataName === 'additional' || dataName === 'licensee_category_path' || dataName === 'licensor_category_path' ? 'no-title' : ''}`}>
                                        {label && dataName !== 'ip_paths' ? label + ':' : ''}
                                    </div>
                                    <div className="c-product__detail-listing-item-info">
                                        <div className="c-product__detail-listing-item-main">
                                            {this.renderElement(
                                                data,
                                                xelacore,
                                                dispatch,
                                                isLicensor,
                                                type,
                                                this.getValue(
                                                    dataName,
                                                    isLicensor,
                                                    data
                                                ),
                                                dataName,
                                                null
                                            )}
                                        </div>
                                    </div>
                                    <Auth restrictTo="licensee">
                                        <Tooltip>{tooltip['licensee']}</Tooltip>
                                    </Auth>
                                    <Auth restrictTo="licensor">
                                        {!!tooltip['licensor'].length && (
                                            <Tooltip>
                                                {tooltip['licensor']}
                                            </Tooltip>
                                        )}
                                    </Auth>
                                    <Auth restrictTo="agent">
                                        {!!tooltip['licensor'].length && (
                                            <Tooltip>
                                                {tooltip['licensor']}
                                            </Tooltip>
                                        )}
                                    </Auth>
                                </li>
                            ) : (
                                <li
                                    key={`${dataName}-${i}-product-detail-listing`}
                                    className={classnames(
                                        'c-product__detail-listing-item',
                                        {
                                            'c-product__detail-listing-item--flex':
                                                type !== 'asinArray',
                                            'c-product__detail-listing-item-error':
                                                vlevel === 'error',
                                            'c-product__detail-listing-item-warn':
                                                vlevel && vlevel !== 'error',
                                            hidden:
                                                dataName === 'images' &&
                                                !fieldVals.length
                                        }
                                    )}
                                >
                                    <div className={`c-product__detail-listing-item-title ${!label || dataName === 'ip_paths' || dataName === 'additional' || dataName === 'licensee_category_path' || dataName === 'licensor_category_path' ? 'no-title' : ''}`}>
                                        {label && dataName !== 'ip_paths' && dataName !== 'additional' ? label + ':' : ''}
                                    </div>
                                    <div
                                        className={`c-product__detail-listing-item-info${
                                            type === 'asinArray' ? '-asin' : ''
                                        }`}
                                    >
                                        {!!(type !== 'asinArray' && type !== 'object' && type !== 'dynamicCategory' && type !== 'dynamicArray') && vlevel && (
                                            <div className="c-product__detail-listing-item-icon">
                                                {vlevel && (
                                                    <Icon
                                                        icon={
                                                            vlevel === 'error'
                                                                ? 'error2'
                                                                : 'warning'
                                                        }
                                                        fill={
                                                            vlevel === 'error'
                                                                ? 'red'
                                                                : 'darkOrange'
                                                        }
                                                        className="u-margin-small-right"
                                                        top={2}
                                                        size="12"
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <div className="c-product__detail-listing-item-main">
                                            {!!((val && val.message) && ((val.value.length<1) || (val.value === '[]'))) && type !== 'dynamicCategory' && type !== 'dynamicArray' && (
                                                <span>{val.message}</span>
                                            )}

                                            {this.renderElement(
                                                data,
                                                xelacore,
                                                dispatch,
                                                isLicensor,
                                                type,
                                                this.getValue(
                                                    dataName,
                                                    isLicensor,
                                                    data
                                                ),
                                                dataName,
                                                fieldVals
                                            )}

                                            {fieldVals.length > 0 &&
                                                type !== 'dynamicCategory' &&
                                                (!ARRAY_TYPES.includes(type) ||
                                                    !data[dataName] ||
                                                    (ARRAY_TYPES.includes(
                                                        type
                                                    ) &&
                                                        data[dataName]
                                                            .length === 1)) && (
                                                    <ul className="o-list c-form-element__validation-status--list">
                                                        {fieldVals.map(
                                                            (
                                                                {
                                                                    message,
                                                                    level,
                                                                    value,
                                                                    field
                                                                },
                                                                index
                                                            ) => (
                                                                <li
                                                                    key={`${field}-${level}-${index}`}
                                                                    className={`c-product__detail-listing-item-level-${level}`}
                                                                >
                                                                    {field ===
                                                                    'licensor_brand' ? (
                                                                        <React.Fragment>
                                                                            <b>
                                                                                "
                                                                                {
                                                                                    value
                                                                                }

                                                                                "
                                                                            </b>{' '}
                                                                            {
                                                                                message
                                                                            }
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        message
                                                                    )}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </div>

                                    {type !== 'asinArray' && (
                                        <Tooltip
                                            warn={vlevel && vlevel !== 'error'}
                                            error={vlevel === 'error'}
                                        >
                                            {tooltip['licensee']}
                                        </Tooltip>
                                    )}
                                </li>
                            );
                        }
                    )}
            </ul>
        );

        return (
            <Fragment>
                <div className="c-product__groups-container">
                    {Object.keys(this.groupedHeaders.grouped).map(
                        (groupName, i) => {
                            let showCategoryAttributes = groupName === 'Category Attributes' && !!categoryAttributes && categoryAttributes.length > 0;
                            let showDynamicAttributes = groupName === 'Dynamic Attributes' && !!data.dynamic_attributes;
                            let showBrandAttributes = groupName === 'Brand Attributes' && hasStaticAttributes && !!data.ips;

                            return (groupName !== 'Category Attributes' && groupName !== 'Dynamic Attributes' && groupName !== 'Brand Attributes' || showCategoryAttributes || showDynamicAttributes || showBrandAttributes) && (
                                <div key={groupName} className="c-product__group">
                                    <div
                                        className={classnames(
                                            'c-product__group-header',
                                            {
                                                'c-product__group-header--collapsed':
                                                    !collapsed[groupName]
                                            }
                                        )}
                                        onClick={() => {
                                            this.setState({
                                                collapsed: {
                                                    ...collapsed,
                                                    [groupName]:
                                                        !collapsed[groupName]
                                                }
                                            });
                                        }}
                                    >
                                        <Auth restrictTo="licensee">
                                            <h5>{groupName}</h5>
                                        </Auth>
                                        <Auth restrictTo="licensor">
                                            <h5>
                                                {specialSectionsNameForLicensor[
                                                    groupName
                                                ] || groupName}
                                            </h5>
                                        </Auth>
                                        <Auth restrictTo="agent">
                                            <h5>
                                                {specialSectionsNameForLicensor[
                                                    groupName
                                                ] || groupName}
                                            </h5>
                                        </Auth>
                                        <span>

                                            <i />
                                        </span>
                                    </div>

                                    {
                                        renderFieldsSet(
                                            Object.values(
                                                this.groupedHeaders.grouped[groupName]
                                            ),
                                            groupName
                                        )
                                    }
                                </div>
                            )
                        }
                    )}
                </div>
                {renderFieldsSet(Object.values(this.groupedHeaders.ungrouped))}
            </Fragment>
        );
    }
}
