export const permConstants = {
    // Catalog
    CATALOG_UPDATE: 'frontend:datamanager:catalog:update',
    CATALOG_READ: 'frontend:datamanager:catalog:read',
    CATALOG_REGISTER: 'frontend:datamanager:catalog:register',
    // CATALOG_SYNDICATE: 'frontend:datamanager:catalog:syndicate',

    // Data Management
    DATAMANAGER_MANAGE_DUPLICATES:
        'frontend:datamanager:upload:manage:duplicates',
    DATAMANAGER_MANAGE_ERRORS: 'frontend:datamanager:upload:manage:errors',
    DATAMANAGER_MANAGE_WARNINGS: 'frontend:datamanager:upload:manage:warns',

    // User Management
    USERS_CREATE: 'frontend:users:users:create',
    USERS_DELETE: 'frontend:users:users:read',
    USERS_UPDATE: 'frontend:users:users:update'
};
