import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import { getUserPermissions } from 'src/js/actions/permissions';
import {
    checkPermissions,
    checkAccountTypePermission
} from 'src/js/helpers/permissions';

export default connect(
    (state) => state,
    (dispatch) => ({ dispatch })
)((props) => <Auth {...props} />);

/*
    < Auth
        restricto="licensor || licensee"
        permission="something"
        view
        manage
    />

*/

class Auth extends Component {
    componentWillMount() {
        const {
            dispatch,
            xelacore: { auth: { token, companyData, userPermissions } = {} }
        } = this.props;

        const modules = (userPermissions || {}).modules;
        if (!modules && companyData && companyData.organisation_id) {
            const { employee_id } = jwtDecode(token).data;
            return dispatch(
                getUserPermissions(companyData.organisation_id, employee_id)
            );
        }
    }

    render() {
        const {
            xelacore: {
                auth: { token, companyData = {}, userPermissions } = {}
            },
            children,
            restrictTo,
            permission = null,
            view = null,
            manage = null,
            employeeId = null
        } = this.props;

        const { modules, system, features } = userPermissions || {};
        const { employee_id: currentEmployeeId } = jwtDecode(token).data;
        const allPerms = Object.assign({}, modules, system, features);

        const userTypeRestriction = checkAccountTypePermission(
            companyData,
            restrictTo
        );
        const noOtherRestrictions =
            (permission === null || !permission.length) &&
            view === null &&
            manage === null;
        if (
            permission &&
            permission.split('.')[0] === 'users' &&
            currentEmployeeId === employeeId
        )
            return null;

        // If given user type and it's incorrect - no other checks
        if (restrictTo && !userTypeRestriction) return null;

        // This check if it's a licensor or licensee and nothing else is restricting it
        if (userTypeRestriction && noOtherRestrictions) return children;

        // Check if there's a permission attached and we've already loaded our users permissions
        if (permission && allPerms && !isEmpty(allPerms)) {
            // Passing an outright permission will autmatically allow the user all access
            // Eg. <Auth permission="something" view manage />  is the same as <Auth permission="something" />

            // If they have basic permissions first of all
            if (checkPermissions(allPerms, permission)) {
                return children;
            }
        }

        return null;
    }
}
