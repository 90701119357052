import {
    getApiUserState,
    setApiUserState,
    fetchOrganisationsInfo,
    fetchCustomReports
} from 'src/js/apicalls/auth/user';
import { setUserSettings, refreshTheRefreshToken, setCustomReports } from './xelacore';
import { buildUserSettings } from 'src/js/actions/userSettings';
import filter from 'lodash/filter';

// showRedirectModal
export function getUserSettings() {
    return dispatch =>
        getApiUserState('settings')
            .then(resp => {
                const { settings } = resp;
                dispatch(buildUserSettings({ settings }));
            })
            .catch(error => {
                // This is a special case when no settings found for user
                const { response: { status } = {} } = error;
                if (status === 401) return dispatch(refreshTheRefreshToken());
                return dispatch(buildUserSettings({}));
            });
}
export function getCustomRecords() {
    return dispatch =>
        fetchCustomReports()
            .then(resp => {
                let filterDashboardType = filter(resp, dashboard => dashboard.view !== 'dashboard');
                dispatch(setCustomReports(filterDashboardType || []));
            })
            .catch(() => {
                dispatch(setCustomReports([]));
            });
}

export function getOrganisationsInfo(id = '') {
    return () => {
        return fetchOrganisationsInfo(id);
    };
}

export function setUserState(key, data) {
    if (!['settings'].includes(key))
        return Promise.reject('Incorrect key, illegal action'); //Now working only with settings
    if (!data) return Promise.reject('No data found, illegal action');
    return dispatch => {
        return setApiUserState(key, data).then(resp => {
            dispatch(setUserSettings({ settings: resp.settings }));
        });
    };
}
