import React from 'react';
import moment from 'moment';
import { Auth, Badge, Button, Tooltip } from 'src/js/components/static';

export default function ApprovalCodeBlock({ ...props }) {
    const {
        approvalCode,
        aprConfirmed,
        aprConfirmedOn,
        confirmCode,
        removeCode,
        updateCode,
        record_id
    } = props;
    return (
        <div>
            <div
                className={`u-margin-top-2x u-margin-bottom ${
                    aprConfirmed ? 'c-product__approved' : 'c-product__provided'
                }`}
            >
                <div className="c-product__approval-code">
                    <Badge
                        green={!!aprConfirmed}
                        orange={!aprConfirmed}
                        text="Concept Code"
                        value={approvalCode}
                        className="u-margin-bottom"
                    />
                    {aprConfirmed && (
                        <div>
                            {/*<Badge*/}
                                {/*transparent*/}
                                {/*text="Confirmed by"*/}
                                {/*value="Malcolm Clark"*/}
                                {/*className="u-margin-bottom"*/}
                            {/*/>*/}
                            <Badge
                                transparent
                                text="Confirmed on"
                                value={moment(aprConfirmedOn).format(
                                    'DD MMM YYYY HH.mm'
                                )}
                                className="u-margin-bottom"
                            />
                        </div>
                    )}
                    {!aprConfirmed && (
                        <Auth restrictTo="licensee">
                            <div>
                                <div className="u-margin-left u-flex-align">
                                    <Button
                                        onClick={() =>
                                            confirmCode(record_id, approvalCode)
                                        }
                                    >
                                        Confirm Concept
                                    </Button>
                                    <Tooltip>
                                        Pressing this button will confirm that the
                                        concept approval code shown is correct. The
                                        licensor for this concept code will be aware
                                        that this match has been confirmed.
                                    </Tooltip>
                                </div>
                            </div>
                        </Auth>
                    )}
                </div>
            </div>
            <Auth restrictTo="licensee">
                <div>
                    {!aprConfirmed && (
                        <div className="u-block u-bold u-bottom">
                            Is the Product Concept Information above incorrect?
                        </div>
                    )}
                    <div className="u-flex">
                        <div className="u-margin-right u-flex-align">
                            <Button
                                blueText
                                noBorder
                                onClick={() =>
                                    updateCode(record_id, approvalCode)
                                }
                            >
                                Change Concept
                            </Button>
                            <Tooltip>
                                If the concept is not the correct concept
                                record for this product, you can change the associated
                                concept by selecting this option.
                            </Tooltip>
                        </div>
                        <div className="u-margin-left u-flex-align">
                            <Button redText noBorder onClick={() => removeCode(record_id, approvalCode)}>
                                Remove concept from product
                                <Tooltip>
                                    If the concept is not the correct
                                    concept for this product, and you are currently
                                    not in a position to confirm the correct
                                    concept; you can remove it by selecting this
                                    option.
                                </Tooltip>
                            </Button>
                        </div>
                    </div>
                </div>
            </Auth>
        </div>
    );
}
