import React from 'react';
import classnames from 'classnames';
import MediaView from 'src/js/components/MediaView';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

export default function Carousel({ images, currentIndex, setIndex }) {
    return (
        <div className="c-carousel">
            {images
                .filter(f => f !== null)
                .map((e, i) => {
                    const cx = classnames('c-carousel__item', {
                        'c-carousel__item--active': i === currentIndex
                    });
                    return (
                        <div
                            key={`${e}-${i}`}
                            className={cx}
                            onClick={() => setIndex(i)}
                        >
                            <LazyLoadComponent
                                placeholder={<div />}
                                visibleByDefault={true}
                                threshold={2000}
                            >
                                <MediaView
                                    className="c-product__dived-img"
                                    src={ e && (e.thumb_url_200 || e.local_url || e.original_url) }
                                    useImgTag
                                />
                            </LazyLoadComponent>
                        </div>
                    );
                })}
        </div>
    );
}
