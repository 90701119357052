import axios from 'axios';
import config from 'config';
import { SCOPE } from 'src/js/constants/oauthConstants';
import { logout } from 'src/js/lib/auth';
import { serializeObject } from 'src/js/helpers/objects';
import responseHandler from '../common/responseHandler';
import { browserHistory } from 'react-router';

/**
 * Fetches an authorisation code for a user
 *
 * @param  {String} token
 * @param  {String} grant_type
 * @return {Promise}
 */

export function fetchOauthCode({ email, password }, grant_type) {
    const postBody = {
        grant_type: grant_type ? grant_type : 'password', // urn:xelacore.io
        username: email,
        password: password,
        client_id: config.client_id,
        scope: SCOPE
    };

    return axios
        .request({
            url: [config.urls.oauth, 'token'].join('/'),
            method: 'post',
            data: serializeObject(postBody),
            params: {},
            headers: {}
        })
        .then(responseHandler)
        .catch(e => {
            if (e.response && e.response.data.error === 'invalid_grant') {
                browserHistory.push('/logout');
            }
            return Promise.reject(e);
        });
}

/**
 *  This API call lists the organistion a user belongs to.
 *  Applying an ID will fetch data for that specific organisation
 *
 * @param  {String} token
 * @param  {string} id
 * @return {Promise}
 */
export function fetchUserOrganisation(token, id = '') {
    return axios
        .request({
            url: [config.urls.auth, 'organisations', id].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') },
            params: {
                page: 1,
                page_size: 10000
            }
        })
        .then(responseHandler)
        .catch((error) => {
            const { response: { status } = {} } = error;
            if (status === 401) return logout();
        });
}

/**
 * Set the bearer token based on a users organisation that they belong to
 *
 * @param  {Object} details
 * @param  {String} details.orgId
 * @param  {String} details.refreshToken
 * @return {Promise}
 */

export function setUserOrganisation(details) {
    const postBody = {
        grant_type: details.orgId
            ? 'urn:xelacore.io:oauth2:refresh-organisation'
            : 'urn:xelacore.io:oauth2:refresh',
        refresh_token: details.refresh_token || details.refreshToken,
        client_id: config.client_id,
        redirect_uri: config.redirect_uri,
        scope: SCOPE
    };

    if (details.orgId) {
        postBody.organisation_id = details.orgId;
    }

    return axios
        .request({
            url: [config.urls.oauth, 'token'].join('/'),
            method: 'post',
            data: serializeObject(postBody)
        })
        .then(responseHandler)
        .catch(e => {
            if (e.response && e.response.data.error === 'invalid_grant') {
                logout();
            }
        });
}
/**
 * Set the bearer token based on a users organisation that they belong to
 *
 * @param  {Object} data
 * @return {Promise}
 */

export function resetUserPassword(data) {
    return axios
        .request({
            url: [config.urls.auth, 'user/reset'].join('/'),
            method: 'post',
            data,
            headers: {}
        })
        .then(responseHandler);
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {String} password
 * @param  {String} token
 * @return {Promise}
 */
export function setNewUserPassword(password, token) {
    return axios
        .request({
            url: [config.urls.auth, 'user/reset', token].join('/'),
            method: 'post',
            data: { password }
        })
        .then(responseHandler);
}
