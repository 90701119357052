import React from 'react';
import { checkSetUrl } from 'src/js/helpers/dataHelpers';
import { Icon } from 'components/static';
import classnames from 'classnames';

export default function Input({
                                  name,
                                  type,
                                  onChange,
                                  onBlur,
                                  onFocus,
                                  value,
                                  id,
                                  defaultValue,
                                  placeholder,
                                  disabled,
                                  actionIcon,
                                  actionPosition,
                                  inputAction
                              }) {
    // Automatically prepend 'https' to URl form types
    const setValue = () => {
        const len = value ? value.length : '';
        if (type === 'url' && len > 0) {
            // Check if the user only started to input
            // plus handle situations like ba.com
            const uxTry = len < 15 && value.indexOf('.') === -1;
            if (
                value.indexOf('http://') > -1 ||
                value.indexOf('https://') > -1 ||
                uxTry
            )
                return value;
            return checkSetUrl('https://' + value);
        }
        return value;
    };

    return (
        <div className={
            classnames('text-icon-input-wrapper', {
                'with-icon': !!inputAction,
                'left-icon': actionPosition === 'left',
                'right-icon': actionPosition !== 'left'
            })}>

            <input
                name={name}
                type={type}
                onChange={onChange}
                value={setValue()}
                onBlur={onBlur}
                maxLength={name === 'product_name' ? 255 : ''}
                onFocus={onFocus}
                id={id}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
            />

            {inputAction && (


                <Icon
                    className="u-clickable"
                    icon={actionIcon}
                    size={20}
                    onClick={inputAction}
                />
            )}
        </div>
    );
}
