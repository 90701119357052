import React from 'react';
import UiButton from 'modules/UiKit/components/Button/Button';
import { Icon } from 'src/js/components/static';

const AdvancedFilteringHeader = ({ resetState, isFiltersReturnedNoData, close }) => (
    <div className="ui-filters-header">
        <div>
            <span>Filters</span>

            {!isFiltersReturnedNoData && (
                <UiButton
                type={'secondary'}
                size={'small'}
                    onClick={() => resetState()}>
                    Clear
                </UiButton>
            )}
        </div>

        <Icon
            className="c-rec-panel__close-menu"
            onClick={() => close()}
            icon="close"
            fill="black"
        />
    </div>
);

export default AdvancedFilteringHeader;
