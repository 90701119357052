import React, { Component } from 'react';
import {
    hideModal,
    displayNotification,
    showModal,
    setMyRecords
} from 'src/js/actions/xelacore';
import { connect } from 'react-redux';
import { pluralise } from 'src/js/helpers/strings';
import { Modal } from 'src/js/components/static';
import {
    getProductInformation,
    registerProducts
} from 'src/js/actions/records';
import RegisterOutputModal from 'src/js/components/Modals/RegisterOutputModal';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import each from 'lodash/each';
import Button from 'modules/UiKit/components/Button/Button';
import { selectAll } from 'src/js/actions/productsFetch';
import { searchableFileds } from 'src/js/constants/advancedFilteringConstants';

import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import { getUrlParam } from 'src/js/helpers/strings';

class RegisterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            errored: false,
            registered: false,
            filteredData: [],
            properSelected: [],
            uploadedData: [],
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }

    componentDidMount() {
        this.getDetailedData();
    }

    showError() {
        const { dispatch, setHideModal, resetRows } = this.props;
        this.setState({ submitting: false });
        setHideModal();
        resetRows();
        return dispatch(
            displayNotification({
                message:
                    'There has been an error submitting your products for registration.',
                type: 'error',
                timeOut: 5000
            })
        );
    }

    getDetailedData() {
        this.setState({ submitting: true });
        const { selectedRows, dispatch } = this.props;
        const obj = {
            record_id: selectedRows
        };
        const pagination = {
            page: 1,
            page_size: selectedRows.length
        };
        return dispatch(getProductInformation('records', obj, pagination, true))
            .then(resp => {
                const { filteredData, properSelected } = this.setProperSelected(
                    resp.records
                );
                this.setState({
                    submitting: false,
                    filteredData,
                    properSelected,
                    uploadedData: resp.records
                });
            })
            .catch(() => {
                this.showError()
            });
    }

    resetState() {
        const { updateFiltersState, queryObject, basicFilterObj, dispatch } = this.props;
        let newQueryObject = cloneDeep(queryObject);
        let newFilters = basicFilterObj;

        dispatch(
            selectAll(
                [],
                true,
                []
            )
        );

        newQueryObject.query.query.bool.must = filter(get(queryObject, 'query.query.bool.must', []), (item) => item.query_string);

        each(newFilters, (filter, key) => {
            if (!includes(searchableFileds, key)) {
                delete newFilters[key];
            }
        });

        updateFiltersState(newQueryObject, newFilters, true, {
            page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT
        });
    }

    submitProductsForRegistration() {
        const {
            selectedRows,
            dispatch,
            setShowModal,
            setRegisterProducts,
            resetRows
        } = this.props;
        const { properSelected } = this.state;
        const registered = [];
        this.setState({
            submitting: true
        });

        // We have to register the products individually or else the b/e will timeout
        // So we fire off and resolve each promise individually
        // We then group all the messags together and fire them off as normal
        const registerProducts = () => {
            let p = Promise.resolve();
            // Comment this out for now
            // const listForDelete = data.filter(item => selectedRows.includes(item.record_id) && !item.registered_with).map(record => record.record_id);
            // if (!listForDelete.length) return setHideModal();
            properSelected.forEach(el => {
                const obj = { records: [el] };
                p = p.then(() => {
                    return setRegisterProducts(obj)
                        .then(({ data }) => {
                            registered.push(data.registered[0]);
                        })
                        .catch(() => {
                            this.showError();
                        });
                });
            });

            return p;
        };

        return registerProducts()
            .then(() => new Promise(resolve => setTimeout(resolve, 2000)))
            .then(() => {
                this.setState({ submitting: false });
                const { registrableIds } = this.props;
                const { uploadedData } = this.state;

                if (registrableIds) {
                    const newRegistrableIds = registrableIds.filter(
                        registrableId =>
                            isEmpty(
                                registered.find(
                                    record => record.record_id === registrableId
                                )
                            )
                    );
                    dispatch(
                        setMyRecords({ registrableIds: newRegistrableIds })
                    );
                }

                resetRows();

                if (!!selectedRows && selectedRows.length > 1) {
                    this.resetState();
                }

                const modalContent = (
                    <RegisterOutputModal
                        data={uploadedData.map(item => ({
                            record_id: item.record_id,
                            licensor: item.licensor,
                            licensor_organisation_id:
                                item.licensor_organisation_id
                        }))}
                        registered={registered}
                        selectedRows={selectedRows}
                        hideModal={() => dispatch(hideModal())}
                    />
                );
                return setShowModal(modalContent);
            })
            .catch(() => {
                this.showError();
            });
    }

    setProperSelected = data => {
        const { selectedRows } = this.props;
        const filteredData = data.filter(
            el => selectedRows.indexOf(el.record_id) > -1
        );
        const properSelected = filteredData.reduce((acc, row) => {
            const { record_id, errors } = row;
            if (acc.indexOf(record_id) === -1 && !errors) {
                acc.push(record_id);
            }
            return acc;
        }, []);
        return { filteredData, properSelected };
    };

    setCounts = () => {
        const { filteredData } = this.state;
        const counts = new Map();
        filteredData.forEach(row => {
            const { count = 0, errored = false } =
                counts.get(row.licensor_organisation_id) || {};
            const isErrors = !!row.errors;
            if (!this.state.errored && isErrors)
                this.setState({ errored: true });
            const isRegistered = !!row.registered_with;
            if (!this.state.registered && isRegistered)
                this.setState({ registered: true });
            counts.set(row.licensor_organisation_id, {
                count: count + 1,
                errored: errored || isErrors,
                licensor: row.licensor
            });
        });
        return counts;
    };

    buildRegisteringItems = () => {
        const counts = this.setCounts();

        const items = [];
        counts.forEach((item, key) => {
            const { count, errored, licensor } = item;
            return items.push(
                <div
                    className="o-box--registration"
                    key={`${key}-${count}-product-registration`}
                >
                    <div className="u-color-blue">
                        <span className="u-fw--bold">{count}</span>
                        &nbsp;
                        {pluralise('product', count)}
                        {errored && <span className="o-box--errored" />}
                    </div>
                    <div className="u-color-blue">
                        <span className="u-color-blue">{licensor}</span>
                    </div>
                </div>
            );
        });
        return items;
    };

    render() {
        const { submitting, errored, selectedLicensor } = this.state;
        const { selectedRows, setHideModal } = this.props;
        
        const submittingBody = <div>Registering records...</div>;

        const body = (
            <div className="o-box--block">
                <span className="u-color-black">
                    You are registering {selectedRows.length}{' '}
                    {pluralise('Product', selectedRows.length)} with
                    <span className='u-color-blue'>{' ' + selectedLicensor.licensor_organisation_name}</span>.
                </span>

                {errored && (
                    <small className="u-color-error">
                        Some records have errors and will fail the Registration
                        process. Are you sure you want to continue?
                    </small>
                )}

                <div
                    className={`u-flex-gap u-flex-align--right ${
                        errored ? 'u-margin-top' : 'u-margin-top-2x'
                    }`}
                >
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => setHideModal()}>
                            Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => this.submitProductsForRegistration()}
                    >
                        Register
                    </Button>
                </div>
            </div>
        );

        return (
            <Modal
                isSmall
                centered
                title="Confirm Registration"
                body={submitting ? submittingBody : body}
            />
        );
    }
}

function mapStateToProps(state) {
    const { xelacore: { userSettings = {} } = {} } = state;
    return { userSettings };
}

const mapDispatchToProps = dispatch => ({
    setShowModal(content) {
        dispatch(showModal(content));
    },
    setHideModal() {
        dispatch(hideModal());
    },
    setRegisterProducts(obj) {
        return dispatch(registerProducts(obj));
    },
    setDisplayNotification(title, type) {
        dispatch(
            displayNotification({
                message: title,
                type,
                timeOut: 5000
            })
        );
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);
