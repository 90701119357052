import React, { Fragment } from 'react';
import { Auth } from 'src/js/components/static';
import QuickSightDashboard from './QuickSight';

const Dashboard = () => {
    return (
        <Fragment>
            <Auth restrictTo="agent">
                <QuickSightDashboard></QuickSightDashboard>
            </Auth>
            
            <Auth restrictTo="licensee">
                <QuickSightDashboard></QuickSightDashboard>
            </Auth>

            <Auth restrictTo="licensor">
                <QuickSightDashboard></QuickSightDashboard>
            </Auth>
        </Fragment>
    );
};

export default Dashboard;
