import React, { useEffect, useState } from 'react';
import {
    fetchQuicksightDashboard
} from 'src/js/apicalls/other/quicksightDashboard';
import Button from 'modules/UiKit/components/Button/Button';
import emptyReport from 'statics/imgs/report-empty.png';

function QuickSightDashboard() {
    const [dashboardUrl, setDashboardUrl] = useState(!!localStorage.getItem('dashboard_main_url') && localStorage.getItem('dashboard_main_url') || '');
    const [errorDashboard, setDashboardError] = useState(false);
    const [busyReport, setBusyReport] = useState(false);

    useEffect(() => {
        fetchDashboard()
    }, []);

    const fetchDashboard = () => {
        setBusyReport(true);

        // if (dashboardUrl.length === 0) {
        //     fetchQuicksightDashboard().then(response => {
        //         localStorage.setItem('dashboard_main_url', response.view_url);
        //         setDashboardUrl(response.view_url_init);
        //         setDashboardError(false);
        //         setBusyReport(false);
        //     }, () => {
        //         setDashboardError(true);
        //         setBusyReport(false);
        //     })
        // } else {
        //     setDashboardUrl(dashboardUrl);
        //     setDashboardError(false);
        //     setBusyReport(false);
        // }


        fetchQuicksightDashboard().then(response => {
            // localStorage.setItem('dashboard_main_url', response.view_url);
            setDashboardUrl(response.view_url_init);
            setDashboardError(false);
            setBusyReport(false);
        }, () => {
            setDashboardError(true);
            setBusyReport(false);
        })
    }

    return (
        <div>
            {!errorDashboard && (<iframe src={dashboardUrl} style={{ height: 'calc(100vh - 120px)', overflowY: 'auto', width: '100%', boxShadow:'none', border:'0' }}></iframe>)}
            
            {errorDashboard && (
                <div className='empty-report'>
                    <img src={emptyReport} />
                    <p>Oops, something went wrong...</p>
                    <Button
                        type="primary"
                        size="small"
                        disabled={busyReport}
                        onClick={() => fetchDashboard()}
                    >
                        Refresh
                    </Button>
                </div>
            )}
        </div>
    );
}

export default QuickSightDashboard;

