import React, { Component, Fragment } from 'react';
import { Icon, Pagination } from 'src/js/components/static';
import { opaQuery } from 'src/js/constants/opaConstants';
import { queryOpaData } from 'src/js/actions/opa';
import OpaSearchPanelItems from './OpaSearchPanelItems';
import SelectedProduct from './SelectedProduct';
import OpaSearchEmptySearch from './OpaSearchEmptySearch';
import OpaSearchPanelTop from './OpaSearchPanelTop';
import {
    buildMatchingDataQuery,
    buildSearchQuery,
    jumpToPage
} from 'src/js/helpers/dataHelpers';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

class OpaSearchPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            searchItems: [],
            queryObject: opaQuery,
            activeProduct: {},
            zeroResults: true,
            noCodes: false,
            submitted: false,
            submitting: false,
            matchingData: false,
            totalItems: 0,
            from: 0,
            size: 15,
            pageNumber: 1,
            error: ''
        };
    }

    handleChange(e) {
        this.setState({
            searchTerm: e.target.value,
            submitted: false,
            from: 0,
            pageNumber: 1
        });
    }

    makeQuery = (search, matching = false) => {
        const { originalData } = this.props;
        const { from, size } = this.state;
        this.setState({ submitted: true, submitting: true });
        const query = matching
            ? buildMatchingDataQuery(originalData, size)
            : buildSearchQuery(search.trim(), from, size, originalData);
        queryOpaData(query)
            .then((data) => {
                const {} = data;
                const totalItems = get(
                    data,
                    '_metadata.pagination.total_records',
                    0
                );
                const itemsFound = get(data, 'records.length', 0);
                const searchItems = itemsFound ? data.records : [];
                this.setState({
                    searchItems: searchItems,
                    zeroResults: searchItems.length <= 0,
                    totalItems,
                    submitting: false,
                    matchingData: matching ? !!itemsFound : false
                });
            })
            .catch((err) => {
                this.setState({ submitting: false, error: err });
            });
    };

    handleSubmit(e) {
        e.preventDefault();
        // const { searchTerm } = this.state;
        // this.makeQuery(searchTerm, false);
    }

    componentDidMount() {
        // this.makeQuery(false, true);
    }

    toggleProductInfo = (productInfo, searchedItem = true, searchItems) => {
        this.props.showProductOverlay(productInfo, searchedItem, searchItems);
    };

    closeProductPanel(closeAll) {
        const { toggleOpaPanel } = this.props;
        this.setState({ activeProduct: {} });
        if (closeAll) return toggleOpaPanel();
        return window.scrollTo(0, 0);
    }

    updatePageHandler = (page) => {
        const { searchTerm, size } = this.state;
        const from = page === 1 ? 0 : (page - 1) * size;
        this.setState(
            {
                pageNumber: page,
                from
            },
            () => {
                return this.makeQuery(searchTerm, false);
            }
        );
    };

    goBack = () => {
        const { toggleOpaPanel } = this.props;
        const { pageNumber } = this.state;
        if (pageNumber === 1) return toggleOpaPanel();
        this.updatePageHandler(pageNumber - 1);
    };

    render() {
        const {
            toggleOpaPanel,
            getProductData,
            record_id,
            updateProductConcept,
            originalData,
            xelacore
        } = this.props;
        const {
            searchItems,
            zeroResults,
            activeProduct,
            noCodes,
            searchTerm,
            totalItems,
            submitted,
            submitting,
            matchingData,
            pageNumber,
            size
        } = this.state;
        const zeroResultsSubmitted =
            zeroResults && !!searchTerm && submitted && !submitting;

        const { concept_info } = originalData;

        const approvalCodes =
            concept_info && concept_info.length > 0
                ? concept_info.map(({ xelacore_concept_id }) => xelacore_concept_id)
                : [];

        return (
            <div className="c-product__opa-panel">
                {!isEmpty(activeProduct) && (
                    <SelectedProduct
                        data={activeProduct}
                        close={(closeAll) => this.closeProductPanel(closeAll)}
                        getProductData={getProductData}
                        record_id={record_id}
                        updateProductConcept={updateProductConcept}
                        licensor={false}
                        xelacore={xelacore}
                    />
                )}
                {isEmpty(activeProduct) && (
                    <Fragment>
                        <OpaSearchPanelTop
                            toggleOpaPanel={toggleOpaPanel}
                            goBack={() => this.goBack()}
                        />

                        {!noCodes && (
                            <div className="c-product__opa-search-panel">
                                <div className="c-map-menu__filter-input">
                                    <form
                                        onSubmit={(e) => this.handleSubmit(e)}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Search here for product concepts"
                                            onChange={(e) =>
                                                this.handleChange(e)
                                            }
                                            value={searchTerm}
                                        />
                                        <button
                                            className="u-flex-align c-map-menu__search"
                                            type="submit"
                                        >
                                            <Icon icon="search" size="14" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        )}

                        {matchingData && (
                            <div className="u-text-center u-bold u-padding-top">
                                Based on your product data, these concepts may
                                match.
                            </div>
                        )}

                        {!zeroResults && !matchingData && submitted && (
                            <Fragment>
                                {searchTerm && (
                                    <div className="u-text-center u-bold u-padding-top">
                                        We found{' '}
                                        <span className="u-color-pink">
                                            {totalItems}
                                        </span>{' '}
                                        concepts for{' '}
                                        <span className="u-color-pink u-capitalize">
                                            {searchTerm}
                                        </span>
                                    </div>
                                )}
                                {!searchTerm && (
                                    <div className="u-text-center u-bold u-padding-top">
                                        We found{' '}
                                        <span className="u-color-pink">
                                            {totalItems}
                                        </span>{' '}
                                        concepts
                                    </div>
                                )}
                            </Fragment>
                        )}

                        {zeroResultsSubmitted && (
                            <OpaSearchEmptySearch searchTerm={searchTerm} />
                        )}

                        {((!zeroResults && submitted) || matchingData) && (
                            <Fragment>
                                <OpaSearchPanelItems
                                    items={searchItems}
                                    toggleProductInfo={this.toggleProductInfo}
                                    approvalCodes={approvalCodes}
                                />

                                {!matchingData && (
                                    <Pagination
                                        currentPage={parseInt(pageNumber)}
                                        totalItems={parseInt(totalItems)}
                                        itemsPerPage={parseInt(size)}
                                        updatePageHandler={(page) =>
                                            this.updatePageHandler(page)
                                        }
                                        jumpToPage={(e) =>
                                            jumpToPage(
                                                e,
                                                this.updatePageHandler
                                            )
                                        }
                                    />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default OpaSearchPanel;
