import React from 'react';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';

export default ({ value, hover = true, name = '' }) => {
    const v = value ? value : imgPlaceholder;
    return (
        <div className="c-rec-table__image-cell">
            <div className="c-rec-table__image-icon">
                <MediaView
                    src={v}
                    alt={name}
                    fallbackSrc={fallbackImageSmall}
                    useImgTag
                />
            </div>
            {hover && <img src={v} className="c-rec-table__hover-image" />}
        </div>
    );
};
