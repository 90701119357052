import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Image = ({
    src,
    onClick,
    onMouseMove,
    onMouseOut,
    className,
    useImgTag,
    children,
    fallbackSrc,
    alt
}) => {
    const [imgSrc, setImgSrc] = useState(src);

    const handleFallbackImage = () => {
        if (fallbackSrc) {
            setImgSrc(fallbackSrc);
        }
    };

    useEffect(() => {
        setImgSrc(src);
    }, [src]);

    return useImgTag ? (
        <LazyLoadImage
            src={imgSrc}
            className={className}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseOut={onMouseOut}
            onError={handleFallbackImage}
            alt={alt}
        />
    ) : (
        <div
            className={className}
            style={{ backgroundImage: `url(${imgSrc})` }}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseOut={onMouseOut}
        >
            {children}
        </div>
    );
};
