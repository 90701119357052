import { displayNotification } from 'src/js/actions/xelacore';

export default function successHandler(dispatch, message) {
    return dispatch(
        displayNotification({
            message,
            type: 'success',
            timeOut: 5000
        })
    );
}
