import React from 'react';
import classnames from 'classnames';

export default function Multiselect({ ...props }) {
    const {
        values = [],
        data = [],
        onChangeHandler,
        keyValue,
        ux,
        textValue
    } = props;
    const uxCx = value =>
        classnames(
            'u-pull-right ',
            { 'c-muliselect__block': ux === 'blocks' },
            {
                'c-muliselect__block-selected':
                    ux === 'blocks' && data.includes(value)
            },
            { 'u-padding-half-bottom': ux !== 'blocks' }
        );

    const onChange = value => {
        if (!values.includes(value)) return;
        const index = data.indexOf(value);
        if (index > -1) {
            data.splice(index, 1);
        } else {
            data.push(value);
        }
        return onChangeHandler(keyValue, data, value);
    };

    return (
        <div>
            {values.map(el => {
                return (
                    <div key={`${keyValue}-${el}`} className={uxCx(el)}>
                        <input
                            className="c-input-checkbox__input"
                            type="checkbox"
                            onChange={() => onChange(el)}
                            id={`${keyValue}-${el}`}
                            name={`${keyValue}-${el}`}
                            checked={data.indexOf(el) > -1}
                        />
                        <label
                            className="c-input-checkbox__label"
                            htmlFor={`${keyValue}-${el}`}
                        >
                            {`${el} ${textValue ? textValue : ''}`}
                        </label>
                    </div>
                );
            })}
        </div>
    );
}
