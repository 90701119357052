import React, { Component } from 'react';
import Button from 'modules/UiKit/components/Button/Button';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

class NoDataCatalogue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadOpa: false
        };
    }

    render() {
        const { toggleUploader, companyData } = this.props;
        const licensor =
            companyData.information && companyData.information.licensor
                ? 'licensor'
                : 'licensee';
        return (
            <div className='no-data-catalogue'>
                {licensor === 'licensor' && (
                    <p className='c-my-records__no-data-text'>
                        You have no products in your Catalogue.
                    </p>
                )}

                {licensor !== 'licensor' && (
                    <p className='c-my-records__no-data-text'>
                        You haven't added any products to your Catalogue yet. <br/> To do this, select the products you would like to register with your Licensor(s).
                    </p>
                )}

                {licensor === 'licensee' && (
                    <Button
                        type="secondary-2"
                        size="medium"
                        onClick={() => browserHistory.push('/my-records')}
                    >
                        My Records
                    </Button>
                )}

                {this.state.uploadOpa && (
                    <Button
                        type="secondary-2"
                        size="medium"
                        onClick={toggleUploader}>
                        Upload Product Approval Records
                    </Button>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { xelacore: { auth: { companyData = {} } = {} } = {} } = state;

    return { companyData };
}

export default connect(mapStateToProps)(NoDataCatalogue);
