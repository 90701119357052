import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';

import {
    logout
} from 'src/js/lib/auth';

import xelacoreLogo from 'statics/imgs/xelacore-logo.svg';
import searchIcon from 'statics/imgs/app/search.svg';

import {
    setUserOrg
} from 'src/js/actions/xelacore';

function OrganisationSelect({
    xelacore,
    dispatch
}) {
    const [searchText, setSearchText] = useState('');
    const { refreshToken = '', organisations = [] } = xelacore.auth;
    const licensors = organisations.filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()));

    useEffect(() => {
        localStorage.removeItem('selectedLicensor');
        localStorage.removeItem('licensorsCount');
    })

    const selectOrg = (org, id, idToken) => {
        sessionStorage.setItem('presavedCatalogueRows', JSON.stringify([]));
        sessionStorage.setItem('catalogueExportDataPPT', JSON.stringify([]));
        sessionStorage.setItem('catalogueExportTitlesPPT', JSON.stringify([]));
        sessionStorage.setItem('catalogueExportRowsPPT', JSON.stringify([]));
        dispatch(setUserOrg(org, id, idToken));
    };

    return (
        <div className="root">
            <div className="logoHolder">
                <img
                    className="logoImg"
                    src={xelacoreLogo}
                    alt="logo"/>
            </div>

            <h1 className="heading">
                Account Selection
            </h1>

            <h3 className="subHeading">
                Please select which account you want to access
            </h3>

            <div className="searchInputContainer">
                <input
                    className="searchInput"
                    autoFocus
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                ></input>
                <img
                    className="searchIcon"
                    src={searchIcon}
                    alt="searchIcon"/>
            </div>

            <div className="orgList">
                {sortBy(licensors, 'name').map((el) => (
                    <div key={el.organisation_id}
                         onClick={() => selectOrg(el, el.organisation_id, {
                             orgId: el.organisation_id,
                             refreshToken
                         })}
                         className="orgCard">
                        <p className="orgName">{el.name}</p>
                        <p className="orgType">{el.type}</p>
                    </div>
                ))}
            </div>

            <button onClick={() => logout(true)}
                    className="logoutButton">
                Log Out
            </button>
        </div>
    );
}

const mapStateToProps = ({
    xelacore
}) => {
    return {
        xelacore
    };
};

export default connect(mapStateToProps)(OrganisationSelect);

