// Query string must be in this format or it won't work:  "query": "*{SEARCHTERMGOESHERE}*"
export const opaQuery = {
    search: {
        body: {
            query: {
                query_string: {
                    query: '',
                    fields: []
                }
            }
        }
    }
};

export const opaMatchingFields = ['product_category', 'licensor_brand'];

export const opaListOmitFields = [
    'product_name',
    'es_meta',
    'images',
    'licensor_organisation_id',
    'licensee_organisation_id',
    'licensee',
    'licensor'
];

export const conceptShowFields = [
    {
        field: 'approval_code',
        label: 'Concept Ref Code',
        forLicensor: true,
        forLicensee: true
    },
    {
        field: 'mpn',
        label: 'MPN',
        forLicensor: true,
        forLicensee: true
    },
    {
        field: 'product_description',
        label: 'Concept Description',
        forLicensor: true,
        forLicensee: true
    },
    {
        field: 'composition',
        label: 'Composition',
        forLicensor: true,
        forLicensee: true
    },
    { field: 'colour', label: 'Colour', forLicensor: true, forLicensee: true },
    { field: 'season', label: 'Season', forLicensor: true, forLicensee: true },
    {
        subfield: 'licensor_category_path',
        field: 'product_category',
        label: 'Concept Category',
        forLicensor: true,
        forLicensee: true
    },
    // {
    //     subfield: 'licensor_category_path',
    //     field: 'product_subcategory',
    //     label: 'Concept Subcategory',
    //     forLicensor: true,
    //     forLicensee: true
    // },

    {
        field: 'licensee',
        label: 'Licensee Name',
        forLicensor: true,
        forLicensee: false
    },
    {
        field: 'licensor',
        label: 'Licensor Name',
        forLicensor: true,
        forLicensee: true
    },
    {
        field: 'licensor_brand',
        label: 'Licensor Brand',
        forLicensor: true,
        forLicensee: true
    }
];
