import { getUploadUrl } from 'src/js/apicalls/files/fileUpload';
import Uploader from 'src/js/lib/uploader';
import { setRecordNotificationHelper } from 'src/js/actions/ingestions';

import {
    NEW_UPLOAD,
    COMPLETED_UPLOAD
} from 'src/js/constants/actions/dataManager';

export function fetchUploadUrl(body) {
    return () => {
        if (!body) return Promise.reject('No body found, illegal action');
        return getUploadUrl(body);
    };
}

export function upload(category, files, onDone, onProgress, onCancel) {
    return () => {
        const uploader = Uploader(category);
        return uploader({ files, onDone, onProgress, onCancel });
    };
}

/**
 * This flow, updates the user's config & fetches the updated data
 *
 * @param  {Array} fileUploadList of FileUpload Class
 * @param  {boolean} isOpaUploader
 * @return {Promise}
 */
export function newUpload(fileUploadList, isOpaUploader) {
    return (dispatch, getState) => {
        const { xelacore } = getState();
        const { auth, pageNotification } = xelacore;

        const beforeUnload = e => {
            const confirmationMessage =
                'Refreshing will cause the current Upload to Fail. Do you want to proceed?';

            (e || window.event).returnValue = confirmationMessage;

            return confirmationMessage;
        };

        return Promise.resolve(auth && auth.token).then(token => {
            if (!token) return;
            dispatch({ type: NEW_UPLOAD, fileUploadList });

            window.addEventListener('beforeunload', beforeUnload, false);

            return Promise.all(
                fileUploadList.map(fileUpload => fileUpload.run())
            ).then(fileUploadList => {
                dispatch({ type: COMPLETED_UPLOAD, fileUploadList });
                // Set notifications in the global Xelacore object
                dispatch(
                    setRecordNotificationHelper(
                        pageNotification,
                        fileUploadList,
                        isOpaUploader
                    )
                );
                window.removeEventListener('beforeunload', beforeUnload, false);
                return fileUploadList;
            });
        });
    };
}
