import React, {Component} from 'react';
import {pluralise} from 'src/js/helpers/strings';
import {Modal, Button} from 'src/js/components/static';
import {hideModal} from 'src/js/actions/xelacore';

export default class BulkDeletionModalOutput extends Component {
    constructor(props) {
        super(props);
    }

    modalBody = () => {
        const {all, dispatch, error} = this.props;
        const totalCount = all.length;
        return (
            <div className="u-full-width">
                <div className="u-color-black u-padding-bottom">
                    <strong>
                        You have submitted {totalCount} Product{' '}
                        {pluralise('Record', totalCount)} for deletion.
                    </strong>
                </div>
                {error && <div className="u-color-black u-padding-bottom">
                    <div className="u-text-left u-padding-half-bottom">Something went wrong. We are unable to perform delete operation.</div>
                    <div className="o-box--registrered-error">
                        {error}
                    </div>
                </div>}
                {!error && <div className="o-box--registrered-success">
                    <span className="u-fw--bold">{totalCount}</span>
                    &nbsp;
                    {pluralise('Record', totalCount)}{' '}
                    {pluralise('has', totalCount)} been successfully deleted
                </div>}

                <span className="u-flex-align u-padding-top u-flex-align--center">
                   <Button purpleGrad onClick={() => dispatch(hideModal())}>Close</Button>
                </span>
            </div>
        )
    };

    render() {
        return <Modal centered title="Bulk Deletion Resolution Summary" body={this.modalBody()}/>;
    }

}
