import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

@connect(null, mapDispatchToProps)

export default class Autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          userInput: ''
        };
    }

    componentDidMount() {
        const { reliesOn } = this.props;
        
        if (!reliesOn) return this.fetchData();
    }

    // Automatically prepend 'https' to URl form types
    onChangeValue = (e) => {
        const { suggestions } = this.state;
        const { onChange, name } = this.props;
        const userInput = e.currentTarget.value;

        const suggestionsMap = map(suggestions, 'product_family_name');
      
        const filteredSuggestions = !!suggestionsMap && suggestionsMap.filter(
          suggestion =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
      
        this.setState({
          activeSuggestion: 0,
          filteredSuggestions,
          showSuggestions: true,
          userInput: e.currentTarget.value
        });

        return onChange(userInput, name);
    };
    
    onClick = e => {
      const { onChange, name } = this.props;

        this.setState({
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          userInput: e.currentTarget.innerText
        });

        return onChange(e.currentTarget.innerText, name);
    }

    fetchData() {
        const { dispatch, fetchValues } = this.props;

        dispatch(fetchValues()).then(({ data }) => {
            this.setState({
                suggestions: data.families
            })
        });
    }
        
    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;
      
        if (e.keyCode === 13) {
          this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
            userInput: filteredSuggestions[activeSuggestion]
          });
        } else if (e.keyCode === 38) {
          if (activeSuggestion === 0) {
            return;
          }
          this.setState({ activeSuggestion: activeSuggestion - 1 });
        }

        else if (e.keyCode === 40) {
          if (activeSuggestion - 1 === filteredSuggestions.length) {
            return;
          }
          this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const { value } = this.props;
        const {
          onClick,
          onKeyDown,
          state: {
            activeSuggestion,
            filteredSuggestions,
            showSuggestions,
            userInput
          }
        } = this;
      
        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
              suggestionsListComponent = (
                <ul className="suggestions">
                  {filteredSuggestions.map((suggestion, index) => {
                    let className;
          
                    if (index === activeSuggestion) {
                      className = 'suggestion-active';
                    }

                    return (
                      <li className={className} key={suggestion} onClick={onClick}>
                        {suggestion}
                      </li>
                    );
                  })}
                </ul>
              );
            } else {
              suggestionsListComponent = (
                <div className="no-suggestions">
                  <em>No suggestions available.</em>
                </div>
              );
            }
          }

        return (
            <React.Fragment>
                <div className='autocomplete-input'>
                    <input
                        type="text"
                        onChange={this.onChangeValue}
                        onKeyDown={onKeyDown}
                        value={value}
                    />
                    {suggestionsListComponent}
                </div>
            </React.Fragment>
        );
    }
}
