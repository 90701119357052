import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Button from 'components/UiKit/Button';
import {
    fetchReports,
    getUnreportedContracts,
    createReport,
    deleteReport,
    createVersion,
    updateReport
} from 'src/js/apicalls/other/royaltyReporting';
import moment from 'moment';
import { Icon } from 'src/js/components/static';
import each from 'lodash/each';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import size from 'lodash/size';
import toString from 'lodash/toString';
import toArray from 'lodash/toArray';
import findIndex from 'lodash/findIndex';
import { filter } from 'lodash';
import orderBy from 'lodash/orderBy'

import emptyReport from 'statics/imgs/report-empty.png';
import { showModal } from 'src/js/actions/xelacore';
import ReportActionConfirmation from './Components/Modals/ReportActionConfirmation';
import { displayNotification } from 'src/js/actions/xelacore';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';

function RoyaltyReporting({xelacore, dispatch}) {
    const [reportsData, setReportsData] = useState([]);
    const [contractsData, setContractsData] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedContract, setSelectedContract] = useState('');
    const [quarters, setQuarters] = useState([]);
    const [years, setYears] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editReportId, setEditReportId] = useState(null);
    const [busy, setBusy] = useState(false);
 
    const {
        auth: {
            companyData: { organisation_id }
        }
    } = xelacore;

    useEffect(() => {
        getReports();
        getContracts();
    }, []);

    const createNewReport = () => {
        let params = {
            licensorOrganisationId: selectedContract,
            year: selectedYear,
            quarter: selectedQuarter
        }
        
        return createReport(params).then(resp => {
            setSelectedContract('');
            setSelectedYear('');
            setSelectedQuarter('');
            getReports();
            getContracts();

            browserHistory.push(`/royalty-reporting/${resp.data.reportId}`);

            return dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'Report successfully created',
                    type: 'success'
                })
            );
        });
    }

    const getReports = () => {
        let groupReports = {};

        setBusy(true);
        
        return fetchReports().then(resp => {
            let years = uniq(map(resp.data, 'year'));

            each(years, year => {
                groupReports[year] = [];
                groupReports[year].year = year;
                groupReports[year].reports = [];
                

                each(resp.data, report => {
                    if(report.year === year) {
                        groupReports[year].reports.push(report);
                    }
                })
            })

            setReportsData(toArray(groupReports));
            setBusy(false);
        });
    }

    const deleteReportFunction = (reportId) => {
        return deleteReport(reportId).then(() => {
            getReports();
            getContracts();

            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'Report successfully deleted',
                    type: 'success'
                })
            );
        });
    }

    const getContracts = () => {
        return getUnreportedContracts(organisation_id).then(resp => {
            setContractsData(resp.data);
            setYears(uniq(map(resp.data, 'year')));
        });
    }

    const filterYears = (event) => {
        setSelectedYear(event.target.value);

        return setQuarters(uniq(map(filter(contractsData, contract => {
            return toString(contract.year) === event.target.value;
        }), 'quarter')));
    }

    const filterQuarters = (event) => {
        setSelectedQuarter(event.target.value);

        setContracts(filter(contractsData, contract => {
            return (toString(contract.quarter) === event.target.value) && (toString(contract.year) === selectedYear);
        }));
    }

    const deleteReportEntries = (reportId) => {
        const modalContent = (
            <ReportActionConfirmation
                title={'Delete Confirmation'}
                confirm={() => deleteReportFunction(reportId)}
                message={'Are you sure you want to delete this report?'}
            />
        );
        
        return dispatch(showModal(modalContent));
    }

    const amendReport = (report) => {
        let params = {
            versionNumber: orderBy(report.versions, 'versionNumber', 'desc')[0].versionNumber + 1
        };

        return createVersion(report.reportId, params).then(() => {
            getReports();

            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'New version successfully created',
                    type: 'success'
                })
            );
        })
    }

    const amendConfirmation = (report) => {
        const modalContent = (
            <ReportActionConfirmation
                title={'Please Confirm'}
                confirm={() => amendReport(report)}
                message={'By proceeding, you will generate a new Royalty Statement for re-submission. Do you wish to continue?'}
                confirmButton={'Continue'}
            />
        );
        
        return dispatch(showModal(modalContent));
    }

    const editTitleFunction = (title, report, index) => {
        report.title = title;

        let editedYearIndex = findIndex(reportsData, rep => {
            return rep.year === report.year;
        })
        
        reportsData[editedYearIndex].reports[index].title = title;

        return setReportsData([...reportsData]);
    }

    const updateReportTitle = (report) => {
        let params = {
            title: report.title
        }
        
        return updateReport(report.reportId, params).then(() => {
            setEditIndex(null);
            setEditReportId(null);

            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'Report title successfully updated',
                    type: 'success'
                })
            );
        })
    }

    return (
        <div className="royalty-reporting">
            <div className="filters-list-container">
                <h2>Royalty Reporting</h2>

                <div className='royalty-reporting-filters'>
                    <span className='group-subtitle'>
                        Reporting period
                    </span>

                    <div className={'c-form-element'}>
                        <div className='c-form-element__wrapper'>
                            <div className='c-select__styled-select'>
                                <select
                                    disabled={!years.length}
                                    name={selectedYear}
                                    onChange={(event) => filterYears(event)}
                                    value={selectedYear}
                                >
                                   <option value={''} disabled hidden>
                                        Please select year
                                    </option>

                                    {years.map((year) => (
                                        <option
                                            key={`${Math.random()}-${year}`}
                                            value={year}
                                        >
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={'c-form-element'}>
                        <div className='c-form-element__wrapper'>
                            <div className='c-select__styled-select'>
                                <select
                                    disabled={!selectedYear}
                                    name={selectedQuarter}
                                    onChange={(event) => filterQuarters(event)}
                                    value={selectedQuarter}
                                >
                                     <option value={''} disabled hidden>
                                        Please select quarter
                                    </option>

                                    {quarters.map((quarter) => (
                                        <option
                                            key={`${Math.random()}-${quarter}`}
                                            value={quarter}
                                        >
                                            Q{quarter}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
 
                    <div className={'c-form-element'}>
                        <div className='c-form-element__wrapper'>
                            <div className='c-select__styled-select'>
                                <select
                                    disabled={!selectedQuarter}
                                    name={'name'}
                                    onChange={(event) => setSelectedContract(event.target.value)}
                                    value={selectedContract}
                                >
                                    <option value={''} disabled hidden>
                                        Please select contract
                                    </option>

                                    {contracts.map((contract) => (
                                        <option
                                            key={`${Math.random()}-${contract}`}
                                            value={contract.licensorOrganisationId}
                                        >
                                            {contract.contractName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <Button disabled={!selectedYear || !selectedQuarter || !selectedContract} onClick={() => createNewReport()}>
                        <Icon
                            className=""
                            fill="white" icon="CONCEPTS"
                            size="14"
                        />

                        Create Report
                    </Button>
                </div>
            </div>

            <div className='container'>
                <div className='royalty-reporting-reports'>
                    {!busy && !!reportsData.length && orderBy(reportsData, 'year', 'desc').map((report) => {
                        return (
                            <div className='royalty-reporting-reports-group'>
                                <span className='group-year'>{report.year}</span>

                                {!!report.reports && !!report.reports.length && report.reports.map((info, id) => {
                                    return (
                                        <div className='royalty-reporting-reports-group-item'>
                                            <div className='group-header'>
                                                <div className='group-header-titles'>
                                                    <span className='group-subtitle'>Reporting period</span>
                                                    <span className='group-title'>Q{info.quarter} {report.year}</span>
                                                </div>

                                                <Icon
                                                    className="delete-icon"
                                                    fill="red" icon="BIN"
                                                    size="14"
                                                    onClick={() => deleteReportEntries(info.reportId)}
                                                />
                                            </div>
                
                                            <div className='group-body'>
                                                <span className='report-title'>
                                                    { editReportId === info.reportId && editIndex === id && (
                                                        <input autoFocus value={size(info.title) > 0 ? info.title : ''} onChange={(e) => editTitleFunction(e.target.value, info, id)}/>
                                                    )
                                                    }

                                                    { editReportId === null && editIndex === null && (
                                                        <a href={`royalty-reporting/${info.reportId}`} title={info.title} className='title'>{size(info.title) > 0 ? info.title : 'Untitled Report'}</a>
                                                    )}

                                                    { editReportId !== info.reportId && editIndex !== null && (
                                                        <a href={`royalty-reporting/${info.reportId}`} title={info.title} className='title'>{size(info.title) > 0 ? info.title : 'Untitled Report'}</a>
                                                    )}
                                                    
                                                    { ((editReportId === null && editIndex === null) || (editReportId !== info.reportId)) && (
                                                        <a className="ml-3"
                                                            onClick={() => {setEditReportId(info.reportId); setEditIndex(id)}}
                                                            href="#">
                                                                <Icon className="edit-icon"
                                                                        fill="black"
                                                                        icon="EDIT_USER" size="14"/>
                                                        </a>
                                                    )}

                                                    { editReportId === info.reportId && editIndex === id && (
                                                        <div className='report-actions'>
                                                            <a className="ml-3"
                                                                onClick={() => updateReportTitle(info)}
                                                                href="#">
                                                                    <Icon className="edit-icon"
                                                                            fill="green"
                                                                            icon="CHECK" size="14"/>
                                                            </a>

                                                            <a className="ml-3"
                                                            onClick={() => {setEditReportId(null); setEditIndex(null)}}
                                                            href="#">
                                                                <Icon className="edit-icon"
                                                                        fill="red"
                                                                        icon="CLOSE" size="14"/>
                                                            </a>
                                                        </div>
                                                    )}
                                                </span>

                                                <span className='report-date'>
                                                    Generated {moment(info.createdAt).format('YYYY.MM.DD')}
                                                </span>

                                                <span className={`report-status ${orderBy(info.versions, 'versionNumber', 'desc')[0].status === 'ingested' || 'empty' ? 'error' : 'success'}`}>
                                                    {info.status === 'empty' ? 'Not submited' : orderBy(info.versions, 'versionNumber', 'desc')[0].status}
                                                </span>

                                                <div className='footer-actions'>
                                                    <Button to={`royalty-reporting/${info.reportId}`}>
                                                        View
                                                    </Button>

                                                    { orderBy(info.versions, 'versionNumber', 'desc')[0].status === 'ingested' && (
                                                        <Button onClick={()=> amendConfirmation(info)}>
                                                            Amend
                                                        </Button>
                                                    )}

                                                    { orderBy(info.versions, 'versionNumber', 'desc')[0].status === 'locked' && (
                                                        <Icon className="download-icon" fill="black" icon="DOWNLOAD" size="14"/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>)
                    })}

                    {!reportsData.length && !busy && (
                        <div className='report-empty-placeholder'>
                            <img src={emptyReport}/>
                            <span>There are no royalty reports created for your organisation.</span>
                        </div>
                    )}

                    {!!busy && (
                        <div className="c-rec-table__page-loader table-loader">
                            <img  className="loader-img" src={imgPinkSpinner} alt="Updating cell..."/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(RoyaltyReporting);

