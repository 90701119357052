import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import xelacoreReducer from 'src/js/reducers/Reducer';

export function buildReducer() {
    const appReducer = combineReducers({
        routing: routerReducer,
        xelacore: xelacoreReducer
    });

    const rootReducer = (state, action) =>
        appReducer(
            action.type === 'XELACORE_CORE::LOGOUT'
                ? {
                      ...appReducer({}, {}),
                      router: (state && state.router) || {}
                  }
                : state,
            action
        );

    return Promise.resolve(rootReducer);
}
