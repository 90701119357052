import React, { Fragment } from 'react';
import {Auth} from 'src/js/components/static';


export default function SpecificDataCatalogue({ ...props }) {
    const { data } = props;
    const {
        licensor,
        company
    } = data;

    return (
        <Fragment>
            <Auth restrictTo="licensee">
                <td className="c-rec-table__cell c-rec-table__reg-status">
                    {licensor}
                </td>
            </Auth>
            <Auth restrictTo="licensor">
                <td className="c-rec-table__cell c-rec-table__reg-status">
                    {company}
                </td>
            </Auth>
        </Fragment>
    );
}
