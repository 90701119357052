import {
    downloadFileUpload,
    downloadFileUploadWithOptions
} from 'src/js/actions/records';
import { displayNotification, hideNotification } from 'src/js/actions/xelacore';

export function downloadFile(name, ingestId, option) {
    return dispatch => {
        dispatch(
            displayNotification({
                timeOut: 5000,
                message: 'Records are downloading',
                type: 'info'
            })
        );
        if (option) {
            return dispatch(
                downloadFileUploadWithOptions(ingestId, option, name)
            ).then(() => {
                dispatch(hideNotification());
                dispatch(
                    displayNotification({
                        timeOut: 5000,
                        message: 'Records successfully downloaded',
                        type: 'success'
                    })
                );
            });
        }
        return dispatch(downloadFileUpload(name)).then(() => {
            dispatch(hideNotification());
            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'Records successfully downloaded',
                    type: 'success'
                })
            );
        });
    };
}
