import { SET_SCHEMAS } from 'src/js/constants/actions/xelacore';

export function schemas(state = {}, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_SCHEMAS:
            return { ...payload };
        default:
            return state;
    }
}
