import React from 'react';
import {Icon} from 'src/js/components/static';

export default function ConflictsHeader({ length, quarantineMode }) {
    return (
        <div className="c-conflicts-popover-header">
            {quarantineMode && (
                <div className="c-conflicts-popover-header-text">
                    <p>
                        <Icon className="u-margin-small-right" icon="DUPLICATES" fill="pink" size="12"/>
                        This record is sharing the same primary attributes with {length} others
                    </p>
                    <p>therefore it's been quarantined</p>
                </div>
            )}

            {!quarantineMode && (
                <div className="c-conflicts-popover-header-text">
                    <p>
                        <Icon className="u-margin-small-right" icon="DUPLICATES" fill="pink" size="12"/>
                        The selected record is conflicting with {length} others.
                    </p>
                    <p>Please check the information presented and confirm or edit where applicable</p>
                </div>
            )}
        </div>
    );
}
