import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import remove from 'lodash/remove';
import {
    searchableFileds,
    searchHeaders
} from 'src/js/constants/advancedFilteringConstants';
import SearchItems from 'src/js/components/AdvancedFiltering/TableSearchItems/SearchItems';
import HeaderSearchItem from 'src/js/components/AdvancedFiltering/TableSearchItems/HeaderSearchItem';
import { Modal, Auth, Icon } from 'src/js/components/static';
import { showModal } from 'src/js/actions/xelacore';

import ProductModal from 'src/js/components/Modals/ProductModal';
import TableRow from './MyRecordsTableRow';

import { selectAll } from 'src/js/actions/productsFetch';

import {
    filterReadyToRegister,
    getFilteredFields
} from 'src/js/helpers/dataHelpers';
import { isQuarantineRecord } from 'src/js/helpers/conflictsHelper';

import { isLicensor } from 'src/js/helpers/permissions';

import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import TableRowSortingIcons from 'src/js/components/TableRow/TableRowSortingIcons';
import { ReactComponent as BandAidIcon } from 'src/js/components/static/Icon/svg-icons/band-aid.svg';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import Conflicts from './Conflicts/Conflicts';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import differenceBy from 'lodash/differenceBy';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';

const checkSelectorOptionList = [{
    value: 'all_on_page',
    label: 'All on Page'
}, {
    value: 'clear_on_page',
    label: 'Clear on Page'
}, { value: 'clear_all', label: 'Clear all' }];

class Table extends Component {
    constructor(props) {
        super(props);

        this.table = React.createRef();
        this.header = React.createRef();
        this.tableHeaderWrap = React.createRef();
        this.scrollbar = React.createRef();

        // Need for more accurate scrolling
        // Creates a bit of a lag on initial load but is a necessary evil
        this.handleScroll = debounce(this.handleScroll, 1);

        this.state = {
            conflictsOpen: null,
            highlightRow: null,
            searchBox: '',
            showLists: false,
            tableWidth: 0,
            inputValue: '',
            fullListingsData: []
        };

        this.searchTable = React.createRef();
        this.mainTable = React.createRef();
        this.popoverRef = React.createRef();

        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    scrollHandler() {
        const distanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        const table = this.table.current;
        const rect = table.getBoundingClientRect();
        const tableDistanceToBottom = Math.max(0, document.documentElement.scrollHeight - (rect.bottom + window.pageYOffset));

        if (window.scrollY > 80) {
            document.getElementById('siteHeader').style.boxShadow = 'none';
            this.tableHeaderWrap.current.classList.add('sticky');
        } else {
            document.getElementById('siteHeader').style.boxShadow =
                '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
            this.tableHeaderWrap.current.classList.remove('sticky');
        }

        if (distanceToBottom < tableDistanceToBottom + 40) {
            this.scrollbar.current.classList.add('bottom_fixed');
        } else {
            this.scrollbar.current.classList.remove('bottom_fixed');
        }
    }

    scrollHandlerBound = this.scrollHandler.bind(this);

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('scroll', this.scrollHandlerBound);
        if (this.mainTable && this.mainTable.current) {
            setTimeout(() => {
                this.setState({
                    tableWidth: this.mainTable.current ? this.mainTable.current.offsetWidth:'auto'
                });
            }, 1000);
        }

    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('scroll', this.scrollHandlerBound);
        document.getElementById('siteHeader').style.boxShadow =
            '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleClickOutside = (event) => {
        if (this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
            this.setState({
                searchBox: null
            });
        }
    };

    resolveConflicts(id, modalActions) {
        const { data = [], dispatch } = this.props;
        const rowData = data.find((el) => el.record_id === id);
        const body = (
            <Conflicts
                data={rowData}
                quarantineMode={isQuarantineRecord(rowData)}
                modalActions={modalActions}
                self={true}
                id={id}
            />
        );

        const contentOfTheModal = (
            <Modal
                scrolling
                isWide
                className="c-conflicts-record__modal" title="Resolve Conflicts" body={body}
            />
        );

        return dispatch(showModal(contentOfTheModal));
    }

    editRow(id, value) {
        // TODO made some components out of there
        const { data = [], dispatch, fetchData, ipLevels, ips } = this.props;
        const rowData = value ? value : data.find((el) => el.record_id === id);
        const body = (
            <ProductModal
                rowData={rowData}
                dataId={id}
                ipLevels={ipLevels}
                ips={ips}
                fetchData={fetchData}
            />
        );
        const contentOfTheModal = (
            <Modal
                scrolling
                className="c-edit-record__modal" title="Edit Record" body={body}
            />
        );
        return dispatch(showModal(contentOfTheModal));
    }

    handleScroll(e, ref) {
        if (ref === 'table') {
            this.header.current.scrollTo(this.table.current.scrollLeft, 0);
            this.scrollbar.current.scrollTo(this.table.current.scrollLeft, 0);
        }

        if (ref === 'header') {
            this.table.current.scrollTo(this.header.current.scrollLeft, 0);
            this.scrollbar.current.scrollTo(this.header.current.scrollLeft, 0);
        }

        if (ref === 'scrollbar') {
            this.header.current.scrollTo(this.scrollbar.current.scrollLeft, 0);
            this.table.current.scrollTo(this.scrollbar.current.scrollLeft, 0);
        }
    }

    handleChangeSearch(event) {
        this.setState({ inputValue: event.target.value });
    }

    openSearchBox(el) {
        const { searchBox } = this.state;
        const { searchItems } = this.props;

        let searchText = searchBox;

        if (
            (el && el.dataName === searchBox) ||
            (el && el.label === searchBox)
        ) {
            searchText = '';
        } else {
            searchText = el.dataName || el.label;
        }

        this.setState({
            searchBox: searchText,
            inputValue: find(searchItems, (item) => {
                return item && item.label === (el.dataName || el.label);
            })
        });
    }

    setConflict(id, close) {
        this.setState({
            conflictsOpen: close ? null : id
        });

        if (!close) {
            document.getElementById('container').style.pointerEvents = 'none';
            document.getElementsByTagName('html')[0].className = 'backdrop';
        } else {
            document.getElementsByTagName('html')[0].className = '';
            document.getElementById('container').style.pointerEvents = null;
        }
    }

    fetchCheckboxSelector(optionSelected) {
        const {
            selectedRows,
            dispatch,
            data
        } = this.props;

        const excludeFromThisPage = differenceBy(selectedRows, data, 'record_id');

        switch (optionSelected) {
            case 'all_on_page':
                return dispatch(
                    selectAll(
                        uniq([
                            ...selectedRows,
                            ...data
                        ]),
                        true,
                        uniq([
                            ...selectedRows,
                            ...data
                        ])
                    )
                );

            case 'clear_on_page':
                return dispatch(
                    selectAll(
                        excludeFromThisPage,
                        true,
                        excludeFromThisPage
                    )
                );
            case 'clear_all':
                return dispatch(
                    selectAll(
                        [],
                        true,
                        []
                    )
                );
        }
    }

    checkAllAction(selectedRows, allChecked, allIds, filteredSelectedData) {
        const { dispatch, registrableIds, data } = this.props;

        const filteredCurrent =
            selectedRows.filter(
                (item) =>
                    !allIds.includes(
                        item.record_id
                    )
            );

        const selected =
            allChecked || selectedRows.filter((item) => allIds.includes(item.record_id)).length > 0
                ? filteredCurrent
                : [
                    ...filteredCurrent,
                    ...data
                ];

        const filteredRegistrable =
            registrableIds.filter(
                (item) =>
                    !allIds.includes(
                        item
                    )
            );

        const newRegistrable =
            filterReadyToRegister(
                data,
                allIds
            );

        const selectedRegistrable =
            allChecked || intersection(filteredSelectedData, allIds).length > 0
                ? filteredRegistrable
                : [
                    ...registrableIds,
                    ...newRegistrable
                ];

        dispatch(
            selectAll(
                uniq(selected),
                true,
                selectedRegistrable
            )
        );
    }

    render() {
        const {
            data = [],
            headers,
            showUpload,
            selectedRows,
            selected,
            sortData,
            sortedBy,
            dispatch,
            fetchData,
            fetching,
            companyData,
            orgId,
            gtinWaiver,
            queryObject,
            basicFilterObj,
            registrableIds,
            searchItems,
            updateFiltersState,
            isFiltersReturnedNoData,
            categoryLevels,
            previousRow,
            ipLevels
        } = this.props;
        const { list = [] } = headers;
        const {
            conflictsOpen,
            highlightRow,
            searchBox,
            tableWidth,
            inputValue
        } = this.state;

        const allIds = data.map((el) => el.record_id);
        const filteredSelectedData = selectedRows.filter((item) => allIds.includes(item.record_id));
        const allChecked = size(data) === size(filteredSelectedData);
        const tableCx = classnames('c-rec-table', {
            'c-rec-table--fetching': fetching
        });
        const licensor = isLicensor(companyData);

        const activeHeaders = getFilteredFields(
            licensor,
            list,
            [],
            true
        ).filter((f) => f.active);
        const activeHeaderList = activeHeaders.map(({ name }) => name);
        const filteredHeaders = defaultHeaders.filter(
            (header) => header.config && header.config.group
        );
        let ipIndex = findIndex(activeHeaderList, item => item === 'ip_paths');
        let categoryIndex = findIndex(activeHeaderList, item => item === 'licensee_category_path');

        const sortedHeaders = activeHeaderList
            .map((e) => filteredHeaders.find((f) => f.dataName === e))
            .filter((f) => f !== undefined && f.dataName !== 'additional' && f.dataName !== 'category_attributes');

        let mapLevels = map(ipLevels, (ip) => {
            return {
                dataName: `ips_${ip.ip_level_id}`,
                label: ip.level_name,
                ip_level_id: ip.ip_level_id,
                active: ipIndex > -1,
                order: ipIndex + 2,
                showInList: ipIndex > -1,
                form: { type: 'dynamicIp' }
            };
        });

        let mapCategories = map(categoryLevels, (cat, i) => {
            return {
                dataName: `licensee_category_path.${i}`,
                label: cat,
                index: i,
                active: categoryIndex > -1,
                order: categoryIndex + 2,
                showInList: categoryIndex > -1,
                form: { type: 'dynamicCategory' }
            };
        });

        if (ipIndex > -1) sortedHeaders.splice(ipIndex, 0, ...mapLevels);
        if (categoryIndex > -1) sortedHeaders.splice(categoryIndex, 0, ...mapCategories);

        remove(sortedHeaders, header => header.dataName === 'ip_paths');
        remove(sortedHeaders, header => header.dataName === 'licensee_category_path');

        return (
            <div>
                <div className={`c-rec-table__wrapper ${fetching ? 'fetching-data' : ''}`}>
                    <div ref={this.tableHeaderWrap}
                         className="c-rec-table c-rec-table__header-container">
                        <Auth
                            restrictTo="licensee"
                            permission="registration.manage"
                        >

                            <div className={`checkbox-selector-wrapper-fixed dark-table-head ${allChecked ? 'full_selected' : (filteredSelectedData.length > 0 || selectedRows.length > 0) ? 'partial_selected' : 'unselected'}`}>
                                <UiCheckbox
                                    extraClass={
                                        allChecked ? 'full_selected' : filteredSelectedData.length > 0 ? 'partial_selected' : ''}
                                    type="checkbox"
                                    checked={allChecked}
                                    onClick={() => {
                                        this.checkAllAction(selectedRows, allChecked, allIds, filteredSelectedData);
                                    }}>

                                </UiCheckbox>

                                <div className="checkbox-action-counter-indicator">
                                    {selectedRows.length > 0 && (<span
                                        className="checkbox-counter">{selectedRows.length}</span>)}
                                    <CustomSelect
                                        onChange={(e) => {
                                            this.fetchCheckboxSelector(e);

                                        }}
                                        values={checkSelectorOptionList}
                                        allowEmpty={true}
                                    />
                                </div>
                            </div>

                        </Auth>
                        <div
                            className="c-rec-table__table-holder c-rec-table__table-header"
                            ref={this.header}
                            onScroll={(e) => this.handleScroll(e, 'header')}
                        >
                            <table className="c-rec-table__top-header">
                                <thead>
                                <tr ref={this.mainTable}>
                                    <th className="c-rec-table__checkbox checkbox-holder">
                                        {/*cell forcheckbox and dropdown*/}
                                    </th>
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-rec c-rec-table__status">
                                        <div className="c-rec-table__icon-holder">
                                            <Icon
                                                icon="LOCK_FULL"
                                                fill="white"
                                                width="10"
                                                height="10"
                                                right="2"
                                                top="2"
                                            />
                                            Record status
                                        </div>
                                    </th>
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-rec c-rec-table__image-cell">
                                        <div className="c-rec-table__icon-holder">
                                            <Icon
                                                icon="LOCK_FULL"
                                                fill="white"
                                                width="10"
                                                height="10"
                                                right="2"
                                                top="2"
                                            />
                                            Image
                                        </div>
                                    </th>

                                    {sortedHeaders.map((el) => {
                                        const key = el.dataName || el.label;
                                        return (
                                            <th
                                                className="c-rec-table__head-cell u-relative"
                                                key={`${key}-mrhead`}
                                            >
                                                <div className="u-flex-align">
                                                    {includes(
                                                        searchableFileds,
                                                        el.dataName
                                                    ) && (
                                                        <HeaderSearchItem
                                                            el={el}
                                                            searchBox={
                                                                searchBox
                                                            }
                                                            elKey={key}
                                                            handleChangeSearch={
                                                                this
                                                                    .handleChangeSearch
                                                            }
                                                            inputValue={
                                                                inputValue
                                                            }
                                                            openSearchBox={() =>
                                                                this.openSearchBox(
                                                                    el
                                                                )
                                                            }
                                                            searchItems={
                                                                searchItems
                                                            }
                                                            basicFilterObj={
                                                                basicFilterObj
                                                            }
                                                            queryObject={
                                                                queryObject
                                                            }
                                                            updateFiltersState={(
                                                                newQuery,
                                                                newFilters,
                                                                updateHard,
                                                                params
                                                            ) =>
                                                                updateFiltersState(
                                                                    newQuery,
                                                                    newFilters,
                                                                    updateHard,
                                                                    params
                                                                )
                                                            }
                                                            popoverRef={
                                                                this
                                                                    .popoverRef
                                                            }
                                                            categoryLevels={categoryLevels}
                                                            clearSearch={(event) => { event.preventDefault();this.setState({inputValue: ''})}}
                                                        />
                                                    )}

                                                    <TableRowSortingIcons
                                                        colour={'black'}
                                                        label={el.label}
                                                        sortData={sortData}
                                                        dataName={
                                                            el.dataName
                                                        }
                                                        isActiveSorting={
                                                            sortedBy.name ===
                                                            el.dataName
                                                        }
                                                        isUp={
                                                            !!(
                                                                sortedBy.name ===
                                                                el.dataName &&
                                                                !sortedBy.dir
                                                            )
                                                        }
                                                        defaultSort="uploaded_at"
                                                        categoryLevels={categoryLevels}
                                                    />
                                                </div>
                                            </th>
                                        );
                                    })}
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-rec">
                                        <TableRowSortingIcons
                                            label="Upload Date"
                                            sortData={sortData}
                                            dataName={'uploaded_at'}
                                            isActiveSorting={
                                                sortedBy.name ===
                                                'uploaded_at'
                                            }
                                            isUp={
                                                !!(
                                                    sortedBy.name ===
                                                    'uploaded_at' &&
                                                    !sortedBy.dir
                                                )
                                            }
                                            defaultSort="uploaded_at"
                                            locked
                                        />
                                    </th>
                                    <th className="c-rec-table__head-cell c-rec-table__head-cell-rec">
                                        <TableRowSortingIcons
                                            label="Modified Date"
                                            sortData={sortData}
                                            dataName={'updated_at'}
                                            isActiveSorting={
                                                sortedBy.name ===
                                                'updated_at'
                                            }
                                            isUp={
                                                !!(
                                                    sortedBy.name ===
                                                    'updated_at' &&
                                                    !sortedBy.dir
                                                )
                                            }
                                            defaultSort="uploaded_at"
                                            locked
                                        />
                                    </th>
                                </tr>
                                </thead>
                            </table>

                            <SearchItems
                                searchItems={searchItems}
                                tableWidth={tableWidth}
                                searchTable={this.searchTable}
                                sortedHeaders={sortedHeaders}
                                searchHeaders={searchHeaders}
                                queryObject={queryObject}
                                basicFilterObj={basicFilterObj}
                                openSearchBox={(el) => this.openSearchBox(el)}
                                updateFiltersState={(
                                    newQuery,
                                    newFilters,
                                    updateHard,
                                    params
                                ) =>
                                    updateFiltersState(
                                        newQuery,
                                        newFilters,
                                        updateHard,
                                        params
                                    )
                                }
                            />
                        </div>
                    </div>

                    <div className={tableCx}>
                        <div
                            className="c-rec-table__table-holder c-rec-table__main-table"
                            ref={this.table}
                            onScroll={(e) => this.handleScroll(e, 'table')}
                            style={{ minHeight: 100 }}
                        >

                            {!isFiltersReturnedNoData ? (
                                <table>
                                    {uniqBy(data, 'record_id').map((el) => {
                                        const key = `${el.record_id}-${el.version_id}`;
                                        return (
                                            <TableRow
                                                key={key}
                                                sortedHeaders={sortedHeaders}
                                                activeHeaders={activeHeaders}
                                                activeHeaderList={
                                                    activeHeaderList
                                                }
                                                dispatch={dispatch}
                                                gtinWaiver={gtinWaiver}
                                                data={el}
                                                licensor={licensor}
                                                fetchData={fetchData}
                                                sortData={sortData}
                                                showUpload={(id) =>
                                                    showUpload(id)
                                                }
                                                selectedRows={selectedRows}
                                                selected={selected}
                                                registrableIds={registrableIds}
                                                highlighted={
                                                    highlightRow === key
                                                }
                                                editRow={() =>
                                                    el.record_id !==
                                                    conflictsOpen
                                                        ? this.editRow(
                                                            el.record_id
                                                        )
                                                        : (modalActions) =>
                                                            this.resolveConflicts(
                                                                el.record_id, modalActions
                                                            )
                                                }
                                                handleClick={() => {
                                                    this.setState({
                                                        highlightRow:
                                                            highlightRow === key
                                                                ? null
                                                                : key
                                                    });
                                                }}
                                                orgId={orgId}
                                                conflictsOpen={conflictsOpen}
                                                setConflicts={(modalActions) =>
                                                    this.resolveConflicts(el.record_id, modalActions)
                                                }
                                                categoryLevels={categoryLevels}
                                                allIds={allIds}
                                                allProducts={data}
                                                previousRow={previousRow}
                                            />
                                        );
                                    })}
                                </table>
                            ) : (
                                <div className="u-full-width u-flex-column u-flex-align">
                                    <BandAidIcon
                                        className="empty-table-icon u-margin-bottom u-margin-top"
                                    ></BandAidIcon>
                                    <h3 className="u-text-center u-margin-bottom">
                                        There are no records to display
                                        <br /> at this time
                                    </h3>
                                    <p className="u-text-center u-margin-bottom">
                                        Please make sure to clear any applied
                                        filters
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        ref={this.scrollbar}
                        className="c-rec-table__bottom-scrollbar"
                        onScroll={(e) => this.handleScroll(e, 'scrollbar')}
                    >
                        <div
                            style={{
                                height: 20,
                                width:
                                    (this.table.current &&
                                        this.table.current.scrollWidth) ||
                                    0
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const { isMyRecords } = props;
    const key = isMyRecords ? 'myRecords' : 'productCatalogue';
    const {
        xelacore: {
            auth: { companyData } = {},
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = []
            } = {},
            [key]: {
                records: { data = [], totalItems, page, page_size } = {},
                fetching: { fetching, fetchErrors } = {},
                filters: { activeList, filters } = {},
                initialCount,
                selectedRows = [],
                registrableIds = [],
                selected = null,
                previousRow = null
            } = {}
        } = {}
    } = state;

    return {
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        data,
        filters,
        totalItems,
        page,
        page_size,
        initialCount,
        selectedRows,
        fetching,
        fetchErrors,
        activeList,
        companyData,
        registrableIds,
        selected,
        previousRow
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);
