import axios from 'axios';
import config from 'config';

import forEach from 'lodash/forEach';

import responseHandler from '../common/responseHandler';

import CryptoJS from 'crypto-js';

const axiosUpload = axios.create({ headers: { Authorization: '' } });

const CancelToken = axios.CancelToken;

/**
 * This API call uploads a file against a URL
 *
 * @param  {String} category
 * @param  {File} file the file to upload
 * @param  {Function} progress The callback to call on each progress event
 * @param  {Function} cancelBind
 * @return {Promise}
 */
export function upload(category, file, progress, cancelBind) {
    return axios
        .request({
            url: [config.urls.files, 'private/upload'].join('/'),
            method: 'post',
            data: { file: { category, name: file.name } },
            cancelToken: new CancelToken(cancelBind || (() => {}))
        })
        .then((response) => {
            const data = responseHandler(response, 'data');
            var decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decrypted);
        })
        .then(({ post_body, location }) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                forEach(post_body, (val, key) => data.append(key, val));
                data.append('file', file);

                setTimeout(() => {
                    axiosUpload
                        .request({
                            url: location,
                            method: 'post',
                            data,
                            onUploadProgress: progress,
                            cancelToken: new CancelToken(
                                cancelBind || (() => {})
                            )
                        })
                        .then((result) => resolve(result))
                        .catch((err) => reject(err));
                }, 0);
            });
        });
}

export function getDroppedFile(url) {
    const newAxios = axios.create({
        baseURL: url
    });
    return newAxios
        .request({
            method: 'GET'
        })
        .then((r) => r);
}
