import React from 'react';
import linked from 'statics/imgs/desktop/concepts/linked.svg';

export default function LinkedNonCatalogue({ record_id }) {
    return (
        <div className="c-product__concept-block">
            <div>
                <div className="u-padding">
                    <img src={linked} className="u-padding" alt="Linked" />
                </div>
                <h3>
                    There are concepts <span>linked</span> to this product
                </h3>
                <div>
                    You can view and edit concept information in{' '}
                    <a href={`/product/catalog/${record_id}`}>
                        Product Catalogue
                    </a>
                    .
                </div>
            </div>
        </div>
    );
}
