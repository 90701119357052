import React, { Component } from 'react';
import { Tooltip } from 'src/js/components/static';

export default class ListBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: true
        };
    }

    handleShowMore() {
        this.setState({ showMore: !this.state.showMore });
    }

    render() {
        const { data, title, triggerShowLess = 2, tooltip } = this.props;
        const { showMore } = this.state;
        // const isShowMoreShown = data.length < triggerShowLess + 1 || data.length !== triggerShowLess + 1;

        return (
            <div className="c-list-box">
                <div className="c-list-box__flexed c-list-box__header">
                    <div className="c-list-box__title">
                        {title}
                        {tooltip && <Tooltip>{tooltip}</Tooltip>}
                    </div>
                    <div>Total</div>
                </div>
                <ul className="c-list-box__ul">
                    {data.map((item, idx) => {
                        if (showMore && idx > triggerShowLess) return null;
                        const { name, total, icon, id } = item;
                        return (
                            <li className="c-list-box__flexed" key={id}>
                                <div className="c-list-box__left-part">
                                    {icon && (
                                        <div className="c-list-box__img-box">
                                            <img src={icon} alt={name} />
                                        </div>
                                    )}
                                    <div className="u-padding">{name}</div>
                                </div>
                                <div className="u-padding">{total}</div>
                            </li>
                        );
                    })}
                    {
                        // isShowMoreShown &&
                        // <li className="c-list-box__flexed" onClick={() => this.handleShowMore()}>
                        //     <div className="c-list-box__show-more">Show {showMore ? 'More' : 'Less'}</div>
                        // </li>
                    }
                </ul>
            </div>
        );
    }
}
