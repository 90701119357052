import React from 'react';
import LinkedNonCatalogue from './LinkedNonCatalogue';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import Button from 'modules/UiKit/components/Button/Button';

export default function AprovalNonRegistered({ ...props }) {
    const {
        isCatalogue,
        registered,
        record_id,
        conceptCodeConfirmedInfo,
        dismissConceptInfoFunc,
        dontShow
    } = props;
    const specialCase =
        !isCatalogue && conceptCodeConfirmedInfo && !!conceptCodeConfirmedInfo.length;

    return (
        <div>
            {specialCase && <LinkedNonCatalogue record_id={record_id} />}
            {!specialCase && (
                <div>
                    {!registered && (
                        <div className="c-product__detail-listing-info">
                            Please <strong>register your product</strong>{' '}
                            with your Licensor(s) to be able to search,
                            browse and link concepts to your product.

                            <div className='u-flex-gap u-flex-align--right u-margin-top'>
                                <UiCheckbox
                                    type="checkbox"
                                    id="dontShow"
                                    name="dontShow"
                                    onChange={() => dontShow('dismissConceptInfo')}
                                    label={'Don\'t show this again'}>

                                </UiCheckbox>
                                <Button
                                        type="secondary-2"
                                        size="small"
                                        onClick={() => dismissConceptInfoFunc()}
                                    >
                                        Dismiss
                                </Button>
                            </div>
                        </div>
                    )}
                    {registered && !isCatalogue && (
                        <div className="c-product__detail-listing-info">
                            Once you register the product with your Licensor(s), make sure to navigate to the Product Catalogue and link the corresponding concepts to this product.

                            <div className='u-flex-gap u-flex-align--right u-margin-top'>
                                <UiCheckbox
                                    type="checkbox"
                                    id="dontShow"
                                    name="dontShow"
                                    onChange={() => dontShow('dismissConceptInfo')}
                                    label={'Don\'t show this again'}>

                                </UiCheckbox>
                                <Button
                                        type="secondary-2"
                                        size="small"
                                        onClick={() => dismissConceptInfoFunc()}
                                    >
                                        Dismiss
                                </Button>
                            </div>
                        </div>
                        
                    )}
                </div>
            )}
        </div>
    );
}
