import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Carousel, Modal, ModalCarousel, Icon } from 'src/js/components/static';
import { showModal } from 'src/js/actions/xelacore';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import MediaView from 'src/js/components/MediaView';

@connect(
    null,
    dispatch => ({ dispatch })
)
class ImagesBlock extends Component {
    constructor(props) {
        super(props);
        const { data = {} } = this.props;
        const { images = [] } = data;
        const primaryIndex = images.findIndex(item => item && item.tag && item.tag === 'primary');
        this.state = {
            currentIndex: primaryIndex === -1 ? 0 : primaryIndex
        };
    }

    setIndex = index => {
        const {data: {images}} = this.props;
        if (index < 0 || index + 1 > images.length) return;
        if (index === this.state.currentIndex) return;

        this.setState({ currentIndex: index });
    };

    showCarouselModal() {
        const {
            data: { images, product_name },
            dispatch
        } = this.props;
        const { currentIndex } = this.state;

        const body = (
            <ModalCarousel
                images={images}
                currentIndex={currentIndex}
                dispatch={dispatch}
            />
        );

        const modal = (
            <Modal
                title={`Viewing images for ${product_name}`}
                body={body}
                className="c-modal--large-box"
            />
        );
        return dispatch(showModal(modal));
    }

    render() {
        const { data, centered } = this.props;
        const { currentIndex } = this.state;
        const {
            images,
            product_category,
            licensor_brand,
            product_name,
            product_description
        } = data;
        
        let currentImage = images && images.length && ( images[0].local_url || images[0].original_url )
        if(images.length > 1 ) {
            currentImage = images && images.length && (images[currentIndex].local_url || images[currentIndex].original_url)
        }
        const overlayDiv = (
            <div className="c-product__approval-image-overlay">
                <Icon icon="search" fill="white" width="20" height="20" />
            </div>
        );

        return (
            <Fragment>
                {centered && (
                    <div className="c-product__images-block-view">
                        {
                            images.length > 1 &&
                            <Fragment>
                                <button
                                    className="c-product__images-button c-product__images-button--prev"
                                    onClick={() => this.setIndex(currentIndex - 1)}
                                >
                                    &lt;
                                </button>
                                <button
                                    className="c-product__images-button c-product__images-button--next"
                                    onClick={() => this.setIndex(currentIndex + 1)}
                                >
                                    &gt;
                                </button>
                            </Fragment>
                        }

                        {!!images.length && (
                            <MediaView
                                className="c-product__current-image"
                                src={currentImage}
                                onClick={() => this.showCarouselModal()}
                            >
                                {overlayDiv}
                            </MediaView>
                        )}

                        {!!images.length && (
                            <Carousel
                                images={images}
                                currentIndex={currentIndex}
                                setIndex={this.setIndex}
                            />
                        )}
                    </div>
                )}
                {!centered && (
                    <div>
                        <div className="c-product__approval-info">
                            {images.length ? (
                                <MediaView
                                    className="c-product__approval-image"
                                    src={currentImage}
                                    onClick={() => this.showCarouselModal()}
                                >
                                    {overlayDiv}
                                </MediaView>
                            ) : (
                                <MediaView
                                    className="c-product__approval-image u-auto-cursor"
                                    src={imgPlaceholder}
                                />
                            )}
                            <div>
                                <h3 className="u-flex-1 c-product__approval-title">
                                    {product_name}
                                </h3>
                                <p>Category: {product_category}</p>
                                <p>
                                    Brand:{' '}
                                    {licensor_brand || 'No brand supplied'}
                                </p>
                                <p>{product_description}</p>
                            </div>
                        </div>
                        <div>
                            <Carousel
                                images={images}
                                currentIndex={currentIndex}
                                setIndex={this.setIndex}
                            />
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default ImagesBlock;
