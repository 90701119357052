import get from 'lodash/get';
import {
    uploadThatImage,
    uploadThatUsersImage,
    publishThatImage
} from 'src/js/apicalls/files/imageUpload';
import {
    BULK_IMAGES_UPLOAD,
    RESET_BULK_IMAGES_UPLOAD,
    UPDATE_BULK_IMAGES_COUNTER
} from 'src/js/constants/actions/dataManager';
import { displayNotification } from './xelacore';
import { updateProductInfo } from './dataManager';

export function uploadImage(data) {
    return () => {
        if (!data) return Promise.reject('No data found, illegal action');

        return uploadThatImage(data);
    };
}

export function uploadUserImage(data) {
    return () => {
        if (!data) return Promise.reject('No data found, illegal action');

        return uploadThatUsersImage(data);
    };
}

export function publishImage(record_id, data) {
    return () => {
        if (!record_id)
            return Promise.reject(
                'I cannot find a record_id, this I feel, is an illegal action'
            );
        if (!data) return Promise.reject('Where is the data? illegal action');
        return publishThatImage(record_id, data);
    };
}

function handleFileUpload(file, recordId, dispatch) {
    const formData = new FormData();
    formData.append('type', 'image');
    formData.append('image', file);
    formData.append('record_id', recordId);

    dispatch({ type: UPDATE_BULK_IMAGES_COUNTER, payload: { recordId } });

    return dispatch(uploadImage(formData))
        .then((resp) =>
            dispatch(publishImage(recordId, { image_url: resp.data.url }))
                .then(() =>
                    dispatch(
                        displayNotification({
                            message: 'New image added',
                            type: 'success'
                        })
                    )
                )
                .catch(() =>
                    dispatch(
                        displayNotification({
                            type: 'error',
                            message:
                                'Failed to publish image. Please try again later'
                        })
                    )
                )
        )
        .catch(() =>
            dispatch(
                displayNotification({
                    type: 'error',
                    message: 'Failed to upload image. Please try again later'
                })
            )
        );
}

async function uploadImages(files, recordId, dispatch, getState) {
    for (const file of files) {
        await handleFileUpload(file, recordId, dispatch, getState);
    }
    return true;
}

export function bulkImagesUpload(files = [], recordId, data) {
    return async (dispatch, getState) => {
        const { filesTotalToUpload = [], filesLeftToUploadCount = 0 } = get(
            getState(),
            ['xelacore', 'dataManager', 'bulkImagesUpload', recordId],
            {}
        );

        const newFilesTotalToUpload = [...filesTotalToUpload, ...files];

        dispatch({
            type: BULK_IMAGES_UPLOAD,
            payload: {
                recordId,
                filesTotalToUpload: newFilesTotalToUpload,
                filesLeftToUploadCount: filesLeftToUploadCount
            }
        });

        const uploaded = await uploadImages(
            files,
            recordId,
            dispatch,
            getState
        );

        if (
            uploaded &&
            newFilesTotalToUpload.length ===
                getState().xelacore.dataManager.bulkImagesUpload[recordId]
                    .filesTotalToUpload.length
        ) {
            dispatch(
                updateProductInfo(recordId, {
                    record: {
                        record_id: data.record_id,
                        version_id: data.version_id,
                        mpn: data.mpn,
                        gtin: data.gtin,
                        product_name: data.product_name,
                        licensor_brand: data.licensor_brand,
                        company_brand: data.company_brand
                    }
                })
            ).finally(() => {
                dispatch({
                    type: RESET_BULK_IMAGES_UPLOAD,
                    payload: { recordId }
                });
            });
        }
    };
}
