import React, { Component } from 'react';
import times from 'lodash/times';
import { Link } from 'src/js/components/static';
import classnames from 'classnames';
import serialize from 'form-serialize';

const getPaginationItemCount = (totalItems, itemsPerPage) => {
    return Math.ceil(totalItems / itemsPerPage);
};

// Exported for testing
export const getPageRange = (number, paginationItemCount) => {
    if (number > paginationItemCount) return [];

    if (!number || !paginationItemCount) return []; //if number of pagination count is not set return empty

    let rangeArray = [];

    //if first page  add current page next three pages
    if (number === 1) {
        let count = 1;
        rangeArray.push(number);
        while (count < 4) {
            rangeArray.push(++number);
            ++count;
        }
        return rangeArray;
    }

    // Add previous page, current page and next two
    rangeArray.push(number - 1);
    rangeArray.push(number);

    let count = 1;
    while (count < 3) {
        ++number;
        if (number <= paginationItemCount) rangeArray.push(number);
        ++count;
    }

    return rangeArray;
};

const inRange = (page, range) => range.indexOf(page) !== -1;

const getPaginationItems = (
    paginationItemCount,
    jumperVisible,
    toggleJumper,
    props
) => {
    const { currentPage, updatePageHandler, jumpToPage } = { ...props };
    const pageRange = getPageRange(currentPage, paginationItemCount);

    const closeAndJump = (e) => {
        const val = serialize(e.target, { hash: true });
        if (val.page > paginationItemCount) {
            e.preventDefault();
            return false;
        }
        toggleJumper(true);
        jumpToPage(e);
    };

    if (paginationItemCount <= 1) return null;

    return times(paginationItemCount, (index) => {
        // Hide items that don't need to be displayed
        if (!inRange(index + 1, pageRange) && index + 1 !== paginationItemCount)
            return null;

        const paginationItemClass = classnames(
            'c-pagination__item',
            { 'c-pagination__item--current': index + 1 === currentPage },
            { 'c-pagination__item--last': index + 1 === paginationItemCount }
        );

        const paginationEllepsisClass = classnames(
            'c-pagination__link',
            'c-pagination__ellepsis',
            {
                'c-pagination__ellepsis--show':
                    index + 1 == paginationItemCount &&
                    !inRange(index + 1, pageRange)
            }
        );

        const paginationLinkClass = classnames('c-pagination__link', {
            'c-pagination__link--current': index + 1 === currentPage,
            'c-pagination__link--last': index + 1 === paginationItemCount,
            'c-pagination__link--first': index + 1 === pageRange[0]
        });

        return (
            <div key={index} className={paginationItemClass}>
                <Link
                    onClick={() => toggleJumper()}
                    className={paginationEllepsisClass}
                >
                    {jumperVisible && (
                        <div className="c-pagination__page-jumper c-pagination--jumper">
                            <div className="c-pagination__bubble c-pagination--jumper">
                                <form
                                    onSubmit={(e) => closeAndJump(e)}
                                    className="c-pagination__page-jumper-form c-pagination--jumper"
                                    action=""
                                >
                                    <input
                                        name="page"
                                        className="c-input-text c-input-text--pagination c-pagination--jumper"
                                        type="text"
                                        placeholder="Page"
                                    />
                                </form>
                            </div>
                        </div>
                    )}
                    <span>...</span>
                </Link>
                <Link
                    className={paginationLinkClass}
                    onClick={() => updatePageHandler(index + 1)}
                >
                    <span>{index + 1}</span>
                </Link>
            </div>
        );
    });
};

const getPreviousNav = (props) => {
    const { currentPage, updatePageHandler } = { ...props };
    const cx = classnames(
        'c-pagination__item--previous',
        'c-pagination__item--nav',
        {
            'c-pagination__vis-hidden': currentPage === 1
        }
    );

    return (
        <div onClick={() => updatePageHandler(currentPage - 1)} className={cx}>
            prev
        </div>
    );
};

const getPager = (props) => {
    const {
        itemsPerPage,
        updateItemsPerPageHandler,
        itemsPerPageOptions
    } = props;

    return (
        <div className="c-pagination__pager">
            <div className="c-pagination__pager-inner">
                <label
                    htmlFor="pagination-label"
                    className="c-pagination__label"
                >
                    Show
                </label>
                <div className="c-select">
                    <select
                        defaultValue={itemsPerPage}
                        onChange={updateItemsPerPageHandler}
                        className="c-select__select-input"
                        name="pagination-label"
                        id="pagination-label"
                    >
                        {itemsPerPageOptions &&
                            itemsPerPageOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

const getNextNav = (paginationItemCount, props) => {
    const { currentPage, updatePageHandler } = { ...props };

    const cx = classnames(
        'c-pagination__item--next',
        'c-pagination__item--nav',
        {
            'c-pagination__vis-hidden': paginationItemCount === currentPage
        }
    );

    return (
        <div onClick={() => updatePageHandler(currentPage + 1)} className={cx}>
            NEXT
        </div>
    );
};

// Exported for testing
export default class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            jumperVisible: false
        };

        this.setWrapperNav = this.setWrapperNav.bind(this);
        this.hideJumper = this.hideJumper.bind(this);
        this.toggleJumper = this.toggleJumper.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.hideJumper);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.hideJumper);
    }

    hideJumper(e) {
        const inputSelected = e.target.classList.contains(
            'c-pagination--jumper'
        );
        if (!this.state.jumperVisible || inputSelected) return;
        return this.setState({ jumperVisible: false });
    }

    toggleJumper(hide) {
        return this.setState({ jumperVisible: !hide });
    }

    setWrapperNav(node) {
        this.wrapperNav = node;
    }

    setNewPage(paginationItemCount) {
        const { updatePageHandler, currentPage } = { ...this.props };
        currentPage !== paginationItemCount
            ? updatePageHandler(paginationItemCount)
            : true;
    }

    render() {
        const {
            totalItems,
            itemsPerPage,
            updatePageHandler,
            currentPage,
            itemsPerPageOptions
        } = { ...this.props };
        const paginationItemCount = getPaginationItemCount(
            totalItems,
            itemsPerPage
        );

        if (
            Number.isNaN(totalItems) ||
            !totalItems
        )
            return null;

        return (
            <div className="c-pagination" ref={this.setWrapperNav}>
                {paginationItemCount > 1 && (
                    <div
                        className={`c-pagination__first-page ${
                            currentPage === 1
                                ? 'c-pagination__first-page--disabled'
                                : ''
                        }`}
                        onClick={() =>
                            currentPage !== 1 ? updatePageHandler(1) : true
                        }
                    >
                        <span>First</span>
                    </div>
                )}

                {getPreviousNav(this.props)}

                <div className="c-pagination__items-holder">
                    {getPaginationItems(
                        paginationItemCount,
                        this.state.jumperVisible,
                        this.toggleJumper,
                        this.props
                    )}
                </div>

                {getNextNav(paginationItemCount, this.props)}

                {itemsPerPageOptions && getPager(this.props)}

                {paginationItemCount > 1 && (
                    <div
                        className={`c-pagination__last-page ${
                            currentPage === paginationItemCount
                                ? 'c-pagination__last-page--disabled'
                                : ''
                        }`}
                        onClick={() =>
                            currentPage !== paginationItemCount
                                ? updatePageHandler(paginationItemCount)
                                : true
                        }
                    >
                        <span>Last</span>
                    </div>
                )}
            </div>
        );
    }
}
