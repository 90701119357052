export const BUCKET = 'inbound';
export const FILE_PATH = '/webupload';

export const NEW_UPLOAD = 'DATA_MANAGER::NEW_UPLOAD';
export const COMPLETED_UPLOAD = 'DATA_MANAGER::COMPLETED_UPLOAD';
export const VALIDATE = 'PRESETS_MANAGER::VALIDATE';

export const BULK_IMAGES_UPLOAD = 'DATA_MANAGER::BULK_IMAGES_UPLOAD';
export const RESET_BULK_IMAGES_UPLOAD = 'DATA_MANAGER::RESET_BULK_IMAGES_UPLOAD';
export const UPDATE_BULK_IMAGES_COUNTER = 'DATA_MANAGER::UPDATE_BULK_IMAGES_COUNTER';
