export const FIELDS_ARRAY = [
    'countryCode',
    'countryName',
    'localCurrency',
    'characterId',
    'characterCode',
    'characterFullName',
    'propertyCode',
    'propertyFullName',
    'productId',
    'productDetail',
    'mpn',
    'gtin',
    'productDescription',
    'retailerCode',
    'retailerFullName',
    'unitsSold',
    'unitsReturned',
    'netUnitsSold',
    'grossSales',
    'returns',
    'discounts',
    'netSales',
    'royaltyType',
    'royaltyRate',
    'netRoyalties',
    'exchangeRate',
    'totalRoyalties',
    'cmf'
]

export const DISABLED_COLUMNS = [
    'countryName',
    'characterId',
    'characterFullName',
    'productId',
    // 'productDetail',
    'productDescription',
    'retailerFullName'
]

export const HEADER_ROW = {
    rowId: 'header',
    height: 40,
    cells: [
      { type: 'header', text: '' },
      { type: 'header', text: 'Country Code' },
      { type: 'header', text: 'Country Name' },
      { type: 'header', text: 'Local Currency' },
      { type: 'header', text: 'Character ID' },
      { type: 'header', text: 'Character Code' },
      { type: 'header', text: 'Character Full Name' },
      { type: 'header', text: 'Property Code' },
      { type: 'header', text: 'Property Full Name' },
      { type: 'header', text: 'Product ID' },
      { type: 'header', text: 'Product Detail' },
      { type: 'header', text: 'Product Number/SKU' },
      { type: 'header', text: 'UPS' },
      { type: 'header', text: 'Product Description' },
      { type: 'header', text: 'Retailer Code' },
      { type: 'header', text: 'Retailer Full Name' },
      { type: 'header', text: 'Units Sold' },
      { type: 'header', text: 'Units Returned' },
      { type: 'header', text: 'Net Units Sold' },
      { type: 'header', text: 'Gross Sales (Local Currency)' },
      { type: 'header', text: 'Returns (Local Currency)' },
      { type: 'header', text: 'Discounts (Local Currency)' },
      { type: 'header', text: 'Net Sales (Local Currency)' },
      { type: 'header', text: 'Royalty Type' },
      { type: 'header', text: 'Royalty Rate' },
      { type: 'header', text: 'Net Royalties (Local Currency)' },
      { type: 'header', text: 'Exchange Rate' },
      { type: 'header', text: 'Total Royalties (Contractual Currency)' },
      { type: 'header', text: 'CMF' }
    ]
  };