import React from 'react';
import VisSense from 'src/js/lib/vissense';

class VisibilityComponent extends React.Component {
    componentDidMount() {
        const throttle = VisSense.Utils.throttle;
        const wrapper = this.visibilityWrapper;
        const that = this;

        VisSense.VisMon.Builder(VisSense(wrapper))
            .on(
                'percentagechange',
                throttle(function(monitor, percentage) {
                    /*
                  IF GREATER THAN GIVEN PERCENTAGE
                 */
                    if (that.props.gt) {
                        if (
                            parseFloat(percentage).toFixed(2) * 100 >
                            that.props.gt.percentage
                        ) {
                            that.props.gt.handler();
                        }
                    }

                    /*
                 IF LESS THAN GIVEN PERCENTAGE
                 */
                    if (that.props.lt) {
                        if (
                            parseFloat(percentage).toFixed(2) * 100 <
                            that.props.lt.percentage
                        ) {
                            that.props.lt.handler();
                        }
                    }
                }, 150)
            )
            .build()
            .start();
    }

    render() {
        return (
            <div
                className={this.props.className}
                ref={div => (this.visibilityWrapper = div)}
            >
                {this.props.children}
            </div>
        );
    }
}

export default VisibilityComponent;
