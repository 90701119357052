import React from 'react';

export const returnUnique = (a) =>{
   return Array.isArray(a)
        ? [
              ...new Set(
                  a.map((f) =>
                      f.field === 'licensor_brand' ? (
                          <React.Fragment>
                              <b>"{f.value}"</b> {f.message}
                          </React.Fragment>
                      ) : (
                          f.message
                      )
                  )
              )
          ]
        : [];
}