import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import each from 'lodash/each';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { isUpdated } from 'src/js/helpers/dataHelpers';

import { hideModal } from 'src/js/actions/xelacore';
import ProductFormEdit from 'src/js/components/ProductFormEdit/ProductFormEdit';
import successHandler from 'src/js/actions/successHandler';
import errorHandler from 'src/js/actions/errorHandler';
import { getProductInfo } from 'src/js/actions/dataManager';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';
import { buildSearchQuery } from 'src/js/helpers/dataHelpers';
import { queryOpaData } from 'src/js/actions/opa';
import {
    getCategoryAttributes
} from 'src/js/actions/categories';

import {
    fetchBrandAgencies
} from 'src/js/apicalls/other/categories';

class ProductModal extends Component {
    constructor(props) {
        super(props);
        const { rowData: { record_id } = {} } = this.props;
        this.state = {
            sent2Update: false,
            record: {},
            loading: true,
            resending: false,
            recordId: record_id,
            categoryAttributes: [],
            agenciesData: []
        };
    }

    componentDidMount() {
        const { recordId } = this.state;
        this.fetchProduct(recordId);
        this.fetchCategoryAttributes();
    }

    fetchAgencies(data) {
        if(!!data.licensor_brand_id) {
            return fetchBrandAgencies(data.licensor_brand_id).then(response => {
                this.setState({
                    agenciesData: response && response.data && map(response.data, item => {
                        return {
                            label: item.agentOrganisationName,
                            value: item.agentOrganisationId
                        }
                    })
                })
            })
        }
    }

    fetchCategoryAttributes() {
        const { rowData } = this.props;

        if(!rowData.licensor_organisation_id || !rowData.licensor_category_root_id) {
            return;
        }

        getCategoryAttributes(rowData.licensor_organisation_id, rowData.licensor_category_root_id).then(response => {
            this.setState({
                categoryAttributes: !!response.data && !!response.data.category_attribute_definitions && response.data.category_attribute_definitions
            })
        })
    }

    fetchConfirmedInfoConcepts(obj) {
        let clonedData = cloneDeep(obj);

        let query = buildSearchQuery(!!obj && obj.concept_code_confirmed_info, obj);

        return queryOpaData(query, obj.licensor_organisation_id, {}).then((response) => {
            each(!!response && response.data, res => {
                let index = !!clonedData && findIndex(clonedData.concept_code_confirmed_info, code => code.xelacore_concept_id === res.xelacoreConceptId);

                if (index > -1) {
                    clonedData.concept_code_confirmed_info[index].concept_code = res.approvalCode;
                }
            });

            this.setState({ record: clonedData });
        });
    }

    fetchProduct(record_id) {
        const { dispatch } = this.props;
        return dispatch(getProductInfo('records', record_id))
            .then((info) => {
                const { record } = info.data;
                const staticAttrs = !!record.static_attributes ? Object.keys(record.static_attributes) : [];

                each(staticAttrs, attr => {
                    record[attr] = record.static_attributes[attr];
                })

                this.setState({ record, loading: false, resending: true });
                this.fetchAgencies(record);
                this.fetchConfirmedInfoConcepts(record);
            })

            .catch((error) => {
                this.setState({ loading: false });
                dispatch(hideModal());
                return errorHandler(dispatch, error);
            });
    }

    setSent = (value) => {
        this.setState({ sent2Update: value, loading: true });
    };

    saveAndRefetch() {
        const { dispatch } = this.props;
        const { recordId } = this.state;

        this.fetchProduct(recordId);
        successHandler(
            dispatch,
            'The changes you have made have been successfully applied.'
        );
        dispatch(hideModal());
    }

    componentDidUpdate() {
        const { sent2Update, notifiedAt } = this.state;

        const messages = get(this, 'props.xelacore.socket.messages', []);
        const recordId = get(this, 'props.rowData.record_id');
        if (
            sent2Update &&
            isUpdated(messages, ['elastic'], notifiedAt, recordId)
        ) {
            this.setState({ notifiedAt: Date.now(), sent2Update: false });
            this.saveAndRefetch();
        }
    }

    showMessage() {
        const { resending } = this.state;
        return (
            <div>
                <div className="u-text-center">
                    <img src={imgPinkSpinner} height="40px" />
                </div>
                <div className="u-text-center">{`${
                    resending ? 'Updating' : 'Fetching'
                } data...`}</div>
            </div>
        );
    }

    render() {
        const { dispatch, xelacore, ipLevels, ips } = this.props;
        const { record, loading, categoryAttributes, agenciesData } = this.state;

        return (
            <Fragment>
                {loading && this.showMessage()}
                {!loading && (
                    <div className="c-modal--full-edit-product">
                        <ProductFormEdit
                            isModalView={true}
                            data={record}
                            save={() => this.saveAndRefetch()}
                            close={() => dispatch(hideModal())}
                            isModal // Used for filtering out 'concept code'
                            setSent={(value) => this.setSent(value)}
                            xelacore={xelacore}
                            categoryAttributes={categoryAttributes}
                            ipLevels={ipLevels}
                            ips={ips}
                            agenciesData={agenciesData}
                        />
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = ({ xelacore }) => ({ xelacore });
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
