import {
    SET_INGESTIONS,
    REMOVE_INGESTIONS,
    UPDATE_INGESTIONS,
    REMOVE_INGESTION
} from 'src/js/constants/actions/xelacore';

const initialState = {
    byCreated: []
}

export function ingestions(state = initialState, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_INGESTIONS:
            const { ingestions = {} } = payload;
            return { ...state, ...ingestions };
        case UPDATE_INGESTIONS: {
            /** Used with v3 websocket */

            let byCreated = state.byCreated.slice()
            let shouldSort = false;

            payload.forEach(ingestion => {
                const idx = state.byCreated.findIndex(o => o.ingestionId == ingestion.ingestionId)
                if (idx !== -1) {
                    byCreated[idx] = ingestion
                } else {
                    byCreated.push(ingestion)
                    shouldSort = true
                }
            })

            if (shouldSort) {
                byCreated = byCreated.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
            }

            return {
                ...state,
                byCreated
            }
        }
        case REMOVE_INGESTION: {
            const idx = state.byCreated.findIndex(o => o.ingestionId == action.payload)
            if (idx == -1) return state;

            return {
                ...state,
                byCreated: [
                    ...state.byCreated.slice(0, idx),
                    ...state.byCreated.slice(idx + 1)
                ]
            }
        }
        case REMOVE_INGESTIONS:
            return { ...payload };
        default:
            return state;
    }
}
