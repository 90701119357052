import React from 'react';
import classnames from 'classnames';
import { Icon } from 'src/js/components/static';
import Contact from './Contact';

export default function SidePanel({ ...props }) {
    const {
        right,
        width = '300px',
        children,
        className,
        close,
        closeBtn,
        closeBtnText,
        contact
    } = props;

    const cx = classnames('c-side-panel', className, {
        'c-side-panel--right': right
    });

    const overlayStyle = `calc(100% - ${width})`;

    return (
        <section className={cx} style={{ width: width }}>
            <div
                className="c-side-panel__overlay"
                onClick={() => close()}
                style={{ width: overlayStyle }}
            />
            <div className="c-side-panel__content">
                {closeBtn && !closeBtnText && (
                    <Icon
                        icon="close"
                        title="close"
                        size={15}
                        onClick={() => close()}
                        className={'c-side-panel__close'}
                    />
                )}
                {closeBtn && closeBtnText && (
                    <span
                        onClick={() => close()}
                        className="c-side-panel__close-block"
                    >
                        <Icon icon="close" title="close" size={15} />
                        {closeBtnText && (
                            <span className="c-side-panel__close-text">
                                close
                            </span>
                        )}
                    </span>
                )}
                {contact && <Contact />}
                {children}
            </div>
        </section>
    );
}
