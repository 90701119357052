import React, {Component} from 'react';
import includes from 'lodash/includes';
import find from 'lodash/find';
import { Icon } from 'src/js/components/static';

export default class extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: props.value || []
        };
    }

    static getDerivedStateFromProps({ value }) {
        return value ? { data: value } : {};
    }

    componentDidMount() {
        this.setState({
            data: this.props.value || []
        });
    }

    formatString(string) {
        return string.replaceAll('_', ' ');
    }

    render() {
        const { data } = this.state;
        const { validations } = this.props;

        return (
            <div className="asin-element">
                {
                    !!data && data.map((el, i) => {
                        let fieldValidation = find(validations, validation => {
                            return !!includes(validation.field, el.attribute_key)
                        });

                        return (
                            <div className={`u-flex c-product__detail-listing-item-main dynamic ${!!fieldValidation && fieldValidation.level}`}
                                 key={`element-${i}`}>
                                <div className="c-product__detail-listing-item-main-box listing-item-title">
                                    <div className="c-product__detail-listing-item-main-box-item">
                                        {el.label || el.attribute_key}:
                                    </div>
                                </div>
                                <div className="c-product__detail-listing-item-main-box">
                                    <div className="c-product__detail-listing-item-main-item">
                                        {el.attribute_value || el.data_values || ''}
                                    </div>

                                    { !!fieldValidation && (
                                        <div className='u-flex u-flex-align'>
                                            <Icon
                                                    icon={
                                                        fieldValidation.level === 'error'
                                                            ? 'error2'
                                                            : 'warning'
                                                    }
                                                    fill={
                                                        fieldValidation.level === 'error'
                                                            ? 'red'
                                                            : 'darkOrange'
                                                    }
                                                    className="u-margin-small-right"
                                                    size="12"
                                                />
                                                <span className="validation-message">
                                                    {this.formatString(fieldValidation.message)}
                                                </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}