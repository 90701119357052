import React from 'react';
import classnames from 'classnames';

const allowedLength = 50;

const displayedValue = (value) => {
    return (value || '').length < allowedLength ? value : value.slice(0, allowedLength) + '...';
};

export default ({ value, bold, isFullText = true }) => (
    <span className={classnames('u-no_transform', {
        'u-bold': bold
    })}>
        <a target="_blank" href={value}>
            {isFullText ? value : displayedValue(value)}
        </a>
    </span>
);
