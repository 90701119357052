import React, { Component } from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import size from 'lodash/size';
import remove from 'lodash/remove';
import each from 'lodash/each';
import map from 'lodash/map';
import includes from 'lodash/includes';
import some from 'lodash/some';
import find from 'lodash/find';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import MediaView from 'src/js/components/MediaView';
import Toggler from 'src/js/components/static/Toggler/Toggler';
import TooltipRegisteredData
    from 'src/js/modules/MyRecords/routes/MyRecords/components/TooltipsData/TooltipRegisteredData';
import { Icon } from 'src/js/components/static';
import Tooltip from 'rc-tooltip';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';

import {
    parsedSets,
    openProduct,
    checkValidations,
    onChange
} from 'src/js/helpers/conflictsHelper';

import {
    errorContentMessage,
    inErrorContent,
    onGtinWaiver
} from 'src/js/components/TableRow/common';

import { getMainImage } from 'src/js/helpers/dataHelpers';

import {
    conflictsList,
    extendedAttrs
} from 'src/js/constants/conflictsConstants';
import BulkTableFooter from './BulkTableFooter';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';

export default class BulkTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalAttr: [],
            hasAdditional: [],
            rowsToUpdate: {},
            isBusy: false,
            tableWidth: 0
        };

        this.tableScroller = React.createRef();
        this.tableRef = React.createRef();
        this.stickyScroller = React.createRef();
        this.wrapperScroller = React.createRef();
        this.headerContainerRef = React.createRef();
    }

    componentDidMount() {
        this.handleHeaderScroll = (e) => {
            this.tableScroller.current.scrollLeft = e.target.scrollLeft;
        };
    }

    componentDidUpdate() {
        this.tableRef.current &&
        this.state.tableWidth !== this.tableRef.current.scrollWidth &&
        this.setState({
            tableWidth: this.tableRef.current.scrollWidth
        });
    }

    /**
     * Update Conflict Sets after actions.
     */
    updateRows = () => {
        const { fetchSets } = this.props;

        this.setState({
            isBusy: true
        });

        let timeoutNumber =
            size(parsedSets(this.state.rowsToUpdate)) > 7 ? 5000 : 3000;

        setTimeout(() => {
            this.setState({
                rowsToUpdate: {},
                isBusy: false
            });

            fetchSets();
        }, timeoutNumber);
    };

    /**
     * Disable row if Confirmed/Registered/In Error
     * @param row
     * @returns {*|boolean}
     */
    disableRow = (row) => {
        return (
            checkValidations(row.validations) ||
            row.conflicts_resolved_local ||
            !!get(row, 'registered_with')
        );
    };

    /**
     * Disable all set if all rows are in disable condition
     * @param set
     * @returns {boolean}
     */
    disableSet = (set) => {
        return (
            size(
                filter(set.rows, (item) => {
                    return !!get(item, 'registered_with');
                })
            ) === size(set.rows)
        );
    };

    /**
     * Filter rows that are not in disable condition
     * @param rows
     * @returns {Array|*}
     */
    filterRows = (rows) => {
        return filter(rows, (row) => {
            return !get(row, 'registered_with');
        });
    };

    /**
     * View More Toggler for each Conflict Set
     * @param index
     */
    toggleExtendedAttrs = (index) => {
        this.state.additionalAttr[index] = !this.state.additionalAttr[index];

        if (this.state.additionalAttr[index]) {
            this.state.hasAdditional.push(index);
        } else {
            remove(this.state.hasAdditional, (item) => {
                return item === index;
            });
        }

        this.forceUpdate();
    };

    allChecked = () => {
        const { rowsToUpdate } = this.state;
        const { conflictSets } = this.props;

        let selectedRows = size(parsedSets(rowsToUpdate));
        let allRows = size(parsedSets(this.parseConflictSets(conflictSets)));

        return !!size(rowsToUpdate) && selectedRows === allRows;
    };

    allConflictSetChecked = (set) => {
        const { rowsToUpdate } = this.state;
        let filteredRows = this.filterRows(set.rows);

        return (
            !!size(rowsToUpdate[set.part_key]) &&
            size(filteredRows) === size(rowsToUpdate[set.part_key])
        );
    };

    selectAllConflictSets = () => {
        const { conflictSets } = this.props;

        if (!this.allChecked()) {
            this.state.rowsToUpdate = {};
        }

        each(conflictSets, (set) => {
            this.markforAction(set, null, true);
        });
    };

    parseConflictSets = (sets) => {
        let parsed = {};

        each(sets, (set) => {
            parsed[set.part_key] = this.filterRows(set.rows);
        });

        return filter(parsed, (s) => {
            return !!size(s);
        });
    };

    markforAction = (set, el, all) => {
        const { conflictSets } = this.props;

        if (!this.state.rowsToUpdate[set.part_key]) {
            this.state.rowsToUpdate[set.part_key] = [];
        }

        if (this.state.rowsToUpdate[set.part_key] && !all) {
            let recordsIds = map(
                this.state.rowsToUpdate[set.part_key],
                'record_id'
            );

            if (includes(recordsIds, el.record_id)) {
                remove(this.state.rowsToUpdate[set.part_key], (item) => {
                    return item.record_id === el.record_id;
                });
            } else {
                this.state.rowsToUpdate[set.part_key].push(el);
            }
        } else if (all) {
            each(conflictSets, (s) => {
                if (set.part_key === s.part_key) {
                    let filteredRows = this.filterRows(s.rows);

                    if (
                        size(this.state.rowsToUpdate[set.part_key]) ===
                        size(filteredRows)
                    ) {
                        this.state.rowsToUpdate[set.part_key] = [];
                    } else {
                        this.state.rowsToUpdate[set.part_key] = filteredRows;
                    }
                }
            });
        }

        this.forceUpdate();
    };

    onScrollHandle = (e, ref) => {
        if (this.delayScroll) {
            clearTimeout(this.delayScroll);
        }
        if (ref === 'table') {
            this.headerContainerRef.current.scrollLeft = e.target.scrollLeft;
        }

        this.delayScroll = setTimeout(() => {
            ref === 'table'
                ? (this.wrapperScroller.current.scrollLeft =
                    this.tableScroller.current.scrollLeft)
                : (this.tableScroller.current.scrollLeft =
                    this.wrapperScroller.current.scrollLeft);
        }, 10);
    };

    onChangeSelect = (e, el, key) => {
        const { linkedBrands } = this.props;
        let value = !!e.target && !!e.target.value ? e.target.value : e;
        let brand = find(linkedBrands, row => row.brand_id === value);

        el[key] = value;
        el.ips[0][0].ip_id = value;
        el.ips[0][0].value = brand.name;
        
        this.forceUpdate();
    }

    render() {
        const {
            additionalAttr,
            hasAdditional,
            rowsToUpdate,
            isBusy
        } = this.state;

        const { conflictSets, cloneData, countdown, loading, linkedBrands } =
            this.props;
        const defaultImage = { imgPlaceholder };

        let filterEmptyRows = filter(rowsToUpdate, (set) => {
            return !!size(set);
        });

        return (
            <div>
                <div
                    className={`c-bulk-conflict--body ${isBusy ? 'busy' : ''}`}
                >
                    <div
                        className={'c-bulk-conflict--header-holder'}
                        onScroll={this.handleHeaderScroll}
                        ref={this.headerContainerRef}
                    >
                        <table className='c-conflicts-popover-body-table '>
                            <thead className='c-conflicts-popover-body-table-header'>
                            <tr>
                                {conflictsList.map((item) => {
                                    if(item.key !== 'ips') {
                                        return (
                                            <th
                                                key={item.key}
                                                className={`${item.key} ${
                                                    !size(hasAdditional) &&
                                                    includes(
                                                        extendedAttrs,
                                                        item.key
                                                    )
                                                        ? 'hidden'
                                                        : ''
                                                }`}
                                            >
                                                {item.key === 'checkbox' && (
                                                    <UiCheckbox
                                                        id={`${item.key}-conflicts-header`}
                                                        name={`${item.key}-conflicts-header`}
                                                        type='checkbox'
                                                        checked={this.allChecked()}
                                                        onChange={() =>
                                                            this.selectAllConflictSets()
                                                        }
                                                        disabled={
                                                            !size(
                                                                parsedSets(
                                                                    this.parseConflictSets(
                                                                        conflictSets
                                                                    )
                                                                )
                                                            )
                                                        }>
                                                    </UiCheckbox>
                                                )}
    
                                                {item.key !== 'checkbox' && (
                                                    <span>{item.label}</span>
                                                )}
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div
                        className='c-bulk-conflict-table'
                        ref={this.tableScroller}
                        onScroll={(e) => this.onScrollHandle(e, 'table')}
                    >
                        {conflictSets.map((set, i) => {
                            return (
                                <div key={set.part_key} className='body'>
                                    <div className='u-flex-align actions-row'>
                                        <UiCheckbox
                                            id={`${set.part_key}-conflicts`}
                                            name={`${set.part_key}-conflicts`}
                                            type='checkbox'
                                            checked={this.allConflictSetChecked(
                                                set
                                            )}
                                            onChange={() =>
                                                this.markforAction(
                                                    set,
                                                    null,
                                                    true
                                                )
                                            }
                                            disabled={this.disableSet(set)}>
                                        </UiCheckbox>

                                        <div className='conflict-set u-flex-align'>
                                            <span className='conflict-set-title'>
                                                Conflict Set {i + 1}
                                            </span>

                                            <Toggler
                                                onToggle={() =>
                                                    this.toggleExtendedAttrs(
                                                        set.part_key
                                                    )
                                                }
                                                value={
                                                    additionalAttr[set.part_key]
                                                }
                                                rightTitle={'View more'}
                                            />
                                        </div>
                                    </div>

                                    <table
                                        ref={this.tableRef}
                                        className='c-conflicts-popover-body-table'
                                    >
                                        <tbody className='c-conflicts-popover-body-table-body'>
                                        {set.rows.map((el) => {
                                            const image = getMainImage(
                                                el.images
                                            );
                                            const key = `${el.record_id}-${el.version_id}`;

                                            let validations = [];

                                            each(el.validations, (item) => {
                                                validations[item.field] =
                                                    item;
                                            });

                                            return (
                                                <tr key={key} className=''>
                                                    {conflictsList.map(
                                                        (item) => {
                                                            if(item.key !== 'ips') {
                                                                return (
                                                                    <td
                                                                        key={
                                                                            item.key
                                                                        }
                                                                        className={`c-rec-table__cell ${item.key}`}
                                                                    >
                                                                        <div>
                                                                            {item.key ===
                                                                                'checkbox' && (
                                                                                    <div className='u-flex-align'>
                                                                                        <UiCheckbox
                                                                                            id={`${key}-conflicts`}
                                                                                            name={`${key}-conflicts`}
                                                                                            checked={some(
                                                                                                rowsToUpdate &&
                                                                                                rowsToUpdate[
                                                                                                    set
                                                                                                        .part_key
                                                                                                    ],
                                                                                                (
                                                                                                    s
                                                                                                ) => {
                                                                                                    return (
                                                                                                        s.record_id ===
                                                                                                        el.record_id
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                            onChange={() =>
                                                                                                this.markforAction(
                                                                                                    set,
                                                                                                    el
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                !!get(
                                                                                                    el,
                                                                                                    'registered_with'
                                                                                                )
                                                                                            }
                                                                                            type='checkbox'>
                                                                                        </UiCheckbox>
    
                                                                                        <div
                                                                                            className='info-labels u-flex-column'>
                                                                                            {!this.disableRow(
                                                                                                el
                                                                                            ) && (
                                                                                                <span
                                                                                                    className='confirmed-label not-confirmed'>
                                                                                                    Not
                                                                                                    Confirmed
                                                                                                </span>
                                                                                            )}
    
                                                                                            {!!get(
                                                                                                el,
                                                                                                'registered_with'
                                                                                            ) && (
                                                                                                <span
                                                                                                    className='confirmed-label registered'>
                                                                                                    Registered
                                                                                                </span>
                                                                                            )}
    
                                                                                            {el.conflicts_resolved_local &&
                                                                                                !get(
                                                                                                    el,
                                                                                                    'registered_with'
                                                                                                ) && (
                                                                                                    <span
                                                                                                        className='confirmed-label'>
                                                                                                        Confirmed
                                                                                                    </span>
                                                                                                )}
    
                                                                                            {checkValidations(
                                                                                                el.validations
                                                                                            ) && (
                                                                                                <Tooltip
                                                                                                    placement='right'
                                                                                                    align={{
                                                                                                        offset: [
                                                                                                            0,
                                                                                                            12
                                                                                                        ]
                                                                                                    }}
                                                                                                    overlay={() =>
                                                                                                        inErrorContent()
                                                                                                    }
                                                                                                    trigger={[
                                                                                                        'hover'
                                                                                                    ]}
                                                                                                >
                                                                                                    <div>
                                                                                                        <span
                                                                                                            className='confirmed-label error'
                                                                                                            onClick={() =>
                                                                                                                openProduct(
                                                                                                                    el.record_id
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            In
                                                                                                            error
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
    
                                                                            {item.key ===
                                                                                'registered' && (
                                                                                    <div className='u-flex'>
                                                                                        <TooltipRegisteredData
                                                                                            data={
                                                                                                el
                                                                                            }
                                                                                            getRegisteredLink={() =>
                                                                                                openProduct(
                                                                                                    el.record_id
                                                                                                )
                                                                                            }
                                                                                        />
    
                                                                                        {el.gtin_waiver && (
                                                                                            <Tooltip
                                                                                                placement='right'
                                                                                                align={{
                                                                                                    offset: [
                                                                                                        0,
                                                                                                        12
                                                                                                    ]
                                                                                                }}
                                                                                                overlay={() =>
                                                                                                    onGtinWaiver()
                                                                                                }
                                                                                                trigger={[
                                                                                                    'hover'
                                                                                                ]}
                                                                                            >
                                                                                                <div>
                                                                                                    <span
                                                                                                        className='gtin-icon'></span>
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </div>
                                                                                )}
    
                                                                            {item.key ===
                                                                                'image' && (
                                                                                    <div
                                                                                        className='c-rec-table__image-icon'>
                                                                                        <MediaView
                                                                                            src={
                                                                                                image
                                                                                            }
                                                                                            alt={
                                                                                                el.product_name
                                                                                            }
                                                                                            fallbackSrc={
                                                                                                fallbackImageSmall
                                                                                            }
                                                                                            useImgTag
                                                                                            onClick={() =>
                                                                                                openProduct(
                                                                                                    el.record_id
                                                                                                )
                                                                                            }
                                                                                            className='u_pointer'
                                                                                        />
                                                                                        {image !==
                                                                                            defaultImage && (
                                                                                                <MediaView
                                                                                                    src={
                                                                                                        image
                                                                                                    }
                                                                                                    className='c-rec-table__hover-image u_pointer'
                                                                                                    fallbackSrc={
                                                                                                        fallbackImageSmall
                                                                                                    }
                                                                                                    useImgTag
                                                                                                    onClick={() =>
                                                                                                        openProduct(
                                                                                                            el.record_id
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                    </div>
                                                                                )}
    
                                                                            {item.key !==
                                                                                'checkbox' &&
                                                                                item.key !==
                                                                                'image' &&
                                                                                item.key !==
                                                                                'licensor_brand_id' &&
                                                                                item.key !==
                                                                                'ips' &&
                                                                                item.key !==
                                                                                'registered' && (
                                                                                    <div>
                                                                                        <FormItemWrapper
                                                                                            type='text'
                                                                                            validations={validations}
                                                                                            maxLength={
                                                                                                item.key === 'product_name'
                                                                                                    ? '255'
                                                                                                    : ''
                                                                                            }
                                                                                            disabled={
                                                                                                (!additionalAttr[
                                                                                                        set
                                                                                                            .part_key
                                                                                                        ] &&
                                                                                                    includes(
                                                                                                        extendedAttrs,
                                                                                                        item.key
                                                                                                    )) ||
                                                                                                this.disableRow(
                                                                                                    el
                                                                                                ) ||
                                                                                                (item.key ===
                                                                                                    'gtin' &&
                                                                                                    el.gtin_waiver)
                                                                                            }
                                                                                            placeholder='Type value'
                                                                                            defaultValue={
                                                                                                !!size(
                                                                                                    el[
                                                                                                        item
                                                                                                            .key
                                                                                                        ]
                                                                                                )
                                                                                                    ? el[
                                                                                                        item
                                                                                                            .key
                                                                                                        ]
                                                                                                    : ''
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                onChange(
                                                                                                    e,
                                                                                                    el,
                                                                                                    item.key
                                                                                                )
                                                                                            }
                                                                                        />
    
    
                                                                                        {!!size(
                                                                                            validations[
                                                                                                item
                                                                                                    .key
                                                                                                ]
                                                                                        ) && (
                                                                                            <Tooltip
                                                                                                placement='right'
                                                                                                align={{
                                                                                                    offset: [
                                                                                                        0,
                                                                                                        20
                                                                                                    ]
                                                                                                }}
                                                                                                overlay={() => errorContentMessage(
                                                                                                    validations[
                                                                                                        item
                                                                                                            .key
                                                                                                        ]
                                                                                                        .message
                                                                                                )}
                                                                                                trigger={[
                                                                                                    'hover'
                                                                                                ]}
                                                                                            >
                                                                                                <span className='tooltip-icon-wrapper'>
                                                                                                    <Icon
                                                                                                        className='error-icon'
                                                                                                        icon='INFO_BUBBLE'
                                                                                                        fill='red'
                                                                                                        size='12'
                                                                                                        tooltip
                                                                                                    />
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </div>
                                                                                )}
    
                                                                            {item.key ===
                                                                                'licensor_brand_id' &&
                                                                                linkedBrands && (
                                                                                    <div
                                                                                        className={`c-form-element ${
                                                                                            item.key
                                                                                        }
                                                                                                    ${
                                                                                            validations[
                                                                                                item
                                                                                                    .key
                                                                                                ]
                                                                                                ? 'error'
                                                                                                : ''
                                                                                        }
                                                                                                    ${
                                                                                            !additionalAttr[
                                                                                                set
                                                                                                    .part_key
                                                                                                ] &&
                                                                                            includes(
                                                                                                extendedAttrs,
                                                                                                item.key
                                                                                            )
                                                                                                ? 'hidden'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
    
                                                                                        <FormItemWrapper
                                                                                            type={'select'}
                                                                                            disabled={
                                                                                                (!additionalAttr[
                                                                                                        set
                                                                                                            .part_key
                                                                                                        ] &&
                                                                                                    includes(
                                                                                                        extendedAttrs,
                                                                                                        item.key
                                                                                                    )) ||
                                                                                                this.disableRow(
                                                                                                    el
                                                                                                )
                                                                                            }
                                                                                            disabledElement={
                                                                                                (!additionalAttr[
                                                                                                        set
                                                                                                            .part_key
                                                                                                        ] &&
                                                                                                    includes(
                                                                                                        extendedAttrs,
                                                                                                        item.key
                                                                                                    )) ||
                                                                                                this.disableRow(
                                                                                                    el
                                                                                                )
                                                                                            }
                                                                                            value={el[item.key] || ''}
                                                                                            onChange={(e) => this.onChangeSelect(e, el, item.key)}
                                                                                            values={
                                                                                                linkedBrands.map(
                                                                                                    ({
                                                                                                         brand_id,
                                                                                                         brand
                                                                                                     }) => {
                                                                                                        return {
                                                                                                            value: brand_id,
                                                                                                            label: brand
                                                                                                        };
                                                                                                    }
                                                                                                )
                                                                                            } />
    
                                                                                        {!!size(
                                                                                            validations[
                                                                                                item
                                                                                                    .key
                                                                                                ]
                                                                                        ) && (
                                                                                            <Tooltip
                                                                                                placement='right'
                                                                                                align={{
                                                                                                    offset: [
                                                                                                        0,
                                                                                                        20
                                                                                                    ]
                                                                                                }}
                                                                                                overlay={errorContentMessage(
                                                                                                    validations[
                                                                                                        item
                                                                                                            .key
                                                                                                        ]
                                                                                                        .message
                                                                                                )}
                                                                                                trigger={[
                                                                                                    'hover'
                                                                                                ]}
                                                                                            >
                                                                                                <span className='tooltip-icon-wrapper'>
                                                                                                    <Icon
                                                                                                        className='error-icon'
                                                                                                        icon='info_bubble'
                                                                                                        fill='red'
                                                                                                        size='12'
                                                                                                        tooltip
                                                                                                    />
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </td>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        })}

                        {!size(conflictSets) && !loading && (
                            <div className='c-bulk-conflict-empty-placeholder'>
                                <h4>No Records in Conflicts</h4>
                                <p>
                                    You will be redirected to My Records in{' '}
                                    <strong>{countdown}</strong> seconds
                                </p>
                            </div>
                        )}

                        {!size(conflictSets) && loading && (
                            <div className='c-bulk-conflict-empty-placeholder'>
                                <div className='loading'></div>
                                <div className='c-spinner'>
                                    <div className='c-spinner-icon'>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <span className='c-spinner-label'>
                                        Please wait...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* <div
                        className='sticky-wrapper'
                        ref={this.wrapperScroller}
                        onScroll={() => this.onScrollHandle()}
                    >
                        <div
                            className='sticky-scroll'
                            ref={this.stickyScroller}
                            style={{ width: tableWidth }}
                        ></div>
                    </div> */}
                </div>

                <div className='c-bulk-conflict--footer'>
                    <BulkTableFooter
                        conflictSets={conflictSets}
                        isBusy={isBusy}
                        rowsToUpdate={filterEmptyRows}
                        fetchSets={() => this.updateRows()}
                        cloneData={this.parseConflictSets(cloneData)}
                    />
                </div>
            </div>
        );
    }
}
