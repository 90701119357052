import React from 'react';

import imgLogo from 'statics/imgs/app/xelacore-logo.svg';

const TechnicalDifficulties = () => (
    <div className="c-maintenance">
        <img
            src={imgLogo}
            alt="Xelacore"
            className="c-maintenance__logo"
            height="50"
        />
        <div className="c-maintenance__body">
            <h1 className="c-maintenance__header">
                We are currently experiencing <br /> technical difficulties
            </h1>
            <p>
                <strong>
                    We are working to fix it and will return to normal as soon
                    as possible.
                </strong>
            </p>
            <p>
                <strong>
                    For urgent enquiries, please email us at{' '}
                    <a href="mailto:info@fabacus.com">info@fabacus.com</a>
                </strong>
            </p>
        </div>
    </div>
);

export default TechnicalDifficulties;
