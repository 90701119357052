import { permConstants } from 'src/js/constants/permissions';

/**
 * Checks whether a user has the given permissions.
 * @param {Object} role The current user role - object of cans and cannots
 * @param {String[]} permissions The permission to check
 */

export const checkPerms = (role, permissions) => {
    if (!role) return false;

    let variations = [];
    permissions = []
        .concat(permissions)
        .map(el => permConstants[el])
        .filter(i => i !== undefined);

    for (let perm of permissions) {
        variations = variations.concat(_createVariations(perm));
    }

    let roleCan = false;

    if (role.can.length > 0) {
        for (let variation of variations) {
            if (role.can.indexOf(variation) >= 0) {
                roleCan = true;
                break;
            }
        }
    }

    if (role.cannot.length > 0) {
        for (let variation of variations) {
            if (role.cannot.indexOf(variation) >= 0) {
                roleCan = false;
                break;
            }
        }
    }

    return roleCan;
};

const _createVariations = string => {
    //creates an array of all the permission sets allowed to do this
    let parts = string.split(':');
    let start = '';
    let output = ['*', string];
    for (let part of parts) {
        output.push(start + part + ':*');
        start += part + ':';
    }
    return output;
};

export const checkPermissions = (userPermissionsObject, permission) => {
    // This is used for route level permisisons as well as component level
    const names = permission.split('.');
    if (names.length <= 1) return !!userPermissionsObject[names[0]];
    return (
        ((userPermissionsObject[names[0]] || {}).can || []).indexOf(
            names[1]
        ) !== -1
    );
};

export const isLicensee = companyData => {
    return companyData ? companyData.type === 'licensee' : false;
};

export const isLicensor = companyData => {
    return companyData ? companyData.type === 'licensor' : false;
};

export const isAgent = companyData => {
    return companyData ? companyData.type === 'agent' : false;
}

export const checkAccountTypePermission = (companyData, restrictTo) => {
    // Checks the user type and that type matches the account type permissions passed to it
    const isUserLicensor = isLicensor(companyData);
    const isUserAgent = isAgent(companyData);

    return (
        (restrictTo === 'licensor' && isUserLicensor && !isUserAgent) ||
        (restrictTo === 'licensee' && !isUserLicensor && !isUserAgent) ||
        (restrictTo === 'agent' && !isUserLicensor && isUserAgent)
    );
};

export const checkPermissionLevels = (
    userPermissionsObject,
    permission,
    level
) =>
    // User already has been checked to see if they have permission so we don't check that again here
    // See if they have the right level of access to it
    userPermissionsObject[permission.split('.')[0]].can.some(
        el => el === level
    );
