import React, {Component} from 'react';

export default class AsinArray extends Component {
    state = {
        data: []
    };

    componentDidMount() {
        this.setState({
            data: this.props.value || []
        });
    }

    render() {
        const {data} = this.state;

        return (
            <div className="asin-element">
                {
                    data.map((el, i) => {
                        return (
                            <div className="u-flex c-product__detail-listing-item-main-asin"
                                 key={`element-${i}`}>
                                <div className="c-product__detail-listing-item-main-asin-asin-box">
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">Source/Information Provider</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">Parent ASIN</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">Child ASIN</div>
                                </div>
                                <div className="c-product__detail-listing-item-main-asin-asin-box u-bold">
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{el.source || '- N/A -'}</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{el.parent || '- N/A -'}</div>
                                    <div className="c-product__detail-listing-item-main-asin-asin-box-item">{el.child || '- N/A -'}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}