export const TOGGLE_LICENSEES_PANEL =
    'LICENSOR_DASHBOARD::TOGGLE_LICENSEES_PANEL';
export const SET_DASHBOARD_DATA = 'LICENSOR_DASHBOARD::SET_DASHBOARD_DATA';
export const SET_DASHBOARD_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_DASHBOARD_DATA_LOADING';
export const TOGGLE_FILTERS_PANEL = 'LICENSOR_DASHBOARD::TOGGLE_FILTERS_PANEL';
export const SET_ALL_FILTER_OPTIONS_SELECTED =
    'LICENSOR_DASHBOARD::SET_ALL_FILTER_OPTIONS_SELECTED';
export const SET_FILTERS_OPTIONS_LOADING =
    'LICENSOR_DASHBOARD::SET_FILTERS_OPTIONS_LOADING';
export const TOGGLE_SELECTED_FILTER_OPTION =
    'LICENSOR_DASHBOARD::TOGGLE_SELECTED_FILTER_OPTION';
export const TOGGLE_SINGLE_LOCAL_FILTER_OPTION =
    'LICENSOR_DASHBOARD::TOGGLE_SINGLE_LOCAL_FILTER_OPTION';
export const TOGGLE_LOCAL_FILTER_OPTION =
    'LICENSOR_DASHBOARD::TOGGLE_LOCAL_FILTER_OPTION';
export const CLEAR_ALL_FILTERS = 'LICENSOR_DASHBOARD::CLEAR_ALL_FILTERS';
export const SET_ALL_LOCAL_FILTER_VALUES_FOR_SECTION =
    'LICENSOR_DASHBOARD::SET_ALL_LOCAL_FILTER_VALUES_FOR_SECTION';
export const SET_FILTERS_OPTIONS = 'LICENSOR_DASHBOARD::SET_FILTERS_OPTIONS';
export const SET_UNSUBMITTED_CATALOGUES_DATA =
    'LICENSOR_DASHBOARD::SET_UNSUBMITTED_CATALOGUES_DATA';
export const SET_UNSUBMITTED_CATALOGUES_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_UNSUBMITTED_CATALOGUES_DATA_LOADING';
export const SET_LICENSEES_DATA = 'LICENSOR_DASHBOARD::SET_LICENSEES_DATA';
export const SET_LICENSEE_RISK_ASSESSMENT_TABLE_FILTERS =
    'LICENSOR_DASHBOARD::SET_LICENSEE_RISK_ASSESSMENT_TABLE_FILTERS';
export const SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA_LOADING';
export const SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA =
    'LICENSOR_DASHBOARD::SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA';
export const SET_LICENSEES_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_LICENSEES_DATA_LOADING';
export const SET_GEOGRAPHICAL_DISTRIBUTION_OF_BRANDS_DATA =
    'LICENSOR_DASHBOARD::SET_GEOGRAPHICAL_DISTRIBUTION_OF_BRANDS_DATA';
export const SET_CATALOGUE_COMPLETENESS_DATA =
    'LICENSOR_DASHBOARD::SET_CATALOGUE_COMPLETENESS_DATA';
export const SET_CONTRACTED_LICENSEES_DATA =
    'LICENSOR_DASHBOARD::SET_CONTRACTED_LICENSEES_DATA';
export const SET_PROGRAM_SNAPSHOT_DATA =
    'LICENSOR_DASHBOARD::SET_PROGRAM_SNAPSHOT_DATA';
export const SET_PROGRAM_SNAPSHOT_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_PROGRAM_SNAPSHOT_DATA_LOADING';
export const SET_LICENSEE_RISK_ASSESSMENT_DATA =
    'LICENSOR_DASHBOARD::SET_LICENSEE_RISK_ASSESSMENT_DATA';
export const SET_TOTAL_SUBMISSIONS_OVER_TIME_DATA_DATA =
    'LICENSOR_DASHBOARD::SET_TOTAL_SUBMISSIONS_OVER_TIME_DATA_DATA';
export const SET_PRODUCT_SUMMARY_DATA =
    'LICENSOR_DASHBOARD::SET_PRODUCT_SUMMARY_DATA';
export const SET_PRODUCTS_LINKED_TO_CONCEPTS_DATA =
    'LICENSOR_DASHBOARD::SET_PRODUCTS_LINKED_TO_CONCEPTS_DATA';
export const SET_PRODUCTS_WITH_HIGH_RISK_FACTOR_DATA =
    'LICENSOR_DASHBOARD::SET_PRODUCTS_WITH_HIGH_RISK_FACTOR_DATA';
export const SET_PRODUCTS_WITH_HIGH_RISK_FACTOR_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_PRODUCTS_WITH_HIGH_RISK_FACTOR_DATA_LOADING';
export const SET_CONCEPT_SUBMISSIONS_ACTIVITY_DATA =
    'LICENSOR_DASHBOARD::SET_CONCEPT_SUBMISSIONS_ACTIVITY_DATA';
export const SET_CONCEPT_SUBMISSIONS_ACTIVITY_TREND_DATA =
    'LICENSOR_DASHBOARD::SET_CONCEPT_SUBMISSIONS_ACTIVITY_TREND_DATA';
export const SET_TABLE_DATA = 'LICENSOR_DASHBOARD::SET_TABLE_DATA';
export const SET_TABLE_DATA_LAZY_LOADING =
    'LICENSOR_DASHBOARD::SET_TABLE_DATA_LAZY_LOADING';
export const SET_ATHLETES_DATA_LOADING =
    'LICENSOR_DASHBOARD::SET_ATHLETES_DATA_LOADING';
export const SET_ATHLETES_DATA = 'LICENSOR_DASHBOARD::SET_ATHLETES_DATA';
export const SET_SELECTED_SECTION_FILTER_OPTION =
    'LICENSOR_DASHBOARD::SET_SELECTED_SECTION_FILTER_OPTION';
export const SET_CURRENT_CONCEPT_ANALYTICS_CHART =
    'LICENSOR_DASHBOARD::SET_CURRENT_CONCEPT_ANALYTICS_CHART';
