import {
    fetchPreviousBatches,
    forceGenerateBatch,
    forceDownloadBatch,
    checkBatchProgress
} from 'src/js/apicalls/redemption/redemption';

export function getPreviousBatches(gtin, targetMarket, language) {
    return () => {
        if (!gtin) return Promise.reject('No GTIN found, illegal action');
        if (!targetMarket)
            return Promise.reject('No targetMarket found, illegal action');
        if (!language)
            return Promise.reject('No language found, illegal action');

        return fetchPreviousBatches(gtin, targetMarket, language);
    };
}

export function generateBatch(gtin, targetMarket, language, count) {
    return () => {
        if (!gtin) return Promise.reject('No GTIN found, illegal action');
        if (!targetMarket)
            return Promise.reject('No targetMarket found, illegal action');
        if (!language)
            return Promise.reject('No language found, illegal action');
        if (!count) return Promise.reject('No count found, illegal action');

        return forceGenerateBatch(gtin, targetMarket, language, count);
    };
}

export function downloadBatch(batchId, name) {
    return () => {
        if (!batchId) return Promise.reject('No batchId found, illegal action');
        if (!name) return Promise.reject('No name found, illegal action');

        return forceDownloadBatch(batchId, name);
    };
}

export function checkBatch(batchId) {
    return () => {
        if (!batchId) return Promise.reject('No batchId found, illegal action');

        return checkBatchProgress(batchId);
    };
}
