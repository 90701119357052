import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { hideModal } from 'src/js/actions/xelacore';
import Button from 'modules/UiKit/components/Button/Button';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';

/*
* In order to dpslay, you need to dispatch an action in Redux.
* Sample below for use in your component:

  import { Modal } from 'src/js/components/static';
  import { showModal } from 'src/js/actions/xelacore';

  displayModal() {
    const {dispatch} = this.props;
    const modalBody = <div>Hello there my lover</div>;
    const modal = <Modal title='Modal Title' body={modalBody} />
    return dispatch(showModal(modal));
  }
*
*/

export default connect(
    state => ({ ...state }),
    dispatch => ({ dispatch })
)(props => <Modal {...props} />);

class Modal extends Component {
    constructor(props) {
        super(props);
        this.escapeKey = this.escapeKey.bind(this);
    }

    componentDidMount() {
        document.body.classList.add('modal-open');
        document.addEventListener('keydown', this.escapeKey, false);
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
        document.removeEventListener('keydown', this.escapeKey, false);
    }

    close() {
        const { dispatch } = this.props;
        dispatch(hideModal());
    }

    escapeKey(event) {
        if (event.keyCode === 27) return this.close();
        return;
    }

    render() {
        const {
            title,
            className,
            alert,
            fixed,
            scrolling,
            xelacore: { modal },
            close,
            body,
            isWide,
            isMedium,
            isSmall,
            isFullScreen,
            isWideFullScreen,
            isWhiteHeader,
            bodyClassName = null
        } = this.props;

        const len = 50;

        const displayedTitle =
            (title || '').length < len
                ? title
                : title.slice(0, len) + '...';

        if (!modal.displayModal) return null;

        const cx = classnames('c-modal', className);
        const fixedCx = classnames('c-modal__box', {
            'c-modal__box--fixed': fixed,
            'c-modal__box--wide': isWide,
            'c-modal__box--full': isFullScreen,
            'c-modal__box--full-wide': isWideFullScreen,
            'c-modal__box--medium': isMedium,
            'c-modal__box--small': isSmall
        });

        const cxBody = classnames('c-modal__body', bodyClassName, {
            'c-modal__body-height': scrolling
        });

        const cxHeader = classnames('c-modal__header', {
            'c-modal__header--white': isWhiteHeader
        });

        const customCloseFunction = () =>
            close !== undefined && typeof close === 'function';
        const customClose = () =>
            customCloseFunction() ? close() : this.close();

        return (
            <div className={cx}>
                <div
                    className="c-modal__overlay"
                    onClick={() => customClose()}
                />

                <div className={fixedCx}>
                    {title && (
                        <div className={cxHeader}>
                            {displayedTitle}

                            <CloseIcon
                                className="c-modal__close-icon"
                                onClick={() => customClose()} />
                        </div>
                    )}
                    <div className={cxBody}>{body}</div>
                    {alert && (
                        <div className="c-modal__footer">
                            <Button
                                type="secondary"
                                size="small"
                                onClick={() => customClose()}>
                                &nbsp;Ok&nbsp;
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
