export const colors = {
    red: 'rgba(255,83,84, 1)',
    purple: 'rgba(176, 91, 255, 1)',
    yellow: 'rgba(255, 192, 91, 1)',
    orange: 'rgba(253, 182, 70, 1)',
    green1: 'rgba(191, 235, 159, 1)',
    green2: 'rgba(122, 189, 144, 1)',
    green3: 'rgba(3, 164, 136, 1)',
    blue: 'rgba(55, 163, 255, 1)',
    blank: 'rgba(0, 0, 0, 0)'
};

export const colors2 = {
    blue: '#456ee6',
    lightBlue: '#58cde6',
    pink: '#ef5d86',
    darkPurple: '#9e4a96',
    darkBlue: '#4e3e92',
    black: '#000000',
    babyBlue: '#52bbe7',
    red: '#ff4646',
    yellow: '#f8b32d'
};

export const iconColors = {
    white: '#ffffff',
    black: '#000000',
    green: '#30b03f',
    coffee: '#b11f1b',
    blue: '#1cc9e4',
    blue2: '#37a3ff',
    blue3: '#5f6cdb',
    purple: '#642359',
    purple2: '#7a4fdb',
    darkPurple: '#4f3773',
    lightPurple: '#92567e',
    violet: '#941dc7',
    orange: '#f95223',
    lightOrange: '#ffa14d',
    darkOrange: '#ff6026',
    red: '#db3222',
    pink: '#ee3c87',
    turquoise: '#11aeb4',
    cyan: '#58c5c8',
    grey0: '#f9f9f9',
    grey1: '#ececec',
    grey2: '#f8f8f8',
    grey3: '#d8d8d8',
    grey4: '#a4a4a6',
    grey5: '#787878',
    grey6: '#586179',
    grey7: '#4e566c',
    grey8: '#3f4657',
    grey9: '#464c5f',
    grey10: '#4c4d58',
    grey11: '#3b3f48',
    grey12: '#909090',
    grey13: '#909090',
    grey14: '#92a6b7',
    grey15: '#d5d5d5',
    grey16: '#454545'
};
