import React from 'react';
import { pluralise } from 'src/js/helpers/strings';
import { Modal } from 'src/js/components/static';

import Button from 'modules/UiKit/components/Button/Button';

export default function RegisterOutputModal({
    hideModal,
    selectedRows
}) {
    const totalCount = selectedRows.length;
    const selectedLicensor = !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}

    const modalBody = (
        <div className="u-full-width">
            <div className="u-color-black u-padding-bottom">
                You have submitted {totalCount} Product{' '}
                {pluralise('Record', totalCount)} for Registration
                <br />
                with
                <span className="u-color-blue">
                    {' ' + selectedLicensor.licensor_organisation_name}.
                </span>
            </div>

            <div className="u-flex-align u-flex-align--right u-flex-gap">
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => hideModal()}>
                        Close
                </Button>

                <Button
                    type="primary"
                    size="small"
                    to="/product-catalogue" onClick={() => hideModal()}>
                    Go to Product Catalogue
                </Button>
            </div>
        </div>
    );

    return <Modal centered title="Register Products" body={modalBody} isSmall/>;
}
