import React from 'react';
import md5 from 'md5';
import moment from 'moment';

import { FloatingMenu, Icon } from 'src/js/components/static';
import { size } from 'lodash';
import { getDownloadUrl, downloadFromUrl, getRejectedRecordsDownloadUrl } from './download';

export default props => {
    const { upload } = props;
    const {
        label,
        recordsSubmitted,
        recordsRejected,
        recordsSavedEs,
        recordsSavedPg,
        sourceInfo,
        ingestionId,
        createdAt,
        userName,
        userEmail,
        import: imported = {}
    } = upload;

    const ingestInfo = {
        submitted: recordsSubmitted,
        accepted: recordsSavedPg,
        rejected: recordsRejected,
        date: moment(createdAt).format('HH:mm Do MMM')
    };
    const showRejectedDownload = !!(ingestionId && imported && recordsRejected);

    return (
        <li className="c-upload-history__list-item">
            <div className="c-upload-history__file-upload-info">
                <FloatingMenu
                    top={20}
                    right
                    upload
                    customButton={<Icon icon="dots" width="15" height="15" />}
                    className="c-upload-history__download"
                >
                    <li onClick={
                        () => getDownloadUrl('private', sourceInfo.category, sourceInfo.filename)
                            .then(url => downloadFromUrl(url))}
                    >
                        Download File
                    </li>

                    {showRejectedDownload && (
                        <li onClick={
                            () =>
                                getRejectedRecordsDownloadUrl(ingestionId)
                                    .then(url => downloadFromUrl(url))
                        }
                        >
                            Download Rejected Records
                        </li>
                    )}
                </FloatingMenu>

                <img
                    className="c-upload-history__uploader"
                    src={`//www.gravatar.com/avatar/${md5(
                        userEmail || ''
                    )}?s=50&d=identicon`}
                    alt="avatar"
                    width="30"
                />

                <div className="c-upload-history__text u-clickable">
                    <div>
                        {label}
                    </div>

                    <div className="link-color">
                        <span className="u-bolder ">Uploaded by: </span>
                        {!!size(userName) && userName || !!size(userEmail) && userEmail || ''}
                    </div>
                </div>
            </div>
            <div className="c-upload-history__info-footer">
                <ul>
                    <li>
                        <b>{ingestInfo.date}</b>
                    </li>

                    <li>
                        Submitted: <b>{ingestInfo.submitted}</b>
                    </li>

                    <li className={`${recordsSavedEs !== recordsSavedPg ? 'u-color-warning' : 'u-color-green'}`}>
                        Accepted: <b>{ingestInfo.accepted}</b>
                    </li>

                    <li className="u-color-red">
                        Rejected: <b>{ingestInfo.rejected}</b>
                    </li>
                </ul>
            </div>
        </li>
    );
};
