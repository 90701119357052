import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import classNames from 'classnames';
import { Icon } from 'components/static';
import BulkLinkConceptsProductGroupList from './components/GoupLists';
import BulkLinkConceptsProductPreview from './components/ProductItemPreview';
import UiButton from 'modules/UiKit/components/Button/Button';
import {
    hideModal
} from 'src/js/actions/xelacore';


class BulkLinkConceptsProductSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedConcepts: props.selectedConcepts
        };
    }

    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    singleProductCOnceptCodes(list) {
        return map(list, item => item.concept_code);
    }

    getFilteredConcept(concepts, codes) {
        return concepts.filter(concept => codes.includes(concept.xelacore_concept_id));
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    clearAll() {
        const { dispatch, data, resetConceptsRows } = this.props;

        resetConceptsRows(flatMap(data, 'list'))
        dispatch(hideModal());
    }

    render() {
        const {
            currentProductItemPreview,
            data,
            currentImageProductItemPreview,
            currentProductItemGroupPreview,
            selectedProductGroupIndex,
            checkProductGroup,
            productPreview,
            removeProductFromList,
            getCurrentImageIndex,
            goToImageArrow,
            selectProductImage,
            filterProductList,
            goBackToProductList,
            singleProductData,
            filteredConceptItems,
            openConceptDetails,
            groupedHeaders,
            xelacore,
            dispatch,
            ipLevels
        } = this.props;

        return (
            <div className={'bulk-product-list-wrapper'}>
                <div
                    className={classNames(
                        'sidebar-header sidebar-header-product u-flex u-flex-align',
                        `${!!currentProductItemPreview ? 'preview-product' : ''}`,
                        `${!!singleProductData ? 'single-preview-product' : ''}`
                    )}
                >
                    {!currentProductItemPreview && (
                        <h2> Selected Products </h2>
                    )}

                    {(currentProductItemPreview && !singleProductData) && (

                        <h2
                            className={'c-pointer'}
                            onClick={() => {

                                goBackToProductList();

                            }
                            }
                        >
                            <Icon
                                fill="black"
                                className={'marg-r-5'}
                                icon="arrow_left_style_2"
                                size="14"
                            />
                            <span>Selected Products</span>

                        </h2>
                    )}

                    {!singleProductData  && (
                        <UiButton
                            className="u-margin-half-left"
                            type="secondary"
                            size="small"
                            onClick={() => this.clearAll()}
                        >
                            Clear
                        </UiButton>
                    )}
                </div>

                {!currentProductItemPreview && (
                    <BulkLinkConceptsProductGroupList
                        data={data}
                        selectedProductGroupIndex={selectedProductGroupIndex}
                        checkProductGroup={checkProductGroup}
                        productPreview={productPreview}
                        removeProductFromList={removeProductFromList}
                        singleProductData={singleProductData}>
                    </BulkLinkConceptsProductGroupList>
                )                }

                {!!currentProductItemPreview &&(
                    <BulkLinkConceptsProductPreview
                        currentProductItemPreview={currentProductItemPreview}
                        data={data}
                        currentImageProductItemPreview={currentImageProductItemPreview}
                        currentProductItemGroupPreview={currentProductItemGroupPreview}
                        selectedProductGroupIndex={selectedProductGroupIndex}
                        groupedHeaders={groupedHeaders}
                        checkProductGroup={checkProductGroup}
                        productPreview={productPreview}
                        removeProductFromList={removeProductFromList}
                        getCurrentImageIndex={getCurrentImageIndex}
                        goToImageArrow={goToImageArrow}
                        selectProductImage={selectProductImage}
                        filterProductList={filterProductList}
                        goBackToProductList={goBackToProductList}
                        singleProductData={singleProductData}
                        filteredConceptItems={filteredConceptItems}
                        openConceptDetails={openConceptDetails}
                        xelacore={xelacore}
                        ipLevels={ipLevels}
                        dispatch={dispatch}
                    ></BulkLinkConceptsProductPreview>
                )
}

            </div>

        );
    }
}

export default connect()(BulkLinkConceptsProductSidebar);
