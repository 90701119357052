import {
    SET_CATEGORIES,
    REMOVE_CATEGORIES,
    SET_LINKED_BRANDS,
    SET_BRANDS
} from 'src/js/constants/actions/xelacore';

export function categories(state = {}, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_CATEGORIES:
            const { categories = [] } = payload;
            return { ...state, ...{ list: categories } };
        case SET_LINKED_BRANDS:
            const linkedBrands = payload || [];
            return { ...state, ...{ linkedBrands } };
        case SET_BRANDS:
            const brands = payload || [];
            return { ...state, ...{ brands } };
        case REMOVE_CATEGORIES:
            return { ...payload };
        default:
            return state;
    }
}
