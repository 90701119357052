import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkImageExists } from 'helpers/dataHelpers';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';

class ImageWrapped extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrlState: 'false'
        };
    }

    componentWillReceiveProps() {
        this.checkExistImage();
    }
    componentDidMount() {
        this.checkExistImage();
    }


    checkExistImage (){
        const {
            imageUrl
        } = this.props;

        checkImageExists(imageUrl,()=>{
            this.setState({imageUrlState:imageUrl});
        },()=>{
            this.setState({imageUrlState:imgPlaceholder});
        });
    }


    render() {
        const {
            imageUrlState
        } = this.state;
        const {
            imageName
        } = this.props;


        return (
            <React.Fragment>
                <img
                    src={imageUrlState}
                    alt={imageName}
                />
            </React.Fragment>
        );
    }
}

export default connect()(ImageWrapped);
