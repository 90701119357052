import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';
import CryptoJS from 'crypto-js';

/**
 *
 * @return {Promise}
 */
export function posExport() {
    return axios
        .request({
            url: [config.urls.catalogv3, 'export/pos/xelacore-licensor-pos-export'].join('/'),
            method: 'post'
        })
        .then(responseHandler);
}

/**
 *
 * @return {Promise}
 */
export function getPosExport(params) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'pos-exports'].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params
        })
        .then(responseHandler);
}

export function getFileDownloadUrl(params) {
    try {
        const url = `${config.urls.filesv3}/retrieve`

        return axios({
            method: 'GET',
            url,
            params
        }).then((response) => {
            let data = responseHandler(response, 'data');
            const decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);

            return decrypted.slice(1, -1);
        });
    } catch (error) {
        return error;
    }
}

export function downloadFromUrl(url) {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}