import React from 'react';
import ListFields from '../Listing/ListFields';
import ProductImages from '../ProductImages/ProductImages';

export default props => {
    const { data, type, originalData, dispatch, xelacore } = props;

    return (
        <div className="c-approval-overlay__main-body-left">
            <ProductImages
                data={data}
                productId={originalData.product_id}
                recordId={originalData.record_id}
                updateData={() => null}
                type={type}
                displayConfirmationModal={() => null}
                noEdit={true}
                filesTotalToUpload={[]}
                filesLeftToUploadCount={[]}
                dispatch={dispatch}
            />

            <h4 className="c-approval-overlay__small-header">
                Product Information
            </h4>
            <h4 className="c-approval-overlay__product-header">
                {data.product_name}
            </h4>

            <p className="u-margin-bottom">{data.product_description}</p>

            <ListFields data={data} isLicensor={false} productType={type} dispatch={dispatch} xelacore={xelacore}/>
        </div>
    );
};