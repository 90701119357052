import axios from 'axios';
import { getFilenameWithoutExt } from 'src/js/helpers/objects';
import config from 'config';
import responseHandler from '../common/responseHandler';
import fileDownload from 'src/js/lib/fileDownload';
import compact from 'lodash/compact';
import each from 'lodash/each';
import forEach from 'lodash/forEach';
import {
    countableRange,
    ids,
    searchableFileds,
    actionsFilter
} from 'src/js/constants/advancedFilteringConstants';
import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import CryptoJS from 'crypto-js';


const axiosUpload = axios.create({ headers: { Authorization: '' } });

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {object} data
 * @param  {string} catalogType
 * @return {Promise}
 */
export function fetchEsData(data, catalogType = 'records') {
    return axios
        .request({
            url: [config.urls.catalog, catalogType, 'search', 'filter'].join(
                '/'
            ),
            data,
            method: 'post'
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {string} id
 * @param  {Boolean} isOpa
 * @param  {Boolean} isOpa
 * @return {Promise}
 */
export function fetchPreviousUploads(id = '', licesnorId, isOpa) {
    const filter = isOpa
        ? `?licensorOrganisationId=${licesnorId}&filter[destination]=xelacore-concepts`
        : `?licensorOrganisationId=${licesnorId}&filter[destination]=xelacore-catalog&filter[job_type]=insert`;

    const withId = id ? `${id}?licensorOrganisationId=${licesnorId}` : filter;

    const url = [config.urls.ingestv3, 'status', withId].join('/');
    return axios
        .request({
            url,
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchIngestStats(id, licesnorId) {
    const url = [config.urls.ingestv3, 'status', id, `?licensorOrganisationId=${licesnorId}`].join('/');
    return axios
        .request({
            url,
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {String} ingestId
 * @return {Promise}
 */
export function fetchIngestStatus(ingestId, licensorId) {
    return axios
        .request({
            url: [config.urls.ingestv3, 'status', ingestId, `?licensorOrganisationId=${licensorId}`].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

export function getPresignedUrl(file, repository, category, isOpa, obj) {
    const url = `${config.urls.filesv3}/upload/${repository}/${encodeURIComponent(encodeURIComponent(category))}/?extension=csv`;
    const path = isOpa
        ? 'xelacore-concepts'
        : 'xelacore-catalog';
    let sourceInfo = {};

    return axios
        .request({
            url,
            method: 'get'
        })
        .then((response) => {
            const data = responseHandler(response, 'data');
            var decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);

            return JSON.parse(decrypted);
        })
        .then((presignedUrl) => {
            const { fields, url } = presignedUrl;
            sourceInfo = presignedUrl.sourceInfo;

            return new Promise((resolve, reject) => {
                const data = new FormData();
                forEach(fields, (val, key) => data.append(key, val));
                data.append('file', file);

                return axiosUpload
                    .request({
                        url,
                        method: 'post',
                        data
                    })
                    .then((result) => resolve(result))
                    .catch((err) => reject(err));
            });
        }).then(() => {
            const params = {
                ...obj,
                sourceInfo: {
                    category: 'catalog/ingest',
                    filename: sourceInfo.filename,
                    repositoryId: sourceInfo.repositoryId,
                    organisationId: sourceInfo.organisationId
                }
            };

            try {
                return axios({
                    method: 'post',
                    url: `${config.urls.ingestv3}/data/${path}/file`,
                    data: params
                }).then((response) => {
                    return response && response.data;
                });
            } catch (error) {
                return error;
            }
        });
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {Object} data
 * @param  {Boolean} isOpa
 * @return {Promise}
 */
export function fetchIngestId(data, isOpa) {
    const path = isOpa
        ? 'data/xelacore-concepts/file'
        : 'data/xelacore-catalog/file';
    return axios
        .request({
            url: [config.urls.ingestv3, path].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {Object} data
 * @return {Promise}
 */
export function fetchOpaId(data) {
    return axios
        .request({
            url: [config.urls.ingestv3, 'data/xelacore-concepts/file'].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

function buildFilterObject(el, query) {
    let newQuery = [];

    if (searchableFileds.includes(el)) return query[el];
    if (countableRange.includes(el)) return { range: { [el]: { gte: 1 } } };
    if (ids.includes(el)) return { terms: { _id: query[el] } };

    if (query[el] && query[el].length > 1) {
        each(query[el], (item) => {
            newQuery.push({ term: { [el]: item } });
        });

        return newQuery;
    } else {
        if (el === 'Actions') {
            return actionsFilter;
        }

        return { term: { [el]: query[el][0] } };
    }
}

function buildFilter(query, orCase) {
    if (orCase) return { must: { terms: query } };
    return Object.keys(query).reduce(
        (acc, el) => {
            const must = 'must';
            acc[must].push(buildFilterObject(el, query));
            return acc;
        },
        { must: [], must_not: [] }
    );
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {String} collection
 * @param  {Object} [query]
 * @param  {Object} pagination
 * @param  {String} orCase
 * @param  {Object} sort
 * @return {Promise}
 */
export function fetchProductInformation(
    collection = 'records',
    query,
    pagination,
    orCase,
    sort,
    categoryLevels
) {
    const { page_size, page } = pagination;
    const bool = query ? buildFilter(query, orCase, categoryLevels) : { must: [] };
    const defaultSorting =
        collection === 'records'
            ? { updated_at: { order: 'desc' } }
            : { last_registered_at: { order: 'desc' } };
    return axios
        .request({
            url: [config.urls.catalog, collection, 'search', 'filter'].join(
                '/'
            ),
            method: 'POST',
            data: {
                query: {
                    from: page > 1 ? page_size * (page - 1) : 0,
                    size: page_size || PAGE_LIMIT,
                    query: {
                        bool
                    },
                    sort: compact([sort || defaultSorting])
                }
            }
        })
        .then((response) => responseHandler(response, null, null));
}

export function fetchAllRecordsID(
    collection = 'records',
    organisation_id='',
    page_size = '300'
) {
    return axios
        .request({
            url: [config.baseUrl+config.urls.catalogv3, 'organisation', organisation_id, collection + `?page_size=${page_size}`].join(
                '/'
            ),
            method: 'POST',
            data: {
                projection: ['record_id']
            }
        })
        .then((response) => {
            return responseHandler(response, null, null);
        });
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {String} id
 * @param  {Object} record
 * @return {Promise}
 */
export function editSingleRecord(id, record) {
    return axios
        .request({
            url: [config.urls.catalog, 'records', id].join('/'),
            method: 'patch',
            data: record,
            params: {}
        })
        .then(responseHandler);
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {Array} recordIds
 * @param  {Object} recordBody
 * @return {Promise}
 */
export function bulkEditRecords(recordIds, recordBody) {
    return axios
        .request({
            url: [config.urls.catalog, 'bulk_update', 'records'].join('/'),
            method: 'patch',
            data: {
                record: recordBody,
                records_ids: recordIds
            },
            params: {}
        })
        .then(responseHandler);
}

/**
 * Updates images for provided records
 *
 * @param  {Array} recordIds
 * @param  {Object} imageFiles
 * @return {Promise}
 */
export function postBulkEditRecordImages(recordIds, imageFiles) {
    const formData = new FormData();
    formData.append('type', 'image');
    Array.from(imageFiles)
        .splice(0, 50)
        .forEach((file) => formData.append('images', file));
    recordIds.forEach((record_id) => formData.append('record_ids', record_id));
    return axios
        .request({
            url: [config.urls.catalog, 'images', 'bulkupload_replace'].join(
                '/'
            ),
            method: 'post',
            data: formData,
            params: {}
        })
        .then(responseHandler);
}

/**
 * Registers a product to the product catalog
 *
 * @param  {Array} ids
 */
export function submitProductsToRegister(ids) {
    return axios
        .request({
            url: [config.urls.catalog, 'register'].join('/'),
            method: 'post',
            data: ids
        })
        .then(responseHandler);
}

/**
 * Get a list of duplicates
 *
 * @param  {String} id
 */
export function getDuplicatesToView(id) {
    return axios
        .request({
            url: [config.urls.catalog, 'records', 'duplicates/manage', id].join(
                '/'
            ),
            method: 'get',
            params: {}
        })
        .then(responseHandler);
}

/**
 * Resolves a record with multiple duplicate
 *
 * @param  {Object} data
 * @param  {String} id
 */
export function submitDuplicateToResolve(id, data) {
    return axios
        .request({
            url: [config.urls.catalog, 'records/duplicates/manage', id].join(
                '/'
            ),
            method: 'patch',
            data
        })
        .then(responseHandler);
}

/**
 * Download previously uploaded file
 *
 * @param  {String} fileName
 * @param  {Boolean} isOpa
 */
export function forceDownloadFileUpload(fileName, isOpa) {
    const category = !isOpa ? 'product%2Fingest' : 'concepts%2Fingest';
    return axios
        .request({
            url: [
                config.urls.files,
                'private/retrieve',
                category,
                fileName
            ].join('/'),
            method: 'get'
        })
        .then((response) => {
            // window.location = responseHandler(response, 'data', 'location');
            let data = responseHandler(response, 'data', 'location');
            const decrypted = CryptoJS.AES.decrypt(
                data,
                'process.env.NODE_ENV'
            ).toString(CryptoJS.enc.Utf8);
            var location = decrypted.slice(1, -1);
            window.location = location;
        });
}

/**
 * Download rejected records
 * (currently only one option)
 *
 * @param  {String} ingestId
 * @param  {String} option
 * @param  {String} fileName
 */
export function forceDownloadFileUploadWithOptions(ingestId, option, fileName) {
    return axios
        .request({
            url: [
                config.urls.catalog,
                'ingest',
                ingestId,
                'rejected_records',
                'downloads'
            ].join('/'),
            method: 'get',
            headers: {
                Accept: 'text/csv'
            }
        })
        .then((response) => {
            const data = responseHandler(response);
            const name = getFilenameWithoutExt(fileName);
            return fileDownload(data, `${name}-rejected.csv`);
        });
}

/**
 * Download rejected records from upload
 * @param  {String} ingestId
 * @return {Promise}
 */
export function fetchRejectedRecords(ingestId) {
    return axios
        .request({
            url: [
                config.urls.ingestv3,
                'status',
                ingestId,
                'import/rejected'
            ].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 * @param  {string} orgId
 * @return {Promise}
 */
export function fetchOrgUsers(orgId) {
    return axios
        .request({
            url: [config.urls.auth, 'organisations', orgId, 'employees'].join(
                '/'
            ),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {string} orgId
 * @return {Promise}
 */
export function fetchOrgInformation(orgId) {
    return axios
        .request({
            url: [config.urls.auth, 'organisations', orgId].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {String} newMap
 * @return {Promise}
 */
export function generateNewMap(newMap) {
    return axios
        .request({
            url: [config.urls.ingest, 'maps'].join('/'),
            method: 'post',
            data: newMap
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {String} id
 * @return {Promise}
 */
export function fetchMaps(id = '') {
    return axios
        .request({
            url: [config.urls.ingest, 'maps', id].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {string} mapId
 * @return {Promise}
 */
export function deleteSingleMap(mapId) {
    return axios
        .request({
            url: [config.urls.ingest, 'maps', mapId].join('/'),
            method: 'delete'
        })
        .then(responseHandler);
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {String} productType
 * @param  {String} productId
 * @return {Promise}
 */
export function fetchProductInfo(productType, productId) {
    return axios
        .request({
            url: [config.urls.catalog, productType, productId].join('/'),
            method: 'get',
            params: {}
        })
        .then(responseHandler);
}

/**
 * Gets the counts of different product figures
 *
 * @param  {String} productId
 * @param  {Object} data
 * @return {Promise}
 */
export function updateProductInformation(productId, data) {
    return axios
        .request({
            url: [config.urls.catalog, 'records', productId].join('/'),
            method: 'patch',
            data,
            params: {}
        })
        .then((response) =>
            responseHandler(response, null, null, { checkStatus: true })
        );
}

/**
 * Gets the counts of different product figures
 *
 * @return {Promise}
 */
export function nukeEmAll() {
    return axios
        .request({
            url: [config.urls.catalog, 'nuke'].join('/'),
            method: 'delete',
            params: {}
        })
        .then((r) => r);
}

/**
 * Make batch Update
 * @param  {Object} records
 * @return {Promise}
 */
export function updateBatch(records) {
    return axios
        .request({
            url: [config.urls.catalog, 'batch_update/records'].join('/'),
            method: 'patch',
            params: {},
            data: { records }
        })
        .then(responseHandler);
}

/**
 * This API bulk deleting records
 *
 * @param  {Object} data
 * @return {Promise}
 */
export function deleteRecords(data) {
    return axios
        .request({
            url: [config.urls.catalog, 'records'].join('/'),
            method: 'delete',
            data
        })
        .then(responseHandler);
}


/**
 * This API bulk deleting records
 *
 * @return {Promise}
 */
export function fetchLinkedLicensors(orgId) {
    return axios
        .request({
            url: [config.urls.policy, orgId, 'links'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}


/**
 * This API bulk deleting records
 *
 * @return {Promise}
 */
export function fetchAgentLicensors() {
    return axios
        .request({
            url: ['v3/registration/agent-select-licensor'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchAgentLicensorsCatalog(orgId) {
    return axios
        .request({
            url: [config.urls.catalogv3, `organisation/${orgId}/catalog`].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}


/**
 * Fetch external validations
 *
 * @return {Promise}
 */
export function getExternalValidations(orgId) {
    return axios
        .request({
            url: [config.urls.policyv3, `validations?organisationIds=${orgId}`].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}
