import omit from 'lodash/omit';
import {
    SET_SOCKET_NOTIFICATION,
    DESTROY_SOCKET_NOTIFICATION
} from 'src/js/constants/actions/xelacore';

export function socketNotification(state = {}, action = {}) {
    switch (action.type) {
        case SET_SOCKET_NOTIFICATION:
            const messages = []
                .concat(action.payload)
                .reduce((result, message) => {
                    message.timestamp = Date.now();
                    if (message.records) {
                        const expanded = message.records.map((record_id) =>
                            omit(
                                {
                                    ...message,
                                    record: { record_id }
                                },
                                'records'
                            )
                        );
                        return result.concat(expanded);
                    }
                    return result.concat(message);
                }, []);

            const expiredAt = Date.now() - 100000;
            return {
                ...state,
                messages: (state.messages || [])
                    .filter((m) => m.timestamp > expiredAt)
                    .concat(messages)
            };

        case DESTROY_SOCKET_NOTIFICATION:
            return { ...state };
        default:
            return state;
    }
}
