import {
    SET_USERS_LIST,
    REMOVE_USERS_LIST
} from 'src/js/constants/actions/xelacore';

export function users(state = {}, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_USERS_LIST:
            const {
                records = {},
                fetch = {},
                filterings = {},
                recordsCount = {},
                roles = [],
                licensors = [],
                selectedRows
            } = payload;
            return {
                ...state,
                ...records,
                ...roles,
                ...licensors,
                ...fetch,
                ...filterings,
                ...recordsCount,
                ...selectedRows
            };
        case REMOVE_USERS_LIST:
            return { ...payload };
        default:
            return state;
    }
}
