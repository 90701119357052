import {
    fetchOpaData,
    fetchOpaCounts,
    confirmApprovalCode,
    upsertCodeConcept,
    removeConcept
} from 'src/js/apicalls/concepts/opa';

export function queryOpaData(data, orgId, pageParams) {
    if (!data) return Promise.reject('No data found, illegal action');
    return fetchOpaData(data, orgId, pageParams);
}

export function getOpaCounts() {
    return () => {
        return fetchOpaCounts();
    };
}

export function conceptCodeUpsert(record_id, concept_code, old_concept_code, version_id) {
    if (!record_id) return Promise.reject('No record_id found, illegal action');
    if (!concept_code)
        return Promise.reject('No concept_code found, illegal action');
    if (!old_concept_code)
        return Promise.reject('No old_concept_code found, illegal action');
    if (!version_id)
        return Promise.reject('No version_id found, illegal action');
    return upsertCodeConcept(record_id, concept_code, old_concept_code, version_id);
}

export function confirmConceptApprovalCode(
    record_id,
    concept_code,
    version_id
) {
    if (!record_id) return Promise.reject('No record_id found, illegal action');
    if (!concept_code)
        return Promise.reject('No concept_code found, illegal action');
    if (!version_id)
        return Promise.reject('No version_id found, illegal action');
    return confirmApprovalCode(record_id, concept_code, version_id);
}

export function removeConceptData(record_id, xelacore_concept_id) {
    if (!record_id) return Promise.reject('No record_id found, illegal action');
    if (!xelacore_concept_id)
        return Promise.reject('No concept_code found, illegal action');
    return removeConcept(record_id, xelacore_concept_id);
}
