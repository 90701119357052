import React from 'react';
import classnames from 'classnames';
import { Triangle } from 'src/js/components/static';
import { MONTHS } from 'src/js/constants/dates';
import UploadHistoryItem from './UploadHistoryItem';

export default (props) => {
    const { year, history, index, collapse, collapsed } = props;
    return (
        <div className="c-upload-history__group">
            {Object.keys(history[year])
                .sort((a, b) => parseInt(a) - parseInt(b))
                .reverse()
                .map((month) => {
                    const cx = classnames('c-upload-history__group-year', {
                        'c-upload-history__group-year--collapsed':
                            collapsed.indexOf(`${year}-${month}`) > -1
                    });

                    return (
                        <div key={`${year}-${index}-${month}`} className={cx}>
                            <div
                                className="c-upload-history__date-bar"
                                onClick={() => collapse(`${year}-${month}`)}
                            >
                                <div className="c-upload-history__month">
                                    {MONTHS[parseInt(month)]} <Triangle />
                                </div>
                                <div className="c-upload-history__year">
                                    {year}
                                </div>
                            </div>
                            <ul className="c-upload-history__list">
                                {history[year][month].map((upload) => (
                                    <UploadHistoryItem
                                        key={upload.ingest_id}
                                        upload={upload}
                                    />
                                ))}
                            </ul>
                        </div>
                    );
                })}
        </div>
    );
};
