import { combineReducers } from 'redux';
import { auth } from './auth';
import { app } from './app';
import { modal } from './modal';
import { lockBody } from './lockBody';
import { globalNotification } from './globalNotification';
import { pageNotification } from './pageNotification';
import { socketNotification } from './socketNotification';
import { notification } from './notification';
import { dataManager } from './dataManager';
import { userSettings } from './userSettings';
import { myRecords } from './myRecords';
import { productCatalogue } from './productCatalogue';
import { ingestions } from './ingestions';
import { schemas } from './schemas';
import { users } from './users';
import { categories } from './categories';
import { licensorDashboard } from './licensorDashboard';
import { licenseeDashboard } from './licenseeDashboard';
import { myDeals } from './myDeals';

export default combineReducers({
    auth,
    app,
    modal,
    globalNotification,
    pageNotification,
    socket: socketNotification,
    notification,
    dataManager,
    userSettings,
    productCatalogue,
    myRecords,
    ingestions,
    schemas,
    users,
    categories,
    lockBody,
    licensorDashboard,
    licenseeDashboard,
    myDeals
});
