import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import size from 'lodash/size';
import { ReactComponent as ArrowLeft } from 'src/js/components/static/Icon/svg-icons/arrow-left.svg';

import BulkTable from './BulkTable/BulkTable';

import { RestrictedRoute } from 'src/js/components/static';
import { getConflicts } from 'src/js/actions/conflicts';
import Button from 'modules/UiKit/components/Button/Button';
import isEmpty from 'lodash/isEmpty';
import { getLinkedBrands } from 'src/js/actions/categories';

class BulkConflict extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conflictSets: [],
            cloneData: [],
            countdown: 5,
            loading: true,
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}
        };
    }

    componentDidMount() {
        this.fetchConflicts();
        this.getLinkedBrands();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getLinkedBrands() {
        const { dispatch, linkedBrands } = this.props;
        if (isEmpty(linkedBrands)) {
            dispatch(getLinkedBrands());
        }
    }

    fetchConflicts = () => {
        const { selectedLicensor } = this.state;
        const { dispatch } = this.props;

        return dispatch(getConflicts(!!selectedLicensor && selectedLicensor.licensor_organisation_id)).then((r) => {
            let cloneConflicts = cloneDeep(r.data.conflict_sets);

            if (!size(r.data.conflict_sets)) {
                this.timerOn();
            }

            this.setState({
                conflictSets: r.data.conflict_sets,
                cloneData: cloneConflicts,
                loading: false
            });
        });
    };

    timerOn = () => {
        this.interval = setInterval(() => {
            const { countdown } = this.state;

            if (countdown > 0) {
                this.setState(({ countdown }) => ({
                    countdown: countdown - 1
                }));
            }

            if (countdown === 0) {
                browserHistory.push('/my-records');
            }
        }, 1000);
    };

    render() {
        const { conflictSets, cloneData, countdown, loading, selectedLicensor } = this.state;

        const { linkedBrands } = this.props;

        return (
            <RestrictedRoute restrictTo="licensee" permission="registration">
                <Fragment>
                    <div className="c-bulk-conflict">
                        <div className="c-bulk-conflict--header">
                            <div className="c-bulk-conflict--header-left">
                                <div className="u-flex-align">
                                    <Button
                                        type="secondary-2"
                                        size="small"
                                        iconLeft={
                                            <ArrowLeft
                                                height="10"
                                                width="16"
                                            ></ArrowLeft>
                                        }
                                        onClick={browserHistory.goBack}
                                    >
                                        Back
                                    </Button>
                                    <h2 className="title">
                                        Conflict Resolution
                                    </h2>
                                </div>
                                <div className="description">
                                    This is where you can view and manage all
                                    your conflict records.
                                </div>
                            </div>
                            <div className=""></div>
                        </div>

                        <BulkTable
                            conflictSets={conflictSets}
                            countdown={countdown}
                            loading={loading}
                            linkedBrands={!!linkedBrands && !!linkedBrands.data && linkedBrands.data[selectedLicensor.licensor_organisation_id].brands}
                            fetchSets={() => this.fetchConflicts()}
                            cloneData={cloneData}
                        />
                    </div>
                </Fragment>
            </RestrictedRoute>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore,
        xelacore: { conflicts, categories: { linkedBrands = [] } = {} } = {}
    } = state;

    return {
        xelacore,
        conflicts,
        linkedBrands
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkConflict);
