import * as React from 'react'
import {
  getCellProperty
} from '@silevis/reactgrid'
import { Icon, Tooltip } from 'src/js/components/static';

const tooltipButton = (
  <span>
      <Icon
          icon="info"
          fill="grey4"
          width="12"
          height="12"
          top={3}
          tooltip
      />
  </span>
);

export class InactiveIcon {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, 'text', 'string')
    const value = parseFloat(text)
    return { ...uncertainCell, text, value }
  }

  render(cell) {
    return (
      <div className="error-cell-text inactvie-icon">
        <span className="text">{cell.text}</span>
          
        <Tooltip
          placement="right"
          button={tooltipButton}
        >
            {'Lorem ipsum dolor sit amet'}
        </Tooltip>
      </div>
    )
  }
}
