import {
    SET_FILTER_VALUE,
    SET_SORT,
    CLEAR_ALL_FILTERS,
    SET_DATA_LOADING,
    SET_PAGE,
    SET_DATA,
    SET_FILTERS
} from 'src/js/constants/actions/myDeals';
import { DATA_STATE } from 'constants/dataState';

const initialState = {
    data: [],
    dataState: DATA_STATE.LOADING,
    pageNumber: null,
    sort: {
        fieldKey: '',
        direction: ''
    },
    totalRecords: 0,
    filters: {}
};

export function myDeals(state = initialState, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_FILTER_VALUE: {
            const { key, filterValue } = payload;
            return {
                ...state,
                pageNumber: 1,
                filters: { ...state.filters, [key]: filterValue }
            };
        }
        case SET_SORT: {
            const { fieldKey, direction } = payload;
            return { ...state, sort: { fieldKey, direction } };
        }
        case SET_FILTERS: {
            return { ...state, filters: { ...state.filters, ...payload } };
        }
        case CLEAR_ALL_FILTERS: {
            return { ...state, pageNumber: 1, filters: {} };
        }
        case SET_DATA_LOADING: {
            return { ...state, dataState: DATA_STATE.LOADING };
        }
        case SET_PAGE: {
            return { ...state, pageNumber: payload };
        }
        case SET_DATA: {
            const { data, totalRecords, dataState } = payload;
            return { ...state, data, totalRecords, dataState };
        }
        default:
            return state;
    }
}
