import CryptoJS from 'crypto-js';
import axios from 'axios';
import config from 'config';
const axiosUpload = axios.create({ headers: { Authorization: '' } });
import responseHandler from '../../../apicalls/common/responseHandler';
import { forEach } from 'lodash';

export function getPreSignedUrl(repositoryId, category, extension) {
  const url = `${config.urls.filesv3}/upload`
  const params = {
    repositoryId,
    category,
    extension
  };

  return axios
    .request({
      url,
      method: 'get',
      params
    })
    .then((response) => {
      const data = responseHandler(response, 'data');
      var decrypted = CryptoJS.AES.decrypt(
        data,
        'process.env.NODE_ENV'
      ).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decrypted);
    })
}

export function uploadFile(file, presignedUrl) {
  const { fields, url } = presignedUrl

  return new Promise((resolve, reject) => {
    const data = new FormData();
    forEach(fields, (val, key) => data.append(key, val));
    data.append('file', file);

    return axiosUpload
      .request({
        url,
        method: 'post',
        data
      })
      .then((result) => resolve(result))
      .catch((err) => reject(err));
  });
}

export function triggerIngest(sourceInfo, licenseeOrganisationId, licensorOrganisationId, label, destination, jobType) {
  const url = `${config.urls.ingestv3}/data/${destination}/file`;

  try {
    return axios({
      method: 'post',
      url,
      data: {
        licenseeOrganisationId,
        licensorOrganisationId,
        label,
        fileStore: 's3',
        sourceInfo,
        jobType
      }
    }).then((response) => {
      return response && response.data
    });
  } catch (error) {
    return error;
  }
}
