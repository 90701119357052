import React, { Component } from 'react';
import { Tooltip } from 'src/js/components/static';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';
import { checkImageExists } from 'src/js/helpers/dataHelpers';

export default class ProductSmallImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageExists: true,
            imageChecked: false,
            imageCheckCount: 0
        };
    }

    componentDidMount() {
        const { imageChecked } = this.state;
        const { smallImageSrc } = this.props;
        let counter = 0;

        if(!imageChecked) {
            let interval = setInterval(() => {
                checkImageExists(smallImageSrc, () => {
                    if (!!smallImageSrc){
                        this.setState({
                            imageChecked: true,
                            imageExists: true
                        });
                        clearInterval(interval);
                    }
                }, () => {
                    if(counter > 15) {
                        clearInterval(interval);
                    }

                    counter++;

                    this.setState({
                        imageExists: false,
                        imageCheckCount: counter
                    });
                });
            }, 1000);
        }
    }

    render() {
        const { smallImageSrc } = this.props;
        const { imageExists, imageCheckCount } = this.state;

        return (
            <div className="c-product__image-upload">
                { (imageExists !== false || imageCheckCount >= 15) && (
                    <MediaView
                        src={smallImageSrc}
                        className="c-product__dived-img"
                        fallbackSrc={fallbackImageSmall}
                        useImgTag
                    />
                )}

                { imageExists === false && imageCheckCount < 15 && (
                    <Tooltip
                        button={
                            <div>
                                <img
                                    src={imgPinkSpinner}
                                    height="15"
                                />
                            </div>
                        }>
                    </Tooltip>
                )}
            </div>
        );
    }
}
