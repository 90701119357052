import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { xelacoreModuleDefinitions } from 'constants/xelacoreModulesDefinitions';
import HeaderUserInfo from './HeaderUserInfo';
import { Link } from 'src/js/components/static';

import HeaderNavigation from './HeaderNavigation';

import imgLogo from 'statics/imgs/app/xelacore-logo.svg';

@withRouter
class Header extends Component {
    constructor(props) {
        super(props);

        const avaialbleModules = props.auth && props.auth.companyData && props.auth.companyData.modules || [];
        const mappedAvailableModules = avaialbleModules.filter((module) =>
            xelacoreModuleDefinitions.has(module) && module!=='reg'
        );
        const modules = mappedAvailableModules.map(
            (module) =>
                xelacoreModuleDefinitions.get(module) || {
                    name: '',
                    icon: '',
                    link: ''
                }
        );

        this.state = {
            userInfo: {},
            menuActive: false,
            modules
        };
    }

    toggleMenu() {
        this.setState({ menuActive: !this.state.menuActive });
    }

    render() {
        const {
            type,
            userData,
            location,
            dispatch,
            auth,
            xelacore,
            auth: { organisations = [], companyData, customReports } = {}
        } = this.props;
        // Breaks header if we include it in the above for basic users accounts
        const companyName = companyData ? companyData.name : null;

        return (
            <header id="siteHeader" className="c-site-header">
                <div className="c-site-header__container">
                    <div className="c-site-header__site-logo">
                        <Link to={'/'}>
                            <img
                                src={imgLogo}
                                alt="Xelacore"
                                className="c-site-header__logo"
                            />
                            <span className="c-site-header__type">{type}</span>
                        </Link>
                    </div>

                    <HeaderNavigation location={location} customReports={customReports} />

                    <div className="c-site-header__user">
                        <HeaderUserInfo
                            user={userData}
                            organisations={organisations}
                            refreshToken={auth.refreshToken}
                            dispatch={dispatch}
                            companyData={companyData}
                            companyName={companyName}
                            xelacore={xelacore}
                        />
                    </div>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    // We don't need the whole xelacore object from redux, we need only userData
    const { xelacore, xelacore: { auth, auth: { userData = {} } = {} } = {} } = state;
    return { auth, userData, xelacore };
}

export default connect(mapStateToProps)(Header);
