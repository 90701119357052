import React from 'react';
import ProductTail from 'modules/UiKit/components/Cards/ProductTail';

export default props => {
    const {
        data,
        selectedRows,
        dispatch,
        previousRow
    } = props;

    return (
        <div className="c-rec-grid__wrapper">
            {!!data && data.map((el, i) => {
                return (<ProductTail itemIndex={i}
                                     data={data}
                                     dispatch={dispatch}
                                     previousRow={previousRow}
                                     selectedRows={selectedRows}
                                     tailItemData={el} />);
            })}
        </div>
    );
};
