'use strict';

// This is dev. Will be changed to ecs-dev later
module.exports = {
    baseUrl: 'https://api.testing.xelacore.io',
    redirect_uri: 'https://testing.xelacore.io',
    client_id: 'f21ce494-0385-4c2e-9175-96f15344aab7',
    injectFlow: function injectFlow() {
        if (typeof window === 'undefined') return;
        if (window.location.protocol !== 'https:')
            window.location.protocol = 'https:';
    },
    services: {
        login: 'https://login.testing.xelacore.io',
        reach: 'https://reach.testing.xelacore.io',
        account: 'https://account.testing.xelacore.io',
        reg: 'https://testing.xelacore.io',
        plm: 'https://testing.app.xelacore.com',
        cam: 'https://dev.concepts.xelacore.io'
    },
    hotjar: {
        trackingCode: 1003511
    }
};
