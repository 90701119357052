import React from 'react';
import RegisterModal from 'src/js/components/Modals/RegisterModal';
import { selectAll } from 'src/js/actions/productsFetch';
import { showModal } from 'src/js/actions/xelacore';

export function registerProductsModal(
    selectedRows,
    fetchData,
    isMyRecords,
    registrableIds,
    basicFilterObj,
    queryObject,
    updateFiltersState
) {
    return dispatch => {
        const modalContent = (
            <RegisterModal
                dispatch={dispatch}
                selectedRows={selectedRows}
                registrableIds={registrableIds}
                customFetch={() => fetchData()}
                resetRows={() => dispatch(selectAll([], isMyRecords, []))}
                basicFilterObj={basicFilterObj}
                queryObject={queryObject}
                updateFiltersState={updateFiltersState}
            />
        );
        dispatch(showModal(modalContent));
    };
}
