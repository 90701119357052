import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import { Auth, Modal } from 'src/js/components/static';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import errorHandler from 'src/js/actions/errorHandler';
import successHandler from 'src/js/actions/successHandler';
import { conceptCodeUpsert } from 'src/js/actions/opa';
import ProductImages from './ProductImages/ProductImages';
import Approval from './Approval/Approval';
import RetailerListings from './RetailerListings/RetailerListings';
import Listing from './Listing/Listing';
import EsgView from './EsgView/EsgView';
import Codes from './Codes/Codes';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';

import {
    showModal,
    hideModal,
    unlockBody
} from 'src/js/actions/xelacore';
import {
    confirmConceptApprovalCode,
    removeConceptData
} from 'src/js/actions/opa';
import CatalogueProductEditModal from 'src/js/components/Modals/CatalogueProductEditModal';
import { getUrlParam } from 'src/js/helpers/strings';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';

import ApprovalOverlay from './ApprovalOverlay/ApprovalOverlay';
import Statuses from './Statuses/Statuses';
import BulkLinkConcepts from 'components/BulkLinkConcepts/BulkLinkConcepts';
import Button from 'modules/UiKit/components/Button/Button';
import Conflicts from 'modules/MyRecords/routes/MyRecords/MyRecordsTable/Conflicts/Conflicts.jsx';
import { resetConceptsRows } from 'src/js/actions/productsFetch';

class ProductDetailTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            redemption: false,
            openQuarantine: false,
            quarantineMode: false,
            modalActions: false,
            showApprovalOverlay: false,
            overlayProductInfo: null,
            showApprovalOverlayWithSearch: false,
            isSticky: false,
            statusWidth: 'auto',
            showView: 'product',
            activeImage: {},
            dismissInfo: !!localStorage.getItem('dismissProductDetailsInfo') && localStorage.getItem('dismissProductDetailsInfo') || 'false',
            dismissConceptInfo: !!localStorage.getItem('dismissConceptInfo') && localStorage.getItem('dismissConceptInfo') || 'false'
        };

        this.statusBarRef = React.createRef();
        this.productWrapperRef = React.createRef();
        this.statusBlockRef = React.createRef();
    }

    componentWillMount() {
        const redemption = getUrlParam('redemption') === 'true';
        this.setState({
            redemption
        });
    }

    dontShow(item) {
        let value = localStorage.getItem(item);
        return localStorage.setItem(item, value === 'true' ? 'false' : 'true');
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);

        this.props.router.setRouteLeaveHook(this.props.route, (info) => {
            if (this.state.changed) {
                this.checkDiscardChanges(info);
                return false;
            }
            return true;
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
        this.props.dispatch(unlockBody());
    }

    hasEsgPermission = () => {
        const esgManage = get(
            this,
            'props.xelacore.auth.userPermissions.features.esg_view.can',
            []
        );

        return (esgManage || []).indexOf('view') !== -1;
    }

    handleScroll = () => {
        const statusBar = this.statusBarRef.current;

        const productContainerEl = this.productWrapperRef.current;
        const statusBlockWidth = this.statusBlockRef.current;

        const productContainerRect = productContainerEl.getBoundingClientRect();

        if (productContainerRect.top < 61) {
            statusBar.classList.add('sticky');
            statusBlockWidth.classList.add('sticky-bar');
            document.getElementById('siteHeader').style.boxShadow = 'none';
        } else {
            statusBar.classList.remove('sticky');
            statusBlockWidth.classList.remove('sticky-bar');
            document.getElementById('siteHeader').style.boxShadow =
                '0 2px 5px 0 rgba(63, 42, 132, 0.08)';
        }
    };

    checkDiscardChanges(pathInfo, toggle) {
        const { dispatch, switchTo } = this.props;
        const push = () => {
            dispatch(hideModal());
            if (toggle) {
                this.setState({ changed: false });
                switchTo('view');
            }
            if (pathInfo) return browserHistory.push(pathInfo.pathname);
        };

        const body = (
            <div>
                <p>
                    You have unsaved changes. Are you sure you want to proceed?
                </p>
                <Button
                    type="secondary"
                    size="small"
                    onClick={() =>
                        this.setState({ changed: false }, () => push())
                    }
                >
                    Yes
                </Button>
                <Button
                    type="secondary-danger-2"
                    size="small"
                    onClick={() => dispatch(hideModal())}>
                    Cancel
                </Button>
            </div>
        );
        const modal = <Modal body={body} title="Discard unsaved changes?" />;
        return dispatch(showModal(modal));
    }

    switchTo(view) {
        if (this.state.changed) return this.checkDiscardChanges(null, true);
        this.props.switchTo(view);
    }

    handleOpening = () => {
        const { dontShowRedirectModal, type, id } = this.props;
        if (dontShowRedirectModal || type !== 'catalog')
            return this.openRecordForEditing();
        this.displayModal(id);
    };

    openRecordForEditing = () => {
        const { id } = this.props;
        browserHistory.push(`/product/records/${id}?edit=true`);
        return location.reload();
        // TODO this is a temporal solution for current model
    };

    displayModal = (record_id) => {
        const { dispatch } = this.props;
        const body = (
            <CatalogueProductEditModal
                openRecordForEditing={() => this.openRecordForEditing()}
                record_id={record_id}
            />
        );
        const title = 'Editing Records in Product Catalogue';
        const contentOfTheModal = <Modal title={title} body={body} isSmall />;
        return dispatch(showModal(contentOfTheModal));
    };

    updateProductConcept = (recordId, concept_code, old_concept_code) => {
        const { getProductData, dispatch, originalData } = this.props;
        const versionId = get(originalData, 'record.version_id');
        const validationVersionId = get(
            originalData,
            'record.validation_version_id'
        );

        this.setState({ fetching: true });
        conceptCodeUpsert(recordId, concept_code, old_concept_code, versionId)
            .then(() => {
                return getProductData(
                    true,
                    recordId,
                    versionId,
                    validationVersionId
                );
            })
            .then(() => {
                successHandler(dispatch, 'Concept code updated');
            })
            .catch((error) => errorHandler(dispatch, error))
            .then(() => {
                this.setState({ fetching: false });
            });
    };

    removeConcept = (recordId, concept_code) => {
        const { dispatch, getProductData, originalData } = this.props;
        const versionId = get(originalData, 'record.version_id');
        const validationVersionId = get(
            originalData,
            'record.validation_version_id'
        );
        this.setState({ fetching: true });
        return removeConceptData(recordId, concept_code)
            .then(() => {
                return getProductData(
                    true,
                    recordId,
                    versionId,
                    validationVersionId
                );
            })
            .then(() => {
                successHandler(
                    dispatch,
                    'The concept has been unlinked from the product'
                );
            })
            .catch((e) =>
                errorHandler(dispatch, {
                    message: `There has been an error deleting concept information: ${e}`
                })
            )
            .then(() => {
                this.setState({ fetching: false });
            });
    };

    cancelEditMode(e, data) {
        const { onChangeItem } = this.props;

        e && e.preventDefault && e.preventDefault();
        const { editedData } = this.state;
        if (!isEmpty(editedData)) return this.confirmCancelEditmodal();
        
        onChangeItem(data);

        return this.switchTo('view');
    }

    confirmCancelEditmodal() {
        const { dispatch } = this.props;

        const hideClose = () => {
            this.switchTo('view');
            dispatch(hideModal());
        };

        const body = (
            <div>
                <p>
                    You have unsaved changes, are you sure you wish to proceed?
                </p>
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => hideClose()}>
                    Confirm
                </Button>
                <Button
                    type="secondary-danger-2"
                    size="small"
                    onClick={() => dispatch(hideModal())}>
                    Cancel
                </Button>
            </div>
        );
        const modal = <Modal body={body} title={'Discard unsaved changes?'} />;
        return dispatch(showModal(modal));
    }

    confirmChangeConcept = (recordId, concept_code, isDelete) => {
        const { oldCode, data } = this.props;
        if (!isDelete && !oldCode) {
            return this.confirmCode(recordId, concept_code, true);
        }
        const operation = isDelete
            ? this.removeConcept
            : this.updateProductConcept;
        const { dispatch } = this.props;
        const title = isDelete ? 'Confirm Removing' : 'Concept Change';

        const { concept_code_confirmed_info = [] } = data;
        const isConfirmed = !!concept_code_confirmed_info.find(
            (confirmedCode) =>
                isDelete
                    ? confirmedCode.concept_code === concept_code
                    : confirmedCode.concept_code === oldCode
        );

        const text = `You have chosen to ${
            isDelete ? 'remove' : 'change'
        } the linked concept for this product. ${
            isConfirmed
                ? 'Your Licensor partner will be updated accordingly in regards to this change.'
                : ''
        } Do you wish to continue?`;
        const content = (
            <div>
                <p>{text}</p>
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => {
                        operation(recordId, concept_code, oldCode);
                        dispatch(hideModal());
                    }}
                >
                    Confirm
                </Button>
                <Button
                    type="secondary-danger"
                    size="small"
                    onClick={() => dispatch(hideModal())}>
                    Cancel
                </Button>
            </div>
        );

        const modal = <Modal body={content} title={title} />;
        return dispatch(showModal(modal));
    };

    confirmCode(id, code) {
        const { dispatch, getProductData, originalData } = this.props;
        const versionId = get(originalData, 'record.version_id', null);
        const validationVersionId = get(
            originalData,
            'record.validation_version_id',
            null
        );

        this.setState({ fetching: true });
        return confirmConceptApprovalCode(id, code, versionId)
            .then(() => {
                return getProductData(true, id, versionId, validationVersionId);
            })
            .then(() => {
                successHandler(
                    dispatch,
                    'Concept successfully linked!'
                    // `Concept code successfully ${
                    //     added ? 'added and ' : ''
                    // } confirmed`
                );
            })
            .catch((e) =>
                errorHandler(dispatch, {
                    message: `There has been an error confirming your code: ${e}`
                })
            )
            .then(() => {
                this.setState({ fetching: false });
            });
    }



    openQuarantineFunction(quarantineMode, modalActions) {
        const { data = [], dispatch } = this.props;
        const body = (
            <Conflicts
                data={data}
                quarantineMode={quarantineMode}
                modalActions={modalActions}
                self={false}
                id={data.record_id}
            />
        );

        const contentOfTheModal = (
            <Modal
                scrolling
                isMedium
                className="c-conflicts-record__modal" title="Resolve Conflicts" body={body}
            />
        );

        return dispatch(showModal(contentOfTheModal));
    }

    showProductOverlay(productInfo) {
        this.linkSingleProduct(productInfo);
    }

    linkSingleProduct(linkedConcept) {
        const { data, dispatch, getProductData, licensor, ipLevels, xelacore } = this.props;
        const modal = (
            <Modal
                title="Concept Linking"
                className="c-bulk-update__modal bulk-link-concepts-modal"
                body={
                    <BulkLinkConcepts
                        singleProduct={true}
                        rows={[data]}
                        licensor={licensor}
                        getProductData={getProductData}
                        previewLinkedConcept={linkedConcept}
                        resetConceptsRows={resetConceptsRows}
                        ipLevels={ipLevels}
                        xelacore={xelacore}
                        dispatch={dispatch}
                    />
                }
            />
        );
        return dispatch(showModal(modal));
    }

    toggleView = (view) => {
        return this.setState({
            showView: view
        })
    }

    render() {
        const {
            dispatch,
            licensor,
            toggleOpaPanel,
            updateData,
            type,
            getProductData,
            confirmArn,
            aprCode,
            data,
            originalData,
            opaDataMatch,
            displayConfirmationModal,
            editMode,
            xelacore,
            filesTotalToUpload,
            filesLeftToUploadCount,
            categoryAttributes,
            ipLevels,
            hasStaticAttributes,
            onChangeItem,
            esgData,
            switchTime,
            cloneData,
            agent,
            agenciesData
        } = this.props;
        const {
            changed,
            fetching,
            showApprovalOverlay,
            overlayProductInfo,
            showApprovalOverlayWithSearch,
            quarantineMode,
            showView,
            dismissInfo,
            dismissConceptInfo
        } = this.state;
        const { record: { record_id } = {} } = originalData;
        const userOrg =
            xelacore.auth && xelacore.auth.token
                ? jwtDecode(xelacore.auth.token).data.org
                : '';

        if (!data) return null;

        let conflictsCount =
            size(data.conflicts_gtin_local) + size(data.conflicts_mpn_local);
        let quarantineCount =
            size(data.quarantine_gtin_local) + size(data.quarantine_mpn_local);

        let modalView =
            !get(data, 'registered_with') && conflictsCount > 0
                ? 'conflicts'
                : !!get(data, 'registered_with') &&
                quarantineCount > 0 &&
                !data.quarantine_resolved_local
                ? 'quarantine'
                : null;

        return (
            <div ref={this.productWrapperRef} className="c-product__wrapper">
                <div ref={this.statusBarRef} className="c-product__status-bar">
                    <div
                        className="c-product__top-block"
                        style={{
                            width:
                                (this.productWrapperRef.current &&
                                    this.productWrapperRef.current
                                        .offsetWidth) ||
                                'auto'
                        }}
                        ref={this.statusBlockRef}
                    >
                        <Statuses
                            cloneData={cloneData}
                            data={data}
                            originalData={originalData}
                            dispatch={dispatch}
                            editRecord={() =>
                                this.handleOpening(
                                    originalData.record.product_id
                                )
                            }
                            updateData={(data, key, callback) =>
                                updateData(data, key, callback)
                            }
                            licensor={licensor}
                            agent={agent}
                            type={type}
                            editMode={editMode}
                            switchTo={(val) => this.switchTo(val)}
                            cancelEditMode={(e, canceledData) => this.cancelEditMode(e, canceledData)}
                            isQuarantine={data.is_quarantine}
                            modalView={modalView}
                            openQuarantine={this.state.openQuarantine}
                            modalActions={this.state.modalActions}
                            openQuarantineFunction={(quarantineMode, modalActions) =>
                                this.openQuarantineFunction(quarantineMode, modalActions)
                            }
                            quarantineMode={quarantineMode}
                            showProductOverlay={() =>
                                this.showProductOverlay(null, true)
                            }
                            linkSingleProduct={() =>
                                this.linkSingleProduct()
                            }
                            getProductData={() => getProductData()}
                            toggleView={(view) => this.toggleView(view)}
                            view={showView}
                            esgData={esgData}
                            hasEsgPermission={this.hasEsgPermission()}
                        />
                    </div>
                </div>

                {showView === 'product' && (
                    <div className='product-view-wrapper'>
                        {showApprovalOverlay && (
                            <ApprovalOverlay
                                data={data}
                                type={type}
                                approvalData={overlayProductInfo}
                                originalData={originalData.record}
                                removeConcept={(id, code) =>
                                    this.removeConcept(id, code)
                                }
                                close={() => {
                                    this.showProductOverlay(null);
                                    this.setState({
                                        overlayProductInfo: null
                                    });
                                }}
                                licensor={licensor}
                                dispatch={dispatch}
                                toggleOpaPanel={(refetch) => toggleOpaPanel(refetch)}
                                updateData={(data, key, callback) =>
                                    updateData(data, key, callback)
                                }
                                confirmArn={confirmArn}
                                record_id={record_id}
                                displayConfirmationModal={(data, key, text) =>
                                    displayConfirmationModal(data, key, text)
                                }
                                confirmCode={() => this.confirmCode(record_id, aprCode)}
                                updateProductConcept={this.confirmChangeConcept}
                                showProductOverlay={(productInfo) =>
                                    this.showProductOverlay(productInfo)
                                }
                                search={showApprovalOverlayWithSearch}
                                opaDataMatch={opaDataMatch}
                                xelacore={xelacore}
                            />
                        )}
                        <div className="c-product__column">
                            {fetching && (
                                <div className="c-product__editing-spinny">
                                    <img src={imgPinkSpinner} />
                                </div>
                            )}

                            <div className="c-product__column-content">
                                <ProductImages
                                    dispatch={dispatch}
                                    data={data}
                                    productId={originalData.record.product_id}
                                    recordId={originalData.record.record_id}
                                    updateData={(data, key, callback) =>
                                        updateData(data, key, callback)
                                    }
                                    type={type}
                                    displayConfirmationModal={(
                                        data,
                                        key,
                                        text,
                                        showKey
                                    ) =>
                                        displayConfirmationModal(
                                            data,
                                            key,
                                            text,
                                            showKey
                                        )
                                    }
                                    filesTotalToUpload={filesTotalToUpload}
                                    filesLeftToUploadCount={filesLeftToUploadCount}
                                />
                                <Approval
                                    dispatch={dispatch}
                                    data={originalData}
                                    approvalCode={aprCode}
                                    toggleOpaPanel={toggleOpaPanel}
                                    getProductData={() => getProductData()}
                                    originalData={originalData.record}
                                    type={type}
                                    opaDataMatch={opaDataMatch}
                                    licensor={licensor}
                                    agent={agent}
                                    confirmCode={(record_id, code) =>
                                        this.confirmCode(record_id, code)
                                    }
                                    removeConcept={(record_id, code) =>
                                        this.confirmChangeConcept(record_id, code, true)
                                    }
                                    updateProductConcept={this.updateProductConcept}
                                    showProductOverlay={(productInfo, search) =>
                                        this.showProductOverlay(productInfo, search)
                                    }
                                    xelacore={xelacore}
                                    dismissInfo={dismissInfo}
                                    dismissConceptInfo={dismissConceptInfo}
                                    dismissConceptInfoFunc={() => this.setState({dismissConceptInfo: 'true'})}
                                    dontShow={(item) => this.dontShow(item)}
                                />

                                { licensor && type === 'catalog' && (
                                    <RetailerListings data={data} dispatch={dispatch}/>
                                )}
                            </div>
                        </div>
                        <div className="c-product__column">
                            <div className="c-product__stretchy-box">
                                {type !== 'catalog' && (dismissInfo === 'false' || dismissInfo === 'null') && (
                                    <Auth restrictTo="licensee">
                                        <div className="u-margin-bottom">
                                            <div className="c-product__detail-listing-info">
                                                Please note, while <strong>you can register products with warnings, you cannot register products that have errors</strong> and you should ensure that all warnings are cleared, in order to have a complete product record, otherwise licensors will see the record as incomplete.

                                                <div className='u-flex-gap u-flex-align--right u-margin-top'>
                                                    <UiCheckbox
                                                        type="checkbox"
                                                        id="dontShow"
                                                        name="dontShow"
                                                        onChange={() => this.dontShow('dismissProductDetailsInfo')}
                                                        label={'Don\'t show this again'}>

                                                    </UiCheckbox>
                                                    <Button
                                                            type="secondary-2"
                                                            size="small"
                                                            onClick={() => this.setState({dismissInfo: 'true'})}
                                                        >
                                                            Dismiss
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Auth>
                                )}
                                <div className="c-product__right-column">
                                    <Listing
                                        editMode={editMode}
                                        switchTime={switchTime}
                                        closeEditMode={(e) => this.cancelEditMode(e)}
                                        updateData={(data, key, callback) =>
                                            updateData(data, key, callback)
                                        }
                                        handleOpening={() =>
                                            this.handleOpening(
                                                originalData.record.product_id
                                            )
                                        }
                                        switchTo={(val) => this.switchTo(val)}
                                        type={type}
                                        data={data}
                                        originalData={originalData.record}
                                        getProductData={getProductData}
                                        changed={changed}
                                        setChanged={(val) =>
                                            this.setState({ changed: val })
                                        }
                                        xelacore={xelacore}
                                        schemasToUse={'catalog'}
                                        licensor={licensor}
                                        opaDataMatch={opaDataMatch}
                                        categoryAttributes={categoryAttributes}
                                        ipLevels={ipLevels}
                                        agenciesData={agenciesData}
                                        hasStaticAttributes={hasStaticAttributes}
                                    />
                                </div>
                            </div>
                        </div>
                        {type === 'catalog' && userOrg === 'misfit' && (
                            <Codes data={data} dispatch={dispatch} />
                        )}
                    </div>
                )}

                {showView === 'esg' && (
                    <EsgView
                            cloneData={cloneData}
                            esgData={esgData}
                            originalData={originalData}
                            cancelEditMode={(e, canceledData) => this.cancelEditMode(e, canceledData)}
                            editMode={editMode}
                            data={data}
                            onChangeItem={onChangeItem}
                            dispatch={dispatch}
                            switchTo={(val) => this.switchTo(val)}
                            updateData={(data, key, callback) =>
                                updateData(data, key, callback)
                            }>
                    </EsgView>
                )}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const {
        xelacore,
        xelacore: { userSettings: { dontShowRedirectModal } = {} } = {}
    } = state;

    return {
        xelacore,
        dontShowRedirectModal,
        messages: get(xelacore, 'socket.messages', []),
        filesTotalToUpload: get(
            xelacore,
            [
                'dataManager',
                'bulkImagesUpload',
                ownProps.originalData.record.record_id,
                'filesTotalToUpload'
            ],
            []
        ),
        filesLeftToUploadCount: get(
            xelacore,
            [
                'dataManager',
                'bulkImagesUpload',
                ownProps.originalData.record.record_id,
                'filesLeftToUploadCount'
            ],
            0
        )
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailTab);
