import React from 'react';
import classnames from 'classnames';
import { IconPaths } from './IconPaths.js';
import { iconColors } from 'src/js/constants/colorConstants';

export default function Icon({ ...props }) {
    const {
        className,
        left,
        right,
        center,
        clickable,
        icon,
        top,
        position,
        description,
        width,
        size,
        height,
        onClick,
        fillCol,
        fill
    } = props;

    const cx = classnames('c-icon', className, {
        'c-icon__left': left,
        'c-icon__right': right,
        'c-icon__center': center,
        'c-icon--clickable': clickable
    });

    const iconName = icon.toUpperCase(); // Just in case we put it in in lowercase.
    const defaultSize = 10;

    const styles = {
        top: top,
        left: left,
        right: right,
        position: position
    };

    return (
        <svg
            className={cx}
            width={width || size || defaultSize}
            height={height || size || defaultSize}
            viewBox="0 0 1024 1024"
            aria-labelledby={`icon-${iconName}-title`}
            onClick={onClick}
            fill={iconColors[fill] || fillCol}
            style={styles}
        >
            {description && (
                <title id={`icon-${iconName}-title`}>{description}</title>
            )}
            <path d={IconPaths[iconName]} />
        </svg>
    );
}
