import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists categories
 * @return {Promise}
 */
export function fetchIps(organisationId, token, licensorOrgId) {
    return axios
        .request({
            url: [config.urls.policy, organisationId, 'ips/licensor', licensorOrgId].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}

/**
 * This API call Lists categories
 * @return {Promise}
 */
export function fetchIpsLevels(organisationId, token, licensorOrgId) {
    return axios
        .request({
            url: [config.urls.policy, organisationId, 'ip_levels/licensor', licensorOrgId].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}

/**
 * This API call Lists categories
 * @return {Promise}
 */
export function fetchIpsLevelsv3(token, licensorOrgId) {
    return axios
        .request({
            url: [config.urls.policyv3, 'organisation', licensorOrgId, 'ipLevels'].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}

/**
 * This API call Lists categories
 * @return {Promise}
 */
export function fetchIpsV3(token, licensorOrgId) {
    return axios
        .request({
            url: [config.urls.policyv3, 'organisation', licensorOrgId, 'ips'].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}