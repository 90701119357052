import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists categories
 * @return {Promise}
 */
export function fetchCategories(organisationId, token) {
    return axios
        .request({
            url: [config.urls.policy, organisationId, 'categories'].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}

/**
 * This API call Lists categories V3
 * @return {Promise}
 */
export function fetchCategoriesV3(organisationId, token) {
    return axios
        .request({
            url: [config.urls.policyv3, 'organisation', organisationId, 'categories'].join('/'),
            method: 'get',
            headers: { Authorization: ['Bearer', token].join(' ') }
        })
        .then(responseHandler);
}

/**
 * This API call Lists categories
 * @return {Promise}
 */
 export function fetchFamilies(orgId) {
    return axios
        .request({
            url: [config.urls.catalog, 'families', orgId].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch brand static attributes
 * @return {Promise}
 */
 export function fetchBrandStaticAttributes(orgId, brandId) {
    return axios
        .request({
            url: [config.urls.policy, 'organisation', orgId, 'brand', brandId, 'attributes/static'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch concept fields
 * @return {Promise}
 */
 export function fetchConceptFields(orgId) {
    return axios
        .request({
            url: [config.urls.ingestv3, 'template', orgId, 'xelacore-concepts'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * This API call Lists linked brands
 * @return {Promise}
 */
export function fetchLinkedBrands(orgId) {
    return axios
        .request({
            url: [config.urls.policy, 'brand', orgId, 'linkedbrands'].join('/'),
            method: 'get'
        })
        .then(responseHandler)
        .then(({ data }) => {
            const rows = Object.values(data).reduce(
                (res, { licensor_organisation_name, brands }) => [
                    ...res,
                    ...brands.map((b) => ({
                        ...b,
                        name: b.brand,
                        licensor_organisation_name
                    }))
                ],
                []
            );
            return {
                data: {
                    data,
                    linkedBrands: {
                        rows,
                        count: rows.length
                    }
                }
            };
        });
}

export function fetchBrandConcepts(params, orgId) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'organisation', orgId, 'concepts'].join('/'),
            method: 'post',
            data: params
        })
        .then(responseHandler);
}

export function fetchBrandAgencies(brandId) {
    return axios
        .request({
            url: [config.urls.policyv3, 'agencies', brandId].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}


/**
 * This API call Lists category attributes
 * @return {Promise}
 */
 export function fetchCategoryAttributes(organisationId, categoryId) {
    return axios
        .request({
            url: [config.urls.policy, 'organisation', organisationId, 'category', categoryId, 'attributes'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchLicensedProperites(organisationId, brandId) {
    return axios
        .request({
            url: [config.urls.policy, 'organisation', organisationId, 'brand', brandId, 'properties'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}