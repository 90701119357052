import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists connections
 */
export function fetchConnections() {
    return axios
        .request({
            url: [config.urls.policy, 'links'].join('/'),
            method: 'get',
            params: { withInfo: true }
        })
        .then(responseHandler);
}

/**
 * This API call Lists of agencies
 */
export function fetchAgencies(organisationId) {
    return axios
        .request({
            url: [
                config.urls.auth,
                'organisations',
                organisationId,
                'agencies'
            ].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchConfig() {
    return axios
        .request({
            url: [config.urls.policy, 'config'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}