export const licenseeFilters = {
    licenseeOrganisationId: {
        key: 'licenseeOrganisationId',
        name: 'Licensees',
        isMultiselect: true
    },
    licensorBrand: {
        key: 'licensorBrand',
        name: 'Brands',
        isMultiselect: false
    },
    licensorFranchise: {
        key: 'licensorFranchise',
        name: 'IPs',
        isMultiselect: true
    },
    productCategory: {
        key: 'productCategory',
        name: 'Categories',
        isMultiselect: true
    },
    productSubCategory: {
        key: 'productSubCategory',
        name: 'Sub-categories',
        isMultiselect: true
    },
    period: {
        key: 'period',
        name: 'Period',
        isMultiselect: false
    }
};

export const defaultOptions = {
    period: [
        { filterId: 'yearly', filterName: 'Yearly' },
        { filterId: 'monthly', filterName: 'Monthly' },
        { filterId: 'weekly', filterName: 'Weekly' },
        { filterId: 'daily', filterName: 'Daily' }
    ]
};

export const licenseeDashboardVisuals = {
    catalogCreationTrackerChart: {
        key: 'catalogCreationTrackerChart',
        displayName: 'Catalogue Creation Tracker',
        description: 'Catalogue Creation Tracker'
    },
    geographicalDistributionOfBrands: {
        key: 'geographicalDistributionOfBrands',
        displayName: 'Brand Coverage',
        description: 'Brand Coverage',
        localFilters: [
            {
                filter: licenseeFilters.productCategory,
                isMultiselect: false
            }
        ]
    },
    linkedBrands: {
        key: 'linkedBrands',
        displayName: 'Linked Brands Over Time',
        description: 'Linked Brands Over Time'
    },
    riskAssessmentChart: {
        key: 'riskAssessmentChart',
        displayName: 'Deal Risk Indicator',
        description: 'Deal Risk Indicator'
    },
    totalSubmissionsOverTime: {
        key: 'totalSubmissionsOverTime',
        displayName: 'Product Registrations Activity',
        description: 'Product Registrations Activity'
    },
    productSummary: {
        key: 'productSummary',
        displayName: 'Product Status Summary',
        description: 'Product Status Summary'
    },
    productsLinkedToConcepts: {
        key: 'productsLinkedToConcepts',
        displayName: 'Concept Linking Status',
        description: 'Concept Linking Status'
    },
    productsWithHighRiskFactor: {
        key: 'productsWithHighRiskFactor',
        displayName: 'Products With High Risk Factor',
        description: 'Products With High Risk Factor'
    },
    conceptSubmissionsActivityData: {
        key: 'conceptSubmissionsActivityData',
        displayName: 'Concept Submissions Activity',
        description: 'Concept Submissions Activity'
    },
    conceptAnalytics: {
        key: 'conceptAnalytics',
        displayName: 'Concept Submissions Analytics',
        description: 'Concept Submissions Analytics',
        childVisuals: {
            conceptsSubmitted: {
                key: 'conceptsSubmitted',
                displayName: 'Concept Submissions',
                description: 'Concept Submissions'
            },
            conceptsApproved: {
                key: 'conceptsApproved',
                displayName: 'Concepts Approved',
                description: 'Concepts Approved'
            },
            approvedProducts: {
                key: 'approvedProducts',
                displayName: 'Approved Products',
                description: 'Approved Products'
            },
            productsWithoutConceptApproval: {
                key: 'productsWithoutConceptApproval',
                displayName: 'Products without Concept Approval',
                description: 'Products without Concept Approval'
            },
            rateOfProductRealization: {
                key: 'rateOfProductRealization',
                displayName: 'Concept-to-Product Conversion Rate',
                description: 'Concept-to-Product Conversion Rate'
            }
        }
    },
    licensorRiskAssessmentTable: {
        key: 'licensorRiskAssessmentTable',
        displayName: 'Summary of Licensor Deals',
        description: 'Summary of Licensor Deals'
    },
    unsubmittedCataloguesData: {
        key: 'unsubmittedCataloguesData',
        displayName: 'Unsubmitted Catalogues',
        description: 'Unsubmitted Catalogues'
    }
};

export const defaultSelectedValues = {
    period: [],
    productCategory: [],
    productSubCategory: [],
    licensorFranchise: [],
    licensorBrand: [],
    licensorOrganisationId: []
};

const licensorBrand = 'licensorBrand';

export const licenseeDashboardSubscriptions = {
    [licenseeDashboardVisuals.catalogCreationTrackerChart.key]: [licensorBrand],
    [licenseeDashboardVisuals.geographicalDistributionOfBrands.key]: [
        licensorBrand
    ],
    [licenseeDashboardVisuals.linkedBrands.key]: [],
    [licenseeDashboardVisuals.riskAssessmentChart.key]: [licensorBrand],
    [licenseeDashboardVisuals.licensorRiskAssessmentTable.key]: [],
    [licenseeDashboardVisuals.totalSubmissionsOverTime.key]: [licensorBrand],
    [licenseeDashboardVisuals.productSummary.key]: [licensorBrand],
    [licenseeDashboardVisuals.productsLinkedToConcepts.key]: [licensorBrand],
    [licenseeDashboardVisuals.productsWithHighRiskFactor.key]: [licensorBrand],
    [licenseeDashboardVisuals.conceptSubmissionsActivityData.key]: [
        licensorBrand
    ],
    [licenseeDashboardVisuals.conceptAnalytics.key]: [licensorBrand]
};
