import cookies from 'js-cookie';

import {
    redirectToLogin
} from 'src/js/lib/auth';

import {
    USER_TOKEN_COOKIE
} from 'src/js/constants/actions/xelacore';

export default function reduxAuth(store) {
    return permissions => (nextState, replace, next) => {
        const { xelacore } = store.getState();

        // Check if the user is already in an authorised session, if yes proceed // with no lag.
        if (xelacore.auth.token && xelacore.auth.userData && !permissions)
            return next();

        // Otherwise, check if the user has been signed in and their session is
        // still active. Otherwise, redirect to /login
        const token = cookies.get(USER_TOKEN_COOKIE);

        if (!token) {
            redirectToLogin(false, window.location.pathname);
        }

        // TODO: Put route permissions into here
        next();
    };
}
