import React from 'react';
import { checkSetUrl } from 'src/js/helpers/dataHelpers';
import { MULTIPLE_VALUES_PLACEHOLDER } from 'src/js/constants/myRecordsConstants';

export default function Input({
    name,
    type,
    onChange,
    onBlur,
    onFocus,
    value,
    id,
    defaultValue,
    placeholder,
    isMultipleValues,
    disabled
}) {
    // Automatically prepend 'https' to URl form types
    const setValue = () => {
        const len = value ? value.length : '';
        if (type === 'url' && len > 0) {
            // Check if the user only started to input
            // plus handle situations like ba.com
            const uxTry = len < 15 && value.indexOf('.') === -1;
            if (
                value.indexOf('http://') > -1 ||
                value.indexOf('https://') > -1 ||
                uxTry
            )
                return value;
            return checkSetUrl('https://' + value);
        }
        return value;
    };

    return (
        <input
            name={name}
            type={type}
            onChange={onChange}
            value={setValue()}
            onBlur={onBlur}
            maxLength={name === 'product_name' ? 255 : ''}
            onFocus={onFocus}
            id={id}
            defaultValue={defaultValue}
            placeholder={isMultipleValues ? MULTIPLE_VALUES_PLACEHOLDER : placeholder}
            disabled={disabled}
        />
    );
}
