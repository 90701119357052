import React from 'react';
import Tile from './Tile';

export default function OpaSearchPanelItems({ items, toggleProductInfo, approvalCodes = [] }) {
    if (!items || !items.length) return null;
        
    return (
        <div className="c-product__opa-search-results">
            {items.map(el => (
                <Tile
                    key={el.xelacore_concept_id}
                    data={el}
                    alreadyLinked={approvalCodes.some(s => s === el.xelacore_concept_id)}
                    setActiveProduct={() => toggleProductInfo(el, true, items)}
                />
            ))}
        </div>
    );
}