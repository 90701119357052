import React from 'react';
import config from 'config';
import { connect } from 'react-redux';
import openSocket from 'socket.io-client';
import { displayNotification, updateIngestions } from 'src/js/actions/xelacore';

class SocketV3 extends React.Component {
  constructor(props) {
    super(props);
    this.websocket = null;
  }

  componentDidMount() {
    this.websocket = openSocket(config.baseUrl, {
      path: '/v3/socket.io',
      query: { token: this.props.token }
    });

    this.websocket.on('event', (event) => {
      if (event.name === 'RegistrationRecordIngestionCreated' || event.name === 'RegistrationRecordIngestionCompleted') {
        this.props.updateIngestions([event.message]);

          const { recordsRejected, recordsSubmitted } = event.message;
          if (recordsSubmitted <= recordsRejected) {
              setTimeout(()=>{
                  document.dispatchEvent(new CustomEvent('onlyRejectedRecordsIngested',{detail:'data'}));
              },1);
          }
      }

      if (event.name === 'RegistrationRecordIngestionCompleted' &&
        event.message.jobType === 'insert' &&
        event.message.destination === 'xelacore-catalog') {
        if (event.message.status == 'completed' &&
          event.message.userId == this.props.userId) {
          this.props.displayNotification('success', 'An ingestion has completed', 5000);
        }
        if (event.message.status == 'failed' &&
          event.message.userId == this.props.userId) {
          this.props.displayNotification('error', 'An ingestion has failed', 5000);
        }
      }
    });
  }

  componentWillUnmount() {
    this.websocket.disconnect();
  }

  render() {

    return null;
  }
}

function mapStateToProps(state) {
  return {
    token: state.xelacore.auth.token,
    userId: state.xelacore.auth.userData.user_id
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateIngestions: (ingestions) => dispatch(updateIngestions(ingestions)),
    displayNotification: (type, message, timeout) => dispatch(displayNotification({ type, message, timeout }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocketV3);
