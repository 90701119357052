import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { SidePanel } from 'src/js/components/static';

import orderBy from 'lodash/orderBy'

function VersionControl({close, versions}) {
    return (
        <SidePanel closeBtn width="500px" close={close}>
            <div className="c-upload-history versions-modal">
                <div className="versions-header">
                    <h3 className="">
                        Version control
                    </h3>
                </div>

                <div className='versions-wrapper'>
                    <table className='versions-table'>
                        <thead>
                            <tr>
                                {/* <th className='image-th'></th>
                                <th>Name</th> */}
                                <th>Version</th>
                                <th>Date generated</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            { orderBy(versions, 'versionNumber', 'desc').map(version => {
                                return <tr>
                                    {/* <td className='image-td'>
                                        <img src=""></img>
                                    </td>
                                    <td>Katie Williams</td> */}
                                    <td>{version.versionNumber}.0</td>
                                    <td>{moment(version.createdAt).format('YYYY.MM.DD')}</td>
                                    <td>
                                        <span className={`download ${version.status === 'ingested' || version.status === 'empty' ? 'disabled' : ''}`}>Download</span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </SidePanel>
    );
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(VersionControl);
