import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';
import { createQueryString } from 'src/js/helpers/objects';

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {string} data.first_name
 * @param  {string} data.last_name
 * @param  {string} data.email
 * @param  {targetMarket} data.phone
 * @param  {language} data.password
 * @param  {language} data.confirmPassword
 * @return {Promise}
 */
export function createNewUser(data) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'signup'].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @return {Promise}
 */
export function confirmUserEmail(params) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'confirm_email'].join('/'),
            method: 'patch',
            params
        })
        .then(responseHandler);
}

/**
 * Posts a request to invite a user to the plattyform
 *
 * @param  {String} orgId
 * @param  {Object} userData
 * @return {Promise}
 */

export function inviteUserToOrganisation(orgId, userData) {
    const newUserData = userData.invites[0];

    return axios
        .request({
            url: [config.urls.auth, 'user'].join('/'),
            method: 'post',
            data: newUserData
        })
        .then(responseHandler);
}
// export function inviteUserToOrganisation(orgId, userData) {
//   console.log(userData);
//   return axios.request({
//     url: [config.urls.auth, 'organisations', orgId, 'employees'].join('/'),
//     method: 'post',
//     data: userData
//   }).then(responseHandler)
// }

/**
 * Request to delete a user from the organisation
 *
 * @param  {String} employeeId
 * @param  {String} orgId
 * @return {Promise}
 */

export function deleteUserFromOrganisation(employeeId, orgId) {
    return axios
        .request({
            url: [
                config.urls.auth,
                'organisations',
                orgId,
                'employees',
                employeeId
            ].join('/'),
            method: 'delete'
        })
        .then(responseHandler);
}

/**
 * Fetch a list of roles and descriptions
 */
export function fetchRoles(orgId) {
    return axios
        .request({
            url: [config.urls.auth, 'organisations', orgId, 'roles'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch a list of roles and descriptions
 */

export function fetchInvitedUsers(orgId, query, pagination) {
    const mergedQuery = Object.assign({}, createQueryString(query), pagination);
    return axios
        .request({
            url: [config.urls.auth, 'organisations', orgId, 'employees'].join(
                '/'
            ),
            method: 'get',
            params: Object.assign({}, mergedQuery)
        })
        .then(responseHandler);
}

export function fetchInvitedUser(orgId, employeeId) {
    return axios
        .request({
            url: [
                config.urls.auth,
                'organisations',
                orgId,
                'employees',
                employeeId
            ].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

export function fetchLicensors(orgId) {
    const url = [config.urls.policy, 'links?licensee_id='].join('/');
    return axios
        .request({
            url: [url, orgId].join(''),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Sets a brand new user up on the system
 */
export function registerNewUser(inviteToken, data) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'invite', inviteToken].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * Fetch a list of brands
 */
export function fetchBrands(orgId) {
    return axios
        .request({
            url: [config.urls.policy, 'brand', orgId, 'brands'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch a list of Franchises
 */
export function fetchFranchises(orgId, brandId) {
    return axios
        .request({
            url: [
                config.urls.policy,
                'brand',
                orgId,
                brandId,
                'franchises'
            ].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch a list of Characters
 */
export function fetchCharacters(orgId, brandId, franchiseId) {
    return axios
        .request({
            url: [
                config.urls.policy,
                'brand',
                orgId,
                brandId,
                franchiseId,
                'characters'
            ].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch a list of Territories
 */
export function fetchTerritories() {
    return axios
        .request({
            url: [config.urls.policy, 'territories'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Fetch a list of Categories
 */
export function fetchCategories() {
    return axios
        .request({
            url: [config.urls.policy, 'categories'].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}

/**
 * Patch an existing Employee
 */
export function updateEmployee(orgId, employeeId, data) {
    return axios
        .request({
            url: [
                config.urls.auth,
                'organisations',
                orgId,
                'employees',
                employeeId
            ].join('/'),
            method: 'patch',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @return {Promise}
 */
export function acceptInvite(invite_token) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'invite', invite_token].join('/'),
            method: 'patch'
        })
        .then(responseHandler);
}
