import config from '../../../config';
import cookies from 'js-cookie';
import axios from 'axios';
import {
    USER_REFRESH_TOKEN_COOKIE,
    USER_TOKEN_COOKIE,
    XELACORE_DOMAIN
} from 'src/js/constants/actions/xelacore';

export const redirectToLogin = (logout, pathname) => {
    const source = config.services.reg.split('://')[1] + (!logout ? (!!pathname && pathname.split('/').length > 1 ? pathname : ' ') : ' ');
    const path = `${config.services.login}?source=${source}`;
    window.location.replace(path);
};

export const logout = (logout) => {
    let pathname = !logout ? window.location.pathname : '';

    return axios
        .request({
            url: [config.urls.oauth, 'logout'].join('/'),
            method: 'get'
        })
        .then(() => {
            clearAuthData();
            redirectToLogin(logout, pathname);
        })
        .catch(() => {
            clearAuthData();
            redirectToLogin(logout, pathname);
        });
};

export const clearAuthData = () => {
    clearUserCookies();
};

export const clearLocalStorage = () => {
    var selectedLicensor = localStorage.getItem('selectedLicensor');
    var licensorsCount = localStorage.getItem('licensorsCount');
    var dismissProductDetailsInfo = localStorage.getItem('dismissProductDetailsInfo');
    var dismissConceptInfo = localStorage.getItem('dismissConceptInfo');
    localStorage.clear();
    localStorage.setItem('selectedLicensor', selectedLicensor);
    localStorage.setItem('licensorsCount', licensorsCount);
    localStorage.setItem('dismissProductDetailsInfo', dismissProductDetailsInfo);
    localStorage.setItem('dismissConceptInfo', dismissConceptInfo);
}

export const clearUserCookies = () => {
    cookies.remove(USER_REFRESH_TOKEN_COOKIE, { domain: XELACORE_DOMAIN });
    cookies.remove(USER_TOKEN_COOKIE, { domain: XELACORE_DOMAIN });
    clearLocalStorage();
};

export const getIsAuthDataPresent = () => {
    const userRefreshToken = cookies.get(USER_REFRESH_TOKEN_COOKIE);
    const userToken = cookies.get(USER_TOKEN_COOKIE);
    return !!userRefreshToken && !!userToken;
};
