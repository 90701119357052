import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

// @todo move component to shared components (on concept and product gallery component unification)

class ZoomableImageItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImgZoomable: false,
            isImgZoomed: false
        };
    }

    componentWillReceiveProps() {
        this.setState({
            isImgZoomed: false
        });
    }


    arrayPaginate(array, pageSize, page) {
        return array.filter((item, index) => {
            return (index < pageSize * page) &&
                (index > (pageSize * (page - 1) - 1));
        });
    }

    getCurrentImageIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    getZoomValue(img) {
        const currentWidth = img.offsetWidth;
        const currentHeight = img.offsetHeight;
        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;
        return Math.max(
            Math.min(
                naturalHeight / currentHeight,
                naturalWidth / currentWidth
            ),
            1
        ).toFixed(1);
    }

    zoomImage(e) {
        const img = e.target;
        const zoomValue = this.state.isImgZoomed ? 1 : this.getZoomValue(img);
        img.style.transform = `scale(${zoomValue})`;
        this.setState(state => ({
            isImgZoomed: !state.isImgZoomed
        }));
    }

    imageOnLoad(e) {
        const img = e.target;
        const currentWidth = img.offsetWidth;
        const currentHeight = img.offsetHeight;
        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;
        this.setState({ isImgZoomable: currentWidth < naturalWidth && currentHeight < naturalHeight });
    }


    handleMouseMove(e) {
        if (!this.state.isImgZoomed) {
            return;
        }
        const img = e.target;
        const { clientX, clientY } = e;
        const {
            top,
            left,
            height,
            width
        } = img.parentElement.getBoundingClientRect();
        if (
            clientY < top ||
            clientY > top + height ||
            clientX < left ||
            clientX > left + width
        ) {
            return;
        } else {
            const zoomValue = this.getZoomValue(img);
            img.style.transform = `scale(${zoomValue})`;
        }
        const xPosition = ((clientX - left) / width) * 100;
        const yPosition = ((clientY - top) / height) * 100;
        const transformOriginValue = `${xPosition}% ${yPosition}% 0`;
        img.style.transformOrigin = transformOriginValue;
    }

    handleMouseOut(e) {
        const img = e.target;
        img.style.transform = 'scale(1)';
    }

    render() {
        const {
            isImgZoomed,
            isImgZoomable
        } = this.state;
        const {
            imageName,
            imageUrl,
            placeholderImage
        } = this.props;

        return (
            <div
                className={classNames(
                    'zoomable-image',
                    {
                        'not-zoomable-image':!isImgZoomable
                    },
                    {
                        'c-product__current-img-holder-zoomable':
                            isImgZoomed === false && !!isImgZoomable &&
                            (placeholderImage !== imageUrl)
                    },
                    {
                        'c-product__current-img-holder-zoomed':
                            isImgZoomed === true &&
                            (placeholderImage !== imageUrl)
                    }
                )}
            >
                <div className={'image-background'}>
                    <img
                        src={imageUrl}
                        alt={imageName}
                    />
                </div>

                <img
                    onLoad={(e) => {
                        this.imageOnLoad(e);
                    }}
                    className={classNames(
                        'preview-image', {
                            'not-zoomable-image':!isImgZoomable
                        },
                        {
                            'c-product__current-img-holder-zoomable':
                                isImgZoomed === false && !!isImgZoomable &&
                                (placeholderImage !== imageUrl)
                        },
                        {
                            'c-product__current-img-holder-zoomed':
                                isImgZoomed === true &&
                                (placeholderImage !== imageUrl)
                        }
                    )}
                    onClick={(e) => {
                        if (placeholderImage !== imageUrl && isImgZoomable) {
                            this.zoomImage(e);
                        }

                    }}
                    onMouseMove={(e) => {
                        this.handleMouseMove(e);
                    }}
                    onMouseOut={(e) => {
                        this.handleMouseOut(e);
                    }}
                    src={imageUrl}
                    alt={imageName}
                />
            </div>
        );
    }
}

export default connect()(ZoomableImageItem);
