import React, { Component } from 'react';
import {
    fetchCategories,
    fetchCategoriesV3
} from 'src/js/apicalls/other/categories';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import map from 'lodash/map';
import { Icon } from 'src/js/components/static';
import { parseV1Response } from 'src/js/helpers/arrays';

export default class extends Component {
    constructor(props) {
        super(props);

        const {value = []} = props;
        this.state = {
            value: value,
            categoryLevels: [],
            categories: []
        };
    }

    componentDidMount() {
        const { reliesOn } = this.props;

        if (!reliesOn) return this.fetchData();
    }

    // Fetch the data as normal
    fetchData() {
        const { xelacore, isAgent } = this.props;
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;
        let selectedLicensor = !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}


        if(isAgent) {
            fetchCategoriesV3(selectedLicensor.licensor_organisation_id, token).then((response) => {
                this.setState({
                    categories: !!response.data && map(response.data, item => parseV1Response(item)),
                    categoryLevels: response.data && response.data.categoryLevels
                })
            });
        } else {
            fetchCategories(organisation_id, token).then((response) => {
                this.setState({
                    categories: response.data && response.data.categories,
                    categoryLevels: response.data && response.data.category_levels
                })
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { value } = this.state;

        if (
            !isEqual(prevProps.value, value)
        ) {
            this.setState({
                value: prevProps.value
            })
        }
    }

    render() {
        const { value, categoryLevels, categories } = this.state;
        const { validations } = this.props;

        let valueToDisplay = !!value && typeof value === 'string'
                                ? !!categories && !!find(categories, category => category.category_id === value) && find(categories, category => category.category_id === value).string_path
                                : value;

        return (
            <div className='c-product-category-levels'>
                {categoryLevels.map((level, key) => {
                    let validation = find(validations, item => {
                        return item.field === `licensee_category_path.${key}`
                    });

                    return (
                        <div className={`c-product-category-levels-level ${!!validation && validation.level}`}>
                            <div className='c-product__detail-listing-item c-product__detail-listing-item--flex'>
                                <span className='c-product__detail-listing-item-title'>{level}</span>
                                
                                { !validation && (
                                    <span className='c-product__detail-listing-item-info'>{!!valueToDisplay && valueToDisplay[key]}</span>
                                )}

                                { !!validation && (
                                    <div className='u-flex'>
                                            <div className="c-product__detail-listing-item-icon">
                                                <Icon
                                                    icon={
                                                        validation.level === 'error'
                                                            ? 'error2'
                                                            : 'warning'
                                                    }
                                                    fill={
                                                        validation.level === 'error'
                                                            ? 'red'
                                                            : 'darkOrange'
                                                    }
                                                    className="u-margin-small-right"
                                                    top={2}
                                                    size="12"
                                                />
                                            </div>
                                            <span className="validation-message">
                                                {validation.message}
                                            </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}
