import React from 'react';
import moment from 'moment';
import Text from './elements/Text';
import ObjectField from './elements/ObjectField';
import Textarea from './elements/Textarea';
import Url from './elements/Url';
import ClickableText from './elements/ClickableText';
import DynamicIp from './elements/DynamicIp';
import { formatValue } from 'src/js/helpers/strings';
export { default as ObjectField } from './elements/ObjectField';
export { default as ClickableText } from './elements/ClickableText';
export { default as AsinArray } from './elements/AsinArray';
export { default as DynamicArray } from './elements/DynamicArray';
export { default as DynamicCategory } from './elements/DynamicCategory';
export { default as DynamicIps } from './elements/DynamicIps';
export { default as Text } from './elements/Text';
export { default as Textarea } from './elements/Textarea';
export { default as Url } from './elements/Url';
export { default as Thumbnail } from './elements/Thumbnail';

export const renderElement = (data, type, value, dataName, func, expand = true) => {
    const text = <Text value={formatValue(value)} />;

    switch (type) {
        case 'date':
            return  formatDate(value);
        case 'time':
        case 'duration': {
            const date = moment(value).format('DD MMM YYYY');
            const time = moment(value).format('hh:mm a');
            return (
                <div>
                    <span>{date}</span>
                    <br/>
                    <span>{time}</span>
                </div>
            );
        }
        case 'selectdropdown':
        case 'dynamicArray':
        case 'object':
        case 'array':
        case 'imageArray':
        return (
            <ObjectField value={value} type={dataName} expand={expand} />
        );
        case 'textarea':
            return <Textarea value={value} expand={expand} />;
        case 'url':
            return <Url value={value} isFullText={expand}/>;
        case 'clickableText':
            return func ? (
                <ClickableText func={() => func()} value={value} />
            ) : (
                text
            );
        case 'asinArray':
            return <Text value={`${!!value && value[0] && value[0].source || ''}`}/>;
        case 'dynamicIp':
            return <DynamicIp data={data} dataName={dataName}/>
        default:
            return text;
    }
};

const formatDate = (date) => {
    if(!date) return '';
    if(moment(date, 'DD MMM YYYY').isValid()) return date;
    return  moment(date).format('DD MMM YYYY');
};
