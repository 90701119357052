import React, { Component } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import {
    fetchBrandAgencies
} from 'src/js/apicalls/other/categories';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';

export default class DynamicAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.data.agent_organisation_id || '',
            brand: props.data.licensor_brand_id,
            agencies: props.agenciesData || []
        };
    }

    componentDidUpdate(prevProps) {
        const { data, agenciesData } = this.props;

        if (prevProps.data.agent_organisation !== this.props.data.agent_organisation) {
            this.setState({
                value: data && data.agent_organisation_id || ''
            });

            if(this.props.data.ips && this.props.data.ips[0] && this.props.data.ips[0][0]) {
                this.fetchAgencies(this.props.data.ips[0][0].ip_id);
            }
        }

        if ((prevProps.agenciesData !== this.props.agenciesData)) {
            this.setState({
                agencies: agenciesData || [],
                value: null
            });
        }
        
        if ((prevProps.data && prevProps.data.ips && prevProps.data.ips[0] && prevProps.data.ips[0][0].ip_id !== this.props.data.ips[0][0].ip_id)) {
            this.fetchAgencies(data.ips[0][0].ip_id);

            this.setState({
                brand: data.ips[0][0].ip_id
            });
        }
    }

    fetchAgencies(ip_id) {
        if(ip_id) {
            return fetchBrandAgencies(ip_id).then(response => {
                this.setState({
                    agencies: response && response.data && map(response.data, item => {
                    return {
                        label: item.agentOrganisationName,
                        value: item.agentOrganisationId
                    }}),
                    value: null
                })
            })
        }
    }

    render() {
        const { value, agencies, brand } = this.state;
        const { data, onChange } = this.props;

        return (
            <CustomSelect
                data={data}
                onChange={onChange}
                value={value}
                unselectOption={'Unselect'}
                fetchValues={() => {}}
                disabled={!size(data.licensor_brand_id) && !size(brand)}
                values={agencies}
                allowEmpty={false}
            />
        )
    }
}
