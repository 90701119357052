import { SET_USER_PERMISSIONS } from 'src/js/constants/actions/xelacore';
import isEmpty from 'lodash/isEmpty';
import { getAllUserInfo } from 'src/js/apicalls/auth/user';

export const setUserPermissions = (payload) => ({
    type: SET_USER_PERMISSIONS,
    payload
});

export function getUserPermissions(orgId, employeeId) {
    return (dispatch) => {
        if (!orgId)
            return Promise.reject('No orgId here. What org do they belong to?');
        if (!employeeId) return Promise.reject('No id here. Who is the user?');

        return getAllUserInfo().then((resp) => {
            const userData = (resp.data.employees || []).find(
                (e) => e.organisation_id === orgId
            );
            if (!isEmpty(userData)) {
                dispatch(setUserPermissions(userData.permissions));
            }
        });
    };
}
