import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import findIndex from 'lodash/findIndex';
import { Icon } from 'src/js/components/static';

import {
    parsedNewFilters,
    parsedNewQuery
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';

import { PAGE_LIMIT } from 'src/js/constants/dataConstants';
import { getUrlParam } from 'src/js/helpers/strings';

class HeaderSearchItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: this.props.inputValue,
            searchItems: this.props.searchItems,
            searchBox: this.props.searchBox
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps);
        }
    }

    onSearch(event, el) {
        if (event.key === 'Enter') {
            this.searchItems(event, el);
        }
    }

    searchItems(event, el) {
        const { searchItems } = this.state;
        const {
            openSearchBox,
            basicFilterObj,
            queryObject,
            updateFiltersState,
            categoryLevels
        } = this.props;

        let existingValueIndex = findIndex(searchItems, (item) => {
            return item.label === el.label;
        });

        let newValue = {
            label: el.label,
            value: event.target.value
        };

        let newFilters = parsedNewFilters(el.dataName, event, basicFilterObj);
        let newQueryObj = parsedNewQuery(el.dataName, event, queryObject, categoryLevels);

        if (existingValueIndex > -1) {
            this.state.searchItems[existingValueIndex].value =
                event.target.value;
        } else {
            this.state.searchItems.push(newValue);
        }

        this.setState({
            searchItems: searchItems
        });

        openSearchBox(el);

        updateFiltersState(newQueryObj, newFilters, true, { page: 1, page_size: getUrlParam('page_size') || PAGE_LIMIT });
    }

    clearSearch(event) {
        event.preventDefault();

        this.setState({
            inputValue: ''
        });
    }

    render() {
        const { inputValue } = this.state;

        const {
            el,
            elKey,
            searchBox,
            openSearchBox,
            popoverRef,
            handleChangeSearch
        } = this.props;

        return (
            <Fragment>
                <div className="c-table-search">
                    <Icon
                        className="u-clickable"
                        icon="search"
                        fill="white"
                        onClick={() => openSearchBox(el)}
                    />
                    {searchBox === elKey && (
                        <div
                            className="c-table-search__popover"
                            ref={popoverRef}
                        >
                            <input
                                className="form-control"
                                autoFocus
                                value={inputValue && inputValue.value}
                                onChange={handleChangeSearch}
                                onKeyPress={(event) => this.onSearch(event, el)}
                                placeholder={'Search for ' + el.label}
                            />
                            <Icon
                                className="u-clickable clear-icon"
                                onClick={(event) => this.clearSearch(event, el)}
                                icon="BIN"
                                fill="gray"
                            />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default connect(
    (state) => ({ ...state }),
    (dispatch) => ({ dispatch })
)(HeaderSearchItem);
