import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import MyRecordsButtonSection from 'src/js/modules/MyRecords/routes/MyRecords/components/ButtonSection/ButtonSection';
import IngestionSection from 'src/js/modules/MyRecords/routes/MyRecords/components/IngestionSection/IngestionSection';
import CatalogueButtonSection
    from 'src/js/modules/ProductCatalogue/routes/ProductCatalogue/components/ButtonSection/ButtonSection';
import UploadHistory from 'src/js/components/UploadHistory/UploadHistory';
import DataFormat from 'src/js/modules/MyRecords/routes/MyRecords/components/DataFormat/DataFormat';
import NoData from '../NoData/NoData';
import UploadPanel from 'src/js/components/ProductComponents/UploadPanel/UploadPanel';
import { Auth } from 'src/js/components/static';
import { isAgent } from 'src/js/helpers/permissions';
import { companyData } from 'src/js/actions/xelacore';

class TopHeaderSection extends Component {
    constructor(props) {
        super(props);
        // First condition from upload=true url
        // second - show controlled (closable) uploader with no data
        this.state = {
            showUploaderState: props.showUploader === 'true' || props.noData,
            showDataFormat: false
        };
    }

    componentDidUpdate(prevProps) {
        const {
            noData,
            isMyRecords,
            totalItems,
            toggleUploader,
            uploaderManuallyClosed,
            showUploader,
            fetching
        } = this.props;

        if (!showUploader && isMyRecords && !uploaderManuallyClosed && (noData !== prevProps.noData) && ((totalItems || 0) < 1) && !fetching) {
            toggleUploader();
        }
    }

    toggleDataFormat() {
        this.setState({ showDataFormat: !this.state.showDataFormat });
    }

    render() {
        const {
            showUploaderState,
            showDataFormat
        } = this.state;

        const {
            dispatch,
            data,
            uploadInfoId,
            pageNotification,
            fetchData,
            fetching,
            noData,
            isMyRecords,
            gtinWaiver,
            selectLicensor,
            isLicensor,
            xelacore,
            selectedLicensor,
            count,
            totalItems,
            toggleUploader,
            showUploader,
            validationsExist,
            toggleUploads,
            showUploads
        } = this.props;

        const myRecordNotification =
            pageNotification && pageNotification['my-records'];
        const showIngestBar =
            myRecordNotification && myRecordNotification.showBar;
        const showMyRecordsButtons =
            isMyRecords;
        const showCatalogueButtons = !isMyRecords;
        const showMyRecordsNoData =
            isMyRecords &&
            noData &&
            !fetching &&
            !showIngestBar;
        const showCatalogueNoData =
            !isMyRecords && noData && !showUploader && !fetching;

        return (
            <Fragment>
                {showMyRecordsButtons && (
                    <MyRecordsButtonSection
                        dispatch={dispatch}
                        location={this.location}
                        productUploads={showUploads}
                        toggleUploads={() => toggleUploads()}
                        uploadInfoId={uploadInfoId}
                        showUploader={showUploader}
                        toggleLists={() => this.toggleLists()}
                        noData={noData}
                        showDataFormat={showDataFormat}
                        toggleDataFormat={() => this.toggleDataFormat()}
                        toggleUploader={(val) => toggleUploader(val)}
                        pageNotification={pageNotification}
                        showIngestBar={showIngestBar}
                        isMyRecords={isMyRecords}
                        totalItems={isAgent(companyData) ? count : totalItems}
                        selectLicensor={selectLicensor}
                        validationsExist={validationsExist}
                    />
                )}

                {showCatalogueButtons && (
                    <CatalogueButtonSection
                        dispatch={dispatch}
                        location={this.location}
                        showUploader={showUploader}
                        toggleUploader={(val) => toggleUploader(val)}
                        selectLicensor={selectLicensor}
                        isLicensor={isLicensor}
                        totalItems={count}
                    />
                )}

                {isMyRecords && (showUploader || showUploaderState) && !validationsExist && (
                    <Auth restrictTo={'licensee'}>
                        <UploadPanel
                            toggleDataFormat={() => this.toggleDataFormat()}
                            close={(val) => toggleUploader(val)}
                            toggleUploads={() => toggleUploads()}
                            dispatch={dispatch}
                            gtinWaiver={gtinWaiver}
                            isOpaUploader={!isMyRecords}
                            noData={noData}
                        />
                    </Auth>
                )}

                {isMyRecords && showDataFormat && (
                    <DataFormat
                        width="600px"
                        close={() => this.toggleDataFormat()}
                    />
                )}

                {isMyRecords && showUploads && (
                    <UploadHistory
                        close={() => toggleUploads()}
                        dispatch={dispatch}
                        id={uploadInfoId}
                    />
                )}

                {showMyRecordsButtons && (
                    <IngestionSection
                        dispatch={dispatch}
                        location={this.location}
                        productUploads={showUploads}
                        pageNotification={pageNotification}
                        showIngestBar={showIngestBar}
                        fetchData={fetchData}
                        isMyRecords={isMyRecords}
                        toggleUploader={(val) => toggleUploader(val)}
                        xelacore={xelacore}
                        isOpaUploader={!isMyRecords}
                        selectedLicensor={selectedLicensor}
                    />
                )}

                {(showCatalogueNoData || showMyRecordsNoData) && (
                    <NoData
                        data={data}
                        toggleUploader={(val) => toggleUploader(val)}
                        showUploader={showUploader}
                        showDataFormat={showDataFormat}
                        toggleDataFormat={() => this.toggleDataFormat()}
                        isMyRecords={isMyRecords}
                        dispatch={dispatch}
                    />
                )}
            </Fragment>
        );
    }
}

function mapStateToProps(state, props) {
    const { isMyRecords } = props;
    const key = isMyRecords ? 'myRecords' : 'productCatalogue';
    const {
        xelacore: {
            [key]: {
                records: { data, totalItems, page, page_size } = {},
                fetching: { fetching, fetchErrors, initialFetch } = {},
                filters: { filters } = {},
                initialCount
            } = {},
            pageNotification
        } = {}
    } = state;

    return {
        data,
        totalItems,
        page,
        page_size,
        fetching,
        fetchErrors,
        initialFetch,
        initialCount,
        filters,
        pageNotification
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopHeaderSection);
