import {
    fetchOauthCode,
    fetchUserOrganisation,
    setUserOrganisation,
    resetUserPassword,
    setNewUserPassword
} from 'src/js/apicalls/auth/login';

export function getOauthCode({ email, password }) {
    return () => {
        if (!email) return Promise.reject('No email found, illegal action');
        if (!password)
            return Promise.reject('No password found, illegal action');

        return fetchOauthCode({ email, password });
    };
}

/**
 * Gets a list of organisations the user belongs to on the system
 *
 * @param  {String} token
 * @return {Promise}
 */

export function getUserOrganisations(token, id) {
    return (dispatch, getState) => {
        const theToken = token || getState().xelacore.auth;
        return fetchUserOrganisation(theToken, id);
    };
}

export function selectUserOrganisation(details) {
    return () => {
        if (!details) return Promise.reject('No details found, illegal action');
        return setUserOrganisation(details);
    };
}

/**
 * This action is dispatched when the user has provided the app with their
 * credentials
 *
 * @param  {Object} creds
 * @param  {String} creds.email
 * @return {Promise}
 */
export function resetPassword(creds) {
    return () => resetUserPassword(creds);
}

/**
 * This action is dispatched when the user has provided the app with their
 * credentials
 *
 * @param  {String} password
 * @param  {String} token
 * @return {Promise}
 */
export function setNewPassword(password, token) {
    return () => setNewUserPassword(password, token);
}
