export const stringToUrl = string => {
    return string
        .toLowerCase()
        .replace(/[^\wèéòàùì\s]/gi, '')
        .replace(/\s+/g, '-');
};

export const urlToString = string => {
    return string.replace(/-/g, ' ');
};

export const removeUnderscore = str => {
    return str.replace(/_/g, ' ');
};

export const removeHyphen = str => {
    return str.replace(/-/g, ' ');
};

export const pluralise = (str, val = 0) => {
    let newVal = val;
    if (isNaN(val)) newVal = 0;
    if (str === 'has') return Number(newVal) === 1 ? str : 'have';
    if (str === 'was') return Number(newVal) === 1 ? str : 'were';
    if (str === 'is') return Number(newVal) === 1 ? str : 'are';
    return Number(newVal) === 1 ? str : str + 's';
};

export const getUrlParam = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isEmail = email => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
};

export const isJson = str => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
};
export const isValidJson = str => {
    try{
        JSON.parse(str);
    }catch (e){
        return false;
    }
    return true;
};

export const formatValue = val => {
    if (typeof val === 'boolean') {
        return val ? 'Yes' : 'No';
    }
    return val;
};

export const getSubsting = (val, len) => {
    if (val.length <= len) return val;
    return val.substring(0, len) + '...';
};

export const getFilePathExtension = (string) => {
    const fileExt = String(string).match(/\.[0-9a-z]+$/i);
    return (fileExt && fileExt[0].toLowerCase()) || false;
};
