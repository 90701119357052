import axios from 'axios';
import config from 'config';
import responseHandler from '../apicalls/common/responseHandler';
import { fetchFilteredCatalogueInformation } from 'src/js/apicalls/common/filteredCatalogue';

export function getFilteredCatalogueInformation(
    query = {},
    collection,
    pagination,
    columns,
    isCsv
) {
    return () => {
        if (!query) return Promise.reject('No query found, illegal action');
        if (!collection)
            return Promise.reject('No collection found, illegal action');

        return fetchFilteredCatalogueInformation(
            query,
            collection,
            pagination,
            columns,
            isCsv
        );
    };
}

export function getAgentRecords(params, pageParams, licensorOrganisationId) {
    return axios
    .request({
        url: [
            config.urls.catalogv3,
            `organisation/${licensorOrganisationId}/catalog?aggregations=true&page=${!!pageParams && pageParams.page || 1}&page_size=${!!pageParams && pageParams.page_size || 25}`
        ].join('/'),
        method: 'post',
        data: params
    })
    .then(responseHandler);
}
