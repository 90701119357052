import React, { Component } from 'react';
import NoDataMyRecods from './NoDataMyRecods';
import NoDataCatalogue from './NoDataCatalogue';
import Button from 'modules/UiKit/components/Button/Button';

export default class NoData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            showUploader,
            toggleUploader,
            toggleDataFormat,
            isMyRecords,
            dispatch
        } = this.props;

        return (
            <div className="u-text-center u-margin-top-4X">
                <h3 className="c-my-records__no-data-header">
                    {isMyRecords ? 'Ready to register your products?' : 'No Products Available'}
                </h3>

                {isMyRecords && (
                    <NoDataMyRecods
                        showUploader={showUploader}
                        toggleUploader={toggleUploader}
                        toggleDataFormat={toggleDataFormat}
                        dispatch={dispatch}
                    />
                )}
                {!isMyRecords && (
                    <NoDataCatalogue
                        showUploader={showUploader}
                        toggleUploader={toggleUploader}
                    />
                )}


                {!showUploader && (
                    <Button
                        type="secondary"
                        size="small"
                        onClick={() => toggleUploader()}>
                        add products
                    </Button>

                )}
            </div>
        );
    }
}
