import {
    SET_PAGE_NOTIFICATION,
    DESTROY_PAGE_NOTIFICATION
} from 'src/js/constants/actions/xelacore';

export function pageNotification(state = {}, action = {}) {
    const { payload } = action;
    const { type } = payload || {};

    switch (action.type) {
        case SET_PAGE_NOTIFICATION:
            if (state && state[type]) {
                state[type] = payload[type];
                return { ...state };
            }
            return { ...state, ...{ [type]: payload[type] } };
        case DESTROY_PAGE_NOTIFICATION:
            delete state[type];
            return { ...state };
        default:
            return state;
    }
}
