import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';
import moment from 'moment';

/**
 * Fetches schemas
 *
 * @return {Promise}
 */
export function fetchSchemas(source = '') {
    return axios
        .request({
            url: [config.urls.catalog, 'schema', source].join('/'),
            method: 'get'
        })
        .then(responseHandler);
}
export function generateExportDoc(data) {
    return axios
        .request({
            url: [ config.urls.catalog,'records', 'export-doc'].join('/'),
            method: 'post',
            data:data,
            responseType: 'arraybuffer'
        })
        .then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
            const currentDate = moment().format('YYYY-MM-DD_HH-mm');

            const documentName = 'Fabacus__'+orgDetails.slug+'__'+currentDate;
            link.setAttribute('download', `${documentName}.${data.format}`);
            document.body.appendChild(link);
            link.click();
        });
}

export function bulkConcepts(data) {
    return axios
        .request({
            url: [config.urls.catalog, 'bulk_update', 'confirm'].join('/'),
            method: 'patch',
            data: data,
            params: {}
        })
        .then(responseHandler);
}
