import React from 'react';
import {
    licenseeFilter,
    licensorFilter,
    licenseeCatalogueFilter
} from './esFilterObjectDisplay';
import AdvancedFilteringSubHeader from './AdvancedFilteringSubHeader';
import AdvancedFilteringChildren from './AdvancedFilteringChildren';

import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { clone, toNumber } from 'lodash';

const AdvancedFilteringBody = ({
    toggleCollapsedGroup,
    collapsedGroup,
    toggleExpandedList,
    expandedList,
    originalAggregations,
    dynamicSpecialValues = {},
    aggregations,
    filterGroup,
    filterTerms,
    queryObject,
    licensor,
    type,
    inputValues,
    categoryLevels,
    filterBusy,
    ipLevels
}) => {
    const addLevelsToFilters = (object) => {
        let cloneObject = clone(object);
        let index = findIndex(cloneObject, obj => obj.key === 'Product Information');
        let findPositionToPush = findIndex(cloneObject[index].subgroups, obj => obj.key === 'Target Market');
        let newArr = [];
        let newCategoryArr = [];

        each(categoryLevels, level => {
            if (!includes(map(cloneObject[index].subgroups, 'key'), level)) {
                newCategoryArr.push({
                    key: level,
                    level: true,
                    subcategoryTitle: true,
                    collapse: true,
                    showSearch: true,
                    noChidren: true
                })
            }
        });

        each(ipLevels, level => {
            if (!includes(map(cloneObject[index].subgroups, 'key'), level.level_name)) {
                newArr.push({
                    index: toNumber(level.level_index),
                    key: level.level_name,
                    level_id: level.ip_level_id,
                    level: true,
                    subcategoryTitle: true,
                    collapse: true,
                    showSearch: true,
                    noChidren: true
                })
            }
        });

        object[index].subgroups.splice(findPositionToPush + 1, 0, ...orderBy(newArr, 'index'));
        object[index].subgroups.unshift(...newCategoryArr);

        return object;
    }

    const staticFilterObject = licensor
        ? licensorFilter
        : type === 'catalog'
        ? licenseeCatalogueFilter
        : licenseeFilter;
        
    const filterObject = addLevelsToFilters(staticFilterObject).map((group) => ({
        ...group,
        subgroups: group.subgroups
            ? group.subgroups.map((subgroup) => ({
                  ...subgroup,
                  specialValues:
                      dynamicSpecialValues[group.key] &&
                      dynamicSpecialValues[group.key][subgroup.key]
                          ? dynamicSpecialValues[group.key][subgroup.key]
                          : subgroup.specialValues
              }))
            : null
    }))

    return (
        <ul className={`c-advanced-filtering__body ${filterBusy ? 'disabled' : ''}`}>
            {filterObject.map((el, i) => {
                const { key, subgroups, onlySearch } = el;
                return (
                    <li
                        className="c-advanced-filtering__group"
                        key={`${key}-${i}-aggregations-list`}
                    >
                        {!onlySearch && (
                            <AdvancedFilteringSubHeader
                                el={key}
                                toggleCollapsedGroup={toggleCollapsedGroup}
                                collapsedGroup={collapsedGroup}
                                categoryLevels={categoryLevels}
                                subgroups={!!subgroups && subgroups.length > 0}
                            >
                                {subgroups &&
                                    !onlySearch &&
                                    subgroups.map((item) => {
                                        const {
                                            key: subkey,
                                            noChidren,
                                            subcategoryTitle
                                        } = item;

                                        return (
                                            <AdvancedFilteringSubHeader
                                                subcategoryTitle={
                                                    subcategoryTitle
                                                }
                                                noChidren={noChidren}
                                                el={subkey}
                                                key={subkey}
                                                toggleCollapsedGroup={
                                                    toggleCollapsedGroup
                                                }
                                                collapsedGroup={collapsedGroup}
                                                categoryLevels={categoryLevels}
                                            >
                                                <AdvancedFilteringChildren
                                                    aggregations={aggregations}
                                                    originalAggregations={
                                                        originalAggregations
                                                    }
                                                    el={item}
                                                    key={`${subkey}-children`}
                                                    filterGroup={filterGroup}
                                                    filterTerms={filterTerms}
                                                    queryObject={queryObject}
                                                    expandedList={expandedList}
                                                    toggleExpandedList={
                                                        toggleExpandedList
                                                    }
                                                    inputValues={inputValues}
                                                    categoryLevels={categoryLevels}
                                                    licensor={licensor}
                                                    filterBusy={filterBusy}
                                                />
                                            </AdvancedFilteringSubHeader>
                                        );
                                    })}
                                {!subgroups && !onlySearch && (
                                    <AdvancedFilteringChildren
                                        aggregations={aggregations}
                                        originalAggregations={
                                            originalAggregations
                                        }
                                        el={el}
                                        filterGroup={filterGroup}
                                        filterTerms={filterTerms}
                                        queryObject={queryObject}
                                        expandedList={expandedList}
                                        toggleExpandedList={toggleExpandedList}
                                        inputValues={inputValues}
                                        categoryLevels={categoryLevels}
                                        licensor={licensor}
                                        filterBusy={filterBusy}
                                    />
                                )}
                            </AdvancedFilteringSubHeader>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

export default AdvancedFilteringBody;
