import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import classnames from 'classnames';
import moment from 'moment';
import { nullToString } from 'src/js/helpers/objects';
import { getMainImage } from 'src/js/helpers/dataHelpers';
import { getIngestStatus } from 'src/js/actions/records';
import MetaComponent from './MetaComponent';
import { Auth, Modal, Triangle } from 'src/js/components/static';
import TooltipApprovedData from './TooltipApprovedData';

import SpecificDataCatalogue from './SpecificDataCatalogue';
import imgPlaceholder from 'statics/imgs/placeholder.jpg';
import { getFilteredFields } from 'src/js/helpers/dataHelpers';
import { renderElement } from 'src/js/components/ProductListElements';
import MediaView from 'src/js/components/MediaView';
import fallbackImageSmall from 'statics/imgs/fallback_img_small.png';

import Popover from 'react-simple-popover';
import Conflicts from 'src/js/modules/MyRecords/routes/MyRecords/MyRecordsTable/Conflicts/Conflicts.jsx';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import BulkLinkConcepts from 'components/BulkLinkConcepts/BulkLinkConcepts';
import { showModal } from 'src/js/actions/xelacore';

import { selectRow } from 'src/js/actions/productsFetch';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';

export default class ProductCatalogueTableRow extends Component {
    constructor(props) {
        super(props);
        this.newRecordsTime = 60;
        this.state = {
            metaVisible: false,
            uploader: null,
            profile_image: null,
            email: '',
            productOverlayData: {},
            showApprovalOverlay: false,
            checkedProductList: []
        };

        this.popoverRef = React.createRef();
    }

    setMeta() {
        const { dispatch, data, users = [], isMyRecords } = this.props;
        const { uploader, metaVisible } = this.state;

        const otherStates = {
            metaVisible: !metaVisible
        };

        if (uploader === null && isMyRecords) {
            return dispatch(getIngestStatus(data.meta.ingest_id)).then(resp => {
                const { uploaded_by } = resp.data.status;
                const user = users.find(el => el.employee_id === uploaded_by);
                const {
                    email,
                    information: { first_name, last_name, profile_image } = {}
                } = user;

                this.setState({
                    email,
                    profile_image,
                    uploader: `${first_name} ${last_name}`,
                    ...otherStates
                });
            });
        }
        return this.setState({ ...otherStates });
    }

    checkDuration(uploaded) {
        const now = moment(new Date());
        const end = moment(uploaded);
        const duration = moment.duration(now.diff(end));
        return duration.asSeconds() < this.newRecordsTime;
    }

    openProduct() {
        const { data: { record_id } = {}, isMyRecords } = this.props;
        const location = isMyRecords
            ? `records/${record_id}`
            : `catalog/${record_id}`;
        return browserHistory.push(`/product/${location}`);
    }

    linkSingleProduct() {
        const { data, dispatch, fetchData, resetConceptsRows, ipLevels, xelacore } = this.props;
        const modal = (
            <Modal
                title="Concept Linking"
                className="c-bulk-update__modal bulk-link-concepts-modal"
                body={
                    <BulkLinkConcepts
                        singleProduct={true}
                        rows={[data]}
                        fetchData={fetchData}
                        resetConceptsRows={resetConceptsRows}
                        ipLevels={ipLevels}
                        xelacore={xelacore}
                        dispatch={dispatch}
                    />
                }
            />
        );
        return dispatch(showModal(modal));
    }

    getRegisteredLink = () => {
        const { data } = this.props;
        return browserHistory.push(`/product/catalog/${data.record_id}`);
    };

    getValue = (data, dataName) => {
        const { licensor } = this.props;

        let categoryDataName = licensor ? 'licensor_category_path' : 'licensee_category_path';

        if (includes(dataName, categoryDataName)) {
            let index = dataName.split('.')[1];
            return data[categoryDataName] && data[categoryDataName][index] && data[categoryDataName][index] || '';
        }

        return data[dataName];
    };

    checkProduct = (e, product) => {
        let tempCheckedProductList = cloneDeep(this.state.checkedProductList);

        let productExist = findIndex(tempCheckedProductList, item => item.product_id === product.product_id) > -1;

        if (e.currentTarget.checked && !productExist) {
            if (!productExist) {
                tempCheckedProductList.push(product);
            }
        }

        this.setState({ checkedProductList: tempCheckedProductList });
        this.props.checkProduct(e, product);
    };


    render() {
        const {
            headers,
            showUpload,
            data,
            isMyRecords,
            licensor,
            highlighted,
            handleClick,
            quarantineOpen,
            setQuarantine,
            dispatch,
            selectedRows,
            previousRow,
            allProducts,
            sortedHeaders
        } = this.props;

        const {
            metaVisible,
            uploader,
            email,
            profile_image
        } = this.state;

        const { record_id, product_name, duplicates, images } = data;
        const isDuplicatedRecord = !!(duplicates
            ? parseInt(duplicates)
            : 0 !== 0);
        const cleanedData = nullToString(data);

        const fullLength = Object.keys(cleanedData).length + 3;

        const activeHeaders = getFilteredFields(licensor, headers).filter(
            f => f.active
        );
        const defaultImage = { imgPlaceholder };

        const image = getMainImage(images);

        const func = () => (!isDuplicatedRecord ? this.openProduct() : false);

        const imageCellCx = classnames(
            'c-rec-table__cell c-rec-table__image-cell',
            {
                'u-clickable': !isDuplicatedRecord
            }
        );
        const rowClasses = classnames('c-rec-table__primary-row', {
            'c-rec-table__highlighted': highlighted
        });

        return (
            <tbody>
            <tr className={rowClasses} onClick={handleClick}>
                <td className="c-rec-table__cell c-rec-table__checkbox checkbox-holder">
                    <Auth restrictTo="licensor">
                        <UiCheckbox
                            type="checkbox"
                            checked={selectedRows.filter(item => item.record_id === data.record_id).length > 0}
                            onClick={(e) =>
                                dispatch(
                                    selectRow(
                                        e,
                                        data,
                                        selectedRows,
                                        false, // isMyRecords
                                        data,
                                        [],
                                        allProducts,
                                        previousRow
                                    )
                                )
                            }>
                        </UiCheckbox>
                    </Auth>
                    <Auth restrictTo="licensee">
                        <UiCheckbox
                            type="checkbox"
                            checked={selectedRows.filter(item => item.record_id === data.record_id).length > 0}
                            onClick={(e) =>
                                dispatch(
                                    selectRow(
                                        e,
                                        data,
                                        selectedRows,
                                        false, // isMyRecords
                                        data,
                                        [],
                                        allProducts,
                                        previousRow
                                    )
                                )
                            }>
                        </UiCheckbox>
                    </Auth>

                    <div
                        className={`c-rec-table__meta-button ${
                            metaVisible
                                ? 'c-rec-table__meta-button--active'
                                : ''
                        }`}
                        onClick={(e) => {
                            e.stopPropagation();
                            this.setMeta();
                        }}
                    >
                        <span>
                            <Triangle purple up={metaVisible} />
                        </span>
                    </div>
                </td>

                <td
                    className="c-rec-table__cell c-rec-table__approval"
                    ref={(node) => {
                        this.popoverRef = node;
                    }}
                >

                    <div className="c-rec-table__status-icons">
                        <Auth restrictTo="licensee">
                            <TooltipApprovedData
                                record={data}
                                openProduct={() => this.linkSingleProduct()}
                                licensor={licensor}
                            />
                        </Auth>
                        <Auth restrictTo="licensor">
                            <TooltipApprovedData
                                record={data}
                                openProduct={() => this.openProduct()}
                                licensor={licensor}
                            />
                        </Auth>

                        {data.quarantine_total_count > 0 && !data.quarantine_resolved_local && (
                            <span
                                className="quarantine-icon"
                                ref={(node) => {
                                    this.popoverRef = node;
                                }}
                                onClick={() => setQuarantine(false)}
                            >
                                    Q
                                </span>
                        )}
                    </div>
                </td>

                <SpecificDataCatalogue
                    data={data}
                    licensor={licensor}
                    openProduct={() => this.openProduct()}
                    metaVisible={metaVisible}
                    setMeta={() => this.setMeta()}
                />

                <td onClick={() => func()} className={imageCellCx}>
                    <div className="c-rec-table__image-icon">
                        <MediaView
                            src={image}
                            alt={product_name}
                            fallbackSrc={fallbackImageSmall}
                            useImgTag
                        />
                    </div>
                    {image !== defaultImage && (
                        <MediaView
                            src={image}
                            className="c-rec-table__hover-image"
                            fallbackSrc={fallbackImageSmall}
                            useImgTag
                        />
                    )}
                </td>
                {sortedHeaders.map(({ form: { type }, dataName }, i) => (
                    <td
                        className={`c-rec-table__cell ${type === 'duration' ? 'u-no-text-transform' : ''}`}
                        key={`${type}-${dataName}-table-row-${i}`}
                    >
                        {renderElement(
                            data,
                            type,
                            this.getValue(data, dataName),
                            dataName,
                            func,
                            false
                        )}
                    </td>
                ))}
            </tr>

            {metaVisible && (
                <MetaComponent
                    data={data}
                    fullLength={fullLength}
                    isMyRecords={isMyRecords}
                    email={email}
                    profile_image={profile_image}
                    uploader={uploader}
                    activeHeaders={activeHeaders}
                    showUpload={showUpload}
                    isDuplicatedRecord={isDuplicatedRecord}
                    recordId={record_id}
                />
            )}

            <Popover
                placement="right"
                containerStyle={{
                    zIndex: 999,
                    width: '812px',
                    height: '444px',
                    background: '#fff',
                    padding: 0,
                    borderRadius: '5px'
                }}
                style={{
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '5px'
                }}
                showArrow={false}
                target={this.popoverRef}
                show={quarantineOpen === record_id}
            >
                <Conflicts
                    data={data}
                    getRegisteredLink={this.getRegisteredLink}
                    setConflicts={() => setQuarantine(record_id, true)}
                    id={record_id}
                    quarantineMode={true}
                    modalActions={false}
                />
            </Popover>
            </tbody>
        );
    }
}
