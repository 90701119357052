import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import isString from 'lodash/isString';
import toArray from 'lodash/toArray';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import each from 'lodash/each';
import map from 'lodash/map';

import { REFERENCE, STRING } from 'src/js/constants/ipsContstants';

import {
    mapLevels,
    removeShadowedIps,
    addValidations,
    findError
} from 'src/js/helpers/ipHierarchy';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';

const mapStateToProps = ({ xelacore }) => ({ xelacore });
const mapDispatchToProps = (dispatch) => ({ dispatch });

@connect(mapStateToProps, mapDispatchToProps)
export default class DynamicIps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: !!props.data && props.data.ips || [],
            ips: props.ips || [],
            groupedIps: {
                parent: []
            },
            filteredMapedList: mapLevels(props.ips, props.ipLevels, props.data && addValidations(props.data)) || [],
            multipleStrings: {}
        };
    }

    componentDidMount() {
        this.groupIps();
        this.parseMultipleStrings();
    }

    componentDidUpdate(prevProps) {
        const { ips, ipLevels, data } = this.props;

        if ((prevProps.ips !== this.props.ips) || (prevProps.ipLevels !== this.props.ipLevels) || (prevProps.data !== this.props.data)) {
            this.setState({
                filteredMapedList: mapLevels(ips, ipLevels, data && addValidations(data)) || [],
                value: data && data.ips || []
            });

            this.groupIps();
            this.parseMultipleStrings();
        }
    }

    parseMultipleStrings() {
        const { ipLevels, data } = this.props;

        let cloneValue = cloneDeep(data.ips);
        let newData = [];

        each(ipLevels, level => {
            let multiStringLevel = !!level.multi && level.data_type === STRING;

            if (!!multiStringLevel) {
                newData[level.ip_level_id] = [];
            }

            each(cloneValue, val => {
                each(val, v => {
                    if (!!level.multi && level.data_type === STRING && v.ip_level_id === level.ip_level_id) {
                        newData[level.ip_level_id].push({
                            id: uniqueId(),
                            value: v.value,
                            ip_level_id: v.ip_level_id
                        });
                    }
                });
            });
        });

        this.setState({
            multipleStrings: newData
        });
    }

    groupIps() {
        const { ips } = this.props;

        let groupedIps = groupBy(ips, (item) => {
            return [item['parent_ip_id'], item['ip_level_id']];
        });
        let parsedArr = [];

        each(groupedIps, (item, key) => {
            let newKey = !key.split(',')[0] ? 'parent' : key.replace(',', '_');

            parsedArr[newKey] = map(item, i => {
                return {
                    label: i.string_path[i.string_path.length - 1],
                    name: i.ip_id
                };
            });
        });

        this.setState({
            groupedIps: parsedArr
        });
    }

    handleChange(e, pathIndex, node) {
        if (!isString(e)) {
            e.preventDefault();
        }

        const { ipLevels, onChange, ips } = this.props;
        const { value } = this.state;

        let cloneValue = cloneDeep(value);
        let selectedValue = !!e && e || '';

        if (!node.multi && node.data_type === STRING) {
            let findValueIndex = findIndex(cloneValue, val => {
                return find(val, v => (v.value === node.value.value) && (v.ip_level_id === node.value.ip_level_id));
            });

            if (findValueIndex > -1) {
                each(cloneValue, (path, index) => {
                    each(path, (ip, i) => {
                        if (ip.ip_level_id === node.ip_level_id) {
                            cloneValue[index][i].value = selectedValue;
                        }
                    });
                });
            } else {
                let newValue = [];

                newValue.push(...this.findParents(node), {
                    ip_level_id: node.ip_level_id,
                    value: selectedValue
                });

                cloneValue.push(newValue);
            }
        }

        if (!node.multi && node.data_type === REFERENCE) {
            let findIp = find(ips, item => item.ip_id === selectedValue);

            if (!!cloneValue.length) {
                each(cloneValue, (ipsPath, index) => {
                    each(ipsPath, (ip, i) => {
                        cloneValue[index][pathIndex] = !!cloneValue[index][pathIndex] && cloneValue[index][pathIndex] || {};
                        cloneValue[index][pathIndex].ip_id = findIp.ip_id;
                        cloneValue[index][pathIndex].ip_level_id = findIp.ip_level_id;
                        cloneValue[index][pathIndex].parent_ip_id = findIp.parent_ip_id;
                        cloneValue[index][pathIndex].value = findIp.string_path[pathIndex];

                        if (i > pathIndex && !!cloneValue[index][i] && cloneValue[index][i].ip_id) {
                            let findChildIp = find(ips, item => item.ip_id === cloneValue[index][i].ip_id);
                            let findChildLevel = find(ipLevels, level => level.ip_level_id === findChildIp.ip_level_id);

                            if (!findChildLevel.multi || (!!findChildLevel.multi && findChildLevel.data_type === REFERENCE)) {
                                cloneValue[index][i] = '';
                            }
                        }
                    });
                });
            } else {
                cloneValue[0] = cloneValue[0] || [];
                cloneValue[0][pathIndex] = !!cloneValue[0][pathIndex] && cloneValue[0][pathIndex] || {};
                cloneValue[0][pathIndex].ip_id = findIp.ip_id;
                cloneValue[0][pathIndex].ip_level_id = findIp.ip_level_id;
                cloneValue[0][pathIndex].parent_ip_id = findIp.parent_ip_id;
                cloneValue[0][pathIndex].value = findIp.string_path[pathIndex];
            }
        }

        this.setState({
            value: this.parseValue(cloneValue),
            filteredMapedList: mapLevels(ips, ipLevels, cloneValue)
        });

        onChange(
            removeShadowedIps(this.parseValue(cloneValue)),
            'ips'
        );
    }

    addNewNode(tree) {
        const { multipleStrings } = this.state;

        multipleStrings[tree.ip_level_id].push({
            id: uniqueId(),
            value: '',
            ip_level_id: tree.ip_level_id
        });

        this.setState({
            multipleStrings
        });
    }

    removeNode(node, tree) {
        const { multipleStrings } = this.state;

        remove(multipleStrings[tree.ip_level_id], ip => {
            return ip.id === tree.id;
        });

        this.setState({
            multipleStrings
        });

        this.mapValue(node, tree, multipleStrings[tree.ip_level_id].length === 0);
    }

    selectUnselect(node, selectedValues) {
        const { value } = this.state;
        const { onChange } = this.props;

        let cloneValue = cloneDeep(value);
        let findOtherIps = filter(cloneValue, val => {
            return val[val.length - 1].ip_level_id !== node.ip_level_id;
        });

        let newValue = [];

        if (!!selectedValues && !!selectedValues.length) {
            remove(cloneValue, val => {
                return val[val.length - 1].ip_level_id === node.ip_level_id;
            });

            each(selectedValues, path => {
                newValue.push([...this.findParents(node), {
                    ip_level_id: node.ip_level_id,
                    value: path.label,
                    ip_id: path.name
                }]);
            });

            cloneValue.push(...newValue);
        } else {
            if (!!findOtherIps.length) {
                remove(cloneValue, val => {
                    return val[val.length - 1].ip_level_id === node.ip_level_id;
                });
            } else {
                cloneValue = [];
            }

            cloneValue.push([...this.findParents(node)]);
        }

        this.setState({
            value: removeShadowedIps(this.parseValue(cloneValue))
        });

        onChange(
            removeShadowedIps(this.parseValue(cloneValue)),
            'ips'
        );
    }

    findParents(node) {
        const { value } = this.state;
        const { ipLevels } = this.props;

        let cloneValue = cloneDeep(value);
        let parents = [];

        for (let i = 0; i < node.level_index; i++) {
            let findLevel = i === (node.level_index - 1)
                ? find(ipLevels, level => (level.level_index === i) && (level.ip_level_id === node.parent_ip_level_id))
                : find(ipLevels, level => level.level_index === i);
            let findPath = find(cloneValue, path => {
                return find(path, ip => ip.ip_level_id === findLevel.ip_level_id);
            });
            let findPathValue = remove(findPath, val => val.ip_level_id === findLevel.ip_level_id);

            parents.push(...findPathValue);
        }

        return parents;
    }

    mapValue(node, tree, lastItem) {
        const { onChange } = this.props;
        const { value, multipleStrings } = this.state;

        let cloneValue = cloneDeep(value);
        let findOtherIps = filter(cloneValue, val => {
            return !!val && !!val[val.length - 1] && val[val.length - 1].ip_level_id !== node.ip_level_id;
        });
        let newValue = [];

        if (!lastItem || !!findOtherIps) {
            remove(cloneValue, val => {
                return find(val, ip => {
                    return ip.ip_level_id === tree.ip_level_id;
                });
            });

            if (!!lastItem) {
                cloneValue.push([...this.findParents(node)]);
            } else {
                each(multipleStrings[tree.ip_level_id], path => {
                    newValue.push([...this.findParents(node), {
                        ip_level_id: path.ip_level_id,
                        value: path.value
                    }]);
                });

                cloneValue.push(...newValue);
            }
        } else {
            cloneValue = [this.findParents(node)];
        }

        this.setState({
            value: removeShadowedIps(this.parseValue(cloneValue))
        });

        onChange(
            removeShadowedIps(this.parseValue(cloneValue)),
            'ips'
        );
    }

    onChangeString(e, node, tree, index) {
        const { multipleStrings } = this.state;

        multipleStrings[tree.ip_level_id][index].value = e.target.value;

        this.setState({
            multipleStrings
        });

        this.mapValue(node, tree);
    }

    parseDropdownValue(value) {
        return map(value, val => {
            return {
                name: val.ip_id,
                label: val.value
            };
        });
    }

    parseValue(value) {
        let mappedValue = map(value, val => {
            return map(val, item => {
                return {
                    ip_id: item.ip_id,
                    value: item.value,
                    ip_level_id: item.ip_level_id
                };
            });
        });

        each(mappedValue, val => {
            remove(val, item => !item.ip_id && !item.value);
        });

        return this.filterValue(mappedValue);
    }

    filterValue(value) {
        let filterValue = filter(value, path => {
            return filter(path, item => {
                return !!item && !!item.value;
            }).length === (!!path && path.length);
        });

        return !filterValue.length ? [value[0]] : filterValue;
    }

    render() {
        const {
            groupedIps,
            filteredMapedList,
            multipleStrings
        } = this.state;
        const {
            ipLevels,
            data
        } = this.props;

        const Tree = ({ data = [] }) => {
            return (
                <div className="d-tree">
                    <ul className="d-flex d-tree-container flex-column">
                        {toArray(data).map((tree, key) => {
                            return (
                                <TreeNode node={tree} key={key} />
                            );
                        })}
                    </ul>
                </div>
            );
        };

        const TreeNode = ({ node }) => {
            const hasChild = node.children && node.children.length > 0;

            let existingParent = node.parent_ip_id + '_' + node.ip_level_id;
            let parent = !!groupedIps && groupedIps.parent || [];
            let ipLevel = !!groupedIps[existingParent] && groupedIps[existingParent] || [];
            let valuesArray = node.level_index === 0 ? parent : ipLevel;
            let disabled = (node.level_index > 0 && node.data_type === REFERENCE && valuesArray.length === 0)
                || (node.level_index > 0 && !data.ips)
                || (node.level_index > 0 && !!data.ips && !!data.ips.length && !!data.ips[0] && !!data.ips[0][0] && !data.ips[0][0].ip_id);
            let findAdditionalError = find(data.validations, validation => validation.field === `ips.${node.ip_level_id}`);

            return (
                <li className={`d-tree-node ${hasChild ? 'has-child' : ''}`}>
                    <div className="tree-wrapper">
                        <div className="col d-tree-node-wrapper">
                            <span className={'node-name'}>
                                {node.level_name}
                            </span>

                            {!!node.multi && node.data_type === REFERENCE && (
                                <div
                                    className={`c-form-element c-form-element__${!!findError(node) || !!findAdditionalError ? node.error_type : ''} node-value ${disabled ? 'disabled' : ''}`}>
                                    <div className="c-form-element__wrapper">
                                        <CustomSelect
                                            enableSearch={!!valuesArray && toArray(valuesArray).length >= 5}
                                            values={valuesArray}
                                            options={valuesArray}
                                            allowEmpty={false}
                                            id={`select-${node.level_name}`}
                                            label={`select-${node.level_name}`}
                                            value={this.parseDropdownValue(node.value).filter(item => !!item.name)}
                                            onSelect={(value) => this.selectUnselect(node, value)}
                                            onChange={(value) => this.selectUnselect(node, value)}
                                            isMulti={true}
                                            entityName={node.level_name}
                                            clearAll={() => this.selectUnselect(node, null)}
                                        />
                                    </div>

                                    {!!findError(node) && (
                                        <span className={'error-message'}>
                                            {!!node.value && toArray(node.value).map((val) => (
                                                <div>{val.validation}</div>
                                            ))}
                                        </span>
                                    )}

                                    {!!findAdditionalError && (
                                        <span className={'error-message'}>
                                            <div>{findAdditionalError.message}</div>
                                        </span>
                                    )}
                                </div>
                            )}

                            {!node.multi && node.data_type === STRING && (
                                <div
                                    className={`c-form-element c-form-element__${!!findError(node) || !!findAdditionalError ? node.error_type : ''} node-value ${disabled ? 'disabled' : ''}`}
                                    key={`${node.level_name}-element-${node.level_index}`}
                                >
                                    {/*<div*/}
                                    {/*    className={`c-form-element__wrapper c-form-element__edit-type-${node.data_type}`}*/}
                                    {/*>*/}
                                    {/*    <input*/}
                                    {/*        key={`${node.level_name}-${node.level_index}`}*/}
                                    {/*        name={`${node.level_name}-${node.level_index}`}*/}
                                    {/*        type="text"*/}
                                    {/*        onBlur={(e) => this.handleChange(e.target.value, node.level_index, node)}*/}
                                    {/*        placeholder={`Please type the ${node.level_name}`}*/}
                                    {/*        defaultValue={node.value.value}*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <FormItemWrapper
                                        key={`${node.level_name}-${node.level_index}`}
                                        name={`${node.level_name}-${node.level_index}`}
                                        type="text"
                                        onBlur={(e) => this.handleChange(e.target.value, node.level_index, node)}
                                        placeholder={`Please type the ${node.level_name}`}
                                        defaultValue={node.value.value}></FormItemWrapper>


                                    {!!findError(node) && (
                                        <span className={'error-message'}>
                                            {node.value.validation}
                                        </span>
                                    )}

                                    {!!findAdditionalError && (
                                        <span className={'error-message'}>
                                            <div>{findAdditionalError.message}</div>
                                        </span>
                                    )}
                                </div>
                            )}

                            {!!node.multi && node.data_type === STRING && (
                                <div className="tree-form-wrapper">
                                    {toArray(multipleStrings[node.ip_level_id]).map((tree, index) => (
                                        <div
                                            className={`c-form-element c-form-element__${tree.error || findAdditionalError ? node.error_type : ''} node-value ${disabled ? 'disabled' : ''}`}
                                            key={`${node.level_name}-element-${index}`}
                                        >

                                            <FormItemWrapper
                                                key={`${node.level_name}-${node.level_index}-${index}`}
                                                name={`${tree.level_name}-${index}`}
                                                type="text"
                                                onBlur={(e) => this.onChangeString(e, node, tree, index)}
                                                placeholder={`Please type the ${node.level_name}`}
                                                defaultValue={tree.value}

                                                inputAction={() => this.removeNode(node, tree)}
                                                actionIcon="close2"
                                            />

                                            {!!tree.error && (
                                                <span className={'error-message'}>
                                                    {tree.validation}
                                                </span>
                                            )}
                                        </div>
                                    ))}

                                    <div
                                        className={`tree-add-new u_pointer ${disabled ? 'disabled' : ''}`}
                                        onClick={() => this.addNewNode(node)}>
                                        + Add {node.level_name}
                                    </div>
                                </div>
                            )}

                            {!node.multi && node.data_type === REFERENCE && (
                                <div
                                    className={`c-form-element c-form-element__${findError(node) || findAdditionalError ? node.error_type : ''} node-value ${disabled ? 'disabled' : ''}`}>
                                    <div className="c-form-element__wrapper">
                                        <CustomSelect enableSearch={!!valuesArray && toArray(valuesArray).length >= 5}
                                                      options={orderBy(valuesArray, [value => !!value && value.label.toLowerCase()], ['asc'])}
                                                      values={orderBy(valuesArray, [value => !!value && value.label.toLowerCase()], ['asc'])}
                                                      allowEmpty={false}
                                                      value={node.value.ip_id || ''}
                                                      onSelect={(value) => this.handleChange(value, node.level_index, node)}
                                                      onChange={(value) => this.handleChange(value, node.level_index, node)}
                                                      isMultiple={false}
                                                      isMulti={false}
                                                      entityName={node.level_name}
                                                      label={node.level_name}
                                        />
                                    </div>

                                    {!!findAdditionalError && (
                                        <span className={'error-message'}>
                                            <div>{findAdditionalError.message}</div>
                                        </span>
                                    )}

                                    {!!findError(node) && (
                                        <span className={'error-message'}>
                                            {node.value.validation}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {hasChild && (
                        <div className="d-tree-content">
                            <ul className="d-flex d-tree-container flex-column">
                                <Tree node={node} data={node.children} />
                            </ul>
                        </div>
                    )}
                </li>
            );
        };

        return (
            <div className="ip-hierarchy-list-display-builder">
                <div className={'tree-wrapper'}>
                    {ipLevels && ipLevels.length > 0 && (
                        <div className={'ip-hierarchy-levels'}>
                            <Tree data={filteredMapedList} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

