import React, { Component } from 'react';
import { connect } from 'react-redux';
import Reorder from 'react-reorder';
import { Icon } from 'src/js/components/static';
import CustomizedButtomBlock from './CustomizedButtomBlock';
import includes from 'lodash/includes';
import {
    changeHeaders,
    updateActiveHeaders,
    reorderHeaders,
    setAllHeaders,
    setListFromSaving
} from 'src/js/actions/userSettings';
import { excludedLicenseeMyRecords } from 'src/js/constants/productConstants/specialCases';

import { getFilteredFields } from 'src/js/helpers/dataHelpers';
import { isLicensor } from 'src/js/helpers/permissions';

import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';

class CustomizedTableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createTableViewSetting: false
        };
    }

    render() {
        const {
            dispatch,
            headers,
            toggleHeaderOptions,
            activeHeaders,
            headerLayouts,
            selectAll,
            dontShowRedirectModal,
            isMyRecords,
            companyData,
            categoryLevels
        } = this.props;
        const noSelectAllSet = selectAll === undefined || selectAll === '';
        const allSelectedVariable = noSelectAllSet ? true : !selectAll;
        const { list: checkList, order: orderList } = headers;
        const list = Array.isArray(checkList)
            ? checkList
            : setListFromSaving(checkList, orderList);
        const licensor = isLicensor(companyData);
        const excludeHeaders = ['company', 'additional', 'category_attributes'];
        const newList = isMyRecords
            ? list.filter(
                f =>
                    !includes(excludeHeaders, f.name) &&
                    f.showInList &&
                    !excludedLicenseeMyRecords.includes(f.name)
                )
            : getFilteredFields(licensor, list);

        const allSelected =
            headers.list
                .filter(f => !includes(excludeHeaders, f.name))
                .find(el => !el.active) === undefined || selectAll;

        return (
            <div className="c-rec-panel__body c-rec-panel__body--no-top u-margin-small-top c-rec-panel__reorder-panel">
                {activeHeaders && (
                    <h4 className="u-margin-bottom">
                        Viewing:{' '}
                        <span className="u-color-blue">
                            {activeHeaders}
                        </span>
                    </h4>
                )}
                <Icon
                    icon="close"
                    fill="black"
                    size="15"
                    className="c-rec-panel__close-menu"
                    onClick={toggleHeaderOptions}
                />

                <section
                    className="c-rec-panel__table-header-item c-rec-panel__table-header-all u-flex"
                    key="select-all-checkbox"
                >
                    <div
                        className="c-rec-panel__table-header-label"
                    >
                        <UiCheckbox
                                onClick={() =>
                                    dispatch(
                                        setAllHeaders(
                                            activeHeaders,
                                            headers,
                                            headerLayouts,
                                            allSelectedVariable
                                        )
                                    )
                                }
                                id="cust-select-all-headers"
                                type="checkbox"
                                checked={allSelected}
                                label={allSelected ? 'Unselect All' : 'Select All'}
                                >
                        </UiCheckbox>
                    </div>
                    <div className="c-rec-panel__table-header-label">
                        <div
                            className="u-clickable"
                            onClick={() =>
                                dispatch(
                                    setAllHeaders(
                                        activeHeaders,
                                        headers,
                                        headerLayouts,
                                        allSelectedVariable,
                                        true
                                    )
                                )
                            }
                            style={{ width: '100px' }}
                        >
                            Default view
                        </div>
                    </div>
                </section>
                <ol className="c-rec-panel__ol u-no-margin">
                    <Reorder
                        reorderId="reorder-headers"
                        reorderGroup="reorder-group"
                        holdTime={100}
                        onReorder={(e, idxMoved, idxPlaced) =>
                            dispatch(
                                reorderHeaders(
                                    newList[idxMoved] && newList[idxMoved].name,
                                    newList[idxPlaced] &&
                                        newList[idxPlaced].name,
                                    headers,
                                    activeHeaders,
                                    headerLayouts,
                                    isMyRecords
                                )
                            )
                        }
                        autoScroll={false}
                        disabled={false}
                        disableContextMenus={true}
                        draggedClassName="c-rec-panel__dragged"
                        placeholderClassName="c-rec-panel__placeholder"
                    >
                        {newList.map((el, i) => {
                            const { label, name, active } = el;
                            return (
                                <li
                                    className="c-rec-panel__table-header-item"
                                    key={`${label}-header-item-${i}`}
                                >
                                    <div
                                        className="c-rec-panel__table-header-label inner"
                                        onClick={() =>
                                            dispatch(
                                                changeHeaders({
                                                    names: name,
                                                    headerLabel: null,
                                                    activeHeaders,
                                                    headers,
                                                    headerLayouts,
                                                    dontShowRedirectModal
                                                })
                                            )
                                        }
                                    >
                                        <div>
                                            <Icon icon="move" fill="grey4" />
                                        </div>

                                        <UiCheckbox
                                                id={name}
                                                type="checkbox"
                                                className="check-marker"
                                                checked={active || false}
                                                label={name === 'licensee_category_path'
                                                ? !!categoryLevels && categoryLevels[0] || 'Category'
                                                : label}
                                                >
                                        </UiCheckbox>
                                    </div>
                                </li>
                            );
                        })}
                    </Reorder>
                </ol>
                <footer className="c-rec-panel__footer">
                    <div className="c-rec-panel__footer-content">
                        <CustomizedButtomBlock
                            toggleHeaderOptions={toggleHeaderOptions}
                            headers={headers}
                            activeHeaders={activeHeaders}
                            updateActiveHeaders={(obj, toDefault) =>
                                dispatch(
                                    updateActiveHeaders({
                                        obj,
                                        toDefault,
                                        activeHeader: activeHeaders,
                                        headerLayouts,
                                        dontShowRedirectModal
                                    })
                                )
                            }
                            headerLayouts={headerLayouts}
                        />
                    </div>
                </footer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore: {
            auth: { companyData } = {},
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = [],
                selectAll,
                dontShowRedirectModal = false
            } = {},
            myRecords: {
                records: { data, totalItems } = {},
                fetching: { fetching, fetchErrors } = {},
                filters: { filters, activeList } = {},
                initialCount
            } = {}
        } = {}
    } = state;

    return {
        lists,
        headers,
        activeHeaders,
        headerLayouts,
        selectAll,
        dontShowRedirectModal,
        data,
        totalItems,
        initialCount,
        fetching,
        fetchErrors,
        filters,
        activeList,
        companyData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizedTableHeader);
