import React from 'react';
import { connect } from 'react-redux';
import HomeHtml from './HomeHtml';
import { RestrictedRoute } from 'src/js/components/static';

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            homeContainerClass: '',
            loadedClass: '',
            section1Class: '',
            section2Class: '',
            section3Class: '',
            section4Class: '',
            isPlayerShown: false
        };
    }

    componentDidMount() {
        const that = this;
        that.setState({
            homeContainerClass: 'o-intro--show',
            section1Class: 'o-intro-vis--loaded'
        });
    }

    section2GtHandler() {
        this.setState({
            section2Class: 'o-intro-vis--loaded'
        });
    }

    section3GtHandler() {
        this.setState({
            section3Class: 'o-intro-vis--loaded'
        });
    }

    section4GtHandler() {
        this.setState({
            section4Class: 'o-intro-vis--loaded'
        });
    }

    showPlayer() {
        this.setState({
            isPlayerShown: !this.state.isPlayerShown
        });
    }

    render() {
        return (
            <RestrictedRoute restrictTo="licensee">
                <HomeHtml
                    homeContainerClass={this.state.homeContainerClass}
                    section1Class={this.state.section1Class}
                    section2Class={this.state.section2Class}
                    section2GtHandler={this.section2GtHandler.bind(this)}
                    section3Class={this.state.section3Class}
                    section3GtHandler={this.section3GtHandler.bind(this)}
                    section4Class={this.state.section4Class}
                    section4GtHandler={this.section4GtHandler.bind(this)}
                    showPlayer={this.showPlayer.bind(this)}
                    isPlayerShown={this.state.isPlayerShown}
                />
            </RestrictedRoute>
        );
    }
}
function mapStateToProps(state) {
    const { xelacore: { auth: { userData = {} } = {} } = {} } = state;
    return { userData };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
