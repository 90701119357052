import React from 'react';
import sortBy from 'lodash/sortBy';
import { SidePanel } from 'src/js/components/static';
import { mandatoryFields } from 'src/js/constants/productConstants/index';
import { headers } from 'src/js/constants/productConstants/headers';

import { Icon } from 'src/js/components/static';

import productUpload from 'statics/files/what-product-data-do-I-need-to-upload.pdf';
import Button from 'modules/UiKit/components/Button/Button';

const extraAttributes = [
    {
        label: 'Recommended Retail Price',
        description: {
            licensee:
                'This field indicates the price the product is meant to be sold at retail'
        },
        order: 34
    },
    {
        label: 'Additional Images',
        description: {
            licensee: 'We recommend you upload up to 10 images'
        },
        order: 23
    }
];

const filteredHeaders = headers.filter(
    header => header.config && header.config.group
);

const neededHeaders = [
    ...filteredHeaders.filter(header => !!header.order),
    ...extraAttributes
];
const sortedHeaders = sortBy(neededHeaders, 'order');

const mandatoryFieldList = mandatoryFields.map((field, idx) => {
    const { label, descriptionLabel } =
        filteredHeaders.find(header => header.dataName === field) || {};
    return <li key={`${label}-${idx}`}>{field === 'images' || field === 'product_description' ? descriptionLabel : label} </li>;
});

const headerJsx = ({ description, dataName, label, descriptionLabel }, idx) => {
    const key = `${dataName ? dataName : label}-${idx}`;

    if(dataName !== 'uploaded_by' && dataName !== 'updated_at' && dataName !== 'registered_by_user') {
        return (
            <div className="c-my-records__data-format--info" key={`${key}`}>
                <h4>{descriptionLabel || label}</h4>
                <div key={`${key}-description`}>{description.licensee}</div>
                {description.examples &&
                    description.examples.map((item, idx) => (
                        <div key={`${item}-${idx}`}>
                            <p>{item}</p>
                        </div>
                    ))}
            </div>
        );
    }
};

const DataFormat = ({ close }) => {
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    const isWindowsStuff =
        isIE || isEdge || navigator.userAgent.indexOf('Firefox') > 0;
    const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1;

    return (
        <SidePanel width="500px" left close={close} closeBtn>
            <div className="c-my-records__data-format">
                <div className="c-my-records__data-format-header">
                    <h3>What product data should I upload?</h3>
                    <p>
                        When preparing your data for upload, make sure that it
                        has been saved using the ‘.CSV’ file format. Uploading
                        data using other file formats will cause your upload to
                        fail.
                    </p>
                    <div>
                        <a
                            className="c-my-records__data-format-download-list"
                            href={productUpload}
                            target="_blank"
                        >
                            <small>
                                <Icon icon="download" /> To save or print this
                                list, please click here
                            </small>
                        </a>
                    </div>
                </div>

                <div className="c-my-records__data-format--info">
                    <h4> Mandatory attributes </h4>
                    <ul className=""> {mandatoryFieldList} </ul>
                </div>

                {sortedHeaders.map(header => headerJsx(header))}

                {!isSafari && (
                    <div
                        className={`u-margin-bottom ${isWindowsStuff &&
                            'u-margin-bottom-4X'}`}
                    >
                        &nbsp;
                    </div>
                )}
                <div className="c-my-records__data-format-footer">
                    <div className="c-my-records__data-format-footer-flexy">
                        <div>
                            Alternatively, you can download the CSV template
                        </div>
                        <div>
                            <Button
                                type={'secondary-2'}
                                size={'small'}
                                to="/files/Xelacore-Product-Data-Upload-Template-Licensee.csv"
                                download
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </SidePanel>
    );
};
export default DataFormat;
