import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * Fetches the user generated lists
 *
 * @return {Promise}
 */
export function fetchLists() {
    return axios
        .request({
            url: [config.urls.catalog, 'tags'].join('/'),
            method: 'get',
            headers: { 'Cache-Control': 'no-cache' },
            params: {}
        })
        .then(responseHandler);
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {Object} listInfo
 * @param  {Object} filters
 * @return {Promise}
 */
export function createNewList(listInfo, filters) {
    return axios
        .request({
            url: [config.urls.catalog, 'tags'].join('/'),
            method: 'post',
            headers: { 'Cache-Control': 'no-cache' },
            data: listInfo,
            params: Object.assign({}, filters)
        })
        .then(responseHandler);
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {Object} listInfo
 * @param  {Object} filters
 * @param  {String} id
 * @return {Promise}
 */
export function patchExistingList(listInfo, filters, id) {
    return axios
        .request({
            url: [config.urls.catalog, 'tags', id].join('/'),
            method: 'patch',
            data: listInfo,
            params: Object.assign({}, filters)
        })
        .then(responseHandler);
}

/**
 * Fetches the rows of a given collection
 *
 * @param  {String} id
 * @return {Promise}
 */
export function deleteListItem(id) {
    return axios
        .request({
            url: [config.urls.catalog, 'tags', id].join('/'),
            method: 'delete',
            params: {}
        })
        .then(responseHandler);
}
