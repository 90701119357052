import { setUserSettings } from 'src/js/actions/xelacore';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import { alwaysSelected } from 'src/js/constants/productConstants/index';
import { setUserState } from 'src/js/actions/userDetails';

function checkActiveHeaderLayouts(headerLayouts) {
    if (!headerLayouts || headerLayouts.length === 0) {
        return setActiveHeader({}, true, null, headerLayouts);
    }
    const defaultSettings = headerLayouts.filter(item => item.byDefault);
    if (!Array.isArray(defaultSettings) || !defaultSettings.length) {
        return setActiveHeader({}, true, null, headerLayouts);
    }
    const defaultSet = defaultSettings[0];
    const { isDefault, name } = defaultSet;

    defaultSet.isFromList = !isDefault;
    return setActiveHeader(defaultSet, isDefault, name, headerLayouts);
}

function shouldDefaultSettingsToBeUpdated(
    name,
    toDefault,
    headerLayouts = [],
    dontShowRedirectModal
) {
    return dispatch => {
        // This is a check if we are want view Default Headers or Customized headers
        // This switch occurs shen User Switch on this, after deleting Custom Header
        // or if no custom headers found
        const newLayout = toDefault ? 'Default Headers' : name;
        const defaultName = headerLayouts.filter(item => item.name === name);
        if (
            Array.isArray(defaultName) &&
            defaultName.length &&
            defaultName[0].byDefault
        )
            return;
        headerLayouts.map(
            item => (item.byDefault = !!(item.name === newLayout))
        );
        const my_records_custom_table_views = helperSetUserStateForSaving(
            headerLayouts
        );
        const settings = {
            my_records_custom_table_views,
            dontShowRedirectModal
        };
        dispatch(setUserState('settings', { state: { settings } }));
    };
}

function helperSetUserSettings(
    headers = {},
    activeHeaders,
    headerLayouts,
    dontShowRedirectModal
) {
    return dispatch => {
        const { list: checkList, order: orderList } = headers;
        const list = Array.isArray(checkList)
            ? checkList.filter(item => item.showInList)
            : setListFromSaving(checkList, orderList);
        const order = Array.isArray(checkList)
            ? checkList.map(item => item.name)
            : orderList;
        const isAllSelected = list.find(el => !el.active) === undefined;
        const newHeaders = { ...headers, list, order };
        const payload = {
            headers: newHeaders,
            activeHeaders,
            headerLayouts,
            selectAll: isAllSelected
        };
        if (dontShowRedirectModal !== undefined) {
            payload.dontShowRedirectModal = dontShowRedirectModal;
        }

        dispatch(setUserSettings({ settings: payload }));
    };
}

export function reorderHeaders(
    moved,
    placed,
    headers = {},
    activeHeaders,
    headerLayouts
) {
    return dispatch => {
        const { list = [] } = headers;
        const arrOfNames = list.map(item => item.name);
        const itemThatHasBeenMoved = arrOfNames.indexOf(moved);
        let itemsPreviousIndex = arrOfNames.indexOf(placed);
        if (itemThatHasBeenMoved === -1 || itemsPreviousIndex === -1) return;
        if (itemsPreviousIndex >= list.length) {
            itemsPreviousIndex = list.length - 1;
        }
        headers.isHeadersChanged = true;
        //const arr = isMyRecords ? list.filter(f => f.name !== 'company' && excludedLicenseeMyRecords.includes(f.name)) : list;
        //arr.splice(
        list.splice(
            itemsPreviousIndex,
            0,
            list.splice(itemThatHasBeenMoved, 1)[0]
        );
        headers.list = [...list];
        dispatch(helperSetUserSettings(headers, activeHeaders, headerLayouts));
    };
}

export function updateActiveHeaders(params) {
    return dispatch => {
        const {
            obj,
            toDefault,
            activeHeader,
            headerLayouts,
            dontShowRedirectModal
        } = params;
        const { name, list, isFromList, order } = obj;
        dispatch(
            shouldDefaultSettingsToBeUpdated(
                name,
                toDefault,
                headerLayouts,
                dontShowRedirectModal
            )
        );
        let activeHeaders = '';
        if (!toDefault) {
            activeHeaders = name ? name : activeHeader ? activeHeader : '';
        }

        const headers = {
            list: toDefault ? setDefaultList() : list,
            order,
            isHeadersChanged: false,
            isDefaultHeader: !!toDefault,
            isFromList: toDefault ? false : isFromList
        };

        dispatch(helperSetUserSettings(headers, activeHeaders, headerLayouts));
    };
}

export function setAllHeaders(
    activeHeaders,
    headers,
    headerLayouts,
    allSelectedVariable,
    resetToDefaults
) {
    return dispatch => {
        if (allSelectedVariable === '' || allSelectedVariable === undefined)
            return;
        const {
            list: toClone,
            order,
            isDefaultHeader,
            isHeadersChanged,
            isFromList: isFromListState
        } = headers;
        if (resetToDefaults && isDefaultHeader && !isHeadersChanged) {
            return;
        }
        let filtered = resetToDefaults
            ? setDefaultList()
            : JSON.parse(JSON.stringify(toClone));
        if (!resetToDefaults) {
            filtered.map(el => {
                if (!alwaysSelected().includes(el.name)) {
                    el.active = allSelectedVariable;
                }
                return el;
            });
        }

        const headersUpdated = {
            list: filtered,
            order: order || false,
            isHeadersChanged: true,
            isDefaultHeader: isDefaultHeader,
            isFromList: isFromListState
        };

        dispatch(
            helperSetUserSettings(headersUpdated, activeHeaders, headerLayouts)
        );
    };
}

export function changeHeaders(params) {
    return dispatch => {
        const {
            names,
            headerLabel = '',
            activeHeaders,
            headers,
            headerLayouts,
            dontShowRedirectModal
        } = params;
        const {
            list: toClone,
            isDefaultHeader,
            isFromList: isFromListState
        } = headers;
        const { list, isFromList, order, name, isDefault } = names;
        if (name === 'Default Headers' && isDefault) {
            return dispatch(
                updateActiveHeaders({
                    obj: {},
                    toDefault: true,
                    activeHeader: 'Default Headers',
                    headerLayouts,
                    dontShowRedirectModal
                })
            );
        }
        let filtered = JSON.parse(JSON.stringify(toClone));
        if (typeof names === 'string' && !alwaysSelected().includes(names)) {
            filtered.map(el => {
                if (el.name === names) el.active = !el.active;
                return el;
            });
        }

        if (typeof names !== 'string') {
            dispatch(
                shouldDefaultSettingsToBeUpdated(
                    headerLabel,
                    null,
                    headerLayouts,
                    dontShowRedirectModal
                )
            );
        }

        const headersUpdated = {
            list: typeof names === 'string' ? filtered : list,
            order: order,
            isHeadersChanged: !!(typeof names === 'string'),
            isDefaultHeader:
                typeof names === 'string' ? isDefaultHeader : false,
            isFromList:
                typeof names === 'string' ? isFromListState : !!isFromList
        };
        const activeHeadersUpdated =
            typeof names === 'string' ? activeHeaders : headerLabel;

        dispatch(
            helperSetUserSettings(
                headersUpdated,
                activeHeadersUpdated,
                headerLayouts
            )
        );
    };
}

export function setActiveHeader(obj, toDefault, activeHeader, headerLayouts) {
    const { name, list, isFromList } = obj;

    // If this set to Default in any case activeHeaders is empty
    let activeHeaders = '';
    if (!toDefault) {
        activeHeaders = name ? name : activeHeader ? activeHeader : '';
    }
    const currentList = toDefault ? setDefaultList() : list;
    const isAllSelected = currentList.find(el => !el.active) === undefined;

    return {
        payload: {
            headers: {
                list: currentList,
                isHeadersChanged: false,
                isDefaultHeader: !!toDefault,
                isFromList: toDefault ? false : isFromList
            },
            activeHeaders,
            headerLayouts
        },
        name: activeHeaders,
        toDefault,
        selectAll: isAllSelected
    };
}

export function buildUserSettings(settings = {}) {
    return dispatch => {
        const {
            settings: {
                my_records_custom_table_views,
                dontShowRedirectModal
            } = {}
        } = settings;
        const headerLayouts = my_records_custom_table_views
            ? reduceUserStateFromSaving(my_records_custom_table_views)
            : undefined;
        const payloadSettings = checkActiveHeaderLayouts(headerLayouts);
        const {
            payload: { headers, activeHeaders, headerLayouts: layouts } = {},
            name,
            toDefault
        } = payloadSettings;
        dispatch(
            shouldDefaultSettingsToBeUpdated(
                name,
                toDefault,
                headerLayouts,
                dontShowRedirectModal
            )
        );
        dispatch(
            helperSetUserSettings(
                headers,
                activeHeaders,
                layouts,
                dontShowRedirectModal
            )
        );
    };
}

// Helper for building the object with key-activity pairs from array
// in given order
// {list: {gpc: true, mpn: true, gtin: false, "company": false, ...
export function helperSetUserStateForSaving(newArray) {
    const builtArray = [...newArray];

    return builtArray.map(item => {
        // There are no lists for Defaults
        const { list, byDefault } = item;

        if (item.list && Array.isArray(list) && !byDefault) {
            const output = {};
            item.order = list.map(item => item.name);
            item.list
                .filter(item => item.active)
                .forEach(key => {
                    output[key.name] = !!key.active;
                });
            item.list = output;
        }
        return item;
    });
}

export function setDefaultList() {
    return defaultHeaders.map(item => {
        const { dataName, label, active, showInList, config } = item;
        return ({ label, name: dataName, showInList, active, config });
    })
}

// Helper for setting an array of Headers from the single list object
export function setListFromSaving(list = {}, order) {
    const activeHeaders = { ...list };
    const arrayActiveHeaders = Object.keys(list);
    const output =
        arrayActiveHeaders && arrayActiveHeaders.length
            ? new Array(arrayActiveHeaders.length)
            : [];

    setDefaultList().forEach(item => {
        const { name, label, showInList } = item;
        const idx = order.indexOf(name);
        output[idx] = { label, name, showInList, active: activeHeaders[name] };
    });
    return output;
}

// Helper for setting an array of Headers for User Setting object
export function reduceUserStateFromSaving(my_records_custom_table_views) {
    const builtArray = [...my_records_custom_table_views];
    const arr = builtArray.map(item => {
        // There are no lists for Defaults
        if (item.list && item.order) {
            item.list = setListFromSaving(item.list, item.order);
        }
        return item;
    });
    return arr;
}

export function setLastUrlVisited(listUrl) {
    return dispatch => {
        dispatch(setUserSettings({settings: {listUrl}}));
    }
}
