import {
    fetchLists,
    createNewList,
    deleteListItem,
    patchExistingList
} from 'src/js/apicalls/other/lists';
import { setUserSettings } from './xelacore';

export function getLists() {
    return dispatch => {
        return fetchLists().then(resp => {
            const tags = { lists: resp.data.tags };
            dispatch(setUserSettings({ tags }));
            return tags;
        });
    };
}

export function createList(listInfo, filters) {
    return () => {
        if (!listInfo)
            return Promise.reject('No listInfo found, illegal action');
        if (!filters) return Promise.reject('No filters found, illegal action');

        return createNewList(listInfo, filters);
    };
}

export function patchList(listInfo, filters, id) {
    return () => {
        if (!listInfo)
            return Promise.reject('No listInfo found, illegal action');
        if (!filters) return Promise.reject('No filters found, illegal action');
        if (!id) return Promise.reject('No id found, illegal action');

        return patchExistingList(listInfo, filters, id);
    };
}

export function deleteList(id) {
    return () => {
        if (!id) return Promise.reject('No list id found, illegal action');

        return deleteListItem(id);
    };
}
