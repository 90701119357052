import axios from 'axios';
import config from 'config';
import responseHandler from 'apicalls/common/responseHandler';
import CryptoJS from 'crypto-js';

export function getDownloadUrl(repositoryId, category, filename) {
  const url = `${config.urls.filesv3}/retrieve`
  const params = {
    repositoryId,
    category,
    filename
  };

  return axios({
    method: 'GET',
    url,
    params
  }).then((response) => {
    const data = responseHandler(response, 'data');
    const decrypted = CryptoJS.AES.decrypt(
      data,
      'process.env.NODE_ENV'
    ).toString(CryptoJS.enc.Utf8);

    return decrypted.slice(1, -1);
  });
}

export function downloadFromUrl(url) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function getRejectedRecordsDownloadUrl(ingestionId) {
  const url = `${config.urls.catalogv3}/export/ingestions/${ingestionId}/rejected`
  /**
   * Use sync true to make this easier to process
   * If files ever start taking too long to process you should disable sync
   * And wait for the websocket update to know when it ready to download
   */
  const params = {
    sync: true
  };

  return axios({
    method: 'GET',
    url,
    params
  }).then((response) => {
    const data = responseHandler(response, 'data');
    const { repositoryId, category, filename } = data.sourceInfo
    return getDownloadUrl(repositoryId, category, filename)
  })
}
