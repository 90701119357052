import {
    fetchProductInformation,
    fetchPreviousUploads,
    fetchIngestStats,
    fetchIngestId,
    editSingleRecord,
    fetchIngestStatus,
    submitProductsToRegister,
    forceDownloadFileUpload,
    forceDownloadFileUploadWithOptions,
    getDuplicatesToView,
    submitDuplicateToResolve,
    fetchRejectedRecords,
    fetchOrgUsers,
    fetchOrgInformation,
    generateNewMap,
    fetchMaps,
    deleteSingleMap,
    nukeEmAll,
    updateBatch,
    bulkEditRecords,
    postBulkEditRecordImages
} from 'src/js/apicalls/mixed/myRecords';

export function getProductInformation(
    catalog,
    query = [],
    pagination = {},
    orCase,
    sort,
    categoryLevels
) {
    return () => {
        if (!catalog) return Promise.reject('No catalog found, illegal action');
        return fetchProductInformation(
            catalog,
            query,
            pagination,
            orCase,
            sort,
            categoryLevels
        );
    };
}

export function getPreviousUploads(id, licesnorId, isOpaUploads) {
    return () => {
        return fetchPreviousUploads(id, licesnorId, isOpaUploads);
    };
}

export function getIngestStats(ingest_id, licensorId) {
    return () => {
        if (!ingest_id)
            return Promise.reject('No ingest_id found, illegal action');
        return fetchIngestStats(ingest_id, licensorId);
    };
}

export function getIngestStatus(ingest_id, licensorId) {
    return () => {
        if (!ingest_id)
            return Promise.reject('No ingest_id found, illegal action');

        return fetchIngestStatus(ingest_id, licensorId);
    };
}

export function getIngestId(data, isOpa) {
    return () => {
        return fetchIngestId(data, isOpa);
    };
}

export function editRecord(id, record) {
    return () => {
        if (!id) return Promise.reject('No list id found, illegal action');
        if (!record)
            return Promise.reject('No list record found, illegal action');

        return editSingleRecord(id, record);
    };
}

export function bulkEditRecord(recordsIds, recordBody) {
    return () => {
        if (!recordsIds)
            return Promise.reject('No list record found, illegal action');

        return bulkEditRecords(recordsIds, recordBody);
    };
}

export function bulkEditRecordImages(recordsIds, images) {
    return () => {
        if (!recordsIds || !images)
            return Promise.reject('No list record found, illegal action');

        return postBulkEditRecordImages(recordsIds, images);
    };
}

export function registerProducts(ids) {
    return () => {
        if (!ids) return Promise.reject('No ids found, illegal action');

        return submitProductsToRegister(ids);
    };
}

export function getDuplicates(id) {
    return () => {
        if (!id) return Promise.reject('No record id found, illegal action');

        return getDuplicatesToView(id);
    };
}

export function resolveDuplicate(id, data) {
    return () => {
        if (!id) return Promise.reject('No id found, illegal action');
        if (!data) return Promise.reject('No data found, illegal action');

        return submitDuplicateToResolve(id, data);
    };
}

export function downloadFileUpload(fileName, isOpa) {
    return () => {
        if (!fileName)
            return Promise.reject('No filename found, illegal action');

        return forceDownloadFileUpload(fileName, isOpa);
    };
}

export function downloadFileUploadWithOptions(ingestId, option, fileName) {
    return () => {
        if (!ingestId)
            return Promise.reject('No Ingest Id is provided, illegal action');
        if (!option) return Promise.reject('No option found, illegal action');
        if (!fileName)
            return Promise.reject('Filename is not set, illegal action');

        return forceDownloadFileUploadWithOptions(ingestId, option, fileName);
    };
}

export function getRejectedRecords(ingestId, fileName) {
    return () => {
        if (!ingestId)
            return Promise.reject('No ingestId found, illegal action');
        if (!fileName)
            return Promise.reject('No fileName found, illegal action');

        return fetchRejectedRecords(ingestId, fileName);
    };
}

export function getOrgUsers(orgId) {
    return () => {
        if (!orgId) return Promise.reject('No orgId found, illegal action');

        return fetchOrgUsers(orgId);
    };
}

export function getOrgInformation(orgId) {
    return () => {
        if (!orgId) return Promise.reject('No orgId found, illegal action');

        return fetchOrgInformation(orgId);
    };
}

export function createNewMap(newMap) {
    return () => {
        if (!newMap) return Promise.reject('No orgId found, illegal action');
        return generateNewMap(newMap);
    };
}

export function getMaps(mapId) {
    return () => {
        return fetchMaps(mapId);
    };
}

export function deleteMap(mapId) {
    return () => {
        if (!mapId) return Promise.reject('No mapId found, illegal action');

        return deleteSingleMap(mapId);
    };
}

export function nukeRecords() {
    return () => {
        return nukeEmAll();
    };
}

export function batchUpdate(records) {
    if (!records)
        return Promise.reject('No records was sent through you filthy human!');
    return updateBatch(records);
}
