import React from 'react';

import bulkLoading from 'statics/imgs/desktop/records/bulk_loading.png';
import { Button } from '../static';

const BulkEditLoading = ({onRetry}) => {

    return (
        <div className='c-bulk-update__loading'>
            <div><img src={bulkLoading} alt="Bulk Edit Loading" height={80} /></div>
            <div><h3>We are gathering the selected records...</h3></div>
            <div><small>Depending on the number of records selected, this may take a few seconds. Click the button to get updated status.</small></div>
            <div>
                <Button
                    onClick={onRetry}
                >
                    Retry
                </Button>
            </div>
        </div>
    );
};

export default BulkEditLoading;
