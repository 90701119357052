import jwtDecode from 'jwt-decode';
import { browserHistory } from 'react-router';

import cookies from 'js-cookie';
import {
    getAllUserInfo,
    getNewUserData,
    resetPassword
} from 'src/js/apicalls/auth/user';
import {
    USER_TOKEN_COOKIE,
    USER_REFRESH_TOKEN_COOKIE,
    ORG_ID_COOKIE,
    XELACORE_DOMAIN,
    APP_INIT,
    USER_DATA,
    COMPANY_DATA,
    USER_PERMISSIONS,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_SUCCESS,
    DISPLAY_NOTIFICATION,
    DESTROY_NOTIFICATION,
    SET_PAGE_NOTIFICATION,
    DESTROY_PAGE_NOTIFICATION,
    MODAL_SHOW,
    MODAL_HIDE,
    LOCK_BODY,
    UNLOCK_BODY,
    NOTIFICATION_SHOW,
    NOTIFICATION_HIDE,
    SET_USER_SETTINGS,
    REMOVE_USER_SETTINGS,
    SET_MY_RECORDS,
    REMOVE_MY_RECORDS,
    SET_PRODUCT_CATALOGUE,
    REMOVE_PRODUCT_CATALOGUE,
    SET_INGESTIONS,
    REMOVE_INGESTIONS,
    LOGOUT,
    USER_ORGANISATIONS,
    CUSTOM_REPORTS,
    USER_REFRESH_TOKEN,
    SET_USERS_LIST,
    REMOVE_USERS_LIST,
    SET_CATEGORIES,
    SET_SUB_CATEGORIES,
    SET_LINKED_BRANDS,
    SET_BRANDS,
    REMOVE_CATEGORIES,
    SET_QUARANTINE,
    SET_SOCKET_NOTIFICATION,
    UPDATE_INGESTIONS,
    REMOVE_INGESTION
} from 'src/js/constants/actions/xelacore';

import {
    setUserPermissions
    // getUserPermissions
} from 'src/js/actions/permissions';
import {
    getUserOrganisations,
    selectUserOrganisation
} from 'src/js/actions/login';
import { getUserSettings, getCustomRecords } from 'src/js/actions/userDetails';
import { logout } from 'src/js/lib/auth';

export const requestLogin = (creds) => ({
    type: LOGIN_REQUEST,
    isFetching: true,
    creds
});

export const receiveLogin = (token) => ({
    type: LOGIN_SUCCESS,
    isFetching: false,
    token
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS
});

export const setFullLogout = () => ({
    type: LOGOUT
});

export const loginError = (message) => ({
    type: LOGIN_FAILURE,
    isFetching: false,
    message
});

export const userData = (userData) => ({
    type: USER_DATA,
    userData
});

export const companyData = (companyData) => ({
    type: COMPANY_DATA,
    companyData
});

export const customReports = (customReports) => ({
    type: CUSTOM_REPORTS,
    customReports
});

export const userPermissions = (permissions) => ({
    type: USER_PERMISSIONS,
    permissions
});

export const showModal = (modalContent) => ({
    type: MODAL_SHOW,
    modalContent
});

export const hideModal = () => ({
    type: MODAL_HIDE
});

export const lockBody = () => ({
    type: LOCK_BODY
});

export const unlockBody = () => ({
    type: UNLOCK_BODY
});

export const showNotification = (notificationContent) => ({
    type: NOTIFICATION_SHOW,
    notificationContent
});

// TODO: Comment or refactor what these notifications are
export const hideNotification = () => ({
    type: NOTIFICATION_HIDE
});

export const displayNotification = (payload) => ({
    type: DISPLAY_NOTIFICATION,
    payload
});

export const destroyNotification = () => ({
    type: DESTROY_NOTIFICATION
});

export const setPageNotification = (payload) => ({
    type: SET_PAGE_NOTIFICATION,
    payload
});

export const destroyPageNotification = (payload) => ({
    type: DESTROY_PAGE_NOTIFICATION,
    payload
});

export const setUserSettings = (payload) => ({
    type: SET_USER_SETTINGS,
    payload
});

export const removeUserSettings = (payload) => ({
    type: REMOVE_USER_SETTINGS,
    payload
});

export const setMyRecords = (payload) => ({
    type: SET_MY_RECORDS,
    payload
});

export const removeMyRecords = (payload) => ({
    type: REMOVE_MY_RECORDS,
    payload
});

export const setProductCatalogue = (payload) => ({
    type: SET_PRODUCT_CATALOGUE,
    payload
});

export const setQuarantine = (payload) => ({
    type: SET_QUARANTINE,
    payload
});

export const removeProductCatalogue = (payload) => ({
    type: REMOVE_PRODUCT_CATALOGUE,
    payload
});

export const setIngestions = (payload) => ({
    type: SET_INGESTIONS,
    payload
});

export const removeIngestions = (payload) => ({
    type: REMOVE_INGESTIONS,
    payload
});

export const updateIngestions = (payload) => ({
    type: UPDATE_INGESTIONS,
    payload
});

export const removeIngestion = (payload) => ({
    type: REMOVE_INGESTION,
    payload
})

export const setCategories = (payload) => ({
    type: SET_CATEGORIES,
    payload
});

export const setSubCategories = (payload) => ({
    type: SET_SUB_CATEGORIES,
    payload
});

export const setLinkedBrands = (payload) => ({
    type: SET_LINKED_BRANDS,
    payload
});

export const setBrands = (payload) => ({
    type: SET_BRANDS,
    payload
});

export const removeCategories = (payload) => ({
    type: REMOVE_CATEGORIES,
    payload
});

export const setUserOrganisations = (payload) => ({
    type: USER_ORGANISATIONS,
    payload
});

export const setCustomReports = (payload) => ({
    type: CUSTOM_REPORTS,
    payload
});

export const setUserRefreshToken = (payload) => ({
    type: USER_REFRESH_TOKEN,
    payload
});

export const setUsersList = (payload) => ({
    type: SET_USERS_LIST,
    payload
});

export const removeUsersList = (payload) => ({
    type: REMOVE_USERS_LIST,
    payload
});

export const socketNotification = (payload) => ({
    type: SET_SOCKET_NOTIFICATION,
    payload
});

// This is the a hardcoded value of the timeout variable that also comes from the back end
// Token is for 10 minutes so we're setting it to 9 for safety
const _timeout = (600 - 60) * 1000;
// This refreshs our token ever 9 minutes in keeping with oAuth standards (There's a 10 minute)
// timeout on the server for the token but we set it at nine just to be safe
const _callRefreshWithTimeout = (timeout, dispatch) => {
    let updateTime = Date.now() + timeout;

    // TODO: So you're probably asking why we're running this every 5 seconds... great question
    // Firstly, this function will check if our current date is greater than our update time
    // If it is, it'll fire off the request to update our token and refresh the time
    // Otherwise it'll do nada
    setInterval(() => {
        if (Date.now() >= updateTime) {
            dispatch(refreshTheRefreshToken());
            // Reset the timer
            return (updateTime = Date.now() + timeout);
        }

        return null;

        // We fire it every five seconds becuase there was an issue we found with the site not having a valid token
        // if the app was open in a tab that wasn't active for longer than the duration of the timeout
        // This was a result of idle tabs processes not being executed as frequently as active ones
        // Therefore we do it this way. Alternatives to this are placing a check on every single API request
        // or using a webworker to manage this process. Right now, this works, and has worked for a while
        // so i don't suggest changing it.
    }, 5000);

    return dispatch(refreshTheRefreshToken());
};

// This is for a users token who is part of an organisation
export function refreshTheRefreshToken() {
    return (dispatch) => {
        const oldRefreshToken = cookies.get(USER_REFRESH_TOKEN_COOKIE);
        const orgId = cookies.get(ORG_ID_COOKIE);

        if (!oldRefreshToken) return logout();
        if (!orgId) return;

        return dispatch(
            selectUserOrganisation({ orgId, refresh_token: oldRefreshToken })
        ).then(({ access_token, refresh_token }) => {
            dispatch(receiveLogin(access_token));
            dispatch(setUserRefreshToken(refresh_token));

            cookies.set(USER_TOKEN_COOKIE, access_token, {
                domain: XELACORE_DOMAIN,
                secure: true,
                sameSite: 'strict'
            });

            cookies.set(USER_REFRESH_TOKEN_COOKIE, refresh_token, {
                domain: XELACORE_DOMAIN,
                secure: true,
                sameSite: 'strict'
            });

            return access_token;
        })
            .catch(() => {
                cookies.remove(ORG_ID_COOKIE, { domain: XELACORE_DOMAIN });
                logout();
            });
    };
}

export function tokensFine() {
    const token = cookies.get(USER_TOKEN_COOKIE);
    const refreshToken = cookies.get(USER_REFRESH_TOKEN_COOKIE);

    return !!token && !!refreshToken && token !== 'undefined' && token !== 'false';
}

export function selectFirstOrganisation(organisations, refreshToken, dispatch) {
    const orgDetails = organisations && organisations[0];
    const idToken = {
        orgId: organisations && organisations[0] && organisations[0].organisation_id,
        refresh_token: refreshToken
    };

    if (organisations.length === 1 && !!idToken.orgId) {
        dispatch(setUserOrg(orgDetails, idToken.orgId, idToken));
    }
}

export function selectOrganisation() {
    localStorage.setItem('redirectTo', window.location.pathname);
    browserHistory.push('/select-organisation');
    window.location.reload();
}

/**
 * This flow first checks if we have an old token in our localstorage
 * If we do we'll decode it and set some variables straightaway such as permissions
 * Then we'll see if it's still valid
 * If it's valid, we'll fetch and set our userData and set the value for the new token
 * in localStorage
 * Finally we return the store to pass down the chain
 *
 * @return {Promise}
 */
export function checkAuthorised(store) {
    return (dispatch) => {
        // Get the  basic building blocks of our local data
        // We probably don't need to use a promise but because of how it's all set up this is a lot easier
        return Promise.resolve()
            .then(() => {
                const oldToken = cookies.get(USER_TOKEN_COOKIE);
                const oldRefreshToken = cookies.get(USER_REFRESH_TOKEN_COOKIE);

                if (!tokensFine()) {
                    logout();
                    return;
                }

                const oldOrgId = cookies.get(ORG_ID_COOKIE);
                const uData = JSON.parse(localStorage.getItem('userData'));
                const orgDetails = JSON.parse(localStorage.getItem('orgDetails'));
                const oldTokenInfo = jwtDecode(oldToken);
                const organisationChanged = !!orgDetails && orgDetails.organisation_id !== oldOrgId;

                if ((location.pathname === '/select-organisation') && !!oldOrgId) {
                    browserHistory.push('/');
                }

                dispatch(setUserPermissions(oldTokenInfo.data.permissions));

                dispatch(getUserOrganisations(oldToken)).then(({ data }) => {
                    const { organisations } = data;
                    const filteredOrganisations = organisations.map(organisation => organisation.organisation_id);

                    // Redirect to select an organisation if it doesn't exist
                    // Or automatically select if there is only one option
                    if (!oldOrgId) {
                        dispatch(setUserOrganisations(organisations));
                        dispatch(setUserRefreshToken(oldRefreshToken));

                        (window.location.pathname !== '/select-organisation') && (organisations.length > 1)
                            ? selectOrganisation()
                            : selectFirstOrganisation(organisations, oldRefreshToken, dispatch);
                    }

                    // Check if user has permissions for current organisation
                    // If not, select one from list
                    if (!!oldOrgId && !filteredOrganisations.includes(oldOrgId)) {
                        cookies.remove(ORG_ID_COOKIE, { domain: XELACORE_DOMAIN });
                        selectFirstOrganisation(organisations, oldRefreshToken, dispatch);
                    }

                    // Select automatically the organisation if ID is present but no details in localstorage
                    if (!!oldOrgId && (!orgDetails || organisationChanged || !uData)) {
                        const orgData = organisations.find(
                            (f) => f.organisation_id === oldOrgId
                        );
                        const idToken = { orgId: oldOrgId, refresh_token: oldRefreshToken };

                        dispatch(
                            setUserOrg(orgData, oldOrgId, idToken)
                        );
                    }
                });

                // Refresh the new token immediately
                // If oldOrgId === null, it means that the user isn't tied to any particular account
                return Promise.resolve(
                    _callRefreshWithTimeout(
                        _timeout,
                        dispatch,
                        oldOrgId === null
                    )
                ).then((token) => {
                    dispatch(receiveLogin(token));

                    getAllUserInfo(token).then((r) => {
                        if (!r.data) return;
                        dispatch(setUserOrganisations(r.data.organisations));
                    });

                    if (!!token) {
                        cookies.set(USER_TOKEN_COOKIE, token, {
                            domain: XELACORE_DOMAIN,
                            secure: true,
                            sameSite: 'strict'
                        });
                    }
                });
            })
            .then(() => store);
    };
}

/**
 * This action is dispatched when the bootstrap function has finished and the app is considered
 * initiated. We set and dispatch information for the user, company data etc
 * and finally the APP_INIT action to let the world know we're ready
 *
 * @param  {Redux} store
 * @param  {Router} routes
 * @return {Promise}
 */
export function appInit({ store, routes }) {
    return (dispatch) => {
        // Check if the auth state is already aware of the userData & token,
        // otherwise resolve their values fromt he cache
        const token = cookies.get(USER_TOKEN_COOKIE);
        const uData = JSON.parse(localStorage.getItem('userData'));
        const cData = JSON.parse(localStorage.getItem('orgDetails'));

        // The master flow
        if (token && uData) {
            // dispatch(receiveLogin(token));
            dispatch(userData(uData));
            dispatch(companyData(cData));
            dispatch(getCustomRecords());
            dispatch(getUserSettings());

            // const { employee_id } = jwtDecode(token).data;
            // dispatch(getUserPermissions(cData.organisation_id, employee_id));
        }
        dispatch({ type: APP_INIT });
        return { store, routes };
    };
}

export function fetchOrganisations(token, refresh_token) {
    return (dispatch) => {
        return dispatch(getUserOrganisations(token)).then(
            ({ data }) => {
                // We then fetch the authorisation & refresh tokens
                const { organisations } = data;
                // Sort organisation names in lexicographic order
                organisations.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );

                const localOrgId = cookies.get(ORG_ID_COOKIE);
                const findSavedOrg =
                    localOrgId &&
                    organisations.find(
                        (f) => f.organisation_id === localOrgId
                    );
                const userOrgData =
                    organisations.length === 1
                        ? organisations[0]
                        : findSavedOrg
                            ? findSavedOrg
                            : null;

                dispatch(setUserOrganisations(organisations));
                dispatch(setUserRefreshToken(refresh_token));

                if (userOrgData) {
                    // If orgId found and in array - auto log in to that
                    const orgId = userOrgData.organisation_id;
                    const idToken = { orgId, refresh_token };
                    return dispatch(
                        setUserOrg(userOrgData, orgId, idToken)
                    );
                }
                // If no default orgId meaning we have multiple organisations, kick back different values to login page
                // The login page will handle this differently then
                return {
                    noSelectedOrg: true,
                    organisations,
                    refresh_token
                };
            }
        );
    };
}

/**
 * This action is performed when the application wants to refresh the user's data.
 * Sets all the information for the organisation a user belongs to
 * Can either be set from the login action that if the user is a employee
 * of either one organisation or has a preselected one or else is called from
 * when the user selects an organisation from the account swithcer on the login page
 * or from the drop down menu in the header
 * @param  {Object} orgData
 * @param  {String} orgId
 * @param  {String} idToken
 * @return {Promise}
 */
export function setUserOrg(orgData, orgId, idToken) {
    return (dispatch) => {
        // Set the values locally for the users organisation
        return dispatch(selectUserOrganisation(idToken))
            .then(({ access_token, refresh_token }) => {
                localStorage.setItem('orgDetails', JSON.stringify(orgData));

                cookies.set(ORG_ID_COOKIE, orgId, {
                    domain: XELACORE_DOMAIN,
                    secure: true,
                    sameSite: 'strict'
                });

                cookies.set(USER_REFRESH_TOKEN_COOKIE, refresh_token, {
                    domain: XELACORE_DOMAIN,
                    secure: true,
                    sameSite: 'strict'
                });

                cookies.set(USER_TOKEN_COOKIE, access_token, {
                    domain: XELACORE_DOMAIN,
                    secure: true,
                    sameSite: 'strict'
                });

                dispatch(receiveLogin(access_token));
                dispatch(setUserRefreshToken(refresh_token));
                dispatch(companyData(orgData));

                _callRefreshWithTimeout(_timeout, dispatch);

                return dispatch(refreshUserData(access_token))
                    .then(() => {
                        cookies.set(USER_TOKEN_COOKIE, access_token, {
                            domain: XELACORE_DOMAIN,
                            secure: true,
                            sameSite: 'strict'
                        });
                        cookies.set(USER_REFRESH_TOKEN_COOKIE, refresh_token, {
                            domain: XELACORE_DOMAIN,
                            secure: true,
                            sameSite: 'strict'
                        });
                    }).then(() => {
                        setTimeout(() => {
                            let redirectUrl = !!localStorage.getItem('redirectTo') && localStorage.getItem('redirectTo') || '';
                            localStorage.removeItem('redirectTo');

                            if (redirectUrl.length > 0) {
                                window.location.href = redirectUrl;
                            } else {
                                window.location.reload();
                            }
                        }, 100);
                    });
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };
}

/**
 * This action is performed when the application wants to refresh the user's data.
 * This can happen on login, when we're updating user data etc
 *
 * @param  {String} token the login token
 * @return {Promise} the promise is resolved on success
 */
export function refreshUserData(token) {
    return (dispatch) => {
        return getNewUserData(token).then((response) => {
            localStorage.setItem('userData', JSON.stringify(response));
            localStorage.setItem('userId', response.user_id);
            dispatch(getUserSettings());
            return dispatch(userData(response));
        });
    };
}

/**
 * This action is dispatched when the user has provided the app with the details
 * to change their password.
 *
 * @param  {Object} creds
 * @param  {String} creds.token
 * @param  {String} creds.password
 * @return {Promise}
 */
export function resetPass(creds) {
    return () => resetPassword(creds);
}
