import React from 'react';
import notFound from 'statics/imgs/desktop/concepts/not_found.svg';

export default function OpaSearchEmptySearch({ searchTerm }) {
    return (
        <div className="c-product__search-no-data">
            <img
                src={notFound}
                className="u-padding"
                alt="No matching concepts"
            />
            <div className="u-text-center u-bold u-padding">
                We couldn't find any concepts for{' '}
                <span className="u-color-pink u-capitalize">{searchTerm}</span>
            </div>
            <div>
                Try using a different search term or wait for the Licensor to
                update the concept information
            </div>
        </div>
    );
}
