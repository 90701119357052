import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import size from 'lodash/size';
import includes from 'lodash/includes';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import { fetchConfig } from 'src/js/apicalls/mixed/connections';
import { getUrlParam, isValidJson } from 'src/js/helpers/strings';
import {
    getFiltersDataFromUrl,
    getSortDataFromUrl
} from 'src/js/helpers/dataHelpers';
import { getEsData } from 'src/js/actions/esData';
import { destroyPageNotification } from 'src/js/actions/xelacore';
import { checkCurrentlyIngested } from 'src/js/actions/ingestions';
import { getLists } from 'src/js/actions/lists';
import { setInitialCount } from 'src/js/actions/myRecords';
import { fetchProducts, setData } from 'src/js/actions/productsFetch';
import {
    getLinkedBrands
} from 'src/js/actions/categories';
import {
    fetchCategories
} from 'src/js/apicalls/other/categories';
import { RestrictedRoute } from 'src/js/components/static';
import { PAGE_LIMIT, NUMBER_OF_RECORDS_EXCEEDS, EXCEEDS_LIMIT } from 'src/js/constants/dataConstants';
import { setBulkFilters } from 'src/js/actions/filters';
import { isUpdated } from 'src/js/helpers/dataHelpers';

import errorHandler from 'src/js/actions/errorHandler';
import DataViewPagination from 'src/js/components/DataViewPagination/DataViewPagination';
import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';
import HeaderSection from 'src/js/components/ProductComponents/HeaderSection/HeaderSection';
import TopHeaderSection from 'src/js/components/ProductComponents/TopHeaderSection/TopHeaderSection';
import Table from './MyRecordsTable/MyRecordsTable';
import esFilterObject from 'src/js/components/AdvancedFiltering/esFilterObject';
import SelectLicensor from 'src/js/components/SelectLicensor/SelectLicensor';
import { fetchLinkedLicensors, getExternalValidations } from 'src/js/apicalls/mixed/myRecords';

import {
    parseSearchItems,
    filterInitialization,
    formatFilterObject,
    addLevelstoQuery
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';
import { DEFAULT_SORT } from 'constants/myRecordsConstants';
import { isLicensor } from 'src/js/helpers/permissions';
import {
    fetchIpsLevels,
    fetchIps
} from 'src/js/apicalls/other/ips';

import { selectAll } from 'src/js/actions/productsFetch';

import {
    parsedNewFilters
} from 'src/js/components/AdvancedFiltering/AdvancedFilteringHelpers';
import { Base64 } from 'js-base64';
import AdvancedFiltering from 'src/js/components/AdvancedFiltering/AdvancedFiltering';

class MyRecords extends Component {
    constructor(props) {
        super(props);
        this.type = 'records';

        this.location = {
            queryObject: this.props.location.query,
            url: this.props.location.pathname
        };

        this.orgId = this.props.xelacore.auth.companyData.organisation_id;
        // Set initial value
        this.notifiedAt = Date.now();
        this.state = {
            key: new Date(), // Used to force re-render on selecting licensor
            allHeaders: [],
            filters: {},
            sortedBy: DEFAULT_SORT,
            uploadInfoId: '',
            configName: '',
            orgId: '',
            duplicateBeingManged: null,
            showUploader: getUrlParam('upload') || false,
            viewing: '',
            gtinWaiver: false,
            queryObject: esFilterObject,
            basicFilterObj: {},
            searchItems: [],
            originalAggregations: {},
            aggregations: {},
            categoryLevels: [],
            ipLevels: [],
            ips: [],
            categories: [],
            uploaderManuallyClosed: false,
            selectedLicensor: !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {},
            validationsExist: false,
            showUploads: false,
            showFilters: false,
            ignoreValidations: false
        };
    }

    componentDidMount() {
        this.handleMount();
        document.addEventListener('onlyRejectedRecordsIngested', () => {
            this.toggleUploader(true);
        });
    }

    handleMount() {
        this.getConfig();
        const parsedUrlSortParam = getSortDataFromUrl();

        if (!isEmpty(parsedUrlSortParam)) {
            this.sortData(parsedUrlSortParam.name, parsedUrlSortParam.dir);
        }

        document.addEventListener('advancedFilterClear', () => {
            this.setState({ sortedBy: DEFAULT_SORT });
        });

        setTimeout(() => {
            this.updateFilters();
        }, 1111);
    }

    getConfig() {
        const { dispatch } = this.props;

        fetchConfig()
            .then((resp) => {
                this.setState({
                    gtinWaiver: !!resp.data.config.gtin_waiver,
                    ignoreValidations: !!resp.data.config.ignore_validations
                });
            })
            .catch((error) => errorHandler(dispatch, error));
    }

    componentWillMount() {
        const { selectedLicensor } = this.state;
        const { dispatch, pageNotification } = this.props;

        checkCurrentlyIngested(dispatch, pageNotification);
        dispatch(getLists());
        this.getCategories();
        this.getLinkedBrands();
        this.getValuesFromURL();
        this.fetchValidations();

        if (selectedLicensor) {
            this.fetchIpLevels();
            this.fetchIps();
            this.fetchLicensors();
        }
    }

    componentWillUnmount() {
        this.props.dispatch(destroyPageNotification({ type: 'my-records' }));
        document.removeEventListener('advancedFilterClear', () => {
            this.setState({ sortedBy: DEFAULT_SORT });
        });
    }

    componentDidUpdate(prevProps) {
        const messages = get(this, 'props.xelacore.socket.messages', []);

        if (
            isUpdated(
                messages,
                ['actions', 'elastic', 'postgres'],
                this.notifiedAt
            ) ||
            prevProps.linkedBrands !== this.props.linkedBrands
        ) {
            this.notifiedAt = Math.max(...messages.map((m) => m.timestamp));

            setTimeout(() => {
                this.fetchInfo();
                this.fetchData(null, getFiltersDataFromUrl());
            }, 0);
        }
    }

    fetchValidations() {
        const { xelacore } = this.props;
        const {
            auth: {
                companyData: { organisation_id }
            }
        } = xelacore;

        return getExternalValidations(organisation_id).then(response => {
            this.setState({
                validationsExist: !!response && !!size(response.data) && !!size(response.data[0].errors)
            });
        });
    }

    toggleUploader(val = null) {
        return this.setState({
            showUploader: val !== null ? val : !this.state.showUploader,
            uploaderManuallyClosed: true
        });
    }

    fetchIpLevels(licensor) {
        const { selectedLicensor } = this.state;
        const { xelacore } = this.props;
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchIpsLevels(organisation_id, token, licensor || selectedLicensor.licensor_organisation_id).then((response) => {
            this.setState({
                ipLevels: response.data && response.data.ip_levels,
                queryObject: addLevelstoQuery(this.state.queryObject, response.data && response.data.ip_levels)
            });
        });
    }

    fetchIps(licensor) {
        const { selectedLicensor } = this.state;
        const { xelacore } = this.props;
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchIps(organisation_id, token, licensor || selectedLicensor.licensor_organisation_id).then((response) => {
            this.setState({
                ips: response.data && response.data.ips
            });
        });
    }

    setAggregations = (updatedAggregations, group, newVal) => {
        this.setState({
            aggregations: { ...updatedAggregations, [group]: newVal }
        });
    };

    updateFiltersState(queryObject, basicFilterObj, hardUpdate = true, params) {
        this.setState(
            {
                page: !!params ? params.page : getUrlParam('page'),
                queryObject,
                basicFilterObj,
                searchItems: parseSearchItems(basicFilterObj)
            },
            () => {
                this.fetchInfo();
                if (hardUpdate) this.fetchData(params, basicFilterObj);
            }
        );
    }

    updateFilters() {
        const { xelacore } = this.props;
        const {
            auth: {
                companyData
            }
        } = xelacore;
        const { categoryLevels, queryObject, basicFilterObj } = this.state;

        const parsedUrlFilterParam = getFiltersDataFromUrl();

        let newFilters = { ...basicFilterObj };
        let newQueryObj = cloneDeep(queryObject);

        if (!isEmpty(parsedUrlFilterParam)) {
            filterInitialization(parsedUrlFilterParam, newFilters, newQueryObj, categoryLevels, isLicensor(companyData));
        }

        this.updateFiltersState(newQueryObj, newFilters, true, {});
    }

    getCategories() {
        const { xelacore } = this.props;
        const { categories, queryObject } = this.state;

        const {
            auth: {
                token,
                companyData
            }
        } = xelacore;

        if (isEmpty(categories)) {
            fetchCategories(companyData.organisation_id, token).then((response) => {
                this.setState({
                    categories: response.data && response.data.categories,
                    categoryLevels: response.data && response.data.category_levels,
                    queryObject: formatFilterObject(queryObject, response.data && response.data.category_levels, isLicensor(companyData))
                });

                this.updateFilters();
            });
        }
    }

    getLinkedBrands() {
        const { dispatch, linkedBrands } = this.props;
        if (isEmpty(linkedBrands)) {
            dispatch(getLinkedBrands());
        }
    }

    checkPageParam() {
        const page = getUrlParam('page') || 1;
        const page_size = getUrlParam('page_size') || PAGE_LIMIT;
        this.setState({ page, page_size });
        return { page, page_size };
    }

    // Checks the URL and sets values pertaining to it
    getValuesFromURL() {
        const { initialCount, dispatch } = this.props;
        if (initialCount === 0) return;

        this.setState({ initialFetch: true });

        const urlFilters = getFiltersDataFromUrl() || {};
        const sort = getSortDataFromUrl() || DEFAULT_SORT;
        dispatch(setBulkFilters(urlFilters, true));
        const recordsCount = localStorage.getItem('recordsCount');
        if (initialCount !== recordsCount)
            dispatch(setInitialCount(recordsCount));
        this.setState({ initialFetch: false });

        this.fetchData({}, urlFilters, undefined, undefined, undefined, sort);
    }

    fetchInfo() {
        const { dispatch } = this.props;
        const { queryObject, aggregations, originalAggregations } = this.state;

        return dispatch(getEsData(queryObject, this.type)).then((resp) => {
            if (aggregations !== resp.aggregations || originalAggregations !== resp.aggregations) {
                this.setState({
                    aggregations: resp.aggregations || {},
                    originalAggregations: resp.aggregations || {}
                });
            }
        });
    }

    fetchData(
        params,
        urlFilters,
        recordId,
        versionId,
        validationVersionId,
        sort
    ) {
        const { selectedLicensor, page, page_size, duplicateBeingManged } = this.state;
        const paramsAll = isEmpty(params) ? this.checkPageParam() : params;
        const { dispatch, initialCount, selectedRows, registrableIds } = this.props;
        const sortedBy = sort || this.state.sortedBy;
        const sortedName =
            sortedBy.name === 'uploaded_by'
                ? 'meta.upload_by_user'
                : sortedBy.name;

        let newFilters = parsedNewFilters('licensor_organisation_id', selectedLicensor.licensor_organisation_id, urlFilters);

        if (params && params.page && params.page_size) {

            if (page !== params.page) {
                this.setState({
                    page: params.page
                });
            }

            if (page_size !== params.page_size) {
                this.setState({
                    page_size: params.page_size
                });
            }
        }

        if (duplicateBeingManged !== null) {
            this.setState({ duplicateBeingManged: null });
        }

        return dispatch(
            fetchProducts(
                paramsAll,
                newFilters,
                initialCount,
                true,
                recordId,
                versionId,
                validationVersionId,
                selectedRows,
                {
                    [sortedName]: sortedBy.dir ? 'desc' : 'asc'
                },
                registrableIds
            )
        );
    }

    sortData(key, forceDir) {
        const dir =
            key === this.state.sortedBy.name ? !this.state.sortedBy.dir : true;

        const sortedBy = {
            name: key,
            dir: forceDir === undefined ? dir : forceDir
        };

        this.setState({ sortedBy });

        this.fetchData(
            null,
            getFiltersDataFromUrl(),
            null,
            null,
            null,
            sortedBy
        );
    }

    showUploadInformation(id) {
        this.setState({ uploadInfoId: id });
    }
    
    toggleUploads() {
        const { uploadInfoId, showUploads } = this.state;

        if (showUploads) {
            if (uploadInfoId) this.showUploadInformation(null);
        }
        return this.setState({ showUploads: !showUploads });
    }

    toggleUploads() {
        const { uploadInfoId, showUploads } = this.state;

        if (showUploads) {
            if (uploadInfoId) this.showUploadInformation(null);
        }
        return this.setState({ showUploads: !showUploads });
    }

    duplicateActive(id) {
        this.setState({ duplicateBeingManged: id });
    }

    selectLicensor(licensor) {
        const { dispatch } = this.props;

        localStorage.setItem('selectedLicensor', JSON.stringify(licensor));

        this.setState({
            selectedLicensor: licensor
        });

        setData(
            {},
            true,
            null,
            null,
            null,
            {},
            {},
            0,
            null,
            dispatch,
            []
        );

        if (!!licensor) {
            this.fetchIpLevels(licensor.licensor_organisation_id);
            this.fetchIps(licensor.licensor_organisation_id);
            this.setState({ key: new Date(), showUploader: false });
        }

        dispatch(
            selectAll(
                [],
                true,
                []
            )
        );

        this.handleMount();
    }

    fetchLicensors() {
        const { selectedLicensor } = this.state;

        return fetchLinkedLicensors(this.orgId).then(response => {
            let mapLinkedLicensors = !!response.data && map(response.data.links, 'licensor_organisation_id') || [];

            if (!!selectedLicensor && !(includes(mapLinkedLicensors, selectedLicensor.licensor_organisation_id))) {
                localStorage.removeItem('selectedLicensor');
            }
        });
    }

    showFiltersToggle(state) {
        this.setState({ showFilters: state !== undefined ? state : !this.state.showFilters });
    }

    render() {
        const {
            uploadInfoId,
            sortedBy,
            duplicateBeingManged,
            initialFetch,
            showUploader,
            page_size,
            gtinWaiver,
            originalAggregations,
            aggregations,
            queryObject,
            basicFilterObj,
            searchItems,
            page,
            categoryLevels,
            selectedLicensor,
            ipLevels,
            ips,
            validationsExist,
            showUploads,
            showFilters,
            ignoreValidations
        } = this.state;

        const {
            data,
            fetchErrors,
            checkIngesting,
            totalItems,
            headers,
            linkedBrands,
            xelacore,
            dispatch
        } = this.props;

        const filtersUrlJSON = Base64.decode((getUrlParam('filters')));
        const urlFilters = isValidJson(filtersUrlJSON) && filtersUrlJSON.length > 10 ? JSON.parse(filtersUrlJSON) : {};

        const noData =
            Array.isArray(data) && !size(data) &&
            (!size(Object.keys(urlFilters).filter((f) => (f !== 'is_quarantine') && (f !== 'licensor_organisation_id')))) &&
            !fetchErrors;

        const {
            auth: {
                companyData
            }
        } = xelacore;

        const showTopHeaderSection = !initialFetch && !checkIngesting;
        const dynamicSpecialValues = {
            'Product Information': {
                Brand: linkedBrands
                    ? linkedBrands.linkedBrands.rows.reduce(
                        (res, { brand_id, brand }) => ({
                            ...res,
                            [brand_id]: brand
                        }),
                        {}
                    )
                    : []
            }
        };

        const isFiltersReturnedNoData =
            Object.entries(aggregations)
                .filter(
                    ([k]) => !['Errors', 'Conflicts', 'Warnings'].includes(k)
                )
                .every(([, v]) => v.buckets && v.buckets.length === 0) &&
            basicFilterObj &&
            Object.keys(basicFilterObj).length > 0;
        const tableTotalCount = totalItems || 0;

        if (parseInt(page) !== 1 && Array.isArray(data) && !size(data) && parseInt(page) > Math.ceil(tableTotalCount / page_size)) {
            let newParams = {
                page: 1,
                page_size: page_size
            };
            const url = new URL(window.location.href);
            url.searchParams.set('page', '1');
            setTimeout(() => {
                this.fetchData(
                    newParams,
                    urlFilters
                );
            }, 11);
        }

        return (
            <RestrictedRoute restrictTo="licensee" permission="registration">
                {!!selectedLicensor && !!selectedLicensor.licensor_organisation_id && (
                    <Fragment>
                        {initialFetch && (
                            <div className="c-rec-table__page-loader">
                                <img
                                    src={imgPinkSpinner}
                                    alt="Loading product data..."
                                />
                            </div>
                        )}

                        {showTopHeaderSection && (
                            <TopHeaderSection
                                uploadInfoId={uploadInfoId}
                                gtinWaiver={gtinWaiver}
                                showUploadInformation={(id) =>
                                    this.showUploadInformation(id)
                                }
                                headers={headers}
                                fetchData={(
                                    params,
                                    urlFilters,
                                    recordId,
                                    versionId,
                                    validationVersionId
                                ) =>
                                    this.fetchData(
                                        params,
                                        urlFilters,
                                        recordId,
                                        versionId,
                                        validationVersionId
                                    )
                                }
                                noData={noData}
                                isMyRecords
                                showUploader={showUploader}
                                toggleUploader={(val) => this.toggleUploader(val)}
                                selectLicensor={(licensor) => this.selectLicensor(licensor)}
                                xelacore={xelacore}
                                selectedLicensor={selectedLicensor}
                                validationsExist={validationsExist}
                                showUploads={showUploads}
                                toggleUploads={() => this.toggleUploads()}
                            />
                        )}

                        <AdvancedFiltering
                            updateFiltersState={(
                                queryObject,
                                basicFilterObj,
                                hardUpdate,
                                params
                            ) =>
                                this.updateFiltersState(
                                    queryObject,
                                    basicFilterObj,
                                    hardUpdate,
                                    params
                                )
                            }
                            dynamicSpecialValues={dynamicSpecialValues}
                            queryObject={queryObject}
                            basicFilterObj={basicFilterObj}
                            aggregations={aggregations}
                            originalAggregations={originalAggregations}
                            isMyRecords={true}
                            showLists={showFilters}
                            setAggregations={this.setAggregations}
                            close={() => this.setState({ showFilters: false })}
                            isFiltersReturnedNoData={isFiltersReturnedNoData}
                            categoryLevels={categoryLevels}
                            ipLevels={ipLevels}
                            xelacore={xelacore}
                            selectedLicensor={selectedLicensor}
                        />

                        {!initialFetch && !checkIngesting && !noData && (
                            <div className="c-my-records">
                                <HeaderSection
                                    updateFiltersState={(
                                        queryObject,
                                        basicFilterObj,
                                        hardUpdate,
                                        params
                                    ) =>
                                        this.updateFiltersState(
                                            queryObject,
                                            basicFilterObj,
                                            hardUpdate,
                                            params
                                        )
                                    }
                                    queryObject={queryObject}
                                    basicFilterObj={basicFilterObj}
                                    aggregations={aggregations}
                                    originalAggregations={originalAggregations}
                                    dynamicSpecialValues={dynamicSpecialValues}
                                    fetchData={(
                                        params,
                                        urlFilters,
                                        recordId,
                                        versionId,
                                        validationVersionId
                                    ) =>
                                        this.fetchData(
                                            params,
                                            urlFilters,
                                            recordId,
                                            versionId,
                                            validationVersionId
                                        )
                                    }
                                    location={this.location}
                                    isMyRecords
                                    noData={noData}
                                    xelacore={xelacore}
                                    setAggregations={this.setAggregations}
                                    isFiltersReturnedNoData={
                                        isFiltersReturnedNoData
                                    }
                                    categoryLevels={categoryLevels}
                                    ipLevels={ipLevels}
                                    ips={ips}
                                    toggleUploader={(val) => this.toggleUploader(val)}
                                    showUploader={showUploader}
                                    validationsExist={validationsExist}
                                    companyData={companyData}
                                    showFilters={showFilters}
                                    showFiltersToggle={(state) => this.showFiltersToggle(state)}
                                    ignoreValidations={ignoreValidations}
                                />

                                <Table
                                    updateFiltersState={(
                                        queryObject,
                                        basicFilterObj,
                                        hardUpdate,
                                        params
                                    ) =>
                                        this.updateFiltersState(
                                            queryObject,
                                            basicFilterObj,
                                            hardUpdate,
                                            params
                                        )
                                    }
                                    queryObject={queryObject}
                                    basicFilterObj={basicFilterObj}
                                    searchItems={searchItems}
                                    fetchData={(
                                        params,
                                        urlFilters,
                                        recordId,
                                        versionId,
                                        validationVersionId
                                    ) =>
                                        this.fetchData(
                                            params,
                                            urlFilters,
                                            recordId,
                                            versionId,
                                            validationVersionId
                                        )
                                    }
                                    showUpload={(id) =>
                                        {this.showUploadInformation(id); this.toggleUploads()}
                                    }
                                    sortedBy={sortedBy}
                                    sortData={(key, dir) => this.sortData(key, dir)}
                                    orgId={this.orgId}
                                    gtinWaiver={gtinWaiver}
                                    duplicateActive={(id) =>
                                        this.duplicateActive(id)
                                    }
                                    duplicateBeingManged={duplicateBeingManged}
                                    isMyRecords
                                    xelacore={xelacore}
                                    categoryLevels={categoryLevels}
                                    ipLevels={ipLevels}
                                    ips={ips}
                                    checkboxselector={'checkboxselector'}
                                />

                                <div className="marg-b-40">
                                    <DataViewPagination
                                        totalItems={totalItems > EXCEEDS_LIMIT ? EXCEEDS_LIMIT : totalItems}
                                        pageNum={page}
                                        fetchData={(
                                            params,
                                            urlFilters,
                                            recordId,
                                            versionId,
                                            validationVersionId
                                        ) =>
                                            this.fetchData(
                                                params,
                                                urlFilters,
                                                recordId,
                                                versionId,
                                                validationVersionId
                                            )
                                        }
                                        pageLimit={page_size}
                                    />
                                </div>

                                {totalItems > EXCEEDS_LIMIT && (
                                    <span className="records-exceeds">
                                        {NUMBER_OF_RECORDS_EXCEEDS}
                                    </span>
                                )}
                            </div>
                        )}
                    </Fragment>
                )}

                {!selectedLicensor.licensor_organisation_id && (
                    <SelectLicensor
                        updateFiltersState={(
                            queryObject,
                            basicFilterObj,
                            hardUpdate,
                            params
                        ) =>
                            this.updateFiltersState(
                                queryObject,
                                basicFilterObj,
                                hardUpdate,
                                params
                            )
                        }
                        xelacore={xelacore}
                        dispatch={dispatch}
                        type={this.type}
                        queryObject={queryObject}
                        basicFilterObj={basicFilterObj}
                        categoryLevels={categoryLevels}
                        selectLicensor={(licensor => this.selectLicensor(licensor))}
                        isMyRecords={true}
                    >
                    </SelectLicensor>
                )}
            </RestrictedRoute>
        );
    }
}

function mapStateToProps(state) {
    const {
        xelacore,
        xelacore: {
            userSettings: {
                lists = [],
                headers = {},
                activeHeaders = '',
                headerLayouts = []
            } = {},
            myRecords: {
                records: { data, totalItems } = {},
                fetching: { fetching, fetchErrors, duplicateBeingManged } = {},
                filters: { filters, activeList } = {},
                initialCount,
                selectedRows = [],
                registrableIds = []
            } = {},
            ingestions: { checkIngesting } = {},
            categories: { list: categories, linkedBrands } = {},
            pageNotification
        } = {}
    } = state;

    return {
        xelacore,
        activeList,
        lists,
        pageNotification,
        categories,
        linkedBrands,
        checkIngesting,
        data,
        totalItems,
        fetching,
        fetchErrors,
        initialCount,
        duplicateBeingManged,
        filters,
        headers,
        activeHeaders,
        headerLayouts,
        selectedRows,
        registrableIds
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRecords);
