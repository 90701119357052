export const maxItemsInList = 5;

export const countableRange = [
    'duplicates',
    'errors',
    'warnings',
    'conflicts_total_count'
];
export const booleanCase = ['is_registered', 'is_concept'];
export const searchableFileds = [
    'gtin',
    'product_name',
    'mpn',
    'product_description',
    'concept_code',
    'product_family_name',
    'licensor_organisation_id'
];
export const ids = ['_id'];

export const searchHeaders = {
    product_name: {
        label: 'Product Name',
        value: 'product_name'
    },
    gtin: {
        label: 'GTIN',
        value: 'gtin'
    },
    mpn: {
        label: 'MPN',
        value: 'mpn'
    },
    product_description: {
        label: 'Product Description',
        value: 'product_description'
    },
    concept_code: {
        label: 'Concept Approval Code',
        value: 'concept_code'
    },
    product_family_name:{
        label:'Product Family',
        value:'product_family_name'
    }
};

export const actionsFilter = {
    bool: {
        must: [
          {
            bool: {
              should: [
                {
                  bool: {
                    must: [
                      {
                        term: {
                          'conflicts_gtin_local_count': 0
                        }
                      },
                      {
                        term: {
                          'conflicts_mpn_local_count': 0
                        }
                      }
                    ]
                  }
                },
                {
                  term: {
                    'conflicts_resolved_local': true
                  }
                }
              ]
            }
          },
          {
              term: {
                errors: 0
              }
            },
          {
              term: {
                  is_registered: false
              }
          }
        ]
    }
}
