import React from 'react';
import notFound from 'statics/imgs/desktop/concepts/not_found.svg';
import Button from 'modules/UiKit/components/Button/Button';

export default function ApprovalNoData({ toggleOpaPanel }) {
    return (
        <div className="c-product__concept-block u-margin-bottom">
            <img
                src={notFound}
                className="u-padding"
                alt="No matching concepts"
            />
            <div>
                We could not find any potential matching concepts based on your
                product data
            </div>
            <div className="u-text-center u-padding">
                <Button
                    type="secondary-2"
                    size="small"
                    onClick={toggleOpaPanel}>
                    Search for concept
                </Button>
            </div>
        </div>
    );
}
