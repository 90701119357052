import React from 'react';
import { Provider, connect } from 'react-redux';
import Raven from 'raven-js';
import { hotjar } from 'react-hotjar';

import config from 'config';
const ravenUrl = `https://${config.sentry.key}@sentry.io/${config.sentry.app}`;

const App = connect(
    state => state,
    dispatch => ({ dispatch })
)(({ children }) => {
    return <div role="app">{children}</div>;
});

export default function AppProvider({ children, store }) {
    if (config.ENV === 'production')
        Raven.config(ravenUrl, { environment: config.env }).install();
    if (config.hotjar && config.hotjar.trackingCode)
        hotjar.initialize(
            config.hotjar.trackingCode,
            config.hotjar.snippetVersion || 6
        );
    return (
        <Provider store={store}>
            <App>{children}</App>
        </Provider>
    );
}
