import React, { Component } from 'react';
import toArray from 'lodash/toArray';
import find from 'lodash/find';
import {
    mapLevels,
    addValidations,
    findError
} from 'src/js/helpers/ipHierarchy';
import { Icon, Tooltip } from 'src/js/components/static';

export default class extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredMapedList: mapLevels(props.ips, props.ipLevels, props.data && addValidations(props.data)) || []
        };
    }

    componentDidUpdate(prevProps) {
        const { ips, ipLevels, data } = this.props;

        if ((prevProps.ips !== this.props.ips) || (prevProps.ipLevels !== this.props.ipLevels) || (prevProps.data !== this.props.data)) {
            this.setState({
                filteredMapedList: mapLevels(ips, ipLevels, data && addValidations(data)) || []
            });
        }
    }

    onTooltipHover = (message) => {
        return (
            <div className="c-rec-table__tooltip c-rec-table__tooltip--duplicate-confirmed">
                <div>{message}</div>
            </div>
        );
    };

    render() {
        const {
            filteredMapedList
        } = this.state;
        const { ipLevels, data } = this.props;

        const Tree = ({data = [], novalue = false}) => {
            return (
                <div className={`d-tree ${!novalue ? 'no-label' : 'no-value'}`}>
                    <ul className="d-flex d-tree-container flex-column">
                        {toArray(data).map((tree, key) => {
                            return (
                                <TreeNode node={tree} key={key} novalue={novalue}/>
                            )
                        })}
                    </ul>
                </div>
            );
        };

        const TreeNode = ({node, novalue}) => {
            const hasChild = node.children && node.children.length > 0;
            let findAdditionalError = find(data.validations, validation => validation.field === `ips.${node.ip_level_id}`);

            return (
                <li className={`d-tree-node ${hasChild ? 'has-child' : ''}`}>
                    <div className="tree-wrapper">
                        <div className={`col d-tree-node-wrapper ${!!findError(node) || !!findAdditionalError ? node.error_type : '' }`}>
                            { novalue && (
                                <span className={"node-name"}>
                                    <strong>{node.level_name}</strong>:
                                </span>
                            )}

                            {!node.multi && !novalue && (
                                <div className='node-value'>
                                    {' ' + !!node && !!node.value && node.value.value}

                                    { !!findAdditionalError && (
                                         <div className='u-flex u-flex-align error-message'>
                                            <div className='c-tooltip-button'>
                                                <Icon
                                                    icon={'error2'}
                                                    fill={'red'}
                                                    className="u-margin-small-right"
                                                    top={2}
                                                    size={11}
                                                />
                                            </div>

                                            <span className="validation-message">
                                                {findAdditionalError.message}
                                            </span>
                                        </div>
                                    )}

                                    { !!findError(node) && (
                                        <div className='u-flex u-flex-align error-message'>
                                            <div className='c-tooltip-button'>
                                                <Icon
                                                    icon={'error2'}
                                                    fill={'red'}
                                                    className="u-margin-small-right"
                                                    top={2}
                                                    size={11}
                                                />
                                            </div>

                                            <span className="validation-message">
                                                {node.value.validation}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}

                            {!!node.multi && !novalue && (
                                <div className={'node-value'}>
                                    { !!findAdditionalError && (
                                         <div className='u-flex u-flex-align error-message'>
                                            <div className='c-tooltip-button'>
                                                <Icon
                                                    icon={'error2'}
                                                    fill={'red'}
                                                    className="u-margin-small-right"
                                                    top={2}
                                                    size={11}
                                                />
                                            </div>

                                            <span className="validation-message">
                                                {findAdditionalError.message}
                                            </span>
                                        </div>
                                    )}

                                    { toArray(node.value).map((val, key) => {
                                        return (
                                            <span className={`${!!val.error ? node.error_type : 'no-error' }`}>
                                                <span>
                                                    { !!val.error && (
                                                        <Tooltip
                                                            icon={'info_bubble'}
                                                            fill={'red'}
                                                            top={2}
                                                            width="11"
                                                            height="11">
                                                                <div className="u-text-center">{val.validation}</div>
                                                        </Tooltip>
                                                    )}

                                                    {' ' + val.value}{key === node.value.length - 1 ? '' : ', ' }
                                                </span>
                                            </span>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    {hasChild && (
                        <div className="d-tree-content">
                            <ul className="d-flex d-tree-container flex-column">
                                <Tree data={node.children} novalue={novalue}/>
                            </ul>
                        </div>
                    )}
                </li>
            );
        };

        return (
            <div className='ip-hierarchy-list-display-builder listing'>
                <div className={"tree-wrapper"}>
                    {ipLevels && ipLevels.length > 0 && (
                        <div className={"ip-hierarchy-levels"}>
                            <Tree data={filteredMapedList} novalue={true}/>
                        </div>
                    )}
                    {ipLevels && ipLevels.length > 0 && (
                        <div className={"ip-hierarchy-levels"}>
                            <Tree data={filteredMapedList} novalue={false}/>
                        </div>
                    )}

                </div>
            </div>
        )
    }
}
