import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import createLogger from 'redux-logger';
import config from 'config';
import cookies from 'js-cookie';

import isDevelopment from 'src/js/lib/isDevelopment';

import { buildReducer } from 'src/js/core/Reducers';
import { buildRoutes } from 'src/js/core/Route';
import { checkAuthorised, appInit } from 'src/js/actions/xelacore';
import { logout } from 'src/js/lib/auth';
import networkService from 'src/js/helpers/networkService';

import {
    USER_TOKEN_COOKIE,
    USER_REFRESH_TOKEN_COOKIE
} from 'src/js/constants/actions/xelacore';

const composeEnhancers =
    /* eslint-disable no-underscore-dangle */
    isDevelopment() &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null || compose;

const loggerMiddleware = createLogger({
    collapsed: true,
    duration: true,
    logErrors: true,
    diff: true
});

config.injectFlow();

/**
 * Bootstraps the redux sotre and the react routes, based on the modules that
 * are available for the user.
 *
 * @param  {Object} initialState The initial Redux State
 * @param  {String} location User's location
 * @return {Promise(store, routes)}
 */
export default function bootstrap(initialState, location) {
    // Initiate both redux store and routers
    return config
        .assertConfig()
        .then(state => {
            networkService.setDefaults();
            // redux initiation
            return (
                configureStore(state, location)
                    // Check if the user is authorised, and set the userData.
                    .then(store => store.dispatch(checkAuthorised(store)))
                    .then(store => {
                        // Build the routes, inject the routes from the fetched moduels as well
                        networkService.setupRequestInterceptor(store);
                        return buildRoutes(state, store).then(routes => ({
                            store,
                            routes
                        }));
                    })
            );
            // Fire an initial action, so the initiated components can do their part
        })
        .then(({ store, routes }) =>
            store.dispatch(appInit({ store, routes }))
        );
}

/**
 * Redux middleware to check the user is logged in.
 * Prevent any actions if user not logged in.
 * @param getState {function} getState from Redux
 * @returns {function(*): Function}
 */
const checkUserLogout = ({ getState }) => next => action => {
    const userId = localStorage.getItem('userId');
    const userToken = cookies.get(USER_TOKEN_COOKIE);
    const userRefreshToken = cookies.get(USER_REFRESH_TOKEN_COOKIE);
    const {
        xelacore: {
            auth: { userData: { user_id = null } = {} }
        }
    } = getState();

    if ((!userId && !user_id) || (userId && !user_id)) {
        // If user not logged in and there is no previously stored user || If the user just logged in
        next(action);
    } else if (userId !== user_id) {
        // If logged in user does not match previously stored user
        logout();
    } else if (!userToken || !userRefreshToken) {
        logout();
    } else {
        next(action);
    }
};

/**
 * Redux Store factory, create a store, and inject an initialState,
 * if there's any
 *
 * @param  {Object} state The initial State
 * @param  {String} location The Url that the app is accessed
 * @return {Redux} The redux Store
 */
export function configureStore() {
    // A functional approach, the buildReducer is Responsible in fetching the
    // modules in a lazy manner, and inject their reducers in the main redux store
    return buildReducer().then(rootReducer => {
        const middlewares = [thunkMiddleware, checkUserLogout];
        if (!isDevelopment()) middlewares.push(createRavenMiddleware(Raven));
        if (isDevelopment()) middlewares.push(loggerMiddleware);
        return createStore(
            rootReducer,
            {},
            composeEnhancers(applyMiddleware(...middlewares))
        );
    });
}
