import React from 'react';
import {Icon} from 'src/js/components/static';

import includes from 'lodash/includes';

export default function TableRowSortingIcons({label, sortData, dataName, isActiveSorting, isUp, locked, defaultSort, categoryLevels, colour}) {
    const sortParam = `${isUp ? defaultSort : dataName}`;
    
    return (
        <div className="u-flex-align">
            <div>
                {locked &&
                    <Icon
                        icon="LOCK_FULL"
                        fill={colour}
                        width="10"
                        height="10"
                        right="2"
                        top="2"
                    />
                }
            </div>
            <div>
                <span className="c-rec-table__head-title">
                    {
                        dataName === 'licensee_category_path'
                                 ? !!categoryLevels && categoryLevels[0] || 'Category'
                                 : label
                    }
                </span>
            </div>
            { dataName !== 'licensee_category_path' && !includes(dataName, 'ips') && dataName !== 'target_market' && dataName !== 'product_description' && (
                <div
                    onClick={() => sortData(sortParam)}
                    className="c-rec-table__triangle-parent"
                >
                    <Icon icon={
                                isActiveSorting && isUp
                                    ? 'TRIANGLE_UP'
                                    : isActiveSorting && !isUp
                                    ? 'TRIANGLE_UP'
                                    : 'TRIANGLE_BOTH'
                                }
                          size="15"
                          fill="white"
                          tooltip
                          className={isActiveSorting && !isUp ? 'is-down' : isActiveSorting && isUp ? 'is-up' : ''}
                    />
                </div>
            )}
        </div>
    )
}
