import React, { Fragment } from 'react';
import SelectedProduct from '../OpaSearchPanel/SelectedProduct';
import { Auth } from 'src/js/components/static';
import Button from 'components/UiKit/Button';

export default (props) => {
    const {
        approvalData,
        record_id,
        updateProductConcept,
        close,
        removeConcept,
        productData,
        showOpaSearchPanel,
        licensor,
        xelacore
    } = props;

    const cCode = approvalData['approval_code'];
    const existingConcept =
        productData.concept_code &&
        productData.concept_code.length > 0 &&
        productData.concept_code.some((el) => el === cCode);
    const confirmedConcept =
        existingConcept &&
        productData.concept_code_confirmed_info.some(
            (el) => el.concept_code === cCode
        );

    const linkConceptButton = (
        <Button
            onClick={() => {
                updateProductConcept(record_id, cCode);
                close();
            }}
        >
            Yes, link it
        </Button>
    );

    const isConceptCorrectLabel = (
        <div className="c-product__is-correct">Is this concept correct?</div>
    );

    const unlinkConceptButton = (
        <Button
            onClick={() => {
                removeConcept(record_id, cCode);
                close();
            }}
        >
            Unlink Concept
        </Button>
    );

    const addAnotherConceptButton = (label) => (
        <Button onClick={() => showOpaSearchPanel()}>{label}</Button>
    );

    return (
        <div>
            <SelectedProduct
                data={approvalData}
                getProductData={() => null}
                close={() => null}
                record_id={record_id}
                updateProductConcept={() => null}
                licensor={licensor}
                xelacore={xelacore}
            />

            <Auth restrictTo="licensee">
                <div>
                    {existingConcept && !confirmedConcept && (
                        <React.Fragment>
                            {isConceptCorrectLabel}
                            <div className="u-flex-gap">
                                {linkConceptButton}
                                {addAnotherConceptButton('Add another concept')}
                            </div>
                        </React.Fragment>
                    )}
                    {existingConcept && confirmedConcept && (
                        <div className="u-flex-gap">
                            {addAnotherConceptButton('Add another concept')}
                            {unlinkConceptButton}
                        </div>
                    )}
                    {!existingConcept && (
                        <React.Fragment>
                            {isConceptCorrectLabel}
                            <div className="u-flex-gap">
                                {linkConceptButton}
                                {addAnotherConceptButton('No, choose another')}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </Auth>
        </div>
    );
};
