import React, { Component } from 'react';
import classnames from 'classnames';
import Input from './Input';
import each from 'lodash/each';
import find from 'lodash/find';
import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import capitalize from 'lodash/capitalize';
import { returnUnique } from 'helpers/formValidationHelpers';

export default class DynamicArray extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.value || [],
            dataToDisplay: this.formatCategoryData(props.value, props.name),
            initialData: cloneDeep(this.formatCategoryData(props.value, props.name))
        };
    }

    onChange(e, key) {
        e.preventDefault();
        const { onChange, value } = this.props;
        const { data, dataToDisplay } = this.state;
        const newObj = { [key]: e.target.value };

        let newObjToDisplay = cloneDeep(dataToDisplay);
        let indexToDisplay = findIndex(dataToDisplay, item => item.attribute_key === key);

        newObjToDisplay[indexToDisplay].attribute_value = e.target.value;

        return this.setState(
            {
                data: Object.assign({}, data, newObj),
                dataToDisplay: newObjToDisplay
            },
            () => onChange(Object.assign({}, value, newObj))
        );
    }

    renderValidations(errors, warnings) {
        if (!errors.length && !warnings.length) {
            return '';
        }

        return (
            <div className="o-list">
                {returnUnique(errors).map((el) => (
                    <p
                        className="c-product__detail-listing-item-level-error"
                        key={`${el}`}
                    >
                        {el}
                    </p>
                ))}
                {returnUnique(warnings).map((el) => (
                    <p
                        className="c-product__detail-listing-item-level-warn"
                        key={`${el}`}
                    >
                        {el}
                    </p>
                ))}
            </div>
        );
    }

    formatCategoryData(value, name) {
        const { categoryAttributes } = this.props;

        let newCategoryAttrsArray = [];

        if (name === 'category_attributes') {
            each(categoryAttributes, attr => {
                newCategoryAttrsArray.push({
                    label: capitalize(attr.attribute_key.replaceAll('_', ' ')),
                    attribute_key: attr.attribute_key,
                    attribute_value: !!value && value[attr.attribute_key] || ''
                })
            })
        } else {
            each(value, val => {
                newCategoryAttrsArray.push({
                    label: val.attribute_key,
                    attribute_key: val.attribute_key,
                    attribute_value: val.attribute_value || ''
                })
            })
        }

        return newCategoryAttrsArray;
    }

    formatString(string) {
        return string.replaceAll('_', ' ');
    }

    render() {
        const { dataToDisplay, initialData } = this.state;
        const { name, validations } = this.props;

        return (
            <div className="c-product__detail-block">
                {!!dataToDisplay && dataToDisplay.map((el, i) => {
                    let initialValue = find(initialData, val => {
                        return el.attribute_key === val.attribute_key
                    })
                    let fieldValidation = find(validations, validation => {
                        return !!includes(validation.field, el.attribute_key)
                    });
                    let error = !!fieldValidation && !!fieldValidation.level && fieldValidation.level === 'warn' && initialValue.attribute_value === el.attribute_value;
                    let warn = !!fieldValidation && !!fieldValidation.level && fieldValidation.level === 'error' && initialValue.attribute_value === el.attribute_value;

                    const cx = classnames(
                        'c-form-element__sub-wrapper u-90-percent',
                        {
                            'c-form-element__sub-wrapper--warn': warn,
                            'c-form-element__sub-wrapper--error': error
                        }
                    );

                    return (
                        <div
                            key={`${name}-element-${i}`}
                            className={`c-form-element ${name === 'dynamic_attributes' ? 'disabled' : ''}`}
                        >
                            <label>{el.label}</label>

                            <div className="u-flex-align">
                                <div className={cx}>
                                    <Input
                                        type="text"
                                        disabled={name === 'dynamic_attributes'}
                                        onChange={(e) => this.onChange(e, el.attribute_key)}
                                        value={el.attribute_value}
                                    />
                                </div>
                            </div>

                            { (!!error || !!warn) && (
                                <span className={`c-product__detail-listing-item-level-${!!fieldValidation && fieldValidation.level || 'error'} error-message`}>
                                    {this.formatString(fieldValidation.message)}
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}
