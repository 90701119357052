import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UiButton from './components/Button/Button';
import UiPagination from './components/Pagination/Pagination';
import { ReactComponent as IconExample } from 'src/js/components/static/Icon/svg-icons/info.svg';
import serialize from 'form-serialize';

function UiKit({}) {
    const [currentPageDefault, setCurrentPageDefault] = useState(1);

    useEffect(() => {
    }, []);

    return (
        <div className="ui-components">
            <div className="ui-components-title">
                <h2>BUTTONS</h2>
            </div>

            <div className="ui-components-box">
                <div className="ui-components-box__title">
                    <h4>PRIMARY BUTTON</h4>
                </div>

                <div className="ui-components-box__buttons">
                    <UiButton
                        type="primary"
                        size="small"
                        disabled
                        onClick={() => {
                        }}
                    >
                        Primary Inactive
                    </UiButton>

                    <UiButton
                        type="primary"
                        size="small"
                        onClick={() => {
                        }}
                    >
                        Primary Small
                    </UiButton>

                    <UiButton
                        iconLeft={<IconExample height="12" width="12"></IconExample>}
                        type="primary"
                        size="small"
                        onClick={() => {
                        }}
                    >
                        Primary Icon
                    </UiButton>

                    <UiButton
                        type="primary"
                        size="medium"
                        onClick={() => {
                        }}
                    >
                        Primary Medium
                    </UiButton>

                    <UiButton
                        type="primary"
                        size="large"
                        onClick={() => {
                        }}
                    >
                        Primary Large
                    </UiButton>
                </div>
            </div>

            <div className="ui-components-box">
                <div className="ui-components-box__title">
                    <h4>SECONDARY BUTTON</h4>
                </div>

                <div className="ui-components-box__buttons">
                    <UiButton
                        type="secondary"
                        disabled
                        size="small"
                        onClick={() => {
                        }}
                    >
                        Secondary Inactive
                    </UiButton>

                    <UiButton
                        type="secondary"
                        size="small"
                        onClick={() => {
                        }}
                    >
                        Secondary Small
                    </UiButton>

                    <UiButton
                        iconLeft={<IconExample height="12" width="12"></IconExample>}
                        type="secondary"
                        size="small"
                        onClick={() => {
                        }}
                    >
                        Secondary Icon
                    </UiButton>

                    <UiButton
                        type="secondary"
                        size="medium"
                        onClick={() => {
                        }}
                    >
                        Secondary Medium
                    </UiButton>

                    <UiButton
                        type="secondary"
                        size="large"
                        onClick={() => {
                        }}
                    >
                        Secondary Large
                    </UiButton>
                </div>

            </div>
            <div className="ui-components-box">
                <div className="ui-components-box__title">
                    <h4>Pagination</h4>
                </div>

                <div className="ui-components-box__buttons">
                    <UiPagination
                        currentPage={currentPageDefault}
                        totalItems={111}
                        itemsPerPage={10}
                        updatePageHandler={(page) => {
                            setCurrentPageDefault(page);
                        }
                        }
                        jumpToPage={(e) => {
                            const payload = serialize(e.target, { hash: true });
                            setCurrentPageDefault(parseInt(payload.page));
                        }}
                    />
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = ({
                             xelacore,
                             dispatch
                         }) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(UiKit);

