import React, { Component } from 'react';
import uniq from 'lodash/uniq';
import each from 'lodash/each';

export default class extends Component {
    constructor(props) {
        super(props);

        const {value = []} = props;
        this.state = {
            value: value,
            ipLevels: []
        };
    }

    render() {
        const { data, dataName } = this.props;

        let ipValue = [];
        let level = dataName.split('_')[1];

        each(!!data.ips && data.ips, ipPath => {
            each(ipPath, ip => {
                if(ip.ip_level_id === level) {
                    ipValue.push(ip.value);
                }
            })
        })

        return (
            <div className='c-product-category-levels'>
                {!!ipValue && uniq(ipValue).map((ip, key) => {
                    return (
                        <span>{ip}{`${key + 1 < uniq(ipValue).length ? ', ' : ' '}`}</span>
                    )
                })}
            </div>
        );
    }
}
