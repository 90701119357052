import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

export default function UiButton({
    type,
    size,
    to,
    onClick,
    children,
    disabled,
    iconLeft,
    iconRight,
    className,
    download,
    target,
    submit = false,
    tooltip
}) {
    const content = (
        <React.Fragment>
            {iconLeft !== undefined && (
                <div className="icon-holder -left">{iconLeft}</div>
            )}
            <span className="button-content">{children}</span>
            {iconRight !== undefined && (
                <div className="icon-holder -right">{iconRight}</div>
            )}
        </React.Fragment>
    );

    const link = download ? (
        <a
            className={classnames(
                'ui-button',
                {
                    [`-${type}`]: type,
                    [`-${size}`]: size,
                    '-disabled': disabled
                },
                className
            )}
            download
            href={to}
            target={!!target ? '_blank' : '_self'}
        >
            {content}
        </a>
    ) : (
        <Link
            className={classnames(
                'ui-button',
                {
                    [`-${type}`]: type,
                    [`-${size}`]: size,
                    '-disabled': disabled
                },
                className
            )}
            to={to}
            onClick={onClick}
        >
            {content}
        </Link>
    );

    const button = (
        <button
            className={classnames(
                'ui-button',
                {
                    [`-${type}`]: type,
                    [`-${size}`]: size,
                    '-disabled': disabled
                },
                className
            )}
            onClick={onClick}
            disabled={!!disabled}
            type={submit ? 'submit' : 'button'}
        >
            {content}
            {!!tooltip?(<span className="button-tooltip">{tooltip}</span>):null}
        </button>
    );

    return to ? link : button;
}

UiButton.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'secondary-2','secondary-danger','secondary-danger-2', 'link-danger', 'link-primary']),
    size: PropTypes.oneOf(['large', 'small', 'medium']),
    to: PropTypes.string,
    onClick: PropTypes.func,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    children: PropTypes.any,
    download: PropTypes.bool,
    disabled: PropTypes.bool
};

UiButton.defaultProps = {
    className: '',
    to: undefined,
    iconLeft: undefined,
    iconRight: undefined,
    children: undefined,
    onClick: () => {},
    disabled: false,
    download: false
};
