import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
// import debounce from 'lodash/debounce';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import includes from 'lodash/includes';
import Button from 'modules/UiKit/components/Button/Button';
import { Icon } from 'src/js/components/static';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import {
    maxItemsInList
} from 'src/js/constants/advancedFilteringConstants';

class AggregatedFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedList: [],
            inputValue: []
        };
    }

    rootRef = createRef();

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('advancedFilterClear', this.handleClickOutside, true);
    }

    handleClickOutside = (event) => {
        const domNode = this.rootRef.current;

        if (!domNode || !domNode.contains(event.target)) {
            this.props.closeFilters();
        }
    }

    toggleExpandedList(id) {
        this.setState({
            expandedList: {
                ...this.state.expandedList, [id]: !this.state.expandedList[id]
            }
        });
    }

    handleSearch(e, name) {
        this.state.inputValue[name] = e.target.value;
        this.forceUpdate();
    }

    filterTableFunc(filter, fil, key) {
        const { filterTable } = this.props;

        filterTable(filter, fil, key);
    }

    render() {
        const { expandedList, inputValue } = this.state;
        const {
            clearFilters,
            headersArr,
            aggregations,
            filtersObj,
            showFilters,
            toggleFilters
        } = this.props;

        return (
            <div ref={this.rootRef}
                 className={`c-advanced-filtering-holder ui-filters ${showFilters ? '-open' : '-closed'}`}>
                <div className='ui-filters-header'>
                    <div>
                        <span>
                            Filters
                        </span>

                        <Button
                            type="secondary"
                            size="small"
                            onClick={clearFilters}
                        >
                            <div className="c-rec-panel__nav__content">
                                Clear
                            </div>
                        </Button>
                    </div>

                    <Icon
                        className="c-rec-panel__close-menu"
                        onClick={toggleFilters}
                        icon="close"
                        fill="black"
                    />
                </div>

                <div className='ui-filters-body'>
                    { toArray(headersArr).map((filter) => {
                        const filtersToDisplay =
                                !!aggregations[filter.key] && (aggregations[filter.key].length > maxItemsInList) && !expandedList[filter.key]
                                ? aggregations[filter.key].slice(0, maxItemsInList)
                                : aggregations[filter.key];

                        const filteredItems = !!inputValue && !!inputValue[filter.key] && !!aggregations && aggregations[filter.key].filter((f) => f.key.toLowerCase().includes(inputValue[filter.key].toLowerCase()));
                        const numbers = size(inputValue[filter.key]) > 0 ? size(filteredItems) : size(aggregations[filter.key]);
                        const limited = numbers > maxItemsInList && !expandedList[filter.key];
                        const expanded = numbers > maxItemsInList && expandedList[filter.key];

                        return (
                            <div>
                                { !!filter && !!filter.filter && !!aggregations && aggregations[filter.key] && !!aggregations[filter.key].length && (
                                    <div className='ui-filter-item'>
                                        <div className='ui-filter-item-label'>{filter.label}</div>

                                        {(numbers > maxItemsInList || inputValue[filter.key]) && (
                                            <div
                                                className="c-advanced-filtering__filter-bar"
                                                key={`${filter.key}-search`}
                                            >
                                                <div className="c-advanced-filtering__filter-input">
                                                    <input
                                                        type="text"
                                                        value={inputValue[filter.key]}
                                                        placeholder="Type here"
                                                        onChange={(e) =>
                                                            this.handleSearch(e, filter.key)
                                                        }
                                                    />
                                                    <div className="c-advanced-filtering__search">
                                                        <Icon fill="black" icon="search" size="14" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        { (!!inputValue && inputValue[filter.key] && filteredItems || filtersToDisplay).map((fil) => {
                                            return (
                                                <UiCheckbox
                                                    onClick={() => this.filterTableFunc(filter, fil, filter.key)}
                                                    id={`cust-select-all-headers-${fil.key}`}
                                                    type="checkbox"
                                                    checked={!!filtersObj && !!filtersObj[filter.key] && !!includes(filtersObj[filter.key], fil.key)}
                                                    label={`${fil.key} (${fil.count})`}
                                                    >
                                                </UiCheckbox>
                                            )})
                                        }

                                        { !!inputValue[filter.key] && numbers === 0 && (
                                            <span className='empty-items'>No items found</span>
                                        )}

                                        {(limited || expanded) && numbers > maxItemsInList && (
                                            <div
                                                key={`${filter.key}-see-all`}
                                                className="c-advanced-filtering__seeAll u_pointer"
                                                onClick={() => this.toggleExpandedList(filter.key)}
                                            >
                                                {limited ? 'See all' : 'See less'}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default connect()(AggregatedFilters);