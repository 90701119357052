import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists retaliers
 * @return {Promise}
 */
 export function fetchRetailerListings(recordId, params) {
    return axios
        .request({
            url: [config.urls.catalogv3, 'records', recordId, 'retailer_listings'].join('/'),
            method: 'get',
            params
        })
        .then(responseHandler);
}

/**
 * This API call Lists retailers details
 * @return {Promise}
 */
 export function fetchRetailersDetails(params) {
    return axios
        .request({
            url: [config.urls.catalog, 'retailers'].join('/'),
            method: 'post',
            params
        })
        .then(responseHandler);
}

export function fetchAggregatedRetailListings(orgId, params, pageParams) {
    return axios
    .request({
        url: [
            config.urls.catalogv3,
            `organisation/${orgId}/retailer_listings?aggregations=true&page=${!!pageParams && pageParams.page || 1}&page_size=${!!pageParams && pageParams.page_size || 25}`
        ].join('/'),
        method: 'post',
        data: params
    })
    .then(responseHandler);
}