import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { Auth } from 'components/static';
import UiCheckbox from 'modules/UiKit/components/FormElements/Checkbox';
import { selectRow } from 'src/js/actions/productsFetch';
import MediaView from 'components/MediaView';
import fallbackImageSmall from '../../../../../../statics/imgs/no-photo.svg';
import TooltipApprovedData from 'src/js/modules/ProductCatalogue/routes/ProductCatalogue/ProductCatalogueTable/TooltipApprovedData';

export default function ProductTail({
                                        tailItemData,
                                        itemIndex,
                                        selectedRows,
                                        dispatch,
                                        data,
                                        previousRow
                                    }) {
    const len = 50;
    const [coverImage, setCoverImage] = useState(tailItemData && tailItemData.images && tailItemData.images[0]);


    const getCoverImageIndex = (item) => {
        return item.images.findIndex(image => {
            return image.image_id === coverImage.image_id;
        });
    };

    const goToItemImage = (direction) => {

        let indexFound = tailItemData.images.findIndex(image => {
            return image.image_id === coverImage.image_id;
        });

        if (direction === 'next') {
            setCoverImage(tailItemData.images[indexFound + 1]);


        }
        if (direction === 'prev') {
            setCoverImage(tailItemData.images[indexFound - 1]);
        }
    };

    const openProduct = (record_id) => {
        browserHistory.push(`/product/catalog/${record_id}`);
    };

    useEffect(() => {
        setCoverImage(tailItemData && tailItemData.images && tailItemData.images[0])
    }, [tailItemData])

    return (
        <div className="ui-card__product-tail" key={`${tailItemData.record_id}-${itemIndex}`}>

            <div
                className="box-item-image"
            >
                <div
                    className="image-content"
                >
                    {!!coverImage && coverImage.thumb_url_200 && (<MediaView
                        className="image-background"
                        src={coverImage.thumb_url_200}
                        useImgTag
                    />)}
                    <MediaView
                        className="image-holder"
                        src={!!coverImage && coverImage.thumb_url_200 ? coverImage.thumb_url_200 : 'placeholder'}
                        alt={tailItemData.product_name}
                        fallbackSrc={fallbackImageSmall}
                        useImgTag
                    />

                    <div
                        className="image-cover-selector"
                    >
                        {getCoverImageIndex(tailItemData) > 0 && (
                            <div
                                className="image-control prev"
                                onClick={() => {
                                    goToItemImage('prev');

                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <g
                                        clip-path="url(#clip0_2005_10134)">
                                        <path
                                            d="M10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 3.92163e-07 10 8.74228e-07C4.48582 1.35629e-06 -1.35629e-06 4.48582 -8.74228e-07 10C-3.92163e-07 15.5142 4.48582 20 10 20ZM6.91082 9.41082L11.0775 5.24418C11.24 5.08168 11.4533 5 11.6667 5C11.88 5 12.0934 5.08168 12.2559 5.24418C12.5817 5.57 12.5817 6.09668 12.2559 6.4225L8.67832 10L12.2558 13.5775C12.5816 13.9033 12.5816 14.43 12.2558 14.7558C11.93 15.0816 11.4033 15.0816 11.0775 14.7558L6.91082 10.5891C6.585 10.2633 6.585 9.73668 6.91082 9.41082Z"
                                            fill="white"
                                            fill-opacity="0.59" />
                                    </g>
                                    <defs>
                                        <clipPath
                                            id="clip0_2005_10134">
                                            <rect
                                                width="20"
                                                height="20"
                                                fill="white"
                                                transform="translate(20 20) rotate(180)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        )}

                        {getCoverImageIndex(tailItemData) < tailItemData.images.length - 1 && (
                            <div
                                className="image-control next"
                                onClick={() => {
                                    goToItemImage('next');
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <g clip-path="url(#clip0_2005_10116)">
                                        <path
                                            d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                                            fill="white"
                                            fill-opacity="0.59" />
                                    </g>
                                    <defs>
                                        <clipPath
                                            id="clip0_2005_10116">
                                            <rect
                                                width="20"
                                                height="20"
                                                fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="ui-card__item-title">
                <div className="-body">
                    <div className="checkbox-holder">
                        <Auth restrictTo="licensee">
                            <UiCheckbox
                                type="checkbox"
                                checked={selectedRows.filter(item => item.record_id === tailItemData.record_id).length > 0}
                                onClick={(e) =>
                                    dispatch(
                                        selectRow(
                                            e,
                                            tailItemData,
                                            selectedRows,
                                            false, // isMyRecords
                                            tailItemData,
                                            [],
                                            data,
                                            previousRow
                                        )
                                    )
                                }>
                            </UiCheckbox>
                        </Auth>
                        <Auth restrictTo="licensor">
                            <UiCheckbox
                                type="checkbox"
                                checked={selectedRows.filter(item => item.record_id === tailItemData.record_id).length > 0}
                                onClick={(e) =>
                                    dispatch(
                                        selectRow(
                                            e,
                                            tailItemData,
                                            selectedRows,
                                            false, // isMyRecords
                                            tailItemData,
                                            [],
                                            data,
                                            previousRow
                                        )
                                    )
                                }>
                            </UiCheckbox>
                        </Auth>
                        <Auth restrictTo="agent">
                            <UiCheckbox
                                type="checkbox"
                                checked={selectedRows.filter(item => item.record_id === tailItemData.record_id).length > 0}
                                onClick={(e) =>
                                    dispatch(
                                        selectRow(
                                            e,
                                            tailItemData,
                                            selectedRows,
                                            false, // isMyRecords
                                            tailItemData,
                                            [],
                                            data,
                                            previousRow
                                        )
                                    )
                                }>
                            </UiCheckbox>
                        </Auth>
                    </div>

                    <div className="-title"
                         onClick={() =>
                             browserHistory.push(
                                 `/product/catalog/${tailItemData.record_id}`
                             )
                         }>
                        {
                            (tailItemData.product_name || '').length < len
                                ? tailItemData.product_name
                                : tailItemData.product_name.slice(0, len) + '...'
                        }
                    </div>
                </div>

                <div className="-description">
                    {
                        (tailItemData.product_description || '').length < len
                            ? tailItemData.product_description
                            : tailItemData.product_description.slice(0, len) + '...'
                    }
                </div>
            </div>

            <div className="ui-card__item-concept">
                <div className="u-flex-align status-box">
                    <div className="c-rec-table__status-icons">
                        <TooltipApprovedData
                            record={tailItemData}
                            openProduct={() => openProduct(tailItemData.record_id)}
                            licensor={true}
                        />
                    </div>
                </div>
            </div>
        </div>);
}
