import {
    LOGOUT,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    USER_DATA,
    USER_PERMISSIONS,
    COMPANY_DATA,
    USER_ORGANISATIONS,
    CUSTOM_REPORTS,
    USER_REFRESH_TOKEN,
    SET_USER_PERMISSIONS
} from 'src/js/constants/actions/xelacore';

export function auth(
    state = {
        isFetching: false
    },
    action = {}
) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: !!action.isFetching,
                token: action.token
            });
        case LOGOUT:
            return { isFetching: false };
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                token: null
            });
        case USER_DATA:
            return Object.assign({}, state, {
                userData: action.userData
            });
        case COMPANY_DATA:
            return Object.assign({}, state, {
                companyData: action.companyData
            });
        case USER_PERMISSIONS:
            return Object.assign({}, state, {
                permissions: action.permissions
            });
        case USER_ORGANISATIONS:
            return Object.assign({}, state, {
                organisations: action.payload
            });
        case CUSTOM_REPORTS:
            return Object.assign({}, state, {
                customReports: action.payload
            });
        case USER_REFRESH_TOKEN:
            return Object.assign({}, state, {
                refreshToken: action.payload
            });
        case SET_USER_PERMISSIONS:
            return Object.assign({}, state, {
                userPermissions: action.payload
            });
        default:
            return state;
    }
}
