import { LOCK_BODY, UNLOCK_BODY } from 'src/js/constants/actions/xelacore';

export function lockBody(
    state = {
        lockBody: false
    },
    action = {}
) {
    switch (action.type) {
        case LOCK_BODY:
            return { ...state, ...{ lockBody: true } };

        case UNLOCK_BODY:
            return { ...state, ...{ lockBody: false } };

        default:
            return state;
    }
}
