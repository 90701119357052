export const countries = [
    {
        label: 'Asia',
        items: [
            { value: 'AFG', label: 'Afghanistan' },
            { value: 'ARM', label: 'Armenia' },
            { value: 'AZE', label: 'Azerbaijan' },
            { value: 'BHR', label: 'Bahrain' },
            { value: 'BGD', label: 'Bangladesh' },
            { value: 'BTN', label: 'Bhutan' },
            { value: 'IOT', label: 'British Indian Ocean Territory' },
            { value: 'BRN', label: 'Brunei' },
            { value: 'KHM', label: 'Cambodia' },
            { value: 'CHN', label: 'China' },
            { value: 'CXR', label: 'Christmas Island' },
            { value: 'CCK', label: 'Cocos (Keeling) Islands' },
            { value: 'GEO', label: 'Georgia' },
            { value: 'HKG', label: 'Hong Kong' },
            { value: 'IND', label: 'India' },
            { value: 'IDN', label: 'Indonesia' },
            { value: 'IRN', label: 'Iran, Islamic Republic of' },
            { value: 'IRQ', label: 'Iraq' },
            { value: 'ISR', label: 'Israel' },
            { value: 'JPN', label: 'Japan' },
            { value: 'JOR', label: 'Jordan' },
            { value: 'KAZ', label: 'Kazakhstan' },
            { value: 'PRK', label: "Korea, Democratic People's Republic of" },
            { value: 'KOR', label: 'Korea, Republic of' },
            { value: 'KWT', label: 'Kuwait' },
            { value: 'KGZ', label: 'Kyrgyzstan' },
            { value: 'LAO', label: "Lao People's Democratic Republic" },
            { value: 'LBN', label: 'Lebanon' },
            { value: 'MAC', label: 'Macao' },
            { value: 'MYS', label: 'Malaysia' },
            { value: 'MDV', label: 'Maldives' },
            { value: 'MNG', label: 'Mongolia' },
            { value: 'MMR', label: 'Myanmar' },
            { value: 'NPL', label: 'Nepal' },
            { value: 'OMN', label: 'Oman' },
            { value: 'PAK', label: 'Pakistan' },
            { value: 'PSE', label: 'Palestinian Territory, Occupied' },
            { value: 'PHL', label: 'Philippines' },
            { value: 'QAT', label: 'Qatar' },
            { value: 'SAU', label: 'Saudi Arabia' },
            { value: 'SGP', label: 'Singapore' },
            { value: 'LKA', label: 'Sri Lanka' },
            { value: 'SYR', label: 'Syrian Arab Republic' },
            { value: 'TWN', label: 'Taiwan' },
            { value: 'TJK', label: 'Tajikistan' },
            { value: 'THA', label: 'Thailand' },
            { value: 'TUR', label: 'Turkey' },
            { value: 'TKM', label: 'Turkmenistan' },
            { value: 'ARE', label: 'United Arab Emirates' },
            { value: 'UZB', label: 'Uzbekistan' },
            { value: 'VNM', label: 'Vietnam' },
            { value: 'YEM', label: 'Yemen' }
        ]
    },
    {
        label: 'Europe',
        items: [
            { value: 'ALB', label: 'Albania' },
            { value: 'AND', label: 'Andorra' },
            { value: 'AUT', label: 'Austria' },
            { value: 'BLR', label: 'Belarus' },
            { value: 'BEL', label: 'Belgium' },
            { value: 'BIH', label: 'Bosnia and Herzegovina' },
            { value: 'BGR', label: 'Bulgaria' },
            { value: 'HRV', label: 'Croatia' },
            { value: 'CYP', label: 'Cyprus' },
            { value: 'CZE', label: 'Czech Republic' },
            { value: 'DNK', label: 'Denmark' },
            { value: 'EST', label: 'Estonia' },
            { value: 'FRO', label: 'Faroe Islands' },
            { value: 'FIN', label: 'Finland' },
            { value: 'FRA', label: 'France' },
            { value: 'DEU', label: 'Germany' },
            { value: 'GIB', label: 'Gibraltar' },
            { value: 'GRC', label: 'Greece' },
            { value: 'GGY', label: 'Guernsey' },
            { value: 'VAT', label: 'Holy See (Vatican City State)' },
            { value: 'HUN', label: 'Hungary' },
            { value: 'ISL', label: 'Iceland' },
            { value: 'IRL', label: 'Ireland' },
            { value: 'IMN', label: 'Isle of Man' },
            { value: 'ITA', label: 'Italy' },
            { value: 'JEY', label: 'Jersey' },
            { value: 'LVA', label: 'Latvia' },
            { value: 'LIE', label: 'Liechtenstein' },
            { value: 'LTU', label: 'Lithuania' },
            { value: 'LUX', label: 'Luxembourg' },
            {
                value: 'MKD',
                label: 'Macedonia, the former Yugoslav Republic of'
            },
            { value: 'MLT', label: 'Malta' },
            { value: 'MDA', label: 'Moldova, Republic of' },
            { value: 'MCO', label: 'Monaco' },
            { value: 'MNE', label: 'Montenegro' },
            { value: 'NLD', label: 'Netherlands' },
            { value: 'NOR', label: 'Norway' },
            { value: 'POL', label: 'Poland' },
            { value: 'PRT', label: 'Portugal' },
            { value: 'ROU', label: 'Romania' },
            { value: 'RUS', label: 'Russia' },
            { value: 'SMR', label: 'San Marino' },
            { value: 'SRB', label: 'Serbia' },
            { value: 'SVK', label: 'Slovakia' },
            { value: 'SVN', label: 'Slovenia' },
            { value: 'ESP', label: 'Spain' },
            { value: 'SJM', label: 'Svalbard and Jan Mayen' },
            { value: 'SWE', label: 'Sweden' },
            { value: 'CHE', label: 'Switzerland' },
            { value: 'UKR', label: 'Ukraine' },
            { value: 'GBR', label: 'United Kingdom' }
        ]
    },
    {
        label: 'Africa',
        items: [
            { value: 'DZA', label: 'Algeria' },
            { value: 'AGO', label: 'Angola' },
            { value: 'BEN', label: 'Benin' },
            { value: 'BWA', label: 'Botswana' },
            { value: 'BFA', label: 'Burkina Faso' },
            { value: 'BDI', label: 'Burundi' },
            { value: 'CMR', label: 'Cameroon' },
            { value: 'CPV', label: 'Cape Verde' },
            { value: 'CAF', label: 'Central African Republic' },
            { value: 'TCD', label: 'Chad' },
            { value: 'COM', label: 'Comoros' },
            { value: 'COG', label: 'Congo' },
            { value: 'COD', label: 'Congo, the Democratic Republic of the' },
            { value: 'CIV', label: 'Ivory Coast' },
            { value: 'DJI', label: 'Djibouti' },
            { value: 'EGY', label: 'Egypt' },
            { value: 'GNQ', label: 'Equatorial Guinea' },
            { value: 'ERI', label: 'Eritrea' },
            { value: 'ETH', label: 'Ethiopia' },
            { value: 'GAB', label: 'Gabon' },
            { value: 'GMB', label: 'Gambia' },
            { value: 'GHA', label: 'Ghana' },
            { value: 'GIN', label: 'Guinea' },
            { value: 'GNB', label: 'Guinea-Bissau' },
            { value: 'KEN', label: 'Kenya' },
            { value: 'LSO', label: 'Lesotho' },
            { value: 'LBR', label: 'Liberia' },
            { value: 'LBY', label: 'Libya' },
            { value: 'MDG', label: 'Madagascar' },
            { value: 'MWI', label: 'Malawi' },
            { value: 'MLI', label: 'Mali' },
            { value: 'MRT', label: 'Mauritania' },
            { value: 'MUS', label: 'Mauritius' },
            { value: 'MYT', label: 'Mayotte' },
            { value: 'MAR', label: 'Morocco' },
            { value: 'MOZ', label: 'Mozambique' },
            { value: 'NAM', label: 'Namibia' },
            { value: 'NER', label: 'Niger' },
            { value: 'NGA', label: 'Nigeria' },
            { value: 'REU', label: 'Réunion' },
            { value: 'RWA', label: 'Rwanda' },
            {
                value: 'SHN',
                label: 'Saint Helena, Ascension and Tristan da Cunha'
            },
            { value: 'STP', label: 'Sao Tome and Principe' },
            { value: 'SEN', label: 'Senegal' },
            { value: 'SYC', label: 'Seychelles' },
            { value: 'SLE', label: 'Sierra Leone' },
            { value: 'SOM', label: 'Somalia' },
            { value: 'ZAF', label: 'South Africa' },
            { value: 'SDN', label: 'Sudan' },
            { value: 'SSD', label: 'South Sudan' },
            { value: 'SWZ', label: 'Swaziland' },
            { value: 'TZA', label: 'Tanzania, United Republic of' },
            { value: 'TGO', label: 'Togo' },
            { value: 'TUN', label: 'Tunisia' },
            { value: 'UGA', label: 'Uganda' },
            { value: 'ESH', label: 'Western Sahara' },
            { value: 'ZMB', label: 'Zambia' },
            { value: 'ZWE', label: 'Zimbabwe' }
        ]
    },
    {
        label: 'Oceania',
        items: [
            { value: 'ASM', label: 'American Samoa' },
            { value: 'AUS', label: 'Australia' },
            { value: 'COK', label: 'Cook Islands' },
            { value: 'FJI', label: 'Fiji' },
            { value: 'PYF', label: 'French Polynesia' },
            { value: 'GUM', label: 'Guam' },
            { value: 'KIR', label: 'Kiribati' },
            { value: 'MHL', label: 'Marshall Islands' },
            { value: 'FSM', label: 'Micronesia, Federated States of' },
            { value: 'NRU', label: 'Nauru' },
            { value: 'NCL', label: 'New Caledonia' },
            { value: 'NZL', label: 'New Zealand' },
            { value: 'NIU', label: 'Niue' },
            { value: 'NFK', label: 'Norfolk Island' },
            { value: 'MNP', label: 'Northern Mariana Islands' },
            { value: 'PLW', label: 'Palau' },
            { value: 'PNG', label: 'Papua New Guinea' },
            { value: 'PCN', label: 'Pitcairn' },
            { value: 'WSM', label: 'Samoa' },
            { value: 'SLB', label: 'Solomon Islands' },
            { value: 'TLS', label: 'Timor-Leste' },
            { value: 'TKL', label: 'Tokelau' },
            { value: 'TON', label: 'Tonga' },
            { value: 'TUV', label: 'Tuvalu' },
            { value: 'UMI', label: 'United States Minor Outlying Islands' },
            { value: 'VUT', label: 'Vanuatu' },
            { value: 'WLF', label: 'Wallis and Futuna' }
        ]
    },
    {
        label: 'North America',
        items: [
            { value: 'AIA', label: 'Anguilla' },
            { value: 'ATG', label: 'Antigua and Barbuda' },
            { value: 'ABW', label: 'Aruba' },
            { value: 'BHS', label: 'Bahamas' },
            { value: 'BRB', label: 'Barbados' },
            { value: 'BLZ', label: 'Belize' },
            { value: 'BMU', label: 'Bermuda' },
            { value: 'CAN', label: 'Canada' },
            { value: 'CYM', label: 'Cayman Islands' },
            { value: 'CRI', label: 'Costa Rica' },
            { value: 'CUB', label: 'Cuba' },
            { value: 'DMA', label: 'Dominica' },
            { value: 'DOM', label: 'Dominican Republic' },
            { value: 'SLV', label: 'El Salvador' },
            { value: 'GRL', label: 'Greenland' },
            { value: 'GRD', label: 'Grenada' },
            { value: 'GLP', label: 'Guadeloupe' },
            { value: 'GTM', label: 'Guatemala' },
            { value: 'HTI', label: 'Haiti' },
            { value: 'HND', label: 'Honduras' },
            { value: 'JAM', label: 'Jamaica' },
            { value: 'MTQ', label: 'Martinique' },
            { value: 'MEX', label: 'Mexico' },
            { value: 'MSR', label: 'Montserrat' },
            { value: 'NIC', label: 'Nicaragua' },
            { value: 'PAN', label: 'Panama' },
            { value: 'PRI', label: 'Puerto Rico' },
            { value: 'KNA', label: 'Saint Kitts and Nevis' },
            { value: 'LCA', label: 'Saint Lucia' },
            { value: 'SPM', label: 'Saint Pierre and Miquelon' },
            { value: 'VCT', label: 'Saint Vincent and the Grenadines' },
            { value: 'TTO', label: 'Trinidad and Tobago' },
            { value: 'TCA', label: 'Turks and Caicos Islands' },
            { value: 'USA', label: 'United States' },
            { value: 'VGB', label: 'Virgin Islands, British' },
            { value: 'VIR', label: 'Virgin Islands, U.S.' }
        ]
    },
    {
        label: 'Antarctica',
        items: [
            { value: 'ATA', label: 'Antarctica' },
            { value: 'BVT', label: 'Bouvet Island' },
            { value: 'ATF', label: 'French Southern Territories' },
            { value: 'HMD', label: 'Heard Island and McDonald Islands' },
            {
                value: 'SGS',
                label: 'South Georgia and the South Sandwich Islands'
            }
        ]
    },
    {
        label: 'South America',
        items: [
            { value: 'ARG', label: 'Argentina' },
            { value: 'BOL', label: 'Bolivia' },
            { value: 'BRA', label: 'Brazil' },
            { value: 'CHL', label: 'Chile' },
            { value: 'COL', label: 'Colombia' },
            { value: 'ECU', label: 'Ecuador' },
            { value: 'FLK', label: 'Falkland Islands (Malvinas)' },
            { value: 'GUF', label: 'French Guiana' },
            { value: 'GUY', label: 'Guyana' },
            { value: 'PRY', label: 'Paraguay' },
            { value: 'PER', label: 'Peru' },
            { value: 'SUR', label: 'Suriname' },
            { value: 'URY', label: 'Uruguay' },
            { value: 'VEN', label: 'Venezuela' }
        ]
    }
];

export const gdpList = {
    USA: 19485394,
    CHN: 12234781,
    JPN: 4872415,
    DEU: 3693204,
    GBR: 2631228,
    FRA: 2582492,
    IND: 2575666,
    BRA: 2055512,
    ITA: 1943835,
    CAN: 1647120,
    KOR: 1577524,
    RUS: 1530750,
    AUS: 1408675,
    ESP: 1314314,
    MEX: 1158229,
    IDN: 1015539,
    TUR: 851541,
    NLD: 830572,
    SAU: 683827,
    CHE: 678938,
    ARG: 637486,
    TWN: 579865,
    SWE: 535607,
    POL: 526211,
    BEL: 494763,
    IRN: 460976,
    THA: 455302,
    AUT: 416835,
    NOR: 399470,
    ARE: 382575,
    NGA: 375769,
    ISR: 353268,
    ZAF: 348872,
    HKG: 341659,
    IRL: 331430,
    DNK: 329865,
    SGP: 323901,
    MYS: 314707,
    PHL: 313595,
    COL: 309191,
    PAK: 302139,
    CHL: 277080,
    VEN: 255092,
    BGD: 254646,
    FIN: 252246,
    VNM: 223779,
    CZE: 215824,
    ROU: 211803,
    PER: 211402,
    GRC: 203085,
    NZL: 202044,
    EGY: 195135,
    PRT: 195041,
    IRQ: 182023,
    QAT: 167605,
    DZA: 167555,
    KAZ: 159406,
    HUN: 139761,
    AGO: 126505,
    SDN: 120265,
    KWT: 119534,
    UKR: 112154,
    MAR: 109708,
    ECU: 104295,
    PRI: 104218,
    CUB: 96851,
    SVK: 95617,
    LKA: 87356,
    DOM: 75931,
    GTM: 75619,
    ETH: 75605,
    KEN: 74938,
    OMN: 70783,
    MMR: 67101,
    LUX: 62316,
    PAN: 61838,
    URY: 59180,
    GHA: 58996,
    BGR: 58222,
    CRI: 57564,
    HRV: 55201,
    BLR: 54441,
    LBN: 53393,
    TZA: 52090,
    MAC: 50361,
    UZB: 49677,
    SVN: 48455,
    LTU: 47544,
    SRB: 41589,
    AZE: 40748,
    JOR: 40708,
    TUN: 40068,
    CIV: 38054,
    TKM: 37915,
    COG: 37642,
    BOL: 37508,
    BHR: 35325,
    CMR: 34924,
    LVA: 30463,
    PRY: 29435,
    YEM: 27956,
    UGA: 27698,
    EST: 25921,
    ZMB: 25868,
    LBY: 25127,
    NPL: 24870,
    SLV: 24805,
    ISL: 24476,
    HND: 22978,
    KHM: 22121,
    TTO: 22104,
    CYP: 22054,
    PNG: 22005,
    AFG: 21992,
    SEN: 21126,
    BIH: 18169,
    ZWE: 18036,
    BWA: 17406,
    PRK: 17364,
    LAO: 16853,
    MLI: 15235,
    GEO: 15159,
    JAM: 14826,
    GAB: 14623,
    PSE: 14498,
    NIC: 13814,
    MUS: 13366,
    NAM: 13244,
    ALB: 13039,
    MOZ: 12651,
    MLT: 12553,
    GNQ: 12487,
    BFA: 12324,
    BRN: 12128,
    BHS: 11791,
    ARM: 11536,
    COD: 11292,
    MKD: 11279,
    MNG: 11135,
    TCD: 10716,
    MDG: 10674,
    GIN: 10208,
    NCL: 9894,
    BEN: 9236,
    RWA: 9136,
    HTI: 8521,
    MDA: 8128,
    NER: 8119,
    KGZ: 7564,
    RKS: 7227,
    TJK: 7145,
    MCO: 6400,
    MWI: 6339,
    LIE: 6295,
    SSD: 5693,
    PYF: 5623,
    BMU: 5601,
    SUR: 5210,
    MRT: 5092,
    TLS: 4970,
    SLE: 4893,
    MNE: 4588,
    TGO: 4576,
    FJI: 4532,
    SWZ: 4482,
    BRB: 4353,
    ERI: 3858,
    CYM: 3480,
    AND: 3278,
    CUW: 3159,
    GUY: 3086,
    MDV: 3032,
    BDI: 2869,
    ABW: 2664,
    BTN: 2562,
    GRL: 2441,
    LBR: 2122,
    LSO: 2081,
    CAF: 1992,
    BLZ: 1902,
    CPV: 1855,
    DJI: 1844,
    LCA: 1718,
    SMR: 1689,
    SOM: 1535,
    SYC: 1486,
    EAZ: 1391,
    ATG: 1248,
    GNB: 1209,
    SLB: 1103,
    SXM: 1059,
    VGB: 902,
    GRD: 884,
    KNA: 852,
    GMB: 851,
    WSM: 824,
    VUT: 812,
    TCA: 797,
    VCT: 729,
    COM: 648,
    DMA: 533,
    TON: 435,
    STP: 337,
    AIA: 311,
    COK: 311,
    FSM: 308,
    PLW: 234,
    MHL: 209,
    NRU: 182,
    KIR: 180,
    MSR: 63,
    TUV: 38,
    ALA: -1,
    ASM: -1,
    ATA: -1,
    BES: -1,
    BVT: -1,
    IOT: -1,
    CXR: -1,
    CCK: -1,
    FLK: -1,
    FRO: -1,
    GUF: -1,
    ATF: -1,
    GIB: -1,
    GLP: -1,
    GUM: -1,
    GGY: -1,
    HMD: -1,
    VAT: -1,
    IMN: -1,
    JEY: -1,
    MTQ: -1,
    MYT: -1,
    NIU: -1,
    NFK: -1,
    MNP: -1,
    PCN: -1,
    REU: -1,
    BLM: -1,
    SHN: -1,
    MAF: -1,
    SPM: -1,
    SGS: -1,
    SJM: -1,
    SYR: -1,
    TKL: -1,
    UMI: -1,
    VIR: -1,
    WLF: -1,
    ESH: -1
};

const flattenCountries = () => {
    const counts = countries.map((el) => el.items);
    return [].concat
        .apply([], counts)
        .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const allCountries = flattenCountries();
export const countriesSortedByContinent = countries.sort((a, b) =>
    a.label < b.label ? -1 : 1
);

export const listOfTargetMarketsByCode = () => {
    const TMs = {};
    countries.forEach((continent) => {
        continent.items.forEach((country) => {
            TMs[country.value] = `${country.label} (${country.value})`;
        });
    });
    return TMs;
};
