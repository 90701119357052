import React from 'react';

import statusImg from 'statics/imgs/desktop/intro/registered.png';
import imgShoes from 'statics/imgs/desktop/intro/shoes.png';
import teddyBearImg from 'statics/imgs/desktop/intro/teddy-bear.png';

import imgIntro1 from 'statics/imgs/desktop/intro/intro-img.png';
import Button from 'modules/UiKit/components/Button/Button';

const HomeHtml = props => {
    const {} = { ...props };
    return (
        <div className="intro">
            <div className="first-section">
                <div className="section-content">
                    <h1 className="main-heading">
                        Welcome to
                        <br />
                        Xelacore
                    </h1>
                    <p className="text">
                        We’re on a mission to transform brand licensing
                        <br />
                        through the power of data
                    </p>
                    <Button
                        type="primary"
                        size="medium"
                        to="my-records"
                    >
                        Get started
                    </Button>
                </div>
                <div className="img-holder">
                    <img src={imgIntro1} alt="intro" />
                </div>
            </div>

            <div className="second-section">
                <div className="second-section-card-holder">
                    <div className="second-section-card">
                        <div className="close-btn-holder">
                            <div className="close-btn">
                                <div className="close-btn-symbol">×</div>
                            </div>
                        </div>
                        <h2>Drag and drop your CSV file</h2>
                        <p>
                            Alternatively click the button below to browse
                            <br />
                            your local files.
                        </p>
                        <Button
                            type="primary"
                            size="medium"
                        >
                            Choose file
                        </Button>
                    </div>
                </div>
                <div className="section-content">
                    <h1 className="sub-heading">
                        Upload your
                        <br /> product data
                    </h1>
                    <p className="text">
                        Simply upload your product information using our template in a
                        <br />
                        CSV format.
                        <br />
                        The data will pass through our validations, in line with brand and
                        <br />industry requirements.
                    </p>
                </div>
            </div>

            <div className="third-section">
                <div className="section-content third-section-content">
                    <h1 className="sub-heading">
                        Resolve any issues
                        <br />
                        found in your data
                    </h1>
                    <p className="text">
                        Once your product records have been uploaded,
                        <br />
                        check to see if there are any issues that require your attention.
                    </p>
                </div>
                <div className="third-section-card-holder">
                    <div className="third-section-card">
                        <div className="close-btn-holder">
                            <div className="close-btn">
                                <div className="close-btn-symbol">×</div>
                            </div>
                        </div>

                        <Button
                            type="secondary"
                            size="medium">
                            View Product Page
                        </Button>

                        <img src={teddyBearImg} alt="Plush bear" />
                        <h3>Teddy Bear</h3>
                        <p>
                            CLASSIC BIG BROWN TEDDY BEAR: It’s big
                            <br />
                            paws mean bigger bear hugs to share with
                            <br />
                            friends! This classic 18” plush bear design
                            <br />
                            features cute paw pad accents and a curious
                            <br />
                            expression that’s impossible not to love!
                        </p>
                        <div className="form-element">
                            <span className="label">GTIN</span>
                            <div className="field -has-error">
                                4785865569683
                            </div>
                            <span className="error-message">
                                This GTIN is valid but is not in the range of
                                your
                                <br />
                                company’s GCPs.
                            </span>
                        </div>
                        <div className="form-element margin-top">
                            <span className="label">MPN</span>
                            <div className="field">
                                4785865569683
                            </div>
                        </div>
                        <div className="submit-btn-holder">
                            <Button
                                type="secondary"
                                size="medium">
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fourth-section">
                <div className="fourth-section-card-holder">
                    <div className="card card-1">
                        <Button type="secondary-danger-2"
                                size="small">
                            Delete
                        </Button>
                        <Button type="secondary" size="small">
                            Edit
                        </Button>
                        <Button type="secondary" size="small">
                            Register
                        </Button>
                    </div>
                    <div className="card card-2">
                        <div className="status-img-holder">
                            <img src={statusImg} alt="registered" />
                        </div>
                        <div className="square"></div>
                        <div className="img-holder">
                            <img src={imgShoes} alt="shoes" />
                        </div>
                        <div className="item-text">White shoes</div>
                        <div className="item-number">4785865569683</div>
                        <div className="menu-symbol">⋮</div>
                    </div>
                </div>
                <div className="section-content">
                    <h1 className="sub-heading">
                        Register your products
                        <br />
                        with your Licensor
                    </h1>
                    <p className="text">
                        Now you have addressed any discrepancies, it is time
                        <br />
                        to register the products into the official product catalogue.
                        <br />
                        Your Licensor(s) will be notified and have visibility to these
                        <br />
                        products in the catalogue.
                    </p>
                </div>
            </div>
            <div className="final-section">
                <h1>You're ready!</h1>
                <p>
                    Start uploading your products here and begin your Xelacore
                    journey!
                </p>
                <Button
                    type="secondary"
                    size="medium"
                    to="my-records"
                    className="upload-btn"
                >
                    Upload
                </Button>
            </div>
        </div>
    );
};

export default HomeHtml;
