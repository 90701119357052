import React from 'react';
export const IMG_EXTENSIONS = [
    'jpg',
    'jpeg',
    'gif',
    'png',
    'eps',
    'fla',
    'ico',
    'icon',
    'odg',
    'psd',
    'bmp',
    'tiff'
];
export const IMG_MAX_SIZE = 2048000;
export const IMG_ERROR_MESSAGES = {
    ONE_FILE: 'Please upload one file at time',
    WRONG_FILE: (
        <p>
            Please upload a valid image file which must be either in{' '}
            <b>JPEG, PNG or GIF</b> format and have a <b>.jpg/.png/.gif</b>{' '}
            extension on the file
        </p>
    ),
    TOO_BIG: 'Image file size is too large, needs to be below 2MB ',
    UNKNOWN: 'Something went wrong.',
    WRONG_URL: 'The image URL provided is invalid, please choose a valid URL'
};
export const NEW_IMAGE = {
    is_primary: false,
    is_public: true,
    local_url: null,
    original_url: '',
    tag: 'additional',
    validations: []
};
