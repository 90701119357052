import React from 'react';
import Button from 'components/UiKit/Button';

const PageNotFound = () => {
    return (
        <div className="c-404">
            <div className="c-404__body">
                <h1 className="c-404__header">404</h1>
                <p>
                    <strong>Oops, this page doesn't seem to exist!</strong>
                </p>
                <p>
                    <strong>
                        Please check the URL you have entered and try again.
                    </strong>
                </p>
                <Button to={'/'}>Take me Home</Button>
            </div>
        </div>
    );
};

export default PageNotFound;
