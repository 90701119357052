import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { allCountries } from 'src/js/constants/countries';
import languages from 'src/js/constants/languageConstants';
import { Triangle } from 'src/js/components/static';
import {
    getImageUrl
} from 'src/js/helpers/dataHelpers';
import capitalize from 'lodash/capitalize';

export default class extends Component {
    constructor(props) {
        super(props);

        const {value = [], validations = []} = props;
        this.state = {
            expanded: value && value.length > 1 && validations && !!validations.length
        };
    }

    formatTargetMarket(v) {
        if (!v) return '';
        return v.map(el => {
            const country = allCountries.find(f => f.value === el);
            return country ? `${country.label} (${el})` : null;
        });
    }

    formatImages(v, validations) {
        return v.map((e, i) => {
            const url = getImageUrl(e);
            const fieldValidations = !!validations && validations.filter(f => f.field === `images.${i}`);
            const className = classnames('', {
                'u-color-violet-4': !fieldValidations.length,
                'u-color-light-orange': fieldValidations.length
            });

            return url && !!fieldValidations.length ? (
                <a href={url} className={className} target="_blank">
                    {url}
                </a>
            ) : null;
        });
    }

    formatLangauges(v) {
        return v.map(el => {
            const lang = languages.find(f => f.code === el);
            return lang ? `${lang.name} (${el.toUpperCase()})` : null;
        });
    }

    formatAdditional(v) {
        return !!v && Object.keys(v).map(el => {
            if(!!el && el.split('_')[0] !== 'dpp' && el.split('_')[0] !== 'esg') {
                return (
                    <span className='additional-listing-wrapper'>
                        <b className='listing-item-title'>{capitalize(el.replaceAll('_', ' '))}:</b> {v[el]}
                    </span>
                );
            } else {
                return null;
            }
        });
    }

    getName(type) {
        switch (type) {
            case 'mpn_combined':
                return 'MPNs';
            case 'images':
                return 'Images';
            case 'language':
                return 'Languages';
            case 'licensor_character':
                return 'Characters';
            case 'licensor_talent':
                return 'Talents';
            case 'licensor_other':
                return 'Other IP fields';
            case 'concept_code':
                return 'Concept codes';
            case 'additional':
                return 'Additional attributes';
            default:
                return 'Target Markets';
        }
    }

    render() {
        const { expanded } = this.state;
        const { value, type, bold, expand, validations = [] } = this.props;
        const cx = classnames('c-product__detail-listing-item-textarea', {
            'c-product__detail-listing-item-textarea--expanded': expanded,
            'additional': type === 'additional'
        });

        const formatVals = (validations) => {
            switch (type) {
                case 'mpn_combined':
                case 'licensor_character':
                case 'licensor_talent':
                case 'licensor_other':
                case 'concept_code':
                    return value || [];
                case 'images':
                    return this.formatImages(value, validations);
                case 'language':
                    return this.formatLangauges(value);
                case 'target_market':
                    return this.formatTargetMarket(value);
                case 'additional':
                    return this.formatAdditional(value);
                default:
                    return [];
            }
        };

        const vals = formatVals(validations) && formatVals(validations).filter(a => a !== null);

        const name = this.getName(type);
        const triangleColor = value && value.length > 1 && validations && !!validations.length ? '$red-normal' :  '#$blue-normal';
        const showMore = (
            <Fragment>
                <span
                    className={`u_pointer u-purple-more ${bold && 'u-bold'}`}
                    onClick={() => this.setState({ expanded: !expanded })}
                >
                    {!expanded ?
                        <span>
                            {`${!!vals && vals.length} ${name}`}
                            <Triangle up={expanded} color={triangleColor} />
                        </span>
                        :
                        <span>
                            <span className='u-purple-more'>Collapse</span>
                            <Triangle up={expanded} color='$blue-normal' />
                        </span>
                    }

                </span>
            </Fragment>
        );

        return (
            <div className={cx}>
                <span>
                    {!(!!vals && vals.length && vals.length && !!validations && !!validations.length) && type!=='additional' && (
                        <i>{`${type=='additional'?'None provided':''}`}</i>
                    )}
                    {!(!!vals && vals.length && vals.length && !!validations && !!validations.length) && vals.length<1 && type==='additional' && (
                        <i>None provided</i>
                    )}
                    {!!(!!vals && vals.length && vals.length>0 && !!validations && !!validations.length) && (
                        <ul className="o-list c-form-element__validation-status--list">
                            {!!validations && validations.map(({ message, level, field }) => (
                                  <li
                                      key={`${type}-${field}-${level}`}
                                      className={`c-product__detail-listing-item-level-${level}`}
                                  >
                                      {message}
                                  </li>
                            ))}
                        </ul>
                    )}
                    {!!vals && vals.length === 1 && (expand || type !== 'images') && (
                        <span>{!!validations && validations.filter(f => f.field === `${type}.0`) ? (vals[0]) : (vals[0])}</span>
                    )}
                    {!!vals && vals.length === 1 && !expand && type === 'images' && (
                        <span>{`${vals.length} Image`}</span>
                    )}


                    {type !=='additional' && expand && !!vals && vals.length > 1 && !expanded && (
                        <div className="additional-wrapper">{showMore}</div>
                    )}

                    {!expand && !!vals && vals.length > 1 && (
                        <span>{`${vals.length} ${name}`}</span>
                    )}

                    {!!vals && vals.length > 1 && (expanded||type ==='additional' ) && (
                        <div className="additional-wrapper">
                            <ul>
                                {vals.map((el, i) => {
                                    const fieldValidations = !!validations && validations.filter(f => f.field === `${type}.${i}`);
                                    const className = classnames('u-small-bottom ', {
                                        'u-color-violet-4': !fieldValidations.length
                                    });
                                    return (
                                        <li
                                            key={`${el}-${i}-${type}`}
                                            className={className}
                                        >

                                            <b> {el} </b>
                                            {fieldValidations.length > 0 && (
                                                <ul className="o-list c-form-element__validation-status--list">
                                                    {fieldValidations.map(
                                                        ({
                                                             message,
                                                             level,
                                                             field
                                                         }) => (
                                                            <li
                                                                key={`${type}-${field}-${level}`}
                                                                className={`c-product__detail-listing-item-level-${level}`}
                                                            >
                                                                {message}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}
                                        </li>
                                    )
                                })}
                            </ul>

                            {type !=='additional' && expand && <div>{showMore}</div>}
                        </div>
                    )}
                </span>
            </div>
        );
    }
}
