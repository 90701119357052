import React, { Component } from 'react';
import classnames from 'classnames';
import { Button, Icon, Triangle } from 'src/js/components/static';

// This component will take children direclty as list items
// Or you can pass an array of objects to it with props of 'dataMenuTitle'
// and a value for 'dataMenuKey' which will display the value of that key in the object.
// Adding a prop of 'filtering' will also enable filtering based on the two props supplied
// for the object above

export default class FloatingMenu extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            menuOpen: false,
            originalData: this.props.menuData || []
        };
    }

    componentWillReceiveProps(nextProps) {
        const { menuData } = nextProps;
        this.setState({ originalData: menuData });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({
            filteredData: this.state.originalData
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ filteredData: this.state.originalData });
            this.collapse();
        }
    }

    handleMenuClick() {
        const { customHandler } = this.props;
        if (customHandler) return customHandler;
        this.handleClick();
    }

    handleClick(val) {
        const { selected } = this.props;
        if (selected)
            this.wrapperRef.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        const { menuOpen } = this.state;

        this.setState({
            filteredData: this.state.originalData,
            menuOpen: typeof val === 'boolean' ? val : !menuOpen
        });
    }

    collapse() {
        this.setState({
            filteredData: this.state.originalData,
            menuOpen: false
        });
    }

    setChildData() {
        const { filteredData } = this.state;
        const { menuTitleKey, menuDataKey } = this.props;
        if (!filteredData) return null;

        return filteredData.map((d, i) => {
            const title = d[menuTitleKey];
            const value = d[menuDataKey];
            return (
                <li onClick={() => this.props.handleClick(value)} key={i}>
                    {title}
                </li>
            );
        });
    }

    addFilter() {
        if (!this.props.filter) return null;

        return (
            <div className="c-floating-menu__filter-box">
                <div className="c-map-menu__filter-input">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={this.filterMenuData.bind(this)}
                    />
                    <div className="c-map-menu__search">
                        <Icon icon="search" size="14" />
                    </div>
                </div>
            </div>
        );
    }

    filterMenuData(e) {
        const { originalData } = this.state;
        const value = e.target.value.toLowerCase();

        const searchResults = originalData.filter(f => {
            return Object.keys(f).find(obj => {
                const stringify = f[obj].toString().toLowerCase();
                return stringify.indexOf(value) > -1;
            });
        });

        this.setState({ filteredData: searchResults });
    }

    render() {
        const {
            right,
            inline,
            className,
            pinkTitle,
            leftTitle,
            purple,
            pink,
            triangle,
            triangleColor,
            title,
            children,
            customButton,
            tiny,
            top,
            left,
            style,
            medium,
            upload
        } = this.props;
        const { menuOpen } = this.state;

        const cx = classnames('c-floating-menu', className, {
            'c-floating-menu--right': right,
            'c-floating-menu--inline': inline
        });

        const listCx = classnames('c-floating-menu__list', {
            'c-floating-menu__list--right': right
        });

        const buttonCx = classnames('u-capitalize', {
            'c-floating-menu__button': !inline,
            'u-color-blue': pinkTitle
        });

        const wrapperCx = classnames('c-floating-menu__wrapper', {
            'c-floating-menu__wrapper--tiny': tiny,
            'c-floating-menu__wrapper--medium': medium,
            'c-floating-menu__wrapper--upload': upload
        });

        const wrapperStyles = { top, left };

        return (
            <div className={cx} ref={this.setWrapperRef} style={style}>
                {customButton && (
                    <div
                        onClick={() => this.handleClick()}
                        className="c-floating-menu__custom-button"
                    >
                        {customButton}
                        {(triangle || triangleColor) && (
                            <Triangle color={triangleColor} up={menuOpen} />
                        )}
                    </div>
                )}
                {!customButton && (
                    <Button
                        className={buttonCx}
                        purple={purple}
                        pink={pink}
                        onClick={() => this.handleClick()}
                    >
                        {leftTitle && <b>{leftTitle}</b>}
                        {title}
                        <Triangle up={menuOpen} />
                    </Button>
                )}

                {menuOpen && (
                    <div className={wrapperCx} style={wrapperStyles}>
                        {this.addFilter()}

                        <ul
                            className={listCx}
                            onClick={() => this.handleMenuClick()}
                        >
                            {children}
                            {this.setChildData()}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}
