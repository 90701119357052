import React from 'react';

export default function NoDataMyRecods({}) {
    return (
        <div className="u-text-center">
            <p className="c-my-records__no-data-text">
                Please get in touch with your Fabacus Account Associate so we can ensure your account is correctly set up <br/>and do it for you to save your time!
            </p>
        </div>
    );
}
