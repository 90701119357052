import {
    DISPLAY_NOTIFICATION,
    DESTROY_NOTIFICATION
} from 'src/js/constants/actions/xelacore';

export function globalNotification(state = {}, action = {}) {
    switch (action.type) {
        case DISPLAY_NOTIFICATION:
            return Object.assign({}, state, action.payload);
        case DESTROY_NOTIFICATION:
            return Object.assign({}, state, {
                message: null,
                type: null,
                timeOut: null
            });
        default:
            return state;
    }
}
