import findIndex from 'lodash/findIndex';
import mapKeys from 'lodash/mapKeys';
//  Addition and removal of strings / ints from simple arrays

export const addRemove = (arry, val, indicator) => {
    if(typeof val === 'object') {
        let index = findIndex(arry, el => el[indicator] === val[indicator]);

        if(index > -1) return arry.filter(el => el[indicator] !== val[indicator])
        arry.push(val);

        return arry;
    } else {
        if (findIndex(arry, el => el === val) > -1) return arry.filter(el => el !== val);
        arry.push(val);

        return arry;
    }
};

export const maxValueFromArray = arr => {
    return (
        Array.isArray(arr) && arr.length && arr.reduce((a, b) => Math.max(a, b))
    );
};

export const parseV1Response = (data) => {
    return mapKeys(data, (_, key) => {
        return key.replace(/[A-Z]/g, function (match) {
            return `_${match.toLowerCase()}`;
        })
    })
}