import { fetchSchemas } from 'src/js/apicalls/catalog/schemas';
import { SET_SCHEMAS } from 'src/js/constants/actions/xelacore';

export function getSchemas(id = '') {
    return () => fetchSchemas(id);
}

export function setSchemas(schemasToUse) {
    return dispatch =>
        dispatch(getSchemas(schemasToUse)).then(resp => {
            const actionToDispatch = () => ({
                type: SET_SCHEMAS,
                payload: {
                    catalog: {
                        error: resp.data.schemas[0].schema,
                        warn: resp.data.schemas[1].schema
                    }
                }
            });
            return dispatch(actionToDispatch());
        });
}
