import React, { Component } from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import chunk from 'lodash/chunk';
import differenceBy from 'lodash/differenceBy';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import isObject from 'lodash/isObject';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import { getGroupedHeaders } from 'helpers/headers';
import { headers as defaultHeaders } from 'constants/productConstants/headers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { generateExportDoc } from 'apicalls/catalog/schemas';
import { hideModal } from 'src/js/actions/xelacore';
import fallbackImageSmall from '../../../../statics/imgs/fallback_img_small.png';
import MediaView from 'components/MediaView';

import Button from 'modules/UiKit/components/Button/Button';
import { ReactComponent as CloseIcon } from 'src/js/components/static/Icon/svg-icons/close-icon.svg';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import { Auth } from 'components/static';

class DragAndDropProductExport extends Component {
    constructor(props) {
        super(props);
        this.groupedHeaders = getGroupedHeaders(defaultHeaders);
        this.state = {
            data: null,
            groupTitleList: [],
            groupedRows: [],
            groupByOptions: [
                {
                    name: 'licensor_category_path',
                    label: 'Product category'
                },
                {
                    name: 'licensor_brand',
                    label: 'Licensed brand'
                },
                // {
                //     name: 'licensor_property',
                //     label: 'licensed property'
                // },
                {
                    name: 'company',
                    label: 'Licensee'
                }
            ],
            exportTemplate: [
                {
                    name: 'pdf',
                    label: 'PDF'
                }, {
                    name: 'pptx',
                    label: 'PPTX'
                }
            ],
            chunkIndex: parseInt((window.innerWidth - 100) / 240),
            selectedFormatForExport: '',
            selectedGroupBy: null,
            selectedSortBy: {},
            showRegroupConfirmationMessage: false,
            tempGroupList: {
                groupedItemsMapped: [], titleList: []
            },
            showDownloadNotification: false,
            showDownloadErrorNotification: false
        };
    }

    componentWillMount() {
        this.fetchData();

    }

    componentDidUpdate() {
        this.windowResizeHandler();
    }

    windowResizeHandler() {
        const resizeHandler = () => {
            this.setState({ chunkIndex: parseInt((window.innerWidth - 100) / 240) });

            window.removeEventListener('resize', resizeHandler);
        };
        window.addEventListener('resize', resizeHandler);

    }

    setCurrentActiveImage(image) {
        this.setState({ conceptActiveImage: image });
    }

    fetchData() {
        const { rows } = this.props;
        const catalogueExportDataPPT = JSON.parse(sessionStorage.getItem('catalogueExportDataPPT')) || [];
        const catalogueExportTitlesPPT = JSON.parse(sessionStorage.getItem('catalogueExportTitlesPPT')) || [];
        const catalogueExportRowsPPT = JSON.parse(sessionStorage.getItem('catalogueExportRowsPPT')) || [];
        rows.forEach(item => {
            item.cover_image = item.images[0];
        });
        if (isEqual(rows, catalogueExportRowsPPT)) {
            this.setState({
                groupedRows: catalogueExportDataPPT,
                groupTitleList: catalogueExportTitlesPPT
            });
        } else {
            if (differenceBy(rows, catalogueExportRowsPPT, 'record_id').length > 0) {
                catalogueExportTitlesPPT.unshift(null);
                catalogueExportDataPPT.unshift(differenceBy(rows, catalogueExportRowsPPT, 'record_id'));
            }

            this.setState({
                groupedRows: catalogueExportDataPPT,
                groupTitleList: catalogueExportTitlesPPT
            });
        }

        setTimeout(() => {
            this.storeLocalObjectData();
            this.refreshGroupList();
        }, 11);
    }

    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    move(source, destination, droppableSource, droppableDestination) {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId.split('_')[0]] = sourceClone;
        result[droppableDestination.droppableId.split('_')[0]] = destClone;
        return result;
    }

    moveRows(data) {
        const { groupedRows, groupTitleList } = this.state;
        const sourceRowInd = data.source.index;
        const destinationRowInd = data.destination.index;
        let groupedRowsClone = cloneDeep(groupedRows);
        let tempTitles = cloneDeep(groupTitleList);
        const [removed] = groupedRowsClone.splice(sourceRowInd, 1);
        const [removedTitle] = tempTitles.splice(sourceRowInd, 1);
        groupedRowsClone.splice(destinationRowInd, 0, removed);
        tempTitles.splice(destinationRowInd, 0, removedTitle);
        this.setState({
            groupedRows: groupedRowsClone,
            groupTitleList: tempTitles
        });
    }

    onDragEnd(result) {
        const { groupedRows, groupTitleList, chunkIndex } = this.state;
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        let tempTitles = cloneDeep(groupTitleList);
        let gsInd = source.droppableId.split('_');
        let gdInd = destination.droppableId.split('_');
        let sInd = gsInd[0];
        let dInd = gdInd[0];
        let sourceIndex = source.index + (chunkIndex * parseInt(gsInd[1]));
        let destinationIndex = destination.index + (chunkIndex * parseInt(gdInd[1]));

        if (result.destination.droppableId === 'maindropable') {
            this.moveRows(result);
        } else {
            if (sInd === dInd) {
                const items = this.reorder(groupedRows[sInd], sourceIndex, destinationIndex);
                const newState = [...groupedRows];
                newState[sInd] = items;
                this.setState({ groupedRows: newState });
            } else {
                source.index = sourceIndex;
                destination.index = destinationIndex;
                const result = this.move(groupedRows[sInd], groupedRows[dInd], source, destination);
                const newState = [...groupedRows];
                newState[sInd] = result[sInd];
                newState[dInd] = result[dInd];
                newState.forEach((group, index) => {
                    if (group.length < 1) {
                        tempTitles[index] = null;
                    }
                });

                this.setState({
                    groupedRows: newState.filter(group => group.length),
                    groupTitleList: tempTitles.filter(title => !!title)
                });

                setTimeout(() => {
                    this.refreshGroupList();
                    this.storeLocalObjectData();
                }, 11);
            }
        }

        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    splitGroup(groupIndex, chunkInd, splitIndex) {
        const { groupedRows, groupTitleList, chunkIndex } = this.state;
        let tempGroup = cloneDeep(groupedRows);
        let tempGroupTitleList = cloneDeep(groupTitleList);
        let realItemIndex = chunkInd * chunkIndex + splitIndex;
        let firstArrayPart = tempGroup[groupIndex].slice(0, realItemIndex);
        let secondArrayPart = tempGroup[groupIndex].slice(realItemIndex);
        tempGroup[groupIndex] = firstArrayPart;
        tempGroup.splice(groupIndex + 1, 0, secondArrayPart);
        tempGroupTitleList.splice(groupIndex + 1, 0, 'Untitled Group');
        this.setState({ groupedRows: tempGroup, groupTitleList: tempGroupTitleList });

        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    itemRemove(itemIndex, group, chunkInd) {
        const {
            groupedRows, chunkIndex
        } = this.state;
        const { rows } = this.props;
        let tempGroup = cloneDeep(groupedRows);
        const realItemIndex = chunkInd * chunkIndex + itemIndex;
        let rowItemIndex = rows.findIndex(element => {
            return element.product_id === tempGroup[group][realItemIndex].product_id;
        });

        remove(rows, (val, key) => {
            return key === rowItemIndex;
        });
        remove(tempGroup[group], (val, key) => {
            return key === realItemIndex;
        });
        this.setState({ groupedRows: tempGroup });
        setTimeout(() => {
            this.refreshGroupList();
            this.storeLocalObjectData();
        }, 11);
    }

    refreshGroupList() {
        let {
            groupedRows,
            groupTitleList
        } = this.state;
        const {
            rows
        } = this.props;
        let newGroupTitleList = cloneDeep(groupTitleList);
        let newGroupedRows = cloneDeep(groupedRows);
        newGroupedRows.forEach((item, index) => {

            newGroupTitleList[index] = newGroupTitleList[index] || 'Untitled Group';
            if (item.length < 1) {
                newGroupedRows[index] = 'deleted';
                newGroupTitleList[index] = 'deleted';
            }
        });
        this.setState({
            groupedRows: newGroupedRows.filter((value) => value !== 'deleted'),
            groupTitleList: newGroupTitleList.filter((value) => value !== 'deleted')
        });

        if (rows.length < 1) {
            this.clearList();
        }

        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    groupByCategory() {
        const { rows } = this.props;
        let groupedItems = groupBy(rows, item => {
            return item.licensor_category_path ? item.licensor_category_path[0] : null;
        });

        let titleList = Object.keys(groupedItems);
        let groupedItemsMapped = Object.values(groupedItems);
        this.setState({ groupedRows: groupedItemsMapped, groupTitleList: titleList });


        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    groupBySelectedCategory(value) {
        const { groupByOptions, groupedRows } = this.state;

        let selectedGroupBy = '';
        let ungroupedRows = [];
        groupedRows.forEach((arrayItem) => {
            ungroupedRows = ungroupedRows.concat(arrayItem);
        });

        selectedGroupBy = value ? value : 'Untitled Group';
        let groupedItems = groupBy(ungroupedRows, item => {
            return !!item[selectedGroupBy] ? (
                (isArray(item[selectedGroupBy]) || isObject(item[selectedGroupBy])) ?
                    item[selectedGroupBy][0] :
                    item[selectedGroupBy]) : null;
        });

        let titleList = Object.keys(groupedItems);
        let groupedItemsMapped = Object.values(groupedItems);
        this.setState({
            selectedGroupBy: find(groupByOptions, ['name', selectedGroupBy]).label,
            showRegroupConfirmationMessage: true,
            tempGroupList: { groupedItemsMapped, titleList }
        });
    }

    confirmRegroupingList(groupedItemsMapped, titleList) {
        this.setState({ groupedRows: groupedItemsMapped, groupTitleList: titleList });
        this.setState({ showRegroupConfirmationMessage: false });
        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    clearList() {
        const { resetSelectedRows, dispatch } = this.props;
        resetSelectedRows();
        sessionStorage.removeItem('catalogueExportDataPPT');
        sessionStorage.removeItem('catalogueExportTitlesPPT');
        sessionStorage.removeItem('catalogueExportRowsPPT');
        sessionStorage.removeItem('presavedCatalogueRows');
        sessionStorage.clear();
        this.setState({ groupedRows: [], groupTitleList: [] });
        dispatch(hideModal());
    }

    sortGroupByCategory(value, ind) {
        const { groupedRows, selectedSortBy } = this.state;
        let tempGroupedRows = cloneDeep(groupedRows);
        let newRow = sortBy(groupedRows[ind], [(rowItem) => {
            return value === 'licensor_category_path' ? rowItem[value][0] : rowItem[value];
        }
        ]);
        let tempSelectedSortBy = cloneDeep(selectedSortBy);
        tempSelectedSortBy[ind] = value;
        tempGroupedRows[ind] = newRow;
        this.setState({ selectedSortBy: tempSelectedSortBy, groupedRows: tempGroupedRows });
    }

    fetchRecordItem(item) {
        return { record_id: item.record_id, cover_image: item.cover_image.image_id };
    }

    fetchGroupedRowsForExport(format) {
        const {
            groupedRows,
            groupTitleList
        } = this.state;

        let tempExportData = {
            template_id: format + 'template',
            format: format,
            sections: []
        };

        groupedRows.forEach((item, index) => {
            let fetchedItem = [];
            item.forEach(record => {
                fetchedItem.push(this.fetchRecordItem(record));
            });
            let section = {
                title: groupTitleList[index],
                records: fetchedItem
            };
            tempExportData.sections.push(section);
        });
        return tempExportData;
    }

    exportTemplateSelected(format) {
        this.setState({ selectedFormatForExport: format, showDownloadNotification: true });
        generateExportDoc(this.fetchGroupedRowsForExport(format)).then(() => {
            this.setState({ showDownloadNotification: false });
        }).catch(() => {
            this.setState({ showDownloadNotification: false });
            this.setState({ showDownloadErrorNotification: true });
            setTimeout(() => {
                this.setState({ showDownloadErrorNotification: false });
            }, 4000);

        });
    }

    storeLocalObjectData() {
        const {
            groupedRows,
            groupTitleList
        } = this.state;
        const { rows } = this.props;
        sessionStorage.setItem('catalogueExportDataPPT', JSON.stringify(groupedRows));
        sessionStorage.setItem('catalogueExportTitlesPPT', JSON.stringify(groupTitleList));
        sessionStorage.setItem('catalogueExportRowsPPT', JSON.stringify(rows));
        sessionStorage.setItem('presavedCatalogueRows', JSON.stringify(rows));
    }

    setGroupTitle(e, groupIndex) {
        const {
            groupTitleList
        } = this.state;
        let tempTitles = cloneDeep(groupTitleList);
        const { target: { value } = {} } = e;

        tempTitles[groupIndex] = e ? value : 'Untitled Group';
        this.setState({ groupTitleList: tempTitles });
        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    renameEmptyTitle(groupIndex) {
        const {
            groupTitleList
        } = this.state;

        let tempTitles = cloneDeep(groupTitleList);
        let inputValue = tempTitles[groupIndex];
        tempTitles[groupIndex] = !!inputValue ? inputValue : 'Untitled Group';
        this.setState({ groupTitleList: tempTitles });
        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    getCoverImageIndex(item) {
        return item.images.findIndex(image => {
            return image.image_id === item.cover_image.image_id;
        });
    }

    goToItemImage(direction, itemIndex, groupIndex, chunkInd) {
        const {
            groupedRows, chunkIndex
        } = this.state;
        const realItemIndex = chunkInd * chunkIndex + itemIndex;
        let tempGroup = cloneDeep(groupedRows);

        let indexFound = tempGroup[groupIndex][realItemIndex].images.findIndex(image => {
            return image.image_id === tempGroup[groupIndex][realItemIndex].cover_image.image_id;
        });

        if (direction === 'next') {
            tempGroup[groupIndex][realItemIndex].cover_image = tempGroup[groupIndex][realItemIndex].images[indexFound + 1];
        }
        if (direction === 'prev') {
            tempGroup[groupIndex][realItemIndex].cover_image = tempGroup[groupIndex][realItemIndex].images[indexFound - 1];
        }
        this.setState({ groupedRows: tempGroup });
        setTimeout(() => {
            this.storeLocalObjectData();
        }, 11);
    }

    chunkGroup(group) {
        let chunkGroupData = chunk(group, this.state.chunkIndex);
        return chunkGroupData;
    }


    render() {
        const {
            groupedRows,
            groupTitleList,
            groupByOptions,
            showRegroupConfirmationMessage,
            tempGroupList,
            selectedGroupBy,
            selectedSortBy,
            showDownloadNotification,
            showDownloadErrorNotification
        } = this.state;

        const { licensee } = this.props;

        const getItemStyle = (isDragging, draggableStyle) => ({
            userSelect: 'none',
            ...draggableStyle
        });

        return (
            <div className="drag-and-drop-modal-wrapper">
                <div className="modal-header-control">
                    <div className="left-sidebar">
                        <h2>Product Selection</h2>
                        <Button
                            type="secondary-danger-2"
                            size="small"
                            onClick={() => {
                                this.clearList();
                            }}
                        >Clear List
                        </Button>
                    </div>
                    <div className="right-sidebar">
                        <div className="select-group">
                            <label>Group by:</label>
                            <CustomSelect
                                onChange={(e) => this.groupBySelectedCategory(e)}
                                values={licensee ? filter(groupByOptions, i => i.name !== 'company') : groupByOptions}
                                value={groupByOptions && selectedGroupBy ? find(groupByOptions, ['label', selectedGroupBy]).name : 'Choose an option'}
                                allowEmpty={true}
                                selectplaceholder="Choose an option"
                            />
                        </div>
                        <div className="download-group">

                            <Button type="primary"
                                    size="small"
                                    disabled={showDownloadNotification || showDownloadErrorNotification}
                                    onClick={() => this.exportTemplateSelected('pdf')}>
                                Export as .PDF
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                disabled={showDownloadNotification || showDownloadErrorNotification}
                                onClick={() => this.exportTemplateSelected('pptx')}>
                                Export as .PPTX
                            </Button>

                        </div>

                        <div className="close-modal">
                            <CloseIcon
                                className="close-modal-icon c-pointer"
                                onClick={() => {
                                    this.props.dispatch(hideModal());
                                }} />
                        </div>

                    </div>
                </div>

                {!!showRegroupConfirmationMessage && (
                    <div className="regroup-confirmation-message">

                        <div className="confirmation-message">
                            <p>Grouping the products by {selectedGroupBy} will reset the current changes. Are you
                                sure? </p>
                        </div>
                        <div className="confirmation-action">
                            <Button
                                type="secondary-2"
                                size="small"
                                onClick={() => {
                                    this.confirmRegroupingList(tempGroupList.groupedItemsMapped, tempGroupList.titleList);
                                }}
                            >Yes, group by <span className="group-by-selected">{selectedGroupBy}</span>
                            </Button>
                            <Button
                                type="secondary-2"
                                size="small"
                                onClick={() => {
                                    this.setState({ selectedGroupBy: null, showRegroupConfirmationMessage: false });
                                }}
                            >Cancel
                            </Button>
                        </div>
                    </div>
                )}
                {!!showDownloadNotification && (
                    <div className="download-notification">
                        <p>Your download will start shortly...</p>
                    </div>
                )}
                {!!showDownloadErrorNotification && (
                    <div className="download-notification error">
                        <p>We are unable to process your request at the moment, please contact your account manager.</p>
                    </div>
                )}

                <div className="modal-content-wrapper">
                    <DragDropContext
                        onDragEnd={(result) => this.onDragEnd(result)}
                    >
                        <Droppable droppableId="maindropable" type="droprows">
                            {(rowProvided, rowSnapshot) => (
                                <div
                                    className={`draggable-groups-wrapper ${rowSnapshot.isDraggingOver ? 'is-dragging-over' : ''}`}
                                    ref={rowProvided.innerRef}
                                    {...rowProvided.droppableProps}>
                                    {groupedRows.map((group, ind) => (
                                        <Draggable
                                            key={'row' + ind}
                                            draggableId={'row' + ind}
                                            index={ind}
                                        >
                                            {(rowDProvided, rowDSnapshot) => (
                                                <div
                                                    className="draggable-group-item"
                                                    ref={rowDProvided.innerRef}
                                                    {...rowDProvided.draggableProps}
                                                    style={getItemStyle(
                                                        rowDSnapshot.isDragging,
                                                        rowDProvided.draggableProps.style
                                                    )}
                                                >
                                                <span
                                                    className="row-drag-handler"
                                                    {...rowDProvided.dragHandleProps}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-arrows-move"
                                                        viewBox="0 0 16 16"
                                                    >
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"
                                                            ></path>
                                                        </svg>
                                                </span>
                                                    <div className="group-header">
                                                        <div className="group-title">
                                                            <input
                                                                type="text"
                                                                value={groupTitleList[ind]}
                                                                onChange={(e) => {
                                                                    this.setGroupTitle(e, ind);
                                                                }}
                                                                onBlur={() => {
                                                                    this.renameEmptyTitle(ind);
                                                                }}
                                                            />
                                                        </div>

                                                        {group && group.length > 1 && (
                                                            <div className="group-sort">
                                                                <div className="select-group">
                                                                    <label>Sort by:</label>
                                                                    <CustomSelect
                                                                        onChange={(e) => {
                                                                            this.sortGroupByCategory(e, ind);
                                                                        }}
                                                                        values={licensee ? filter(groupByOptions, i => i.name !== 'company') : groupByOptions}
                                                                        value={selectedSortBy[ind] ? selectedSortBy[ind] : null}
                                                                        selectplaceholder="Choose an option"
                                                                    />
                                                                </div>
                                                            </div>)}

                                                    </div>


                                                    {this.chunkGroup(group).map((chunkedGroup, chunkInd) => (<Droppable
                                                        key={ind}
                                                        droppableId={`${ind}_${chunkInd}`}
                                                        direction="horizontal"
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={`drag-group ${snapshot.isDraggingOver ? 'is-dragging-over' : ''}`}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >

                                                                <div className="group-items">
                                                                    {chunkedGroup.map((item, itemIndex) => {
                                                                        return (
                                                                            <div className="draggable-box-wrapper">
                                                                                {itemIndex > 0 && (
                                                                                    <div className="group-splitter">
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                this.splitGroup(ind, chunkInd, itemIndex);
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                className="splitter-letter"
                                                                                            ></span>
                                                                                        </button>
                                                                                    </div>
                                                                                )}

                                                                                <div className="draggable-box-item">
                                                                                    <Draggable
                                                                                        key={item.product_id}
                                                                                        draggableId={item.product_id}
                                                                                        index={itemIndex}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps.style
                                                                                                )}
                                                                                            >
                                                                                                <div
                                                                                                    className="box-item-wrapper"
                                                                                                >

                                                                                                    <button
                                                                                                        className="item-remove-action"
                                                                                                        onClick={() => {
                                                                                                            this.itemRemove(itemIndex, ind, chunkInd);
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                            fill="#fff"
                                                                                                            viewBox="0 0 30 30"
                                                                                                            width="30px"
                                                                                                            height="30px"
                                                                                                        >
                                                                                                            <path
                                                                                                                d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                                                                                                            />
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                    <div
                                                                                                        className="box-item-image"
                                                                                                    >
                                                                                                        <div
                                                                                                            className="image-content"
                                                                                                        >
                                                                                                            <MediaView
                                                                                                                src={!!item.cover_image && item.cover_image.thumb_url_200 ? item.cover_image.thumb_url_200 : 'placeholder'}
                                                                                                                alt={item.product_name}
                                                                                                                fallbackSrc={fallbackImageSmall}
                                                                                                                useImgTag
                                                                                                            />

                                                                                                            <div
                                                                                                                className="image-cover-selector"
                                                                                                            >
                                                                                                                {this.getCoverImageIndex(item) > 0 && (
                                                                                                                    <div
                                                                                                                        className="image-control prev"
                                                                                                                        onClick={() => {
                                                                                                                            this.goToItemImage('prev', itemIndex, ind, chunkInd);

                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            width="20"
                                                                                                                            height="20"
                                                                                                                            viewBox="0 0 20 20"
                                                                                                                            fill="none">
                                                                                                                            <g
                                                                                                                                clip-path="url(#clip0_2005_10134)">
                                                                                                                                <path
                                                                                                                                    d="M10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 3.92163e-07 10 8.74228e-07C4.48582 1.35629e-06 -1.35629e-06 4.48582 -8.74228e-07 10C-3.92163e-07 15.5142 4.48582 20 10 20ZM6.91082 9.41082L11.0775 5.24418C11.24 5.08168 11.4533 5 11.6667 5C11.88 5 12.0934 5.08168 12.2559 5.24418C12.5817 5.57 12.5817 6.09668 12.2559 6.4225L8.67832 10L12.2558 13.5775C12.5816 13.9033 12.5816 14.43 12.2558 14.7558C11.93 15.0816 11.4033 15.0816 11.0775 14.7558L6.91082 10.5891C6.585 10.2633 6.585 9.73668 6.91082 9.41082Z"
                                                                                                                                    fill="white"
                                                                                                                                    fill-opacity="0.59" />
                                                                                                                            </g>
                                                                                                                            <defs>
                                                                                                                                <clipPath
                                                                                                                                    id="clip0_2005_10134">
                                                                                                                                    <rect
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        fill="white"
                                                                                                                                        transform="translate(20 20) rotate(180)" />
                                                                                                                                </clipPath>
                                                                                                                            </defs>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                )}

                                                                                                                {this.getCoverImageIndex(item) < item.images.length - 1 && (
                                                                                                                    <div
                                                                                                                        className="image-control next"
                                                                                                                        onClick={() => {
                                                                                                                            this.goToItemImage('next', itemIndex, ind, chunkInd);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <svg
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                            width="20"
                                                                                                                            height="20"
                                                                                                                            viewBox="0 0 20 20"
                                                                                                                            fill="none">
                                                                                                                            <g clip-path="url(#clip0_2005_10116)">
                                                                                                                                <path
                                                                                                                                    d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                                                                                                                                    fill="white"
                                                                                                                                    fill-opacity="0.59" />
                                                                                                                            </g>
                                                                                                                            <defs>
                                                                                                                                <clipPath
                                                                                                                                    id="clip0_2005_10116">
                                                                                                                                    <rect
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        fill="white" />
                                                                                                                                </clipPath>
                                                                                                                            </defs>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="box-item-info">
                                                                                                        <div
                                                                                                            className="title-content"
                                                                                                        >
                                                                                                            <h4>
                                                                                                                {item.product_name}
                                                                                                            </h4>
                                                                                                        </div>
                                                                                                        <div>


                                                                                                            <h5>
                                                                                                                <b>{licensee ? 'Licensor:' : 'Licensee:'}</b>
                                                                                                                <span
                                                                                                                    title={licensee ? item.licensor : item.company}
                                                                                                                > {licensee ? item.licensor : item.company}</span>
                                                                                                            </h5>
                                                                                                            <h5>
                                                                                                                <b>Brand:</b>
                                                                                                                <span
                                                                                                                    title={item.licensor_brand}
                                                                                                                >{item.licensor_brand}</span>
                                                                                                            </h5>
                                                                                                            <h5>
                                                                                                                <b>Category:</b>
                                                                                                                <Auth restrictTo={'licensee'}>
                                                                                                                    <span title={item.licensee_category_path[0]}>
                                                                                                                        {item.licensee_category_path[0]}
                                                                                                                    </span>
                                                                                                                </Auth>
                                                                                                                <Auth restrictTo={'licensor'}>
                                                                                                                    <span title={item.licensor_category_path[0]}>
                                                                                                                        {item.licensor_category_path[0]}
                                                                                                                    </span>
                                                                                                                </Auth>
                                                                                                                <Auth restrictTo={'agent'}>
                                                                                                                    <span title={item.licensor_category_path[0]}>
                                                                                                                        {item.licensor_category_path[0]}
                                                                                                                    </span>
                                                                                                                </Auth>

                                                                                                            </h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="drag-placeholder">
                                                                                                        <svg
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                            width="16"
                                                                                                            height="16"
                                                                                                            fill="currentColor"
                                                                                                            className="bi bi-arrows-move"
                                                                                                            viewBox="0 0 16 16"
                                                                                                        >
                                                                                                            <path
                                                                                                                fill-rule="evenodd"
                                                                                                                d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"
                                                                                                            ></path>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                </div>

                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>))}


                                                </div>

                                            )}

                                        </Draggable>

                                    ))}
                                </div>
                            )}

                        </Droppable>
                    </DragDropContext>


                </div>
            </div>
        );
    }
}


export default connect()(DragAndDropProductExport);
