import axios from 'axios';
import config from 'config';
import {
    createQueryString,
    createQueryStringForCsv
} from 'src/js/helpers/objects';
import fileDownload from 'src/js/lib/fileDownload';
import responseHandler from './responseHandler';

/**
 * Fetches the rows of a given collection
 *
 * @param  {Object} query
 * @param  {String} collection
 * @param  {Object} pagination
 * @param  {Array} columns
 * @param  {Boolean} isCsv
 * @return {Promise}
 */
export function fetchFilteredCatalogueInformation(
    query = {},
    collection = 'catalog',
    pagination,
    columns,
    isCsv
) {
    const mergedQuery = Object.assign(
        {},
        isCsv ? createQueryStringForCsv(query) : createQueryString(query),
        createQueryStringForCsv(columns),
        pagination
    );
    const headers = {};
    if (isCsv) headers['Content-Type'] = 'text/plain';

    return axios
        .request({
            url: [config.urls.catalog, collection, 'search/filter'].join('/'),
            method: 'get',
            params: Object.assign({}, mergedQuery), // Query needs to be converted to a special string
            headers
        })
        .then(response => {
            const data = responseHandler(response);
            if (isCsv) {
                const date = new Date();
                const timestamp = date.getTime();
                return fileDownload(data, `${timestamp}-codes.csv`);
            }
            return response.data;
        });
}
