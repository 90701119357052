import React, { Component } from 'react';
import moment from 'moment';
import Button from 'components/UiKit/Button';
import { hideModal } from 'src/js/actions/xelacore';

export default class ProductCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numberOfCodes: null,
            codesConfirmed: '',
            fromDate: null,
            toDate: null,
            errorMsg: null
        };
    }

    componentWillMount() {
        const { numberOfCodes } = this.props;
        this.setState({ numberOfCodes });
    }

    handleChange(e, key) {
        this.setState({ [key]: e.target.value });
    }

    checkDates() {
        const { fromDate, toDate } = this.state;
        const today = moment().format('YYYY-MM-DD');

        this.setState({ errorMsg: null });

        if ((fromDate && !toDate) || (!fromDate && toDate)) {
            this.setState({
                errorMsg: 'Please enter a valid date for both fields'
            });
            return false;
        }

        if (
            moment(fromDate).isBefore(today) ||
            moment(toDate).isBefore(today)
        ) {
            this.setState({
                errorMsg:
                    'Please ensure the dates you have entered are for either today or in the future'
            });
            return false;
        }

        return true;
    }

    render() {
        const { codesConfirmed, numberOfCodes, errorMsg } = this.state;
        const { dispatch, data } = this.props;
        const { images, gtin, product_name } = data;
        const formattedImages = images && images.find(el => el && el.tag && el.tag === 'primary');
        const primary_image_url = formattedImages && (formattedImages.local_url || formattedImages.original_url);

        const validNumber = Number(numberOfCodes) && Number(numberOfCodes) > 0;

        const CODES_CSV_LINK = `https://s3.eu-west-1.amazonaws.com/redemption-demo.xelacore.io/redemption-demo_${numberOfCodes}.csv`;

        return (
            <div className="u-text-left">
                <p className="u-color-black u-fat-bottom">
                    <b>You are generating one-use serialised codes for:</b>
                </p>

                <div className="u-flex u-flex-1">
                    <div style={{ width: '30%' }}>
                        <div className="c-product__code-modal-image-holder">
                            <img
                                src={primary_image_url}
                                className="c-product__code-modal-image"
                            />
                        </div>
                    </div>

                    <div className="u-margin-left">
                        <h2 className="u-color-green u-margin-bottom">
                            {product_name}
                        </h2>
                        <ul className="o-list">
                            <li className="u-margin-bottom">
                                <b>GTIN:</b> {gtin}
                            </li>
                            <li className="u-margin-bottom">
                                <b>Serialised Codes:</b>
                                <input
                                    className="c-product__entry-box"
                                    value={numberOfCodes}
                                    onChange={e =>
                                        this.handleChange(e, 'numberOfCodes')
                                    }
                                />
                                {!validNumber && (
                                    <small className="u-color-red">
                                        Please enter a valid number
                                    </small>
                                )}
                            </li>
                            <li className="u-margin-bottom">
                                <b>Reach Campaign:</b> Nobody's Child Summer
                            </li>
                            <li className="u-margin-bottom">
                                <div className="u-small-bottom">
                                    <b>Activation Date:</b>{' '}
                                    <small>
                                        <i>Optional Field</i>
                                    </small>
                                </div>
                                <div>
                                    <b>from</b>
                                    <input
                                        type="date"
                                        className="c-product__entry-box"
                                        onChange={e =>
                                            this.handleChange(e, 'fromDate')
                                        }
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                    <b>to</b>
                                    <input
                                        type="date"
                                        className="c-product__entry-box"
                                        onChange={e =>
                                            this.handleChange(e, 'toDate')
                                        }
                                        min={moment().format('YYYY-MM-DD')}
                                    />
                                </div>
                                {errorMsg && (
                                    <p
                                        className="u-color-red"
                                        style={{ fontWeight: 500 }}
                                    >
                                        {errorMsg}
                                    </p>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="u-text-center">
                    <div className="u-margin-bottom">
                        <p>To confirm your selection, please type "Confirm"</p>
                        <input
                            className="c-product__entry-box c-product__entry-box--large"
                            type="text"
                            onChange={e =>
                                this.handleChange(e, 'codesConfirmed')
                            }
                        />
                    </div>

                    <div className="u-flex-align--center">
                        <Button to={CODES_CSV_LINK}
                                className="u-margin-half-right"
                                download
                                target
                                disabled={codesConfirmed !== 'Confirm' || !validNumber}
                                type="action">
                            Generate
                        </Button>

                        <Button onClick={() => dispatch(hideModal())}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}