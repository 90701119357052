import React from 'react';
import classnames from 'classnames';
import { Icon } from 'src/js/components/static';
import { Tooltip } from 'src/js/components/static';
import { registeredContent } from 'src/js/components/TableRow/common';

export default function TooltipRegisteredData({ ...props }) {
    const { data, getRegisteredLink } = props;

    /* We are using next logic
    Not registered => `catalog_version: null`
    Registered => `catalog_version === version_id`
    Registered with different information => `catalog_version !== version_id`
    */

    // const regged =
    //     data.registered_version_id &&
    //     data.registered_version_id === data.version_id;
    // const reggedDifferent =
    //     data.registered_version_id &&
    //     data.registered_version_id !== data.version_id;
    const reggedNot = !data.registered_version_id;

    const cxReg = classnames('c-rec-table__status-icons-icon marg-r-5', {
        'c-rec-table__status-icons-icon--green': !reggedNot,
        'c-rec-table__status-icons-icon--grey': reggedNot,
        'u-clickable': !reggedNot
    });

    const reggedParam = reggedNot
        ? 'not registered'
        : 'registered';
    const icon = reggedNot
        ? 'folder_not_in'
        : 'folder_in';
    const tooltipButton = (
        <span>
            <Icon icon={icon} size="12" fill="white" tooltip left={-1}/>
        </span>
    );

    const link = () => (!reggedNot ? getRegisteredLink() : null);

    return (
        <div className={cxReg} onClick={() => link()}>
            <Tooltip
                align={{ offset: [15, 30] }}
                className="c-tooltip__centered"
                button={tooltipButton}
                overlay={() => registeredContent(reggedParam)}
            />
        </div>
    );
}
