import React, { useEffect, useState } from 'react';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';
import size from 'lodash/size';
import { Link } from 'react-router';
import classnames from 'classnames';
import { Auth } from 'src/js/components/static';
import { navList } from 'src/js/constants/navList';

const checkIsSameLocation = (event, href) => {
    if (location.pathname === href) {
        event.preventDefault();
    }
};

export default function HeaderNavigation(props) {
    const [navigation, setNavigation] = useState(navList);
    const { location, customReports } = props;

    useEffect(() => {
        let nav = cloneDeep(navigation);

        if (customReports && size(customReports) > 0) {
            nav.push({
                key: 'analytics',
                name: 'Analytics',
                href: '/analytics'
            });

            setNavigation(nav);
        }
    }, [customReports]);

    return (
        <div className="c-site-header__nav">
            <div>
                {navigation.map(
                    ({ key, name, href, restrictTo, permission, level }) => {
                        const isCatRecord =
                            location.pathname.indexOf('/product/catalog') >
                                -1 && key === 'product-catalogue';
                        const isProdRecord =
                            location.pathname.indexOf('/product/records') >
                                -1 && key === 'my-records';

                        const cx = classnames('c-site-header__nav-item', {
                            'c-site-header__nav-item--active':
                                (href !== '/' &&
                                    location.pathname.indexOf(href) > -1) ||
                                (href === '/' && location.pathname === '/') ||
                                isCatRecord ||
                                isProdRecord
                        });

                        const link = (
                            <div key={key} className={cx}>
                                <Link
                                    onClick={(event) =>
                                        checkIsSameLocation(event, href)
                                    }
                                    to={href}
                                >
                                    {name}
                                </Link>
                            </div>
                        );
                        if (restrictTo || permission)
                            return (
                                <Auth
                                    key={key}
                                    permission={compact([
                                        permission,
                                        level
                                    ]).join('.')}
                                    restrictTo={restrictTo}
                                >
                                    {link}
                                </Auth>
                            );
                        return link;
                    }
                )}
            </div>
        </div>
    );
}
