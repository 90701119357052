import { isJson } from 'src/js/helpers/strings';
import isEmpty from 'lodash/isEmpty';
import { headers as defaultHeaders } from 'src/js/constants/productConstants/headers';
import { fetchProducts } from './productsFetch';
import { setMyRecords, setProductCatalogue } from 'src/js/actions/xelacore';

// We are working with the MyRecords root on the Redux Store
// for conveniency the filter handling was moved to the separate file myRecordsFilters

// Set the filter to the Redux Store as a subtree for MyRecords
// Creating a new one or updating current state correspondingly
// Added isMyRecords to make this universal both for myRecords and productCatalogue
export function setFilter(
    filter,
    name = null,
    isNewActiveList,
    filters,
    initialCount,
    isMyRecords,
    categoryLevels
) {
    return (dispatch) => {
        const usedFilter = isJson(filter);
        const filtersToMerge = isNewActiveList && name !== null ? {} : filters;
        const finalFilter = isEmpty(filter)
            ? filter
            : Object.assign({}, filtersToMerge, usedFilter);
        const openFilters = buildOpenFilters(finalFilter);
        const payloadFilters = {
            filters: {
                filters: finalFilter,
                activeList: name,
                openFilters
            }
        };
        const setData = isMyRecords ? setMyRecords : setProductCatalogue;
        dispatch(setData({ filterings: payloadFilters }));
        dispatch(fetchProducts(null, finalFilter, initialCount, isMyRecords, categoryLevels));
    };
}

// Remove filter from the Redux store as a subtree for MyRecords
export function deleteFilter(
    name,
    activeList,
    filters,
    initialCount,
    isMyRecords
) {
    return (dispatch) => {
        let newFilters = Object.assign({}, filters);
        if (typeof name === 'string') {
            delete newFilters[name];
        }
        const finalFilter = typeof name === 'string' ? newFilters : {};
        const openFilters = buildOpenFilters(finalFilter);
        const payloadFilters = {
            filters: {
                filters: isEmpty(newFilters) ? null : newFilters,
                activeList,
                openFilters
            }
        };
        const setData = isMyRecords ? setMyRecords : setProductCatalogue;
        dispatch(setData({ filterings: payloadFilters }));
        dispatch(fetchProducts(null, finalFilter, initialCount, isMyRecords));
    };
}

export function setBulkFilters(filters, isMyRecords, openFiltersParam) {
    return (dispatch) => {
        const openFilters = openFiltersParam
            ? openFiltersParam
            : buildOpenFilters(filters);
        const payloadFilters = {
            filters: {
                filters,
                openFilters
            }
        };
        const setData = isMyRecords ? setMyRecords : setProductCatalogue;
        dispatch(setData({ filterings: payloadFilters }));
    };
}

export function setRemoveAll(param, isMyRecords) {
    return (dispatch) => {
        const setData = isMyRecords ? setMyRecords : setProductCatalogue;
        dispatch(
            setData({
                advanced: {
                    removeFilters: param
                }
            })
        );
    };
}

export function setOpenFilters(openFilters, filters, isMyRecords) {
    return (dispatch) => {
        const payloadFilters = {
            filters: {
                filters,
                openFilters
            }
        };
        const setData = isMyRecords ? setMyRecords : setProductCatalogue;
        dispatch(setData({ filterings: payloadFilters }));
    };
}

export function buildOpenFilters(filters) {
    const openFilters = [];
    if (isEmpty(filters)) return openFilters;
    defaultHeaders
        .filter((header) => header.config && header.config.group)
        .map((item) => {
            const { name } = item;
            if (filters[name]) {
                const obj = Object.assign({}, item);
                obj.value = filters[name];
                openFilters.push(obj);
            }
        });
    return openFilters;
}
