import React from 'react';
import { Link } from 'react-router';

export default function CustomLink({ to, children, ...props }) {
    return (
        <Link {...props} to={to} style={props.style}>
            {children}
        </Link>
    );
}
