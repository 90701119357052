import { displayNotification } from 'src/js/actions/xelacore';

export default function errorHandler(dispatch, error) {
    return dispatch(
        displayNotification({
            message: `Something went wrong. ${error.message}`,
            type: 'error',
            timeOut: 5000
        })
    );
}
