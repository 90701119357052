import React from 'react';
import md5 from 'md5';

export default function Gravatar({
    email = '',
    width = 50,
    alt = 'User Avatar',
    ...props
}) {
    const emailMd5 = md5(email);
    const altText = alt;

    return (
        <img
            src={`//www.gravatar.com/avatar/${emailMd5}?s=${width}&d=identicon`}
            alt={altText}
            {...props}
        />
    );
}
