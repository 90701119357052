const envName = 'development';

const isWindow = typeof window !== 'undefined';
const ENV =
    (process && process.env.RUNTIME_ENV) ||
    (isWindow && window.__ENVIRONMENT__) ||
    envName;

const isDevelopment = ENV === `${envName}`;

export default () => isDevelopment;
export function getEnv() {
    return ENV;
}
