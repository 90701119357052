import React from 'react';
import { Image } from './index';

/**
 * MediaView is a high-level wrapper for displaying different media types embedded like native images
 * @param src {String} usually it's file path or URL
 * @param onClick {Function} function handler
 * @param className {String} className to append to wrapper
 * @param useImgTag {Boolean} switch between render method of native image via <img> or <div> with background
 * @param children {Object} children JSX to render inside <div> image
 * @param fallbackSrc {String} fallback image path to display in case of loading error
 * @param alt {String} alt tag for images
 * @returns {*}
 * @constructor
 */
const MediaView = ({
    src,
    onClick,
    onMouseMove,
    onMouseOut,
    className,
    useImgTag,
    children,
    fallbackSrc,
    alt
}) => {
    if (!src) return null;

    return (
        <Image
            src={src}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseOut={onMouseOut}
            className={className}
            useImgTag={useImgTag}
            fallbackSrc={fallbackSrc}
            alt={alt}
        >
            {children}
        </Image>
    );
};

export default MediaView;
