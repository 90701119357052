import { browserHistory } from 'react-router';
import each from 'lodash/each';
import size from 'lodash/size';
import get from 'lodash/get';

export const parsedSets = (sets) => {
    let parsedRows = [];

    each(sets, (set) => {
        each(set, (row) => {
            parsedRows.push(row);
        });
    });

    return parsedRows;
};

export const openProduct = (record_id) => {
    browserHistory.push(`/product/records/${record_id}`);
};

export const checkValidations = (validations) => {
    return (
        validations &&
        validations.some((validation) => validation.level === 'error')
    );
};

export const onChange = (e, el, key) => {
    el[key] = e.target.value;
};

export const isQuarantineRecord = (data) => {
    const quarantineCount =
        size(data.quarantine_gtin_local) + size(data.quarantine_mpn_local);
    return !!get(data, 'registered_with') && quarantineCount > 0;
};
