import isEmpty from 'lodash/isEmpty';
import { setMyRecords, setProductCatalogue, setQuarantine } from './xelacore';
import { getProductInformation } from './records';
import { checkRecordsCount } from './myRecords';
import { addRemove } from '../helpers/arrays';
import { browserHistory } from 'react-router';
import { filterReadyToRegister, setUrlValues } from '../helpers/dataHelpers';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';

let numberOfIterations = 0;

export function fetchProducts(
    params,
    filters,
    initialCount,
    isMyRecords,
    recordId,
    versionId,
    validationVersionId,
    selectedRows,
    sort,
    registrableIds,
    categoryLevels
) {
    if (!filters) filters = {}; // Take null into account
    
    return dispatch => {
        const pagination = {
            page: params ? params.page : 1,
            page_size: params ? params.page_size : 20
        };
        const productUrl = isMyRecords ? 'my-records' : 'product-catalogue';
        const locationString = setUrlValues(pagination, filters, sort);
        browserHistory.push(`/${productUrl}?${locationString}`);

        const payloadFetching = {
            fetching: {
                fetching: true
            }
        };
        const catalog = isMyRecords ? 'records' : 'catalog';
        const setMethod = isMyRecords ? setMyRecords : setProductCatalogue;
        dispatch(setMethod({ fetch: payloadFetching }));
        return dispatch(
            getProductInformation(catalog, filters, pagination, false, sort, categoryLevels)
        )
            .then(response => {
                setData(
                    response,
                    isMyRecords,
                    recordId,
                    versionId,
                    validationVersionId,
                    params,
                    filters,
                    initialCount,
                    setMethod,
                    dispatch,
                    selectedRows,
                    registrableIds
                );
                if (!isMyRecords) {
                    dispatch(
                        getProductInformation(catalog, filters, pagination, categoryLevels)
                    ).then(quarantineResponse => {
                        setData(
                            quarantineResponse,
                            isMyRecords,
                            recordId,
                            versionId,
                            validationVersionId,
                            params,
                            filters,
                            initialCount,
                            setQuarantine,
                            dispatch,
                            selectedRows
                        );
                    });
                }
            })
            .catch(e => {
                // Add variable fetchErrors to show correctly NoData
                const { response: { status } = {} } = e;
                const payloadRecords = {
                    records: {
                        data: [],
                        totalItems: 0,
                        page: 1,
                        page_size: 20
                    }
                };
                const payloadFetching = {
                    fetching: {
                        fetching: false,
                        fetchErrors: status > 399
                    }
                };
                const payload = {
                    records: payloadRecords,
                    fetch: payloadFetching,
                    initialCount: initialCount === null ? 0 : initialCount
                };

                return dispatch(setMethod({ payload }));
            });
    };
}

export function setData(
    response,
    isMyRecords,
    recordId,
    versionId,
    validationVersionId,
    params,
    filters,
    initialCount,
    setMethod,
    dispatch,
    selectedRows
) {
    const { records, count, _metadata } = response;
    const total_records =
        _metadata && _metadata.pagination && _metadata.pagination.total_records;
    const page = _metadata && _metadata.pagination && _metadata.pagination.page;
    const page_size =
        _metadata && _metadata.pagination && _metadata.pagination.page_size;
    // Special case for very slow update
    if (isMyRecords && recordId && versionId && numberOfIterations < 10) {
        const valueFound = records.find(item => item.record_id === recordId);
        const version_id = valueFound && valueFound.version_id;
        const validation_version_id =
            valueFound && valueFound.validation_version_id;
        if (
            (version_id &&
                version_id === versionId &&
                validation_version_id === validationVersionId) ||
            !valueFound
        ) {
            numberOfIterations += 1;
            return setTimeout(
                () =>
                    dispatch(
                        fetchProducts(
                            params,
                            filters,
                            initialCount,
                            isMyRecords,
                            recordId,
                            versionId,
                            validationVersionId
                        )
                    ),
                1000
            );
        }
    }
    numberOfIterations = 0;

    const payloadRecords = {
        records: {
            data: records,
            totalItems: total_records ? total_records : count,
            page: page || 1,
            page_size: page_size || 20
        }
    };
    const payloadFetching = {
        fetching: {
            fetching: false
        }
    };
    const recordsCount = {
        initialCount:
            initialCount === null || initialCount === undefined
                ? total_records
                : initialCount
    };
    const payload = {
        records: payloadRecords,
        fetch: payloadFetching,
        recordsCount
    };

    dispatch(!!setMethod ? setMethod(payload) : isMyRecords ? setMyRecords(payload) : setProductCatalogue(payload));
    
    if (isMyRecords) {
        dispatch(checkRecordsCount(total_records, initialCount, filters));
        // Check if in the updated records there are no records with conflicts
        const newSelected = selectedRows.filter(selectedRow =>
            isEmpty(
                records.find(
                    record =>
                        record.record_id === selectedRow && record.conflicts
                )
            )
        );
        const newRegistrable = selectedRows.filter(registrableId =>
            isEmpty(
                records.find(
                    record =>
                        record.record_id === registrableId &&
                        (record.errors ||
                            record.registered_with ||
                            (record.conflicts_total_count &&
                                !record.conflicts_resolved_local))
                )
            )
        );

        dispatch(selectAll(newSelected, true, newRegistrable));
    } else {
        dispatch(setProductCatalogue({selectedRows: selectedRows}))
    }
}

export function allChecked(selectedIds, allIds) {
    let selected = [];

    selected = filter(allIds, id => {
        return includes(selectedIds, id);
    })

    return selected.length === selectedIds.length;
}

export function selectRow(
    e,
    id,
    selectedRows = [],
    isMyRecords,
    data,
    registrableIds,
    allIds,
    previousRow
) {
    let rowIndex = findIndex(allIds, el => el.record_id === data.record_id);

    return dispatch => {
        const payloadSelectedRows = {
            selectedRows: selectedRows || []
        };
        let selectedIds = [];

        if (e.shiftKey && previousRow !== null) {
            if(previousRow < rowIndex) {
                for (let i = previousRow; i <= rowIndex; i++) {
                    selectedIds.push(allIds[i]);
                }

                if(allChecked(selectedIds, payloadSelectedRows.selectedRows)) {
                    for (let i = previousRow; i <= rowIndex; i++) {
                        remove(payloadSelectedRows.selectedRows, id => id === allIds[i]);
                    }

                    selectedIds = [];
                } else {
                    for (let i = previousRow; i <= rowIndex; i++) {
                        payloadSelectedRows.selectedRows.push(allIds[i]);
                    }

                    selectedIds = [];
                }
            } else {
                for (let i = rowIndex; i <= previousRow; i++) {
                    selectedIds.push(allIds[i]);
                }
                
                if(allChecked(selectedIds, payloadSelectedRows.selectedRows)) {
                    for (let i = rowIndex; i <= previousRow; i++) {
                        remove(payloadSelectedRows.selectedRows, id => id === allIds[i]);
                    }

                    selectedIds = [];
                } else {
                    for (let i = rowIndex; i <= previousRow; i++) {
                        payloadSelectedRows.selectedRows.push(allIds[i]);
                    }

                    selectedIds = [];
                }
            }

            payloadSelectedRows.previousRow = rowIndex;
        } else {
            payloadSelectedRows.selectedRows = addRemove(selectedRows, id, 'record_id');
            payloadSelectedRows.previousRow = rowIndex;
        }

        payloadSelectedRows.selectedRows = uniq(payloadSelectedRows.selectedRows)

        if (isMyRecords) {
            payloadSelectedRows.registrableIds = filterReadyToRegister(
                [data],
                [id]
            ).length
                ? addRemove(registrableIds, id)
                : registrableIds;
        }

        payloadSelectedRows.selected = rowIndex;

        const setMethod = isMyRecords ? setMyRecords : setProductCatalogue;
        return dispatch(setMethod(payloadSelectedRows));
    };
}

export function selectAll(ids, isMyRecords, registrableIds) {
    return dispatch => {
        let payloadSelectedRows = { selectedRows: ids };

        if (isMyRecords) {
            payloadSelectedRows.registrableIds = registrableIds;
        }

        const setMethod = isMyRecords ? setMyRecords : setProductCatalogue;
        return dispatch(setMethod(payloadSelectedRows));
    };
}

export function resetConceptsRows(selectedRows, data, isMyRecords, dispatch) {
    const clonedCheckedProducts = cloneDeep(selectedRows);
    const mappedListId = data.map(item => item.record_id);

    let payloadSelectedRows = {
        selectedRows: filter(clonedCheckedProducts, item => !(mappedListId.includes(item.record_id)))
    };

    const setMethod = isMyRecords ? setMyRecords : setProductCatalogue;
    return dispatch(setMethod(payloadSelectedRows));
}

export function setSortedData(data, totalItems, page, page_size, isMyRecords) {
    return dispatch => {
        // @TODO get rid of useless keys
        const payloadRecords = {
            records: {
                data,
                totalItems,
                page,
                page_size
            }
        };
        const setMethod = isMyRecords ? setMyRecords : setProductCatalogue;
        return dispatch(setMethod({ records: payloadRecords }));
    };
}
