import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from 'components/UiKit/Button';
import {
    fetchReportEntries,
    fetchReportData,
    updateEntriesData,
    deleteEntriesData,
    updateReport,
    createVersion,
    getProductDetails
} from 'src/js/apicalls/other/royaltyReporting';
import IngestReport from '../Components/IngestReport'
import { Icon } from 'src/js/components/static';

import { ReactGrid} from '@silevis/reactgrid';

import size from 'lodash/size';
import { getUrlParam } from 'src/js/helpers/strings';
import { PAGE_LIMIT } from 'src/js/constants/dataConstants';

import toNumber from 'lodash/toNumber';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import find from 'lodash/find';
import each from 'lodash/each';
import cloneDeep from 'lodash/cloneDeep';
import toPlainObject from 'lodash/toPlainObject'
import ReportActionConfirmation from './Modals/ReportActionConfirmation';
import VersionsModal from './Modals/VersionsModal';
import { showModal } from 'src/js/actions/xelacore';

import { displayNotification } from 'src/js/actions/xelacore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ErrorCellTemplate } from './ErrorCellTemplate';
import { LoaderCellTemplate } from './LoaderCellTemplate';
import { ErrorValueTemplate } from './ErrorValueTemplate'
import { ErrorNumberValueTemplate } from './ErrorNumberValueTemplate'
import { InactiveIcon } from './InactiveIcon'
import { findIndex, toArray } from 'lodash';

import imgPinkSpinner from 'statics/imgs/desktop/pink-spinner.gif';

import {
    FIELDS_ARRAY,
    DISABLED_COLUMNS,
    HEADER_ROW
} from 'src/js/constants/royaltyReportingConstants';

const getColumns = () => {
    let columns = [
        {
            columnId: 'error',
            width: 50,
            resizble: false
        }
    ];

    each(FIELDS_ARRAY, key => {
        columns.push({
            columnId: key,
            width: 150,
            resizble: true
        });
    })

    return columns;
}

  const validatedCell = (entry, idx, path) => {
    let findValidation = !!entry.validations && entry.validations.length > 0 && entry.rowNumber === idx&& find(entry.validations, validation => {
        return validation.error.problem.path === path;
    })

    return !!findValidation && findValidation.error.problem.system === false;
  }
  
  const highlightValidation = (entry, idx, path, nonEditable) => {
    return !nonEditable ? {
        borderColor: '#efefef',
        color: validatedCell(entry, idx, path) ? '#ff7474' : '#000',
        backgroundColor: (idx + 1) % 2 == 0 ? '#f6f6f6' : 'transparent',
        border: {
            bottom: {
                width: '1px',
                style: 'solid'
            }
        }
    } : {
        borderColor: '#ebebeb',
        backgroundColor: (idx + 1) % 2 == 0 ? 'rgba(236, 240, 248, 1)' : 'rgba(0, 128, 255, 0.06)' ,
        border: {
            bottom: {
                width: '1px',
                style: 'solid'
            }
        }

    }
  }

  const validationCellType = (entry, idx, path, type) => {
    return !!entry.busy[path]
           ? 'loader'
           : validatedCell(entry, idx, path)
           ? (type === 'number' ? 'errorNumber' : 'error')
           : type
  }

  const textValue = (entry, idx, path) => {
    return validatedCell(entry, idx, path) && !includes(DISABLED_COLUMNS, path)
        ? JSON.stringify({path, entry})
        : entry[path];
  }

  const findValidation = (entry, path) => {
    let validation = !!entry.validations && entry.validations.length > 0 && find(entry.validations, item => {
        return item.error.problem.path === path
    });

    return !!validation && !!validation.error && validation.error.problem.title || '';
  }

  const numberValue = (entry, path) => {
    return toNumber(entry[path])
  }

  const rowFormatter = (type, entry, idx, path, productDetails) => {
    let nonEditable = !!includes(DISABLED_COLUMNS, path);

    return type === 'dropdown' ? {
        type: !!entry.busy[path] ? 'loader' : 'dropdown',
        selectedValue: entry[path],
        inputValue: '',
        isOpen: entry.isOpen[path],
        style: highlightValidation(entry, idx, path),
        values: productDetails.map((detail) => {
            return {
                label: detail,
                value: detail
            }
        }),
        text: ''
    } : {
        type: nonEditable ? type : validationCellType(entry, idx, path, type),
        text: type === 'text' ? textValue(entry, idx, path) : type === 'inactiveIcon' ? entry[path] : findValidation(entry, path),
        style: highlightValidation(entry, idx, path, nonEditable),
        nonEditable: nonEditable,
        value: type === 'number' ? numberValue(entry, path) : 0
    }
  }

  const getRows = (entries, productDetails) => [
    HEADER_ROW,
    ...entries.map((entry, idx) => ({
      rowId: idx,
      height: 40,
      cells: [
        {   // Tooltip column for row in error
            type: `${!!entry.validations && entry.validations.length > 0 ? 'errorTooltip' : 'text'}`,
            text: '',
            style: highlightValidation(entry, idx, ''),
            nonEditable: true
        },
        rowFormatter('text', entry, idx, 'countryCode'),
        rowFormatter(idx === 0 ? 'inactiveIcon' : 'text', entry, idx, 'countryName'),
        rowFormatter('text', entry, idx, 'localCurrency'),
        rowFormatter(idx === 0 ? 'inactiveIcon' : 'text', entry, idx, 'characterId'),
        rowFormatter('text', entry, idx, 'characterCode'),
        rowFormatter(idx === 0 ? 'inactiveIcon' : 'text', entry, idx, 'characterFullName'),
        rowFormatter('text', entry, idx, 'propertyCode'),
        rowFormatter('text', entry, idx, 'propertyFullName'),
        rowFormatter(idx === 0 ? 'inactiveIcon' : 'text', entry, idx, 'productId'),
        rowFormatter('dropdown', entry, idx, 'productDetail',  productDetails),
        rowFormatter('text', entry, idx, 'mpn'),
        rowFormatter('text', entry, idx, 'gtin'),
        rowFormatter(idx === 0 ? 'inactiveIcon' : 'text', entry, idx, 'productDescription'),
        rowFormatter('text', entry, idx, 'retailerCode'),
        rowFormatter(idx === 0 ? 'inactiveIcon' : 'text', entry, idx, 'retailerFullName'),
        rowFormatter('number', entry, idx, 'unitsSold'),
        rowFormatter('number', entry, idx, 'unitsReturned'),
        rowFormatter('number', entry, idx, 'netUnitsSold'),
        rowFormatter('number', entry, idx, 'grossSales'),
        rowFormatter('number', entry, idx, 'returns'),
        rowFormatter('number', entry, idx, 'discounts'),
        rowFormatter('number', entry, idx, 'netSales'),
        rowFormatter('text', entry, idx, 'royaltyType'),
        rowFormatter('number', entry, idx, 'royaltyRate'),
        rowFormatter('number', entry, idx, 'netRoyalties'),
        rowFormatter('number', entry, idx, 'exchangeRate'),
        rowFormatter('number', entry, idx, 'totalRoyalties'),
        rowFormatter('number', entry, idx, 'cmf')
      ]
    }))
  ];

function ReportTable({
    params,
    dispatch
}) {
    const [reportEntries, setReportEntries] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [reportVersions, setReportVersions] = useState([]);
    const [uploadArea, setUploadArea] = useState(false);
    const [reportTitle, setReportTitle] = useState('');
    const [editTitleMode, setEditTitleMode] = useState(false);
    const [reportId] = useState(params && params.id || '');
    const [columns, setColumns] = useState(getColumns());
    const [page, setPage] = useState(1);
    const [totalItems, setTotalResults] = useState(0);
    const [page_size, setPageSize] = useState(25);
    const [versionControl, setVersionControl] = useState(false);
    const [busy, setBusy] = useState(false);
    const [productDetails, setProductDetails] = useState([]);

    const rows = getRows(reportEntries, productDetails);

    useEffect(() => {
        getReportEntries();
        getReportInformation();
        checkPageParam();
        getProductDetailsOptions();
    }, []);

    const isJsonString = (jsonString) => {
        try {
            var o = JSON.parse(jsonString);

            if (o && typeof o === 'object') {
                return o;
            }
        }
        catch (e) { }
    
        return false;
    };

    const applyChangesToEntries = (
        changes,
        prevEntry
      ) => {
        let changedData = {
            rows: [],
            edits: []
        }

        changes.forEach((change) => {
          const entryId = change.rowId;
          const fieldName = change.columnId;

          let index = findIndex(prevEntry, entry => {
              return change.rowId === entry.rowNumber;
          })
          
            changedData.edits = toPlainObject(changedData.edits);
            changedData.rows = [{
                first: changes[0].rowId,
                last: changes[changes.length - 1].rowId
            }];

            if(change.type !== 'dropdown') {prevEntry[index].busy[change.columnId] = true}

            if((change.type !== 'number') && (change.type !== 'errorNumber') && (change.type !== 'dropdown')) {
                let appliedChange =
                    isJsonString(change.newCell.text)
                        ? JSON.parse(change.newCell.text).entry[JSON.parse(change.newCell.text).path]
                        : change.newCell.text;

                prevEntry[entryId][fieldName] = appliedChange;
                changedData.edits[fieldName] = appliedChange;

                if(change.previousCell.text !== change.newCell.text) {
                    prevEntry[entryId].busy[change.columnId] = true;

                    updateCellRequest(changedData, changes, prevEntry);
                }
            } else if(change.type === 'dropdown') {
                prevEntry[index].isOpen[change.columnId] = !prevEntry[index].isOpen[change.columnId];
                prevEntry[entryId][fieldName] = change.newCell.selectedValue;
                changedData.edits[fieldName] = change.newCell.selectedValue;

                if(change.previousCell.selectedValue !== change.newCell.selectedValue) {
                    prevEntry[entryId].busy[fieldName] = true;

                    updateCellRequest(changedData, changes, prevEntry);
                }
            } else {
                prevEntry[entryId][fieldName] = change.newCell.value;
                changedData.edits[fieldName] = change.newCell.value;

                if(change.previousCell.value !== change.newCell.value) {
                    updateCellRequest(changedData, changes, prevEntry);
                }
            }
        });

        return [...prevEntry];
    };

    const updateCellRequest = (changedData, changes, prevEntry) => {
        return updateEntriesData(reportId, changedData).then((response) => {
            each(changes, change => {
                let index = findIndex(prevEntry, entry => {
                    return change.rowId === entry.rowNumber;
                })
    
                prevEntry[index].busy[change.columnId] = false;
                reportEntries[index].busy[change.columnId] = false;
            })

            each(map(changes, 'rowId'), row => {
                let index = findIndex(response.data, data => {
                    return data.rowNumber === row;
                })

                each(FIELDS_ARRAY, key => {
                    prevEntry[response.data[index].rowNumber][key] = response.data[index][key];
                });

                prevEntry[response.data[index].rowNumber].validations = response.data[index].validations;
            })

            getReportInformation();
        })
    }

    const handleChanges = (changes) => {
        setReportEntries((prevEntry) => applyChangesToEntries(changes, prevEntry));
   };

    const handleColumnResize = (ci, width) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };

            prevColumns[columnIndex] = updatedColumn;

            return [...prevColumns];
        });
    }

    const getReportEntries = (params) => {
        let newReportEntries = cloneDeep(reportEntries) || [];
        let newParams = {
            page: !!params && params.page === page ? page + 1 : page,
            page_size: !!params && params.page_size || page_size
        }

        if(!reportEntries.length) {
            setBusy(true);
        }

        setPage(newParams.page);

        fetchReportEntries(reportId, newParams).then(response => {
            each(response.data, (entry) => {
                entry.busy = [];
                entry.isOpen = [];

                each(entry, (value, key) => {
                    entry.busy[key] = false;
                    entry.isOpen[key] = false;
                });
            })

            newReportEntries.push(...response.data);

            if(!size(response.data)) {
                setUploadArea(true);
                setReportEntries([]);
            } else {
                setReportEntries(newReportEntries);
                setTotalResults(response._metadata.totalResults);
                setPageSize(response._metadata.pageSize);
            }

            setBusy(false);
        })
    }

    const getReportInformation = () => {
        return fetchReportData(reportId).then(response => {
            setReportData(response.data);
            setReportTitle(response.data.title);
            setReportVersions(orderBy(response.data.versions, 'versionNumber', 'desc'))
        })
    }

    const getProductDetailsOptions = () => {
        return getProductDetails(reportId).then(response => {
            setProductDetails(toArray(response.data));
        })
    }

    const checkPageParam = () => {
        const page = getUrlParam('page') || 1;
        const page_size = getUrlParam('page_size') || PAGE_LIMIT;

        setPage(toNumber(page));
        setPageSize(toNumber(page_size));
        
        return { page, page_size };
    }

    const deleteRows = () => {
        return deleteEntriesData(reportId).then(() => {
            getReportEntries();

            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'All report entries successfully deleted',
                    type: 'success'
                })
            );
        })
    }
  
    const handleChange = (e) => {
      setReportTitle(e.target.value);
    }
     
    const handleFocus = (e) => {
      e.currentTarget.select();
    }

    // const findOpenedDropdownCellLocation = (changes) => {
    //     var openedDropdownChanges = changes.filter(function (change) { return change.type === "dropdown" && change.newCell.isOpen; });

    //     if (openedDropdownChanges.length >= 1) {
    //         var cell = openedDropdownChanges[0];
    //         return { rowId: cell.rowId, columnId: cell.columnId };
    //     }

    //     return undefined;
    // }

    const saveReportTitle = () => {
        let params = {
            title: reportTitle
        }

        return updateReport(reportId, params).then(() => {
            setEditTitleMode(false);

            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'Report title successfully updated',
                    type: 'success'
                })
            );
        })
    }

    const cancelEditing = () => {
        setEditTitleMode(false);
        setReportTitle(reportData.title);
    }

    const deleteReportEntries = () => {
        const modalContent = (
            <ReportActionConfirmation
                title={'Delete Confirmation'}
                confirm={() => deleteRows()}
                message={'Are you sure you want to delete all entries?'}
            />
        );
        
        return dispatch(showModal(modalContent));
    }

    const amendReport = () => {
        let params = {
            versionNumber: reportData.versions[reportData.versions.length - 1].versionNumber + 1
        };

        return createVersion(reportId, params).then(() => {
            getReportEntries();
            getReportInformation();

            dispatch(
                displayNotification({
                    timeOut: 5000,
                    message: 'New version successfully created',
                    type: 'success'
                })
            );
        })
    }

    const amendConfirmation = () => {
        const modalContent = (
            <ReportActionConfirmation
                title={'Please Confirm'}
                confirm={() => amendReport()}
                message={'By proceeding, you will generate a new Royalty Statement for re-submission. Do you wish to continue?'}
                confirmButton={'Continue'}
            />
        );
        
        return dispatch(showModal(modalContent));
    }

    return (
        <div className="royalty-reporting">
            <div className="filters-list-container">
                <div className='left'>
                    <a href="/royalty-reporting">
                        <Icon className="back-icon" fill="black" icon="ARROW_LEFT_STYLE_2" size="14"/>
                    </a>

                    <span className='report-group-title'>
                        Q{reportData.quarter}-{reportData.year}
                    </span>

                    <div className='report-header'>
                        <div className='report-title'>
                            {editTitleMode && (
                                <input autoFocus value={reportTitle}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    
                                />
                            )}

                            {!editTitleMode && (
                                <h2>
                                    {reportTitle || 'Untitled Report'}
                                </h2>
                            )}
                        </div>

                        {!editTitleMode && (
                            <div className="edit-title-actions">
                                <Icon className="edit-icon"
                                    fill="black"
                                    icon="EDIT_USER"
                                    onClick={() => setEditTitleMode(!editTitleMode)}
                                    size="14"
                                />
                            </div>

                        )}

                        {!!editTitleMode && (
                            <div className="edit-title-actions">
                                <span className='save' onClick={() => saveReportTitle()}>Save</span>
                                <span className='cancel' onClick={() => cancelEditing()}>Cancel</span>
                            </div>
                        )}
                    </div>
                    
                    { reportVersions && reportVersions.length > 1 && (
                        <div className="edit-title-actions">
                            <span className='save' onClick={() => setVersionControl(true)}>Version control</span>
                        </div>
                    )}
                    
                    { !!reportVersions && !!reportVersions.length && reportVersions[0].status === 'ingested' && (
                        <Button onClick={() => amendConfirmation()}>
                            Amend statement
                        </Button>
                    )}
                    
                    { !!reportVersions && !!reportVersions.length && reportVersions[0].status === 'locked' && (
                        <Button>
                            Download report
                        </Button>
                    )}
                </div>

                <div className='royalty-reporting-filters'>
                    <a href="#" onClick={() => setUploadArea(true)}>
                        Upload new file
                    </a>

                    <Button
                        className="download-template"
                        to="/files/Blank-Sales-Report.xlsx"
                        download
                    >
                        <Icon
                            className=""
                            fill="white" icon="DOWNLOAD"
                            size="14"
                        />

                        Download Template
                    </Button>

                    <Button disabled={ size(reportEntries) === 0 }>
                        <Icon
                            className=""
                            fill="white" icon="REPORTS"
                            size="14"
                        />
                        
                        Generate Report
                    </Button>

                    <Button className="delete-button" disabled={ size(reportEntries) === 0 } onClick={() => deleteReportEntries()}>
                        <Icon
                            className=""
                            fill="white" icon="BIN"
                            size="14"
                        />
                        
                        Delete Entries
                    </Button>
                </div>
            </div>

            {!!busy && (
                <div className="c-rec-table__page-loader table-loader">
                    <img  className="loader-img" src={imgPinkSpinner} alt="Updating cell..."/>
                </div>
            )}

            {!busy && uploadArea && (
                <IngestReport close={() => setUploadArea(false)} reportId={reportId} fetchList={() => getReportEntries()}></IngestReport>
            )}

            { !busy && size(reportEntries) > 0 && (
                <div>
                    <InfiniteScroll
                        dataLength={reportEntries.length}
                        next={() => getReportEntries({page, page_size})}
                        hasMore={reportEntries.length < totalItems}
                        loader={
                            <p style={{ textAlign: 'center' }}>
                                <b>Loading...</b>
                            </p>
                        }
                        scrollableTarget="scrollableDiv"
                        endMessage={
                        <p style={{ textAlign: 'center' }}>
                            {/* <b>You have seen it all</b> */}
                        </p>
                        }
                        hasChildren={true}
                    >
                        <div style={{'overflow': 'auto', 'maxHeight': '550px'}} id="scrollableDiv">
                            <div className='reactgrid-gold'>
                                <ReactGrid
                                    onCellsChanged={handleChanges}
                                    onColumnResized={handleColumnResize}
                                    rows={rows}
                                    columns={columns}
                                    enableRangeSelection
                                    enableFillHandle
                                    stickyTopRows={1}
                                    customCellTemplates={{
                                        errorTooltip: new ErrorCellTemplate(),
                                        loader: new LoaderCellTemplate(),
                                        error: new ErrorValueTemplate(),
                                        errorNumber: new ErrorNumberValueTemplate(),
                                        inactiveIcon: new InactiveIcon()
                                    }}
                                    />
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            )}

            {versionControl && (
                <VersionsModal
                    versions={reportData.versions}
                    close={() => setVersionControl(false)}
                />
            )}
        </div>
    );
}

const mapStateToProps = ({
    xelacore,
    dispatch
}) => {
    return {
        xelacore,
        dispatch
    };
};

export default connect(mapStateToProps)(ReportTable);