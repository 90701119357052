import React, { Component } from 'react';
import classnames from 'classnames';
import { Triangle } from 'src/js/components/static';

// This component will only take children direclty as list items

export default class DropdownList extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            menuOpen: true,
            originalData: this.props.menuData || []
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({
            filteredData: this.state.originalData
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.collapse();
        }
    }

    handleMenuClick() {
        const { customHandler } = this.props;
        if (customHandler) return customHandler;
        this.handleClick();
    }

    handleClick(val) {
        const { menuOpen } = this.state;

        this.setState({
            menuOpen: typeof val === 'boolean' ? val : !menuOpen
        });
    }

    collapse() {
        this.setState({
            menuOpen: false
        });
    }

    render() {
        const {
            right,
            inline,
            className,
            triangleClassname,
            children,
            tiny,
            top,
            left,
            style,
            medium,
            width
        } = this.props;
        const { menuOpen } = this.state;

        const cx = classnames('c-floating-menu', className, {
            'c-floating-menu--right': right,
            'c-floating-menu--inline': inline
        });

        const listCx = classnames('c-floating-menu__list', {
            'c-floating-menu__list--right': right
        });

        const wrapperCx = classnames('c-floating-menu__wrapper', {
            'c-floating-menu__wrapper--tiny': tiny,
            'c-floating-menu__wrapper--medium': medium
        });

        const buttonCx = classnames(
            'c-floating-menu__custom',
            triangleClassname
        );

        const wrapperStyles = { top, left, width };

        return (
            <div className={cx} ref={this.setWrapperRef} style={style}>
                <div onClick={() => this.handleClick()} className={buttonCx}>
                    {<Triangle up={menuOpen} />}
                </div>

                {menuOpen && (
                    <div className={wrapperCx} style={wrapperStyles}>
                        <ul
                            className={listCx}
                            onClick={() => this.handleMenuClick()}
                        >
                            {children}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}
