import React from 'react';

export const licensorFilters = {
    licenseeOrganisationId: {
        key: 'licenseeOrganisationId',
        name: 'Licensees',
        isMultiselect: true
    },
    licensorBrand: {
        key: 'licensorBrand',
        name: 'Brands',
        isMultiselect: false
    },
    licensorFranchise: {
        key: 'licensorFranchise',
        name: 'IPs',
        isMultiselect: true
    },
    productCategory: {
        key: 'productCategory',
        name: 'Categories',
        isMultiselect: true
    },
    productSubCategory: {
        key: 'productSubCategory',
        name: 'Sub-categories',
        isMultiselect: true
    },
    period: {
        key: 'period',
        name: 'Period',
        isMultiselect: false
    }
};

export const defaultOptions = {
    period: [
        { filterId: 'yearly', filterName: 'Yearly' },
        { filterId: 'monthly', filterName: 'Monthly' },
        { filterId: 'weekly', filterName: 'Weekly' },
        { filterId: 'daily', filterName: 'Daily' }
    ]
};

export const licensorDashboardVisuals = {
    programSnapshot: {
        key: 'programSnapshot',
        displayName: 'Licensee Activity',
        description:
            'This table shows your Licensees’ activity on Xelacore alongside their approved concepts',
        localFilters: [
            {
                filter: licensorFilters.licenseeOrganisationId,
                isMultiselect: true
            },
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: true
            },
            {
                filter: licensorFilters.productCategory,
                isMultiselect: true
            }
        ]
    },
    catalogCreationTrackerChart: {
        key: 'catalogCreationTrackerChart',
        displayName: 'Catalogue Creation Tracker',
        description:
            'A relative indicator of how complete the catalogue is, based on the number of Active Licensees who have registered products to the catalogue vs the number of Contracted Licensees. Please note, this is not an absolute measure of catalogue completeness at a product/SKU level. This is meant to serve as an approximate guide for monitoring Licensee onboarding and the catalogue’s readiness to be syndicated.'
    },
    geographicalDistributionOfBrands: {
        key: 'geographicalDistributionOfBrands',
        displayName: 'Brand Coverage',
        description:
            'This map shows territories where Licensees have contractual rights to distribute products, as well as where products could potentially be sold (according to any GS1 data requirements or their packaging specifications). It can be viewed by Licensee and by category.',
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                },
                {
                    filter: licensorFilters.productCategory,
                    isMultiselect: false
                }
            ]
        ]
    },
    contractedLicensees: {
        key: 'contractedLicensees',
        displayName: 'Contracted Licensees Activity',
        description:
            'This graph shows the change in number of Contracted Licensees over time, with filterable views to identify trends by category and IP.',
        localFilters: [
            {
                filter: licensorFilters.productCategory,
                isMultiselect: false
            },
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: false
            },
            {
                filter: licensorFilters.period,
                default: 'monthly',
                isMultiselect: false,
                hasSearch: false,
                allowEmpty: false
            }
        ]
    },
    licenseeRiskAssessmentChart: {
        key: 'licenseeRiskAssessmentChart',
        displayName: 'Licensee Risk Indicator',
        description: 'Licensee Risk Indicator'
    },
    licensorLiveProducts: {
        key: 'licensorLiveProducts',
        displayName: 'Live Product Count',
        description:
            'These statistics below relate only to products which have been set as live in the market by the Licensee.'
    },
    totalSubmissionsOverTime: {
        key: 'totalSubmissionsOverTime',
        displayName: 'Registered Products Activity',
        description:
            'This graph shows the change in number of registered products to the catalogue over time, with filterable views to identify trends by category, IP and Licensee.',
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                },
                {
                    filter: licensorFilters.productCategory,
                    isMultiselect: false
                }
            ],
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: false
            },
            {
                filter: licensorFilters.period,
                default: 'monthly',
                isMultiselect: false,
                hasSearch: false,
                allowEmpty: false
            }
        ]
    },
    complianceTrackingChart:{
        key: 'complianceTrackingChart',
        displayName: 'Compliance Tracker Chart',
        description:
            'This chart shows the number of registered products in the catalogue alongside their current availability in the market and whether they have been linked to a submitted concept. It also indicates the number of products that have been quarantined due to data quality issues or syndicated to third party partners.',
        localFilters: []
    },
    registrationRiskIndicator:{
        key: 'registrationRiskIndicator',
        displayName: 'Registration Risk Indicator',
        description:
            'This chart shows the number of registered products in the catalogue alongside their current availability in the market and whether they have been linked to a submitted concept. It also indicates the number of products that have been quarantined due to data quality issues or syndicated to third party partners.',
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                }
            ]
        ]
    },
    productSummary: {
        key: 'productSummary',
        displayName: 'Product Status Summary',
        description:
            'This chart shows the number of registered products in the catalogue alongside their current availability in the market and whether they have been linked to a submitted concept. It also indicates the number of products that have been quarantined due to data quality issues or syndicated to third party partners.',
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                },
                {
                    filter: licensorFilters.productCategory,
                    isMultiselect: false
                }
            ],
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: false
            }
        ]
    },
    productsLinkedToConcepts: {
        key: 'productsLinkedToConcepts',
        displayName: 'Concept Linking Status',
        description:
            'This chart shows how many registered products have been linked to a corresponding concept. It also includes a breakdown of the respective approval statuses of the linked concept. The chart is filterable by Licensee, category and IP.',
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                },
                {
                    filter: licensorFilters.productCategory,
                    isMultiselect: false
                }
            ],
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: false
            }
        ]
    },
    productsWithHighRiskFactor: {
        key: 'productsWithHighRiskFactor',
        displayName: 'Products With High Risk Factor',
        description:
            'This table displays products linked to concepts that are not fully approved. These could potentially be products that are or have been available in the market, which were not fully approved. The table is filterable by Licensee, category and IP.',
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                },
                {
                    filter: licensorFilters.productCategory,
                    isMultiselect: false
                }
            ],
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: false
            }
        ]
    },
    conceptAnalytics: {
        key: 'conceptAnalytics',
        displayName: 'Concept Submissions Analytics',
        description: (
            <span>
                These counters and graph provide some high-level details in
                respect to Licensees concept submission data.
                <br />
                <br />
                These counters and the graphical representation over time are
                filterable by Licensee, Category and IP, to help provide some
                actionable insights.
            </span>
        ),
        childVisuals: {
            conceptsSubmitted: {
                key: 'conceptsSubmitted',
                displayName: 'Concept Submissions',
                description:
                    'The number of concept submissions received by you.'
            },
            conceptsApproved: {
                key: 'conceptsApproved',
                displayName: 'Concepts Approved',
                description:
                    'The number of concept submissions that have been approved by you.'
            },
            approvedProducts: {
                key: 'approvedProducts',
                displayName: 'Approved Products',
                description:
                    'The number of Products that have an approved concept submission associated/linked to it.'
            },
            productsWithoutConceptApproval: {
                key: 'productsWithoutConceptApproval',
                displayName: 'Products without Concept Approval',
                description:
                    'The number products that have been registered to the catalogue that do not have an approved concept submission associated/linked to it.'
            },
            rateOfProductRealization: {
                key: 'rateOfProductRealization',
                displayName: 'Concept-to-Product Conversion Rate',
                description:
                    'The percentage of Approved Products relative to the number of Concepts Approved.'
            }
        },
        localFilters: [
            [
                {
                    filter: licensorFilters.licenseeOrganisationId,
                    isMultiselect: false
                },
                {
                    filter: licensorFilters.productCategory,
                    isMultiselect: false
                }
            ],
            {
                filter: licensorFilters.licensorFranchise,
                isMultiselect: false
            },
            {
                filter: licensorFilters.period,
                default: 'monthly',
                isMultiselect: false,
                hasSearch: false,
                allowEmpty: false
            }
        ]
    },
    keyAnalytics: {
        key: 'keyAnalytics',
        displayName: 'Redemption Analytics',
        description: ''
    },
    geoKeyEngagement: {
        key: 'geoKeyEngagement',
        displayName: 'Geographical key engagement',
        description: ''
    },
    scansVsUserRegistration: {
        key: 'scansVsUserRegistration',
        displayName: 'Scan vs User Registration over time',
        description: ''
    },
    convertersByGender: {
        key: 'convertersByGender',
        displayName: 'Converters by gender',
        description: ''
    },
    convertersByAge: {
        key: 'convertersByAge',
        displayName: 'Converters by age',
        description: ''
    },
    topAthletes: {
        key: 'topAthletes',
        displayName: 'Top athletes by user choice',
        description: ''
    }
};

export const defaultSelectedValues = {
    period: ['daily'],
    productCategory: [],
    productSubCategory: [],
    licensorFranchise: [],
    licensorBrand: [],
    licenseeOrganisationId: []
};

export const redemptionVisuals = [
    licensorDashboardVisuals.keyAnalytics.key,
    licensorDashboardVisuals.geoKeyEngagement.key,
    licensorDashboardVisuals.scansVsUserRegistration.key,
    licensorDashboardVisuals.convertersByGender.key,
    licensorDashboardVisuals.convertersByAge.key,
    licensorDashboardVisuals.topAthletes.key
];

const [
    licenseeOrganisationId,
    licensorBrand,
    licensorFranchise,
    productCategory,
    productSubCategory
] = Object.values(licensorFilters).map((f) => f.key);

export const licensorDashboardSubscriptions = {
    [licensorDashboardVisuals.programSnapshot.key]: [
        licenseeOrganisationId,
        licensorFranchise,
        productCategory,
        licensorBrand
    ],
    [licensorDashboardVisuals.catalogCreationTrackerChart.key]: [licensorBrand],
    [licensorDashboardVisuals.geographicalDistributionOfBrands.key]: [
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.contractedLicensees.key]: [
        licenseeOrganisationId,
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.licenseeRiskAssessmentChart.key]: [
        licenseeOrganisationId,
        licensorBrand
    ],
    [licensorDashboardVisuals.licensorLiveProducts.key]: [licensorBrand],
    [licensorDashboardVisuals.totalSubmissionsOverTime.key]: [
        licenseeOrganisationId,
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.productSummary.key]: [
        licenseeOrganisationId,
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.productsLinkedToConcepts.key]: [
        licenseeOrganisationId,
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.productsWithHighRiskFactor.key]: [
        licenseeOrganisationId,
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.conceptAnalytics.key]: [
        licenseeOrganisationId,
        licensorBrand,
        licensorFranchise,
        productCategory,
        productSubCategory
    ],
    [licensorDashboardVisuals.keyAnalytics.key]: [],
    [licensorDashboardVisuals.geoKeyEngagement.key]: [],
    [licensorDashboardVisuals.scansVsUserRegistration.key]: [],
    [licensorDashboardVisuals.convertersByGender.key]: [],
    [licensorDashboardVisuals.convertersByAge.key]: [],
    [licensorDashboardVisuals.topAthletes.key]: []
};

export const DATA_LENGTH_THRESHOLD = 5000;
