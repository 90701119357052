import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    getMainImage
} from 'helpers/dataHelpers';
import { truncate } from 'lodash/string';
import size  from 'lodash/size';
import conceptPlaceholder from 'statics/imgs/no-photo.svg';
import angleLeftIcon from 'statics/imgs/icons/angle_left_icon.svg';
import angleRightIcon from 'statics/imgs/icons/angle_right_icon.svg';

class RetailerListingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            activeRetailer: props.retailer || {}
        };
    }

    componentDidMount() {
        const {activeRetailer} = this.state;

        this.selectRetailerImage(!!activeRetailer.images && activeRetailer.images[0]);
    }

    selectRetailer(retailer) {
        this.setState({
            activeRetailer: retailer,
            currentImagePreview: !!retailer.images && retailer.images[0]
        })
    }

    selectRetailerImage(image) {
        this.setState({ currentImagePreview: image });
    }

    goToImageArrow(dir, list, current, state) {
        state = state || 'currentImagePreview';
        const currentIndex = this.getCurrentIndex(list, current);
        if (dir === 'prev' && currentIndex > 0) {
            this.setState({ [state]: list[currentIndex - 1] });
        }

        if (dir === 'next' && currentIndex < list.length) {
            this.setState({ [state]: list[currentIndex + 1] });
        }
    }

    getCurrentIndex(list, current) {
        current = current || list[0];
        return list.indexOf(current);
    }

    render() {
        const { activeRetailer, currentImagePreview } = this.state;
        const { retailersList } = this.props;

        return (
            <div className="retailer-listing-modal">
                <div className="retailer-listing-modal__details">
                    <div className="retailer-listing-modal__details-image">
                        <div className="retailer-listing-modal__details-image-image">
                            {!currentImagePreview && (
                                <img
                                    src={getMainImage(activeRetailer.images, 800)}
                                    alt={activeRetailer.title}
                                />
                            )}
                            
                            {currentImagePreview && (
                                <img
                                    src={currentImagePreview.thumb_url_800}
                                    alt={activeRetailer.title}
                                />
                            )}

                            {
                                (<div className={"image-gallery-control"}>
                                    {this.getCurrentIndex(activeRetailer.images, currentImagePreview) > 0 && (

                                        <div
                                            className={"icon-holder  arrow-left"}
                                            onClick={() => {
                                                this.goToImageArrow('prev', activeRetailer.images, currentImagePreview, 'currentImagePreview');
                                            }}
                                        >
                                            <img
                                                src={angleLeftIcon}
                                                alt={"prev"}
                                            />
                                        </div>
                                    )}
                                    {this.getCurrentIndex(activeRetailer.images, currentImagePreview) < activeRetailer.images.length - 1 && (
                                        <div
                                            className={"icon-holder  arrow-right"}
                                            onClick={() => {
                                                this.goToImageArrow('next', activeRetailer.images, currentImagePreview, 'currentImagePreview');
                                            }}
                                        >
                                            <img
                                                src={angleRightIcon}
                                                alt={"next"}
                                            />
                                        </div>)}
                                </div>)
                            }
                        </div>

                        <div className="retailer-listing-modal__details-image-more">
                            { activeRetailer.images && activeRetailer.images.map(image => {
                                return (
                                    <div
                                        className={classNames(
                                            'retailer-listing-modal__details-image-more-image',
                                            `${(!!image && image.image_id) === (!!currentImagePreview && currentImagePreview.image_id) ? 'active' : ''}`
                                        )}
                                        onClick={() => this.selectRetailerImage(image)}
                                    >
                                        <img src={image.thumb_url_200} />
                                    </div>
                                )
                            })}
                        </div>

                        <div className="retailer-listing-modal__details-image-domain">
                            <div className="retailer-listing-modal__details-image-domain-name">
                                <div className="retailer-listing-modal__details-image-domain-name-image">
                                    <img
                                        src={activeRetailer.retailerDetail.retailerLogoImage || conceptPlaceholder}
                                        alt="title"
                                    />
                                </div>

                                <span className="retailer-listing-modal__details-image-domain-name-name"
                                      title={activeRetailer.retailerDetail.retailerDomain}>
                                    {
                                        truncate(activeRetailer.retailerDetail.retailerDomain, {
                                            'length': 22,
                                            'separator': ' '
                                        })
                                    }
                                </span>
                            </div>
                            
                            { !!activeRetailer.url && (
                                <div className="retailer-listing-modal__details-image-domain-button">
                                    <a className="ui-kit-button" href={activeRetailer.url} target="_blank">
                                        View on retailer website
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="retailer-listing-modal__details-table">
                        <div className={"child-field-item title"}>
                            <b className={"child-label u-capitalize"}>
                                {
                                    truncate(activeRetailer.title, {
                                        'length': 40,
                                        'separator': ' '
                                    })
                                }
                            </b>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-caitalize"}>
                                Retailer Name:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.retailerDetail.retailerName || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capialize"}>
                                Retailer Domain:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.retailerDetail.retailerDomain || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitalie"}>
                                Retailer Identifier:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.retailerDetail.retailerIdentifier || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitaliz"}>
                                Retailer Description:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.retailerDetail.retailerDescription || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitalze"}>
                                Retailer Exclusive:
                            </b>

                            <span className={"child-data"}>
                                {' ' + (activeRetailer.retailerExclusive === false ? 'No' : 'Yes') || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitalize"}>
                               Retailer Listing ID:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.listingId || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitlize"}>
                                Retailer Channel:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.retailerChannel || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitalize"}>
                                Retailer Product GTIN:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.gtin || ''}
                            </span>
                        </div>

                        <div className={"child-field-item"}>
                            <b className={"child-label u-capitaliz"}>
                                Retailer Product MPN:
                            </b>

                            <span className={"child-data"}>
                                {' ' + activeRetailer.mpn || ''}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="viewing-listing">
                    <div className="viewing-listing__title">
                        Viewing Listing {this.getCurrentIndex(retailersList, activeRetailer) + 1}/{size(retailersList)}
                    </div>

                    <div className="viewing-listing__tiles">
                        { retailersList && retailersList.map(otherRetailer => {
                            return (
                                <div className={classNames(
                                        'retailer-tile',
                                        `${activeRetailer.retailerListingId === otherRetailer.retailerListingId ? 'active' : ''}`
                                     )}
                                     onClick={() => this.selectRetailer(otherRetailer)}
                                >
                                    <div className="retailer-image">
                                        <img
                                            src={getMainImage(otherRetailer.images, 200, conceptPlaceholder)}
                                            alt="title"
                                        />
                                    </div>

                                    <div className="retailer-info">
                                        <div className="title">
                                            {
                                                truncate(otherRetailer.title, {
                                                    'length': 25,
                                                    'separator': ' '
                                                })
                                            }
                                        </div>

                                        <div className="domain">
                                            <div className="image">
                                                <img
                                                    src={otherRetailer.retailerDetail.retailerLogoImage || conceptPlaceholder}
                                                    alt="title"
                                                />
                                            </div>
                                            <span className="name" title={otherRetailer.retailerDetail.retailerDomain}>
                                                {
                                                    truncate(otherRetailer.retailerDetail.retailerDomain, {
                                                        'length': 15,
                                                        'separator': ' '
                                                    })
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}


export default connect()(RetailerListingModal);
