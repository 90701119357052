import * as React from 'react'
import {
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from '@silevis/reactgrid'
import { Icon, Tooltip } from 'src/js/components/static';
import { toNumber } from 'lodash';

const tooltipButton = (
  <span>
      <Icon
          icon="info_bubble"
          fill="grey4"
          width="12"
          height="12"
          top={3}
          tooltip
      />
  </span>
);

export class ErrorNumberValueTemplate {

  getCompatibleCell(uncertainCell) {
    const value = getCellProperty(uncertainCell, 'value', 'number')

    return { ...uncertainCell, value }
  }

  handleKeyDown(cell, keyCode, ctrl, shift, alt) {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
    return { cell, enableEditMode: true }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    }
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({ ...cell, value: toNumber(cellToMerge.value) })
  }
  
  render(cell, isInEditMode, onCellChanged) {
    return (
      <div className="error-cell">
        { isInEditMode && (
          <input
            ref={(input) => {
              input && input.focus();
            }}
            defaultValue={cell.value}
            onKeyDown={(e) => {
              if(e.keyCode === 13) {
                onCellChanged(
                  this.getCompatibleCell({ ...cell, value: toNumber(e.currentTarget.value) }),
                  true
                )
                e.stopPropagation();
              }

              e.stopPropagation();
            }}
            onPointerDown={(e) => e.stopPropagation()}
            onCopy={(e) => e.stopPropagation()}
            onCut={(e) => e.stopPropagation()}
            onPaste={(e) => e.stopPropagation()}
          />
        )}

        { !isInEditMode && (
          <div className="error-cell-text">
            <span className="text">{cell.value}</span>
              
            <Tooltip
              placement="right"
              button={tooltipButton}
            >
                {cell.text}
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
}
