import React, { Component } from 'react';
import classnames from 'classnames';

class Toggler extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { value, onToggle, rightTitle, leftTitle, small, disabled } = this.props;
        const cx1 = classnames('c-toggler__wrapper', {
            'c-toggler--active': value,
            'disabled': disabled
        });
        const cx2 = classnames('c-toggler', {
            'c-toggler--small': small
        });

        return (
            <div className={cx1}>
                {leftTitle && (
                    <p className="c-toggler__title c-toggler__title-left">
                        {leftTitle}
                    </p>
                )}
                <label className={cx2}>
                    <input
                        type="checkbox"
                        checked={value}
                        onChange={() => onToggle()}
                    />
                    <span className="c-toggler__slider c-toggler__circle"></span>
                </label>
                {rightTitle && (
                    <p className="c-toggler__title c-toggler__title-right">
                        {rightTitle}
                    </p>
                )}
            </div>
        );
    }
}

export default Toggler;
