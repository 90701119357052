import React from 'react';
import levenshtein from 'js-levenshtein';

import { Modal, Icon } from 'src/js/components/static';
import { headers } from 'src/js/constants/productConstants/headers';
import { keyFields } from 'src/js/constants/productConstants/index';
import { conditionalFields } from 'src/js/constants/productConstants/index';
import { valueToString } from 'src/js/helpers/objects';
import Button from 'modules/UiKit/components/Button/Button';
import {
    DynamicIps,
    DynamicCategory
} from 'src/js/components/ProductListElements';

import { cloneDeep, isEqual } from 'lodash';

export default function ProductReRegistrationModal({
    defaultData,
    editedData,
    onHide,
    onConfirm,
    ipLevels,
    ips,
    dispatch,
    xelacore
}) {
    const cloneData = cloneDeep(defaultData);
    const changedKeyFields = keyFields.filter(field => {
        let checkField = field === 'ip_paths' ? 'ips' : field === 'licensee_category_path' ? 'licensee_category_id' : field;
        let isSimilar = checkField === 'ips'
                        ? isEqual(defaultData[checkField], editedData[checkField])
                        : valueToString(defaultData[checkField]) === valueToString(editedData[checkField]);
        return (
            editedData.hasOwnProperty(field === 'licensee_category_id' ? 'licensee_category_path' : checkField) && !isSimilar
        );
    });
    const changedConditionalFields = conditionalFields.filter(
        field =>
            editedData.hasOwnProperty(field) &&
            (!!defaultData[field] && levenshtein(defaultData[field], editedData[field]) * 100) /
                (!!defaultData[field] && defaultData[field].length) || 0 >
                50
    );

    const changedFields = [...changedKeyFields, ...changedConditionalFields];

    const getFieldValue = value => {
        if (typeof value === 'boolean') {
            return !value ? 'No' : 'Yes';
        }
        if (typeof value === 'string') {
            if (value.toLowerCase() === 'false') return 'No';
            if (value.toLowerCase() === 'true') return 'Yes';
            return value;
        }
        if (typeof value === 'object' && Array.isArray(value)) {
            return value.join(', ');
        }
        return value;
    };

    const body = (
        <div className="u-full-width">
            <b className="u-color-orange reregistration-modal__note">
                The changes you have made below will require this product record
                to be re-registered. You will also need to reconfirm the
                associated Concept:{' '}
            </b>
            <div className="reregistration-modal__fields">
                <div className="reregistration-modal__fields__header">
                    <div className="reregistration-modal__fields--before">
                        Attributes Before
                    </div>
                    <div className="reregistration-modal__fields__arrow" />
                    <div className="reregistration-modal__fields--after">
                        Attributes After
                    </div>
                </div>
                <div className="reregistration-modal__fields__list">
                    {changedFields.map(field => {
                        const fieldInfo = headers.find(
                            header => header.dataName === field
                        );
                        let checkField = field === 'ip_paths' ? 'ips' : field;
                        cloneData[checkField] = editedData[checkField];

                        return (
                            <div
                                className="reregistration-modal__fields__row"
                                key={`reregistration-field-${field}`}
                            >
                                <div className="reregistration-modal__fields--before reregistration-modal__fields__row__item reregistration-modal__fields__row__item--before">
                                    {
                                        checkField !== 'licensee_category_path' && checkField !== 'ips' && ( <div className="reregistration-modal__fields__row__item__label">
                                            {fieldInfo.label}:
                                        </div>
                                    )}
                                    <div className="reregistration-modal__fields__row__item__value">
                                        { checkField === 'ips'
                                            ? <DynamicIps
                                                xelacore={xelacore}
                                                dispatch={dispatch}
                                                value={defaultData[checkField]}
                                                data={defaultData}
                                                ips={ips}
                                                ipLevels={ipLevels}
                                            />
                                            : checkField === 'licensee_category_path'
                                            ? <DynamicCategory
                                                xelacore={xelacore}
                                                dispatch={dispatch}
                                                value={defaultData[checkField]}
                                                type={'licensee_category_path'}
                                            />
                                            : getFieldValue(defaultData[checkField])
                                        }
                                    </div>
                                </div>
                                <div className="reregistration-modal__fields__arrow reregistration-modal__fields__row__item">
                                    <Icon
                                        icon="ARROW_RIGHT_STYLE_2"
                                        fill="grey3"
                                        size="15"
                                        top="4"
                                    />
                                </div>
                                <div className="reregistration-modal__fields--after reregistration-modal__fields__row__item reregistration-modal__fields__row__item--after">
                                    {
                                        checkField !== 'licensee_category_path' && checkField !== 'ips' && ( <div className="reregistration-modal__fields__row__item__label">
                                            {fieldInfo.label}:
                                        </div>
                                    )}
                                    <div className="reregistration-modal__fields__row__item__value">
                                        { checkField === 'ips'
                                            ? <DynamicIps
                                                xelacore={xelacore}
                                                dispatch={dispatch}
                                                value={editedData[checkField]}
                                                data={cloneData}
                                                ips={ips}
                                                ipLevels={ipLevels}
                                            />
                                            : checkField === 'licensee_category_path'
                                            ? <DynamicCategory
                                                xelacore={xelacore}
                                                dispatch={dispatch}
                                                value={editedData['licensee_category_id']}
                                                type={'licensee_category_path'}
                                            />
                                            : getFieldValue(editedData[checkField])
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="u-flex-gap u-flex-align--right">
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => onHide()}>
                        Cancel
                </Button>
                <Button
                    type="secondary"
                    size="small"
                    onClick={e => onConfirm(e)}>
                        Confirm
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            title="You will need to re-register this product"
            body={body}
            className="re-registration-modal"
            isWideFullScreen={true}
        />
    );
}
