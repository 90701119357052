import React from 'react';
import classnames from 'classnames';

export default function Triangle({ ...props }) {
    const {
        title,
        className,
        up,
        left,
        right,
        orange,
        white,
        purple,
        turquoise,
        blue,
        color,
        black,
        colour,
        grey
    } = props;
    const styles = {
        borderTopColor: color
    };

    const cx = classnames('o-triangle', className, {
        'o-triangle--up': up,
        'o-triangle--left': left,
        'o-triangle--right': right,
        'o-triangle--orange': orange,
        'o-triangle--grey': grey,
        'o-triangle--white': white || colour === 'white',
        'o-triangle--purple': purple,
        'o-triangle--turquoise': turquoise,
        'o-triangle--blue-normal': blue,
        'o-triangle--black': black || colour === 'black'
    });

    return <span style={styles} className={cx} title={title} />;
}
