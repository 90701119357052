import { APP_INIT } from 'src/js/constants/actions/xelacore';

export function app(state = {}, action = {}) {
    switch (action.type) {
        case APP_INIT:
            return state;
        default:
            return state;
    }
}
