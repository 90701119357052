import React, { Component, createRef } from 'react';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import { Icon } from 'src/js/components/static';
import Button from 'modules/UiKit/components/Button/Button';
import { NEW_IMAGE } from 'src/js/constants/imagesConstants';

import { getImageUrl } from 'src/js/helpers/dataHelpers';
import { returnUnique } from 'helpers/formValidationHelpers';
import FormItemWrapper from 'modules/UiKit/components/FormElements/FormItem';

// Used to update the array of images specifically
// Do not edit this to allow for it to edit arrays
// That will be silly

export default class ImageArray extends Component {
    state = {
        data: [],
        imageFiles: [],
        isUpload: true,
        previewPosition: {
            left: 0,
            top: 0
        }
    };

    componentDidMount() {
        this.setState({
            data: this.props.value || []
        });
        this.fileInputRef = createRef();
    }

    onChange(e, el) {
        const { onChange } = this.props;
        const { data } = this.state;

        let index = findIndex(data, (image) =>
            image.image_id
                ? image.image_id === el.image_id
                : image.u_id === el.u_id
        );

        data[index].original_url = e.target.value;

        const primary = data.find((img) => img.tag === 'primary');

        if (!primary) {
            data[0].tag = 'primary';
            data[0].is_primary = true;
        }
        return this.setState({ data }, () => onChange(data));
    }

    onImageHover(e) {
        const { x: left, y: top } = e.target.getBoundingClientRect();
        this.setState({
            previewPosition: {
                left,
                top
            }
        });
    }

    deleteImage(el) {
        const { data } = this.state;
        const { onChange } = this.props;

        let index = findIndex(data, (image) =>
            image.image_id
                ? image.image_id === el.image_id
                : image.u_id === el.u_id
        );

        if (index === 0 && data[index].tag === 'primary' && data.length > 1) {
            data[1].tag = 'primary';
            data[1].is_primary = true;
        }

        index === 0 && data.length === 1
            ? (data[index] = { ...NEW_IMAGE })
            : data.splice(index, 1);

        return this.setState({ data }, () => onChange(data));
    }

    addImage() {
        const { onChange } = this.props;
        const { data } = this.state;

        let lastIndex = data.length - 1;

        data.push({
            ...NEW_IMAGE,
            u_id:
                !!data[lastIndex] && data[lastIndex].u_id
                    ? data[lastIndex].u_id + 1
                    : 1000
        });

        return this.setState({ data }, () => onChange(data));
    }

    renderValidations(errors, warnings) {
        if (!errors.length && !warnings.length) {
            return '';
        }

        return (
            <div className="o-list">
                {returnUnique(errors).map((el) => (
                    <p
                        className="c-product__detail-listing-item-level-error"
                        key={`${el}`}
                    >
                        {el}
                    </p>
                ))}
                {returnUnique(warnings).map((el) => (
                    <p
                        className="c-product__detail-listing-item-level-warn"
                        key={`${el}`}
                    >
                        {el}
                    </p>
                ))}
            </div>
        );
    }

    handleFileSelectChange(e) {
        const files = e.target.files;
        this.setState({ imageFiles: Array.from(files) });
        this.props.onImageUploadChange(files);
    }

    render() {
        const { data } = this.state;
        const { name } = this.props;

        let filteredData = filter(
            data,
            (element) =>
                !!element.validations.length ||
                (!element.validations.length && !element.image_id)
        );

        return (
            <div className="c-product__detail-block u-flex-column">
                <div className="u-margin-bottom u-flex u-flex-align--center">
                    <Button
                        type={this.state.isUpload ? 'primary' : 'secondary'}
                        size="small"
                        // green={this.state.isUpload}
                        onClick={() =>
                            !this.state.isUpload &&
                            this.setState({
                                isUpload: true,
                                data: []
                            })
                        }
                    >
                        Via upload
                    </Button>
                    <Button
                        type={!this.state.isUpload ? 'primary' : 'secondary'}
                        size="small"
                        // green={!this.state.isUpload}
                        onClick={() => {
                            if (this.state.isUpload) {
                                this.setState({
                                    isUpload: false,
                                    imageFiles: []
                                });
                                this.props.onImageUploadChange([]);
                            }
                        }}
                    >
                        Via link
                    </Button>
                </div>
                {this.state.isUpload ? (
                    <React.Fragment>
                        <input
                            ref={this.fileInputRef}
                            type="file"
                            multiple={true}
                            onChange={this.handleFileSelectChange.bind(this)}
                            accept="image/png, image/jpeg"
                            style={{ display: 'none' }}
                        />
                        <Button
                            type="secondary"
                            size="large"
                            onClick={() => this.fileInputRef.current.click()}
                        >
                            Upload images from your device
                        </Button>
                        {this.state.imageFiles.length > 0 && (
                            <div className="u-flex-align u-flex-gap u-flex-wrap u-margin-top">
                                {this.state.imageFiles.map((img) => (

                                    <div
                                        className="c-form-element__image-holder"
                                        key={img.name}
                                    >

                                        <img
                                            className="c-form-element__image-holder__img"
                                            src={URL.createObjectURL(img)}
                                            width={50}
                                            height={50}
                                            onMouseOver={this.onImageHover.bind(
                                                this
                                            )}
                                        ></img>
                                        <img
                                            className="c-form-element__image-holder__img__hover"
                                            src={URL.createObjectURL(img)}
                                            style={{
                                                left:
                                                    this.state.previewPosition
                                                        .left - 200,
                                                top:
                                                    this.state.previewPosition
                                                        .top + 50
                                            }}
                                            width={200}
                                            height={200}
                                        ></img>

                                        <div
                                            className="c-form-element__image-delete-btn"
                                            onClick={() => {
                                                this.setState(
                                                    ({ imageFiles }) => {
                                                        const filteredImageFiles =
                                                            imageFiles.filter(
                                                                ({ name }) =>
                                                                    name !==
                                                                    img.name
                                                            );

                                                        this.props.onImageUploadChange(
                                                            filteredImageFiles
                                                        );
                                                        return {
                                                            imageFiles:
                                                            filteredImageFiles
                                                        };
                                                    }
                                                );
                                            }}
                                        >
                                            x
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {filteredData.map((el, i) => {
                            const link = getImageUrl(el);
                            const { validations = [] } = el;
                            const errors = validations.filter(
                                (f) => f.level === 'error'
                            );
                            const warnings = validations.filter(
                                (f) => f.level === 'warn'
                            );

                            return (
                                <div
                                    key={`${name}-element-${i}`}
                                    className="c-form-element"
                                >
                                    <FormItemWrapper
                                        actionIcon="close2"
                                        inputAction={() =>
                                            this.deleteImage(el)
                                        }
                                        type="text"
                                        onChange={(e) =>
                                            this.onChange(e, el)
                                        }
                                        value={link}
                                    />
                                    {this.renderValidations(errors, warnings)}
                                </div>
                            );
                        })}
                        <div
                            className="u-flex-align u-clickable"
                            onClick={() => this.addImage()}
                        >
                            <small className="u-padding-half-left u-padding-half-right">
                                Add a new image via link
                            </small>
                            <div>
                                <Icon
                                    icon="add"
                                    size={15}
                                    title="Add a new item"
                                    description="Add a new item"
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
