import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {Object} data
 * @return {Promise}
 */
export function fetchOpaData(data, orgId, pageParams) {
    return axios
        .request({
            url: [config.urls.catalogv3, `concepts?page=${!!pageParams && !!pageParams.page && pageParams.page || 1}&page_size=${!!pageParams && !!pageParams.page_size && pageParams.page_size || 15}`].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @return {Promise}
 */
export function fetchOpaCounts() {
    return axios
        .request({
            url: [config.urls.concepts, 'count'].join('/'),
            method: 'post',
            data: {}
        })
        .then(responseHandler);
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {Object} data
 * @return {Promise}
 */
export function confirmApproval(data) {
    return axios
        .request({
            url: [config.urls.concepts, 'confirm'].join('/'),
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * This API call create or update product concept
 *
 * @param  {String} id
 * @param  {String} concept_code
 * @param  {String} old_concept_code
 * @param  {String} version_id
 * @return {Promise}
 */
export function upsertCodeConcept(id, concept_code, old_concept_code, version_id) {
    const data = { record: { version_id } };
    return axios
        .request({
            url: [
                config.urls.catalog,
                'records',
                id,
                'concepts',
                concept_code,
                old_concept_code
            ].join('/'),
            method: 'patch',
            data
        })
        .then(responseHandler);
}

/**
 * This API call confirm concept code
 *
 * @param  {String} id
 * @param  {String} concept_code
 * @param  {String} version_id
 * @return {Promise}
 */
export function confirmApprovalCode(id, concept_code, version_id) {
    const data = { record: { version_id } };
    return axios
        .request({
            url: [
                config.urls.catalog,
                'records',
                id,
                'concepts',
                concept_code
            ].join('/'),
            method: 'patch',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists reports based on tags and custom filters
 *
 * @param  {String} id
 * @param  {String} xelacore_concept_id
 * @return {Promise}/records/{record_id}/concepts/{concept_code}
 */
export function removeConcept(id, xelacore_concept_id) {
    return axios
        .request({
            url: [
                config.urls.catalog,
                'records',
                id,
                'concepts',
                xelacore_concept_id
            ].join('/'),
            method: 'delete',
            params: {}
        })
        .then(responseHandler);
}
