import {
    SET_TABLE_DATA,
    SET_TABLE_DATA_LAZY_LOADING,
    SET_PRODUCTS_LINKED_TO_CONCEPTS_DATA,
    TOGGLE_LICENSEES_PANEL,
    TOGGLE_FILTERS_PANEL,
    SET_LICENSEES_DATA,
    SET_LICENSEES_DATA_LOADING,
    SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA,
    SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA_LOADING,
    SET_LICENSEE_RISK_ASSESSMENT_TABLE_FILTERS,
    SET_UNSUBMITTED_CATALOGUES_DATA,
    SET_UNSUBMITTED_CATALOGUES_DATA_LOADING,
    SET_FILTERS_OPTIONS,
    SET_FILTERS_OPTIONS_LOADING,
    TOGGLE_SELECTED_FILTER_OPTION,
    TOGGLE_LOCAL_FILTER_OPTION,
    SET_ALL_FILTER_OPTIONS_SELECTED,
    SET_DASHBOARD_DATA,
    SET_DASHBOARD_DATA_LOADING,
    SET_ATHLETES_DATA,
    SET_ATHLETES_DATA_LOADING,
    SET_CURRENT_CONCEPT_ANALYTICS_CHART,
    SET_ALL_LOCAL_FILTER_VALUES_FOR_SECTION,
    CLEAR_ALL_FILTERS,
    TOGGLE_SINGLE_LOCAL_FILTER_OPTION
} from 'src/js/constants/actions/licensorDashboard';
import { DATA_STATE } from 'src/js/constants/dataState';
import {
    licensorDashboardVisuals,
    licensorFilters,
    defaultSelectedValues,
    DATA_LENGTH_THRESHOLD
} from 'constants/dashboardVisuals';

const defaultChartData = {
    data: [],
    dataState: DATA_STATE.LOADING
};

const defaultTableData = {
    data: [],
    totals: {},
    sort: {},
    dataState: DATA_STATE.LOADING,
    isLazyLoading: false,
    isLastRecordFetched: false
};

const initialState = {
    filters: {
        dataState: DATA_STATE.LOADING,
        isFiltersReady: false,
        options: {},
        selectedValues: defaultSelectedValues
    },
    localFilters: Object.values(licensorDashboardVisuals).reduce(
        (res, { key, localFilters }) => ({
            ...res,
            [key]: localFilters
                ? localFilters.reduce((res, filter) => {
                      const resFilter = filter.length > 0 ? filter[0] : filter;
                      return {
                          ...res,
                          [resFilter.filter.key]: filter.default
                              ? [filter.default]
                              : []
                      };
                  }, {})
                : {}
        }),
        {}
    ),
    isLicenseesPanelOpen: false,
    isFiltersPanelOpen: false,
    unsubmittedCatalogues: { ...defaultTableData },
    licenseesData: { ...defaultTableData },
    licenseeRiskAssessmentTableData: { ...defaultTableData },
    programSnapshot: { ...defaultTableData },
    productsWithHighRiskFactor: { ...defaultTableData },
    geographicalDistributionOfBrands: { ...defaultChartData },
    catalogCreationTrackerChart: { ...defaultChartData },
    contractedLicensees: {
        ...defaultChartData
    },
    licenseeRiskAssessmentChart: { ...defaultChartData },
    licensorLiveProducts: { ...defaultChartData },
    totalSubmissionsOverTime: { ...defaultChartData },
    productSummary: { ...defaultChartData },
    complianceTrackingChart: { ...defaultChartData },
    registrationRiskIndicator: { ...defaultChartData },
    productsLinkedToConcepts: { ...defaultChartData },
    conceptAnalytics: {
        ...defaultChartData,
        currentConceptAnalyticsChart:
            licensorDashboardVisuals.conceptAnalytics.childVisuals
                .conceptsSubmitted
    },
    keyAnalytics: { ...defaultChartData },
    geoKeyEngagement: { ...defaultChartData },
    scansVsUserRegistration: { ...defaultChartData },
    convertersByGender: { ...defaultChartData },
    convertersByAge: { ...defaultChartData },
    topAthletes: { ...defaultChartData },
    athletesData: { ...defaultChartData }
};

export function licensorDashboard(state = initialState, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_DASHBOARD_DATA_LOADING: {
            const newState = payload.reduce((acc, visualId) => {
                const newData = {
                    ...state[visualId],
                    dataState: DATA_STATE.LOADING
                };
                return { ...acc, [visualId]: newData };
            }, {});
            return {
                ...state,
                ...newState
            };
        }
        case SET_DASHBOARD_DATA: {
            const newState = Object.entries(payload).reduce((acc, [k, v]) => {
                const newData = { ...state[k], ...v };
                return {
                    ...acc,
                    [k]: {
                        ...newData,
                        data:
                            newData.data.length &&
                            newData.data.length > DATA_LENGTH_THRESHOLD
                                ? newData.data.splice(0, DATA_LENGTH_THRESHOLD)
                                : newData.data
                    }
                };
            }, {});
            return {
                ...state,
                ...newState
            };
        }
        case SET_FILTERS_OPTIONS: {
            const { dataState = DATA_STATE.SUCCESS, options } = payload;
            const selectedValues = {
                ...state.filters.selectedValues,
                licensorBrand:
                    options.licensorBrand && options.licensorBrand.length > 0
                        ? [options.licensorBrand[0].filterId]
                        : []
            };
            return {
                ...state,
                filters: {
                    ...state.filters,
                    dataState,
                    options,
                    selectedValues,
                    isFiltersReady: true
                }
            };
        }
        case SET_FILTERS_OPTIONS_LOADING: {
            return {
                ...state,
                filters: { ...state.filters, dataState: DATA_STATE.LOADING }
            };
        }
        case SET_ALL_FILTER_OPTIONS_SELECTED: {
            const { section } = payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedValues: section
                        ? {
                              ...state.filters.selectedValues,
                              [section]: []
                          }
                        : defaultSelectedValues
                }
            };
        }
        case TOGGLE_SELECTED_FILTER_OPTION: {
            const { section, value } = payload;
            const { isMultiselect } = licensorFilters[section];
            const sectionValues = state.filters.selectedValues[section] || [];
            const newSectionValues = !isMultiselect
                ? [value]
                : sectionValues.includes(value)
                ? sectionValues.filter((v) => v !== value)
                : [...sectionValues, value];
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedValues: {
                        ...state.filters.selectedValues,
                        [section]:
                            !isMultiselect && value === undefined
                                ? []
                                : newSectionValues
                    }
                }
            };
        }
        case TOGGLE_LOCAL_FILTER_OPTION: {
            const { section, value, chartKey } = payload;
            const localFiltersFlat = licensorDashboardVisuals[
                chartKey
            ].localFilters.reduce(
                (res, f) => (f.length > 0 ? [...res, ...f] : [...res, f]),
                []
            );
            const isMultiselect = localFiltersFlat.find(
                (filterInfo) => filterInfo.filter.key === section
            ).isMultiselect;
            const sectionValues = state.localFilters[chartKey][section] || [];
            const newSectionValues = !isMultiselect
                ? [value]
                : sectionValues.includes(value)
                ? sectionValues.filter((v) => v !== value)
                : [...sectionValues, value];

            return {
                ...state,
                localFilters: {
                    ...state.localFilters,
                    [chartKey]: {
                        ...state.localFilters[chartKey],
                        [section]: value === undefined ? [] : newSectionValues
                    }
                }
            };
        }
        case TOGGLE_SINGLE_LOCAL_FILTER_OPTION: {
            const { section, value, chartKey, filtersToClear } = payload;
            const localFiltersFlat = licensorDashboardVisuals[
                chartKey
            ].localFilters.reduce(
                (res, f) => (f.length > 0 ? [...res, ...f] : [...res, f]),
                []
            );
            const isMultiselect = localFiltersFlat.find(
                (filterInfo) => filterInfo.filter.key === section
            ).isMultiselect;
            const sectionValues = state.localFilters[chartKey][section] || [];
            const newSectionValues = !isMultiselect
                ? [value]
                : sectionValues.includes(value)
                ? sectionValues.filter((v) => v !== value)
                : [...sectionValues, value];

            return {
                ...state,
                localFilters: {
                    ...state.localFilters,
                    [chartKey]: {
                        ...Object.entries(state.localFilters[chartKey]).reduce(
                            (res, [k, v]) =>
                                filtersToClear.includes(k)
                                    ? res
                                    : { ...res, [k]: v },
                            {}
                        ),
                        [section]: value === undefined ? [] : newSectionValues
                    }
                }
            };
        }
        case SET_ALL_LOCAL_FILTER_VALUES_FOR_SECTION: {
            const { section, value } = payload;
            const newSectionValues = value === undefined ? [] : [value];
            const newLocalFilters = Object.entries(state.localFilters).reduce(
                (res, [chartKey, value]) => {
                    if (value[section])
                        return {
                            ...res,
                            [chartKey]: {
                                ...value,
                                [section]: newSectionValues
                            }
                        };
                    return res;
                },
                {}
            );
            return {
                ...state,
                localFilters: {
                    ...state.localFilters,
                    ...newLocalFilters
                }
            };
        }
        case CLEAR_ALL_FILTERS: {
            const {
                filters: { options }
            } = state;
            const licensorBrand =
                options.licensorBrand && options.licensorBrand.length > 0
                    ? [options.licensorBrand[0].filterId]
                    : [];
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedValues: { ...defaultSelectedValues, licensorBrand }
                },
                localFilters: initialState.localFilters
            };
        }
        case TOGGLE_LICENSEES_PANEL: {
            return {
                ...state,
                isLicenseesPanelOpen: !state.isLicenseesPanelOpen
            };
        }
        case TOGGLE_FILTERS_PANEL: {
            return {
                ...state,
                isFiltersPanelOpen: !state.isFiltersPanelOpen
            };
        }
        case SET_LICENSEES_DATA: {
            const {
                data = [],
                dataState = DATA_STATE.ERROR,
                isLastRecordFetched = true
            } = payload;
            return {
                ...state,
                ...{
                    licenseesData: {
                        data,
                        dataState,
                        isLastRecordFetched
                    }
                }
            };
        }
        case SET_LICENSEES_DATA_LOADING: {
            return {
                ...state,
                ...{
                    licenseesData: {
                        ...state.licenseesData,
                        dataState: DATA_STATE.LOADING
                    }
                }
            };
        }
        case SET_UNSUBMITTED_CATALOGUES_DATA: {
            const {
                data = null,
                totals = null,
                sort = null,
                dataState = DATA_STATE.ERROR,
                isLastRecordFetched = true
            } = payload;
            return {
                ...state,
                ...{
                    unsubmittedCatalogues: {
                        ...state.unsubmittedCatalogues,
                        sort,
                        data,
                        totals,
                        dataState,
                        isLastRecordFetched
                    }
                }
            };
        }
        case SET_UNSUBMITTED_CATALOGUES_DATA_LOADING: {
            return {
                ...state,
                ...{
                    unsubmittedCatalogues: {
                        ...state.unsubmittedCatalogues,
                        dataState: DATA_STATE.LOADING
                    }
                }
            };
        }
        case SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA_LOADING: {
            return {
                ...state,
                ...{
                    licenseeRiskAssessmentTableData: {
                        ...state.licenseeRiskAssessmentTableData,
                        dataState: DATA_STATE.LOADING
                    }
                }
            };
        }
        case SET_LICENSEE_RISK_ASSESSMENT_TABLE_FILTERS: {
            return {
                ...state,
                ...{
                    licenseeRiskAssessmentTableData: {
                        ...state.licenseeRiskAssessmentTableData,
                        sort: {},
                        data: [],
                        dataState: DATA_STATE.LOADING,
                        isLastRecordFetched: false,
                        filters: payload
                    }
                }
            };
        }
        case SET_LICENSEE_RISK_ASSESSMENT_TABLE_DATA: {
            const {
                data = [],
                dataState = DATA_STATE.ERROR,
                isLastRecordFetched = true,
                sort
            } = payload;
            return {
                ...state,
                ...{
                    licenseeRiskAssessmentTableData: {
                        ...state.licenseeRiskAssessmentTableData,
                        data,
                        dataState,
                        isLastRecordFetched,
                        sort
                    }
                }
            };
        }
        case SET_TABLE_DATA: {
            const {
                tableName,
                data = null,
                totals = null,
                sort = null,
                dataState = DATA_STATE.ERROR,
                isLastRecordFetched = true,
                isLazyLoading = false
            } = payload;
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    isLazyLoading,
                    sort,
                    data,
                    totals,
                    dataState,
                    isLastRecordFetched
                }
            };
        }
        case SET_TABLE_DATA_LAZY_LOADING: {
            const tableName = payload;
            return {
                ...state,
                [tableName]: {
                    ...state[tableName],
                    dataState: DATA_STATE.LOADING,
                    isLazyLoading: true
                }
            };
        }
        case SET_PRODUCTS_LINKED_TO_CONCEPTS_DATA: {
            const { data = {}, dataState = DATA_STATE.ERROR } = payload;
            return {
                ...state,
                ...{ productsLinkedToConcepts: { data, dataState } }
            };
        }
        case SET_ATHLETES_DATA_LOADING: {
            return {
                ...state,
                ...{
                    athletesData: {
                        ...state.athletesData,
                        dataState: DATA_STATE.LOADING
                    }
                }
            };
        }
        case SET_ATHLETES_DATA: {
            return {
                ...state,
                ...{
                    athletesData: {
                        data: payload,
                        dataState: DATA_STATE.SUCCESS
                    }
                }
            };
        }
        case SET_CURRENT_CONCEPT_ANALYTICS_CHART: {
            return {
                ...state,
                ...{
                    conceptAnalytics: {
                        ...state.conceptAnalytics,
                        currentConceptAnalyticsChart: payload
                    }
                }
            };
        }

        default:
            return state;
    }
}
