import axios from 'axios';
import config from 'config';
import { refreshTheRefreshToken } from 'src/js/actions/xelacore';
import { logout } from 'src/js/lib/auth';

export default {
    setDefaults: () => {
        axios.defaults.baseURL = config.baseUrl;
        axios.defaults.headers.common['Accept'] = 'Application/Json';
    },
    setupRequestInterceptor: store => {
        axios.interceptors.request.use(
            c => {
                const { token } = store.getState().xelacore.auth;

                if (token != null)
                    c.headers.Authorization = ['Bearer', token].join(' ');
                return c;
            },
            err => {
                return Promise.reject(err);
            }
        );
    },

    setupInterceptors: (store) => {
        axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                const { response: { status, data } = {} } = error;
                if (error.response && (status >= 401 && status < 500)) {
                    try {
                        store.dispatch(refreshTheRefreshToken());
                    } catch (err) {
                        logout();
                    }
                }

                if (
                    status >= 500 &&
                    data.error_description === 'auth.invalidRefreshToken'
                ) {
                    logout();
                }

                return Promise.reject(error);
            }
        );
    }
};
