import { MODAL_SHOW, MODAL_HIDE } from 'src/js/constants/actions/xelacore';

export function modal(
    state = {
        displayModal: false,
        content: {}
    },
    action = {}
) {
    switch (action.type) {
        case MODAL_SHOW:
            const content = action.modalContent;
            const displayModal = true;
            return Object.assign({}, state, { displayModal, content });

        case MODAL_HIDE:
            return Object.assign({}, state, {
                displayModal: false,
                content: null
            });

        default:
            return state;
    }
}
