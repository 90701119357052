import React, { useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import includes from 'lodash/includes';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import each from 'lodash/each';
import map from 'lodash/map';
import { Tooltip } from 'components/static';
import {
    DynamicSelect,
    Autocomplete,
    ObjectField,
    ImageArray,
    CommonArray,
    Checkbox,
    DynamicCategory,
    DynamicArray,
    DynamicIps,
    DynamicAgent
} from 'components/ProductFormElements';
import { AsinArray } from 'components/ProductListElements';

import { coreFields } from 'constants/conflictsConstants';
import { isQuarantineRecord } from 'helpers/conflictsHelper';

import { returnUnique } from 'helpers/formValidationHelpers';
import CustomSelect from 'modules/UiKit/components/FormElements/CustomSelect';
import Input from 'modules/UiKit/components/FormElements/Input';
import Textarea from 'modules/UiKit/components/FormElements/Textarea';
import Date from 'modules/UiKit/components/FormElements/Date';

export default function FormItemWrapper(props) {
    const {
        id,
        label,
        hideLabel,
        tooltip,
        validations = [],
        type,
        localValidations,
        name,
        data,
        isInline,
        values,
        categories,
        disabledElement,
        isMultiple,
        xelacore,
        isLicensor,
        isAgent,
        ipLevels,
        ips,
        onChange,
        onInputBlur,
        vertical,
        itmHasError,
        agenciesData,
        originalData
    } = props;
    // Render the desired form element element
    // These are for editing product data and nothing else!
    // These styles rely on the FormElement styling. This will need to change
    const renderFormElement = () => {
        switch (type) {
            case 'autocomplete':
                return <Autocomplete {...props} />;
            case 'imageArray':
                return (
                    <ImageArray
                        onBlur={() => setIsFocused(false)}
                        onFocus={() => setIsFocused(true)}
                        {...props}
                    />
                );
            case 'array':
                return <CommonArray {...props} />;
            case 'dynamicSelect':
                return <DynamicSelect {...props} />;
            case 'licensedProperty':
                return <LicensedProperties {...props} />;
            case 'selectdropdown':
                return <CustomSelect fieldHasErrors={handleFieldHasErrors} {...props} />;
            case 'object':
                return <ObjectField {...props} />;
            case 'date':
                return (
                    <Date
                        onBlur={() => setIsFocused(false)}
                        onFocus={() => setIsFocused(true)}
                        {...props}
                    />
                );
            case 'textarea':
                return (
                    <Textarea
                        onBlur={() => setIsFocused(false)}
                        onFocus={() => setIsFocused(true)}
                        {...props}
                    />
                );
            case 'select':
                return <CustomSelect {...props} fetchValues={() => props.fetchValues || null} values={props.values || []}/>;
            case 'checkbox':
                return <Checkbox {...props} />;
            case 'asinArray':
                return <AsinArray {...props} />;
            case 'dynamicArray':
                return <DynamicArray {...props} validations={validations} />;
            case 'dynamicCategory':
                return <DynamicCategory
                    {...props}
                    xelacore={xelacore}
                    validations={validations}
                    filteredValidations={filteredValidations}
                    type={isLicensor || isAgent ? 'licensor_category_path' : 'licensee_category_path'}
                    isLicensor={isLicensor}
                />;
            case 'dynamicIps':
                return <DynamicIps
                    data={data}
                    xelacore={xelacore}
                    isLicensor={isLicensor}
                    ipLevels={ipLevels}
                    ips={ips}
                    isMultiple={isMultiple}
                    onChange={onChange}
                />;
            case 'dynamicAgent':
                return <DynamicAgent
                            data={data}
                            onChange={onChange}
                            xelacore={xelacore}
                            originalData={originalData}
                            agenciesData={agenciesData}/>;
            default:
                return (
                    <Input
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => {
                            setIsFocused(false);
                            if(!!onInputBlur && typeof onInputBlur === 'function'){
                                onInputBlur();
                            }
                        }}
                        {...props}
                    />
                );
        }
    };

    const handleFieldHasErrors = (clildData) => {
        setcChildHasErrors(clildData.length > 0);
    };

    const errors = validations.filter(
        (f) => f.level === 'error' && ((f.field === 'agent_organisation_id' || f.field === 'agent_organisation') ? 'agent_organisation_id' : props.name || f.field === `static_attributes.${props.name}`)
    );
    const warnings = validations.filter(
        (f) => f.level === 'warn' && (f.field === props.name || f.field === `static_attributes.${props.name}`)
    );

    let targetList = [];

    const [isFocused, setIsFocused] = useState(false);
    const [childHasErrors, setcChildHasErrors] = useState(false);

    if (name === 'target_market') {
        each(values, (value) => {
            each(value.items, (item) => {
                targetList.push(item);
            });
        });
    }

    const validationErrorsFields = [
        'product_category',
        'product_sub_category',
        'target_market',
        'language',
        'market_availability_date',
        'product_status',
        'manufacturing_status',
        'licensor_brand',
        'licensor'
    ];
    const categoriesList =
        categories &&
        categories.list &&
        map(categories.list, (cat) => {
            return toLower(cat.name);
        });

    const targetValues = targetList && map(targetList, 'value');

    const productStatusError = data && data.length > 0 ? (
        (name === 'product_status' &&
            size(data[name]) > 0 &&
            (!includes(
                    map(values, (value) => toLower(value.name)),
                    toLower(data[name])
                ) ||
                !size(data.market_availability_date))) ||
        (toLower(data[name]) === 'live' &&
            moment(data.market_availability_date).isAfter()) ||
        (toLower(data[name]) === 'new product listing' &&
            moment(data.market_availability_date).isBefore())) : null;

    const manufacturingErr =
        name === 'manufacturing_status' &&
        size(data[name]) > 0 &&
        !includes(
            map(values, (value) => toLower(value.name)),
            toLower(data[name])
        );

    const categoryError =
        name === 'product_category' &&
        size(data[name]) > 0 &&
        !includes(categoriesList, toLower(data[name]));

    const targetError =
        name === 'target_market' &&
        size(data[name]) > 0 &&
        !targetValues.some((r) => data[name].includes(r));

    const marketAvError =
        name === 'market_availability_date' &&
        size(data[name]) > 0 &&
        ((toLower(data.product_status) === 'live' &&
                moment(data[name]).isAfter()) ||
            (toLower(data.product_status) === 'new product listing' &&
                moment(data[name]).isBefore()));

    const hasError =
        (includes(validationErrorsFields, name) && size(data[name]) === 0) ||
        categoryError ||
        productStatusError ||
        targetError ||
        manufacturingErr ||
        itmHasError ||
        marketAvError ||
        (errors && errors.length > 0);

    const redError =
        (errors.length > 0 &&
            (!includes(validationErrorsFields, name) || hasError)) ||
        (!errors.length &&
            includes(validationErrorsFields, name) &&
            size(data[name]) === 0) ||
        hasError;

    const warningError =
        (warnings.length > 0 &&
            (!includes(validationErrorsFields, name) || hasError)) ||
        (!warnings.length &&
            includes(validationErrorsFields, name) &&
            size(data[name]) === 0) ||
        hasError;

    const cx = classnames('form-item', {
        'vertical-group': !!vertical && (label !== 'Attributes'),
        'form-item-grouped': type === 'imageArray',
        'form-item-error': (!disabledElement && redError) || childHasErrors,
        'form-item-focus': !disabledElement && isFocused,
        'form-item-warn': !disabledElement && warningError,
        'form-item-is-inline': isInline,
        'categories': type === 'dynamicCategory',
        disabled:
            disabledElement || (!!data && data.length > 0 ?
                ((isQuarantineRecord(data) && includes(coreFields, name))
                    || name === 'licensor_property' && !data.licensor_brand_id) : null)
    });

    const filteredValidations = data && data.record_id ? validations.filter(v => data.record_id === v.record_id) : [];

    return (
        <div className={cx}>
            {!hideLabel && label && type !== 'dynamicCategory' && label !== 'Attributes' && (
                <label className={'main-label'} htmlFor={id}>
                    {label}
                </label>)
            }

            <div className="field-item-wrapper">
                <div
                    className={classnames(`${type !== 'dynamicCategory' ? 'extra-wrapper' : ''}
                    input-wrapper  edit-type-${type}
                    ${['select', 'selectdropdown'].includes(type.toLowerCase()) ? 'select-wrapper' : ''}`,
                        {
                            'inline-item': isInline,
                            'with-tooltip': !!tooltip && type !== 'asinArray' && type !== 'dynamicCategory'
                        }
                    )}
                >
                    {renderFormElement()}

                    {!!tooltip && type !== 'asinArray' && type !== 'dynamicCategory' && (
                        <div className="form-item-tooltip">
                            <Tooltip
                                error={!disabledElement && redError}
                                warn={!disabledElement && warningError}
                            >
                                {tooltip}
                            </Tooltip>
                        </div>
                    )}

                </div>
                <div className="validation-box">
                    {
                        localValidations && localValidations.length > 0 && localValidations[name] && (
                            <p className="form-item-local-validation">
                                {label} <span>{localValidations[name]}</span>
                            </p>
                        )
                    }

                    {validations.length > 0 && type !== 'dynamicCategory' &&
                        (!includes(validationErrorsFields, (name === 'agent_organisation' ? 'agent_organisation_id' : name)) || hasError) && (
                            <ul className="o-list error-details-list">
                                {returnUnique(errors).map((el) => (
                                    <li
                                        key={`${el}`}
                                        className="item-error-details"
                                    >
                                        {el}
                                    </li>
                                ))}
                                {returnUnique(warnings).map((el) => (
                                    <li
                                        key={`${el}`}
                                        className="c-product__detail-listing-item-level-warn"
                                    >
                                        {el}
                                    </li>
                                ))}
                            </ul>
                        )}
                </div>
            </div>

        </div>
    );
}
