import {
    SET_USER_SETTINGS,
    REMOVE_USER_SETTINGS
} from 'src/js/constants/actions/xelacore';

export function userSettings(state = {}, action = {}) {
    const { payload = {} } = action;
    switch (action.type) {
        case SET_USER_SETTINGS:
            const { settings = {}, tags = {}, filters = {} } = payload;
            const { dontShowRedirectModal, url } = settings;
            if (url) {
                return { ...state, ...settings, url };
            }
            if (dontShowRedirectModal === undefined) {
                settings.dontShowRedirectModal =
                    state && state.dontShowRedirectModal;
            }
            return { ...state, ...settings, ...tags, ...filters };
        case REMOVE_USER_SETTINGS:
            return { ...payload };
        default:
            return state;
    }
}
