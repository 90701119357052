import React, { Component } from 'react';
import axios from 'axios';
import config from 'config';
import IngestBar from './IngestBar';
import responseHandler from 'apicalls/common/responseHandler';
import { connect } from 'react-redux';
import { updateIngestions } from 'src/js/actions/xelacore';
import { IngestionStatusMessage } from './IngestionStatusMessage';

class IngestionSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false
        };
    }

    componentDidMount() {
        this.setState({ fetching: true });
        const selectedLicensor = !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {};

        const url = `${config.urls.ingestv3}/status?licensorOrganisationId=${selectedLicensor.licensor_organisation_id}&filter[destination]=xelacore-catalog&filter[job_type]=insert&filter[status]=running`;
        axios.request(url)
            .then(response => responseHandler(response, 'data'))
            .then(response => {
                this.setState({ fetching: false });
                this.props.updateIngestions(response);
            });
    }

    render() {
        /**
         * Only show the current user's ingestions
         * This stops the interface jumping around
         */

        const myIngestions = this.props.ingestions.filter(o => {
            return o.userId === this.props.userId &&
                o.jobType === 'insert' &&
                o.destination === 'xelacore-catalog' &&
                o.licensorOrganisationId === this.props.selectedLicensor.licensor_organisation_id;
        });

        return (
            <div>
                {myIngestions.map(ingestion => (
                    <div key={ingestion.ingestionId}>
                        <IngestBar ingestion={ingestion} />
                    </div>
                ))}
                <IngestionStatusMessage ingestions={myIngestions} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ingestions: state.xelacore.ingestions.byCreated || [],
        userId: state.xelacore.auth.userData.user_id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateIngestions: (ingestions) => dispatch(updateIngestions(ingestions))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestionSection);

