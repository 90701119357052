import React, { Component } from 'react';
import { getUrlParam } from 'src/js/helpers/strings';
import { PAGE_LIMIT, ITEMS_PER_PAGE } from 'src/js/constants/dataConstants';
import { getFiltersDataFromUrl, jumpToPage } from 'src/js/helpers/dataHelpers';
import UiPagination from 'modules/UiKit/components/Pagination/Pagination';

// Put in propTypes here

class DataViewPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsPerPageOptions: ITEMS_PER_PAGE,
            itemsPerPage: props.pageLimit || PAGE_LIMIT,
            pageNumber: props.isModal ? props.pageNum || 1 : getUrlParam('page') || 1
        };
    }

    componentDidUpdate(prevProps) {
        const { pageNum, pageLimit } = this.props;

        if (prevProps.pageNum !== this.props.pageNum) {
            this.setState({
                pageNumber: pageNum
            });
        }

        if (prevProps.pageLimit !== this.props.pageLimit) {
            this.setState({
                itemsPerPage: pageLimit
            });
        }
    }

    updateItemsPerPageHandler(e) {
        const itemsPerPage = e.target.value;

        this.setState(
            {
                itemsPerPage,
                pageNumber: 1 // go back to first page
            },
            () => {
                const params = {
                    page: 1,
                    page_size: itemsPerPage
                };
                this.props.fetchData(params, getFiltersDataFromUrl());
            }
        );
    }

    updatePageHandler = page => {
        const { itemsPerPage } = this.state;

        this.setState(
            {
                pageNumber: page
            },
            () => {
                const params = {
                    page: page,
                    page_size: itemsPerPage
                };
                return this.props.fetchData(params, getFiltersDataFromUrl());
            }
        );
    };

    render() {
        const { pageNumber, itemsPerPage, itemsPerPageOptions } = this.state;
        const { totalItems = 0 ,extraclass, itemsPerPageHidden = false} = this.props;

        return (
            <UiPagination
                extraclass={extraclass}
                updateItemsPerPageHandler={this.updateItemsPerPageHandler.bind(
                    this
                )}
                currentPage={parseInt(pageNumber)}
                totalItems={parseInt(totalItems)}
                itemsPerPage={parseInt(itemsPerPage)}
                itemsPerPageOptions={itemsPerPageOptions}
                updatePageHandler={page => this.updatePageHandler(page)}
                itemsPerPageHidden={itemsPerPageHidden || false}
                jumpToPage={e => jumpToPage(e, this.updatePageHandler)}
            />
        );
    }
}

export default DataViewPagination;
