import {
    setLinkedBrands,
    setBrands
} from 'src/js/actions/xelacore';
import {
    fetchBrands
} from 'src/js/apicalls/auth/users';
import {
    fetchCategories,
    fetchLinkedBrands,
    fetchCategoryAttributes,
    fetchLicensedProperites,
    fetchFamilies
} from 'src/js/apicalls/other/categories';

export function getProuctCategories() {
    return () => fetchCategories();
}

export function getProductFamilies() {
    return (dispatch, getState) => {
        const {
            xelacore: {
                auth: {
                    companyData: { organisation_id }
                }
            }
        } = getState();

        return fetchFamilies(organisation_id).then((data) => {
            return data;
        });
    }
}

export function getLinkedBrands() {
    return (dispatch, getState) => {
        const {
            xelacore: {
                auth: {
                    companyData: { organisation_id }
                }
            }
        } = getState();

        return fetchLinkedBrands(organisation_id).then((data) => {
            dispatch(setLinkedBrands(data.data));
            return data;
        });
    };
}

export function getBrands() {
    return (dispatch, getState) => {
        const {
            xelacore: {
                auth: {
                    companyData: { organisation_id }
                }
            }
        } = getState();

        return fetchBrands(organisation_id).then((data) => {
            dispatch(setBrands(data.data.brands));
            return data;
        });
    };
}

export function getCategoryAttributes(licensorOrgId, categoryId) {
    return fetchCategoryAttributes(licensorOrgId, categoryId).then((data) => {
        return data;
    });
}

export function getLicensedProperty(licensorOrgId, brandId) {
    return fetchLicensedProperites(licensorOrgId, brandId).then((data) => {
        return data;
    });
}
