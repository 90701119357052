import { getPreviousUploads } from 'src/js/actions/records';
import { setPageNotification, setIngestions } from 'src/js/actions/xelacore';

// Check if there are any active ingestions for the user
export function checkCurrentlyIngested(dispatch, pageNotification) {
    const selectedLicensor = !!localStorage.getItem('selectedLicensor') && JSON.parse(localStorage.getItem('selectedLicensor')) || {}

    dispatch(setIngestions({ ingestions: { checkIngesting: true } }));
    dispatch(getPreviousUploads(null, selectedLicensor.licensor_organisation_id)).then(resp => {
        const { data: { status = [] } = {} } = resp;
        const ingesting = status
            .filter(item => !item.completedAt)
            .map(item => {
                const {
                    ingestionId: ingestionId,
                    sourceInfo: sourceInfo
                } = item;

                return { ingestionId, sourceInfo };
            });

        dispatch(setIngestions({ ingestions: { checkIngesting: false } }));
        if (!ingesting.length) return;
        dispatch(
            setRecordNotificationHelper(
                pageNotification,
                ingesting,
                false,
                true
            )
        );
    });
}

// Build PageNotification on active FileUpload
export function setRecordNotificationHelper(
    pageNotification,
    fileUploadList,
    isOpaUploader,
    asIs
) {
    return dispatch => {
        if (!isOpaUploader) {
            const myRecordNotification =
                pageNotification && pageNotification['my-records'];
            let files = asIs
                ? fileUploadList
                : fileUploadList.map(file => ({ name: file.name }));
            let filesProcessed = files;

            if (myRecordNotification) {
                const prevFiles =
                    myRecordNotification && myRecordNotification.files;
                const prevFilesProcessed =
                    myRecordNotification && myRecordNotification.filesProcessed;
                files = [...prevFiles, ...files];
                filesProcessed = [...prevFilesProcessed, ...filesProcessed];
            }
            const payload = {
                type: 'my-records',
                'my-records': {
                    message: 'active ingest',
                    count: fileUploadList.length,
                    files,
                    filesProcessed,
                    showBar: true
                }
            };
            dispatch(setPageNotification(payload));
        }
    };
}
