import { ErrorBoundary } from 'modules/ErrorBoundary/ErrorBoundary';
import React from 'react';
import Helmet from 'react-helmet';

export default function AppHelmet({
    title,
    titleTemplate,
    appName,
    themeColour,
    description,
    keywords,
    children
}) {
    return (
        <div className={'appHelmet'}>
            <Helmet
                title={title}
                titleTemplate={titleTemplate || [title, '- %s'].join(' ')}
                link={[
                    {
                        rel: 'icon',
                        href: '/favicon.ico',
                        role: 'favicon',
                        type: 'image/x-icon'
                    },
                    { rel: 'manifest', href: 'manifest.json' }
                ]}
                meta={[
                    { 'char-set': 'utf-8' },
                    { name: 'application-name', content: appName },
                    { name: 'mobile-web-app-capable', content: 'yes' },
                    { name: 'theme-color', content: themeColour },
                    {
                        'http-equiv': 'X-UA-Compatible',
                        content: 'IE=edge,chrome=1'
                    },
                    {
                        name: 'viewport',
                        content:
                            'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
                    },
                    { name: 'description', content: description },
                    { name: 'keywords', content: keywords }
                ]}
            />
            <ErrorBoundary>{children}</ErrorBoundary>
        </div>
    );
}
