import React, {Component} from 'react';
import {connect} from 'react-redux';
import Zendesk from 'react-zendesk';

const ZENDESK_KEY = '31390640-7c48-4816-b478-209bd0040cac';

class ZendeskWrapper extends Component {
    render() {
        const userData = this.props.xelacore.auth.userData;
        return (
            <div className="c-breadcrumbs">

                <Zendesk defer ide zendeskKey={ZENDESK_KEY} onLoaded={() => {
                    window.zE('webWidget', 'prefill', {
                        name: {value: `${userData.information.first_name} ${userData.information.last_name}`},
                        email: {value: userData.email}
                    });
                }}/>
            </div>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({dispatch})
)(ZendeskWrapper);
