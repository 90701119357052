import React from 'react';
import PropTypes from 'prop-types';

export default function UiCheckbox({
                                       type,
                                       onChange,
                                       checked,
                                       disabled,
                                       onClick,
                                       id,
                                       key,
                                       label,
                                       name,
                                       defaultChecked,
                                       readOnly,
                                       extraClass
                                   }) {
    const checkbox = (
        <div
            className={`ui-checkbox-container ${readOnly ? 'readOnly' : ''} ${extraClass}`}
            onClick={onClick}
        >
            <input
                id={id || ''}
                key={key || ''}
                type={type}
                disabled={disabled}
                checked={checked}
                defaultChecked={defaultChecked}
                name={name || ''}
                onChange={onChange}
                className="ui-checkbox-container__check-marker"
            />

            <div className="ui-checkbox-container__checkmark"></div>

            {!!label && (
                <label htmlFor={id || ''}>{label}</label>
            )}
        </div>
    );

    return checkbox;
}

UiCheckbox.propTypes = {
    type: PropTypes.oneOf(['checkbox', 'radio']),
    to: PropTypes.string,
    onClick: PropTypes.func,
    iconLeft: PropTypes.any,
    iconRight: PropTypes.any,
    children: PropTypes.any,
    download: PropTypes.bool,
    disabled: PropTypes.bool
};

UiCheckbox.defaultProps = {
    className: '',
    to: undefined,
    iconLeft: undefined,
    iconRight: undefined,
    children: undefined,
    onClick: () => {
    },
    disabled: false,
    download: false
};
