import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from './Select';
import toLower from 'lodash/toLower';
import { headers } from 'src/js/constants/productConstants/headers';

// A little helper function to get the first or nth key of an object based on position
const first = (obj = {}, n = 0) => obj[Object.keys(obj)[n]];

const mapDispatchToProps = (dispatch) => ({
    dispatch
});

@connect(null, mapDispatchToProps)
export default class DynamicSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        const { reliesOn, data } = this.props;

        // If it doesn't rely on anything, just fetch (1)
        // If it relies on a field, check that the field has a value first and then do a filtered fetch (2)
        // If we're doing (2) then we will need to do the following:
        // A. Get the id of that reliant value e.g. Categeory ID
        // C. Filter these values based off the initial ID we found and setState
        // TODO: Cache these values fetched in B instead of constantly refetching htem
        // In order for this component to work, the ordering of the keys of the object that comes in must be the same or else it will be broken

        if (!reliesOn) return this.fetchData();
        if (reliesOn && data[reliesOn]) return this.fetchFilteredData();
    }

    // Fetch the data as normal
    fetchData() {
        const { dispatch, fetchValues, valueProp } = this.props;
        dispatch(fetchValues()).then(({ data }) => {
            const selectData = first(data).rows;
            const dataToSet = selectData.map((el) => ({
                name: valueProp ? el[valueProp] : toLower(el.name),
                label: el.name
            }));

            this.setState({ data: dataToSet });
        });
    }

    // Fetch the data that's reliant on some form of ID
    fetchFilteredData() {
        const { dispatch, fetchValues, data, reliesOn } = this.props;
        return this.getIdRef(data[reliesOn]).then((id) => {
            dispatch(fetchValues()).then((resp) => {
                const retData = resp.data;
                const vals = first(retData).rows.filter(
                    (f) => first(f, 1) === id
                );

                return this.setState({
                    data: vals
                        .map((el) => ({
                            name: toLower(el.name),
                            label: el.name
                        }))
                        .sort((subcat1, subcat2) =>
                            subcat1.name > subcat2.name ? 1 : -1
                        )
                });
            });
        });
    }

    getIdRef(value) {
        const { dispatch, reliesOn } = this.props;
        // Get the fetch data function, of the reliant header for this field
        // E.g.  I'm product sub category and i'm reliant on the product category for my values
        const func = headers.find((el) => el.dataName === reliesOn).form
            .fetchValues;

        return dispatch(func()).then(({ data }) => {
            const reliantData = first(data).rows;
            const matchingVal = reliantData.find(
                ({ name }) =>
                    (typeof name === 'string' ? name.toLowerCase() : name) ===
                    (typeof value === 'string' ? value.toLowerCase() : value)
            );
            return first(matchingVal);
        });
    }

    render() {
        const { data } = this.state;

        return (
            <div className="c-select__styled-select">
                <Select {...this.props} values={data} />
            </div>
        );
    }
}
