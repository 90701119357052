import React, { Component, Fragment } from 'react';
import { Badge, Button, Icon } from 'src/js/components/static';
import ImagesBlock from './ImagesBlock';
import { conceptShowFields } from 'src/js/constants/opaConstants';
import {
    fetchCategories
} from 'src/js/apicalls/other/categories';

class SelectedProduct extends Component {
    constructor(props) {
        super(props);
        this.block = React.createRef();

        this.state = {
            height: 400,
            categoryLevels: []
        };
    }

    componentDidMount() {
        const height =
            this.block.current &&
            this.block.current.getBoundingClientRect().height;
        this.setState({ height });
        this.fetchCategories();
    }

    fetchCategories() {
        const { xelacore } = this.props;
        const {
            auth: {
                token,
                companyData: { organisation_id }
            }
        } = xelacore;

        fetchCategories(organisation_id, token).then((response) => {
            this.setState({
                categoryLevels: response.data && response.data.category_levels
            })
        });
    }

    render() {
        const { height, categoryLevels } = this.state;
        const {
            data = {},
            close,
            updateProductConcept,
            record_id,
            onlyView,
            licensor = false
        } = this.props;
        window.scrollTo(0, 0);
        const newHeight =
            (isNaN(height) || height - 770) < 400 ? 400 : height - 770;

        const filterField = licensor ? 'forLicensor' : 'forLicensee';
        const conceptFields = conceptShowFields.filter(
            (field) => !!field[filterField]
        );

        const conceptData = { ...data, ...data.properties };

        const confirm = !onlyView && (
            <div className="c-opa-product__concept-confirm">
                <div className="c-product__is-correct">
                    Is this the right concept?
                </div>
                <div className="u-padding-right">
                    <Button long onClick={() => close()}>
                        No
                    </Button>
                    <Button
                        long
                        purpleGrad
                        onClick={() => {
                            updateProductConcept(
                                record_id,
                                data['approval_code']
                            );
                            close(true);
                        }}
                    >
                        Yes
                    </Button>
                </div>
            </div>
        );

        const {
            product_name,
            confirmed_by,
            product_description,
            approval_status,
            approval_stage
        } = data;

        return (
            <Fragment>
                <div className="c-opa-product">
                    {licensor && (
                        <div className="c-opa-product__concept-approval-status">
                            <Badge
                                green={approval_status === 'Fully Approved'}
                                orange={approval_status !== 'Fully Approved'}
                                text="Status"
                                value={approval_status}
                            />


                            <Badge
                                text="Stage"
                                value={approval_stage}
                            />
                        </div>
                    )}
                    <div className="c-opa-product__top-block u-margin-bottom">
                        <div className="c-opa-product__back-button-top">
                            <Button onClick={() => close()} square>
                                <span>
                                    <Icon
                                        icon="arrow_left"
                                        left
                                        width="13"
                                        height="13"
                                        top={2}
                                    />{' '}
                                    Back
                                </span>
                            </Button>
                        </div>
                        <div className="c-opa-product__concept-title-top">
                            <h2>Link Product Concept</h2>
                        </div>
                        <div
                            className="c-opa-product__close"
                            onClick={() => close()}
                        >
                            <Icon
                                icon="close2"
                                fill="black"
                                height="20"
                                width="20"
                            />
                        </div>
                    </div>

                    <div>
                        <ImagesBlock data={data} centered />
                    </div>

                    <div className="c-product__concept-detail-listing">
                        <h3 className="u-margin-bottom u-color-violet-2">
                            Concept Information
                        </h3>
                        <h2 className="u-padding-top u-padding-bottom u-color-black u-capitalize">
                            {product_name}
                        </h2>
                        {product_description && (
                            <p className="u-margin-bottom">
                                {product_description}
                            </p>
                        )}
                        <ul
                            style={{ maxHeight: newHeight, overflow: 'auto' }}
                            className="u-no-padding"
                        >
                            {conceptFields.map((e, i) => (
                                <li
                                    key={`concept-field-${e.field}-${i}`}
                                    className="c-product__detail-listing-item"
                                >
                                    <b className="u-capitalize">{e.field === 'licensor_category_path' ? categoryLevels[0] : e.label}</b>:
                                    &nbsp;
                                    {
                                        e.field === 'licensor_category_path'
                                            ? !!conceptData[e.field] && conceptData[e.field][0] || ''
                                            : conceptData[e.field]}
                                </li>
                            ))}
                        </ul>
                        {!confirmed_by && confirm}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SelectedProduct;
