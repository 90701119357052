import React, { Component } from 'react';

export class IngestionStatusMessage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    let hasIngestions = this.props.ingestions.length > 0;
    let hasActiveIngestions = false
    let hasRowsWritten = false;

    this.props.ingestions.forEach((ingestion) => {
      if (ingestion.completedAt == null) hasActiveIngestions = true;
      if (ingestion.recordsSavedEs > 0) hasRowsWritten = true;
    })

    if (!hasIngestions) return null

    return (
      <div className="c-file-upload__progress-tracker-wrapper">
        <div className="c-file-upload__progress-tracker">

          {hasActiveIngestions && (
            <div>
              Processing records &nbsp;
              <span className="u-padding-half-left">
                <span className="c-file-upload__progress-tracker-spinner">
                  ↻
                </span>
              </span>
            </div>
          )}

          {(hasRowsWritten) && (
            <div
              className="c-file-upload__progress-tracker-footer"
            >
              Records may be automatically refreshed multiple times until your data is ready.
            </div>
          )}
        </div>
      </div >
    );
  }
}
