export const navList = [
    {
        key: 'home',
        name: 'Home',
        href: '/intro',
        restrictTo: 'licensee'
    },
    {
        key: 'dashboard',
        name: 'Dashboard',
        href: '/',
        restrictTo: 'agent'
    },
    {
        key: 'dashboard',
        name: 'Dashboard',
        href: '/',
        restrictTo: 'licensee'
    },
    {
        key: 'dashboard',
        name: 'Dashboard',
        href: '/',
        restrictTo: 'licensor'
    },
    {
        key: 'my-records',
        href: '/my-records',
        name: 'My Records',
        restrictTo: 'licensee',
        permission: 'registration'
    },
    {
        key: 'product-catalogue',
        href: '/product-catalogue',
        name: 'Product Catalogue'
    },
    {
        key: 'royalty-reporting',
        href: '/royalty-reporting',
        name: 'Royalty Reporting',
        restrictTo: 'licensee',
        permission: 'royalty_report'
    },
    {
        key: 'retailer-listings',
        href: '/retailer-listings',
        name: 'Retailer Listings',
        restrictTo: 'licensor',
        permission: 'retailer_listings'
    },
    {
        key: 'royalty-report-assistant',
        href: '/royalty-report-assistant',
        name: 'Royalty Report Co-Pilot',
        restrictTo: 'licensee',
        permission: 'licensee_royalty_assistant'
    }
];
