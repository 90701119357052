import get from 'lodash/get';
import without from 'lodash/without';
import uniq from 'lodash/uniq';
import {
    NEW_UPLOAD,
    COMPLETED_UPLOAD,
    BULK_IMAGES_UPLOAD,
    RESET_BULK_IMAGES_UPLOAD,
    UPDATE_BULK_IMAGES_COUNTER
} from 'src/js/constants/actions/dataManager';

export function dataManager(state = { bulkImagesUpload: {} }, action = {}) {
    // TODO: Implement these!
    switch (action.type) {
        case NEW_UPLOAD:
            return Object.assign({}, state, {
                fileUploadList: uniq(
                    [].concat.apply(get(state, 'fileUploadList', []), [
                        action.fileUploadList
                    ])
                )
            });
        case COMPLETED_UPLOAD:
            return Object.assign({}, state, {
                fileUploadList: without(
                    get(state, 'fileUploadList', []),
                    ...action.fileUploadList
                )
            });
        case BULK_IMAGES_UPLOAD: {
            const {
                recordId,
                filesTotalToUpload,
                filesLeftToUploadCount
            } = action.payload;
            return {
                ...state,
                bulkImagesUpload: {
                    ...state.bulkImagesUpload,
                    [recordId]: {
                        filesTotalToUpload,
                        filesLeftToUploadCount
                    }
                }
            };
        }
        case RESET_BULK_IMAGES_UPLOAD: {
            const { recordId } = action.payload;
            return {
                ...state,
                bulkImagesUpload: {
                    ...state.bulkImagesUpload,
                    [recordId]: {
                        filesTotalToUpload: [],
                        filesLeftToUploadCount: 0
                    }
                }
            };
        }
        case UPDATE_BULK_IMAGES_COUNTER: {
            const { recordId } = action.payload;
            return {
                ...state,
                bulkImagesUpload: {
                    ...state.bulkImagesUpload,
                    [recordId]: {
                        ...state.bulkImagesUpload[recordId],
                        filesLeftToUploadCount:
                            state.bulkImagesUpload[recordId]
                                .filesLeftToUploadCount + 1
                    }
                }
            };
        }
        default:
            return state;
    }
}
