import map from 'lodash/map';
import debounce from 'lodash/debounce';
// import {upload} from 'src/js/apicalls/fileManager';
import { upload } from 'src/js/apicalls/files/fileUpload';

/**
 * A helper to upload files against the upload APi call.
 *
 * It wraps an upload call with the category and the path that needs to be used.
 * The returned call curries the above arguments, and expects a list of files,
 * the auth token, and the callbacks to use in order to track the uploading
 * progress.
 *
 * The return of the curry call, is a promise. Each result of the promise is a
 * FileUpload instance, that has been boorstraped and ready to run the uplaod
 * progress. Through the FileUpload you may also cancel the ongoing request, and
 * also track the progress of the file.
 *
 * Example of Use:
 *
 * ```
 * const uploader = Uploader('inbound', '/', {});
 *
 * uploader({authToken, files:[file], function onDone ({index, name, result}}) {
 *   // this will fire once the upload process is done.
 * }, function onProgress ({index, name, progress, event}) {
 *   // This will be called on each progress update, the progress var holds the
 *   // value of the percentage.
 * }, function onCancel ({index, name, err}) {
 *   // Happens when the progress has been error or canceled by the cancel call
 * }).then((fileUploadList) => {
 *   return Promise.all(fileUploadList.map((fileUpload) => fileUpload.run()));
 * }).then((fileUploadList) => {
 *   // upload is done, check
 *   // fileUploadList[0].isDone and fileUploadList[0].result or
 *   // fileUploadList[0].isCanceled and fileUploadList[0].error.
 *   // or rely on the callbacks that you have defined above
 * });
 *
 * ...later in the view...
 *
 * const uploadView = fileUploadList.map((fileUpload) => {
 *   return (
 *     <div>
 *       <span>{fileUpload.name}</span>
 *       <bar id="uploadBar" progress={fileUpload.progress} />
 *       <buton id="cancelBtn" disabled={fileUpload.isFinished} onClick={() => fileUpload.cancel()} />
 *     </div>
 *   );
 * });
 * ```
 *
 * @param {String} bucket   The bucket to aim for the upload
 * @param {String} filePath The file path to use for the request
 * @param {Object} opts     Arguments to pass to the Axios Request
 * @return {Function}
 */
// export default function Uploader (bucket, filePath, opts) { //bucket is the category, filePath is the filename
export default function Uploader(category) {
    // bucket is the category, filePath is the filename
    /**
     * The Curry function that will be used to create the FileUpload instances
     *
     * @param  {File[]} files A list of File instances
     * @param  {Function} onDone   The callback to use when a file is done
     * @param  {Function} onProgress A callback to use to notify for progress events
     * @param  {Function} onCancel   A callback to use when a cancellation happens
     * @return {Promise(FileUploadList)}
     */
    return ({ files, onDone, onProgress, onCancel }) => {
        const filesList = [].concat(files);

        return Promise.all(
            map(filesList, (file, index) => {
                const fileUpload = new FileUpload(index, category, file); // remove filepath
                // const fileUpload = new FileUpload(token, index, bucket, filePath, file, newOpts); // remove filepath

                fileUpload.onDone = onDone || (() => {});
                fileUpload.onProgress = onProgress || (() => {});
                fileUpload.onCancel = onCancel || (() => {});

                return fileUpload;
            })
        );
    };
}

class FileUpload {
    constructor(index, category, file) {
        this.category = category;
        // this.filePath = filePath;
        this.file = file;
        this.index = index;
        this.name = file.name;
        this.isUploading = false;
        this.isDone = false;
        this.isCanceled = false;
        this.isFinished = false;
    }

    // Passing through the calculated progress
    handleState(state, value) {
        switch (state) {
            case 'done':
                return this.onDone(value);
            case 'progress':
                return this.onProgress(value);
            case 'cancel':
                return this.onCancel(value);
        }
    }

    run() {
        if (this.isUploading)
            return Promise.reject(`File: ${this.name} is being uploaded.`);
        if (this.isDone)
            return this.handleState('done', {
                index: this.index,
                name: this.name,
                result: this.result
            });

        this.isCanceled = false;
        this.isUploading = true;
        this.progress = 0;
        this.total = 0;
        this.uploaded = 0;
        this.speed = 0;
        this.timeRemaining = +Infinity;
        this.error = null;
        this.result = null;
        this.started = Date.now();

        return upload(
            this.category,
            this.file,
            (progressEvent) => {
                const now = Date.now();
                const elapsed = now - this.started;

                if (progressEvent.lengthComputable) {
                    this.total = progressEvent.total;
                    this.uploaded = progressEvent.loaded;
                    this.speed = this.uploaded / (elapsed / 1000);
                    this.timeRemaining =
                        (this.total - this.uploaded) / this.speed;
                    this.progress = Math.round(
                        (this.uploaded * 100) / this.total
                    );
                }

                this.handleState('progress', {
                    index: this.index,
                    name: this.name,
                    progress: this.progress,
                    uploaded: this.uploaded,
                    speed: this.speed,
                    timeRemaining: this.timeRemaining,
                    event: progressEvent
                });
            },
            (cancel) => {
                this.cancel = debounce(cancel, 200, { trailing: true });
            }
        )
            .then((result) => {
                this.isUploading = false;
                this.isDone = true;
                this.isFinished = true;
                this.result = result;
                this.progress = 100;
                this.cancel = () => {};

                this.handleState('done', {
                    index: this.index,
                    name: this.name,
                    result: this.result
                });
            })
            .catch((err) => {
                this.isUploading = false;
                this.isCanceled = true;
                this.isFinished = true;
                this.error = err;
                this.cancel = () => {};

                this.handleState('cancel', {
                    index: this.index,
                    name: this.name,
                    err: err
                });
            })
            .then(() => this);
    }

    cancel() {}

    onDone() {}

    onProgress() {}

    onCancel() {}
}
