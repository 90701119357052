import axios from 'axios';
import config from 'config';
import responseHandler from '../common/responseHandler';

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {String} id
 * @return {Promise}
 */
export function uploadThatImage(data) {
    return axios
        .request({
            url: [config.urls.catalog, 'images', 'upload'].join('/'),
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {String} id
 * @return {Promise}
 */
export function uploadThatUsersImage(data) {
    return axios
        .request({
            url: [config.urls.auth, 'user', 'image'].join('/'),
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            data
        })
        .then(responseHandler);
}

/**
 * This API call Lists previous uploads to the account
 *
 * @param  {String} id
 * @return {Promise}
 */
export function publishThatImage(record_id, data) {
    return axios
        .request({
            url: [
                config.urls.catalog,
                'images',
                record_id,
                'privacy',
                'public'
            ].join('/'),
            method: 'patch',
            data
        })
        .then(responseHandler);
}