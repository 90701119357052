import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import MyRecordsPageNotification from './components/MyRecordsPageNotification';
import {
    setPageNotification,
    destroyPageNotification
} from 'src/js/actions/xelacore';

@withRouter
class PageNotification extends Component {
    constructor(props) {
        super(props);
    }

    setPath() {
        return this.props.location.pathname.replace(/^\//, '');
    }

    /**
     * Method for closing opened view IngestBars + deletion Closed and finished IngestBars
     */
    checkMyRecordsNotification(pageNotification, path) {
        const { toggleNotification, destroyNotification } = this.props;
        const myRecordNotification =
            pageNotification && pageNotification['my-records'];
        if (!myRecordNotification) return null;
        if (
            myRecordNotification &&
            path !== 'my-records' &&
            myRecordNotification.showBar
        ) {
            toggleNotification(myRecordNotification, true);
        }
        if (!myRecordNotification.showBar && !myRecordNotification.count) {
            destroyNotification();
            return null;
        }
    }
    render() {
        const {
            xelacore: { globalNotification, pageNotification } = {},
            toggleNotification
        } = this.props;
        const path = this.setPath();
        const isAnyGlobal = globalNotification && globalNotification.message;
        const notification = pageNotification[path];
        this.checkMyRecordsNotification(pageNotification, path);
        if (!notification) return null;
        if (path === 'my-records' && notification.showBar) return null;

        const cx = classnames('c-local-notification', {
            'u-margin-top-2x': isAnyGlobal
        });

        return (
            <div className={cx}>
                <div className="c-local-notification__notification-text">
                    {path === 'my-records' && (
                        <MyRecordsPageNotification
                            notification={notification}
                            toggleNotification={() =>
                                toggleNotification(notification)
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { xelacore } = state;
    return { xelacore };
}

const mapDispatchToProps = dispatch => ({
    destroyNotification() {
        dispatch(destroyPageNotification({ type: 'my-records' }));
    },
    toggleNotification(notification, close) {
        notification.showBar = close ? false : !notification.showBar;
        const payload = {
            type: 'my-records',
            'my-records': notification
        };
        dispatch(setPageNotification(payload));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageNotification);
