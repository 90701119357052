import { fetchProductInformation } from 'src/js/apicalls/mixed/myRecords';
import {
    fetchAllConflicts,
    fetchConflict,
    fetchQuarantine,
    deleteTheConflicts,
    mergeConflictsMpn,
    deleteSets,
    getConflictsCount
} from 'src/js/apicalls/conflicts/conflicts';

export function getAllConflicts(pagination) {
    const q = {
        conflicts: { gte: 1 }
    };
    return fetchProductInformation('records', q, pagination);
}

export function getConflicts(licensorId) {
    return () => {
        return fetchAllConflicts(licensorId);
    };
}

export function conflictsCount(licensorId) {
    return () => {
        return getConflictsCount(licensorId);
    };
}

export function getConflict(id, self=false) {
    if (!id)
        return Promise.reject('No product was sent through you filthy human!');
    return () => {
        return fetchConflict(id, self);
    };
}

export function getQuarantine(id, self=false) {
    if (!id)
        return Promise.reject('No product was sent through you filthy human!');
    return () => {
        return fetchQuarantine(id, self);
    };
}

export function deleteConflicts(id, record_ids) {
    if (!id)
        return Promise.reject(
            'No product was sent through you filthy human! How can i delete without any id!?'
        );
    if (!record_ids)
        return Promise.reject(
            'No product ids was sent through you filthy human! How can i delete without any id!?'
        );
    return deleteTheConflicts(id, { record_ids });
}

export function deleteConflictSets(record_ids) {
    if (!record_ids)
        return Promise.reject(
            'No product ids was sent through you filthy human! How can i delete without any id!?'
        );
    return deleteSets({ record_ids });
}

export function mergeConflicts(id, ids) {
    if (!id)
        return Promise.reject('No record was sent through you filthy human!');
    if (!ids)
        return Promise.reject(
            'No record id was sent through you filthy human!'
        );
    return mergeConflictsMpn(id, { ids });
}
